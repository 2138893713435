const industries = [
  {
    value: '0001',
    label: '信息技术',
    children: [
      {
        value: '000101',
        label: '计算机软件',
      },
      {
        value: '000102',
        label: '硬件设施服务'
      },
      {
        value: '000103',
        label: '电子商务',
      },
      {
        value: '000104',
        label: '游戏'
      },
      {
        value: '000105',
        label: '企业应用',
      },
      {
        value: '000106',
        label: '即时通讯'
      },
      {
        value: '000107',
        label: '影音视频',
      },
      {
        value: '000108',
        label: '社交网络'
      },
      {
        value: '000109',
        label: '网络安全',
      },
      {
        value: '000110',
        label: '软件开发'
      },
      {
        value: '000111',
        label: '系统集成',
      },
      {
        value: '000112',
        label: '电信运营服务'
      },
      {
        value: '000113',
        label: '广播电视传输',
      },
      {
        value: '00024',
        label: '卫星传输服务'
      },
      {
        value: '000114',
        label: '其他软件服务',
      },
      {
        value: '000115',
        label: '其他互联网'
      },
    ]
  },
  {
    value: '0002',
    label: '教育行业',
    children: [
      {
        value: '000201',
        label: '学前教育',
      },
      {
        value: '000202',
        label: '初中等教育'
      },
      {
        value: '000204',
        label: '中等职业教育',
      },
      {
        value: '000205',
        label: '高等职业教育'
      },
      {
        value: '000206',
        label: '高等教育',
      },
      {
        value: '000207',
        label: '教育培训机构'
      },
      {
        value: '000208',
        label: '技能培训',
      },
      {
        value: '000209',
        label: '在线教育'
      },
      {
        value: '000210',
        label: '特殊教育'
      },
      {
        value: '000211',
        label: '教育服务机构',
      },
      {
        value: '000212',
        label: '教育行政机构'
      },
      {
        value: '000213',
        label: '课后服务',
      },
      {
        value: '000214',
        label: '其他教育'
      },
    ]
  },
  {
    value: '0003',
    label: '制造业',
    children: [
      {
        value: '000301',
        label: '日用/化妆品',
      },
      {
        value: '000302',
        label: '食品/饮料'
      },
      {
        value: '000303',
        label: '服装服饰'
      },
      {
        value: '000304',
        label: '家电/数码'
      },
      {
        value: '000305',
        label: '奢侈品/珠宝'
      },
      {
        value: '000306',
        label: '酒品'
      },
      {
        value: '000307',
        label: '烟草业'
      },
      {
        value: '000308',
        label: '办公/工艺品'
      },
      {
        value: '000309',
        label: '家具',
      },
      {
        value: '000310',
        label: '化学纤维',
      },
      {
        value: '000312',
        label: '金属制品'
      },
      {
        value: '000313',
        label: '汽车交通',
      },
      {
        value: '000314',
        label: '通信/计算机'
      },
      {
        value: '000315',
        label: '电子/半导体',
      },
      {
        value: '000316',
        label: '纺织业'
      },
      {
        value: '000317',
        label: '皮/革/毛/羽/鞋',
      },
      {
        value: '000318',
        label: '木材草编制造'
      },
      {
        value: '000319',
        label: '石化/炼焦/燃料',
      },
      {
        value: '000320',
        label: '造纸和纸制品'
      },
      {
        value: '000321',
        label: '化工原料制品',
      },
      {
        value: '000322',
        label: '橡胶和塑料'
      },
      {
        value: '000323',
        label: '废弃资源利用',
      },
      {
        value: '000324',
        label: '金属/机械修理'
      },
      {
        value: '000325',
        label: '非金属矿物',
      },
      {
        value: '000326',
        label: '黑色金属冶炼'
      },
      {
        value: '000327',
        label: '有色金属冶炼',
      },
      {
        value: '000328',
        label: '通用设备制造'
      },
      {
        value: '000329',
        label: '专用设备制造',
      },
      {
        value: '000330',
        label: '其他运输设备'
      },
      {
        value: '000331',
        label: '电气机械/器材',
      },
      {
        value: '000332',
        label: '仪器仪表制造'
      },
      {
        value: '000333',
        label: '其他制造业',
      }
    ]
  },
  {
    value: '0004',
    label: '贸易/批发/零售',
    children: [
      {
        value: '000401',
        label: '临时促销',
      },
      {
        value: '000402',
        label: '进出口',
      },
      {
        value: '000403',
        label: '批发',
      },
      {
        value: '000404',
        label: '商店/超市',
      },

      {
        value: '00045',
        label: '电视/电话销售',
      },
      {
        value: '00046',
        label: '零售',
      },
      {
        value: '00047',
        label: '零售服务',
      },
      {
        value: '00048',
        label: '其他贸易',
      },
    ]
  },
  {
    value: '0005',
    label: '房地产业',
    children: [
      {
        value: '000501',
        label: '房地产开发',
      },
      {
        value: '000502',
        label: '房地产中介',
      },
      {
        value: '000503',
        label: '房地产代理',
      },
      {
        value: '000504',
        label: '房地产策划',
      },
      {
        value: '000505',
        label: '房地产租赁',
      },
      {
        value: '000506',
        label: '物业管理',
      },
      {
        value: '000507',
        label: '商业地产',
      },
      {
        value: '000508',
        label: '住宅地产',
      },
      {
        value: '000509',
        label: '工业地产',
      },
      {
        value: '000510',
        label: '养老地产',
      },
      {
        value: '000511',
        label: '产业园区',
      },
      {
        value: '000512',
        label: '地产行政机构',
      },
      {
        value: '000513',
        label: '地产服务机构',
      },
      {
        value: '000514',
        label: '其他地产',
      }
    ]
  },
  {
    value: '0006',
    label: '建筑业',
    children: [
      {
        value: '000601',
        label: '建筑业',
      },
      {
        value: '000602',
        label: '建筑设计',
      },
      {
        value: '000603',
        label: '建筑建材',
      },
      {
        value: '000604',
        label: '家居建材',
      },
      {
        value: '000605',
        label: '建筑行政机构',
      },
      {
        value: '000606',
        label: '建筑服务机构',
      },
      {
        value: '000607',
        label: '房屋建筑',
      },
      {
        value: '000608',
        label: '土木工程',
      },
      {
        value: '000609',
        label: '建筑安装',
      },
      {
        value: '000610',
        label: '装修装演',
      },
      {
        value: '000611',
        label: '其他建筑业',
      },
    ]
  },
  {
    value: '0007',
    label: '金融业',
    children: [
      {
        value: '000701',
        label: '银行',
      },
      {
        value: '000702',
        label: '保险',
      },
      {
        value: '000703',
        label: '证券',
      },
      {
        value: '000704',
        label: '投资',
      },
      {
        value: '000705',
        label: '基金',
      },
      {
        value: '000706',
        label: '互联网金融',
      },
      {
        value: '000707',
        label: '其他金融业',
      }
    ]
  },
  {
    value: '0008',
    label: '服务业',
    children: [
      {
        value: '000801',
        label: '连锁酒店',
      },
      {
        value: '000802',
        label: '普通酒店',
      },
      {
        value: '000803',
        label: '其他酒店住宿',
      },
      {
        value: '000804',
        label: '普通餐饮',
      },
      {
        value: '000805',
        label: '连锁餐饮',
      },
      {
        value: '000806',
        label: '外卖餐饮',
      },
      {
        value: '000807',
        label: '其他餐饮',
      },
      {
        value: '000808',
        label: '旅游景点/场所',
      },
      {
        value: '000809',
        label: '娱乐健身',
      },
      {
        value: '000810',
        label: '家政服务',
      },
      {
        value: '000811',
        label: '中介代理',
      },

      {
        value: '000812',
        label: '网吧',
      },
      {
        value: '000813',
        label: '出行交通服务',
      },
      {
        value: '000814',
        label: '旅行社',
      },
      {
        value: '000815',
        label: '其他旅游服务',
      },
      {
        value: '000816',
        label: '客票服务',
      },
      {
        value: '000817',
        label: '其他服务业',
      }
    ]
  },
  {
    value: '0009',
    label: '运输/物流/仓储',
    children: [
      {
        value: '000901',
        label: '仓储业',
      },
      {
        value: '000902',
        label: '道路运输业',
      },
      {
        value: '000903',
        label: '铁路运输业',
      },
      {
        value: '000904',
        label: '水上运输业',
      },
      {
        value: '000905',
        label: '航空运输业',
      },
      {
        value: '000906',
        label: '管道运输业',
      },
      {
        value: '000907',
        label: '其他运输业',
      },
      {
        value: '000908',
        label: '装卸搬运业',
      },
      {
        value: '000909',
        label: '运输代理业',
      },
      {
        value: '000910',
        label: '客票代理业',
      },
      {
        value: '000911',
        label: '邮政服务',
      },
      {
        value: '000912',
        label: '快递服务',
      },
      {
        value: '000913',
        label: '配送揽收服务',
      },
      {
        value: '000914',
        label: '其他快递服务',
      }
    ]
  },
  {
    value: '0010',
    label: '文体/娱乐/传媒',
    children: [
      {
        value: '001001',
        label: '新闻',
      },
      {
        value: '001002',
        label: '广告/公关',
      },
      {
        value: '001003',
        label: '报纸/杂志',
      },
      {
        value: '001004',
        label: '广播',
      },
      {
        value: '001005',
        label: '影视',
      },
      {
        value: '001006',
        label: '出版',
      },
      {
        value: '001007',
        label: '文化艺术业',
      },
      {
        value: '001008',
        label: '体育',
      },
      {
        value: '001009',
        label: '动漫',
      },
      {
        value: '001010',
        label: '媒体',
      },
      {
        value: '001011',
        label: '其他文体',
      },
      {
        value: '001012',
        label: '其他娱乐',
      },
      {
        value: '001013',
        label: '其他传媒',
      }
    ]
  },
  {
    value: '0011',
    label: '商业服务/租赁',
    children: [
      {
        value: '001101',
        label: '会计审计服务',
      },
      {
        value: '001102',
        label: '人力资源服务',
      },
      {
        value: '001103',
        label: '管理咨询服务',
      },
      {
        value: '001104',
        label: '法律服务',
      },
      {
        value: '001105',
        label: '检测认证服务',
      },
      {
        value: '001106',
        label: '翻译服务',
      },
      {
        value: '001107',
        label: '咨询调查服务',
      },
      {
        value: '001108',
        label: '知识产权服务',
      },
      {
        value: '001109',
        label: '安全保护服务',
      },
      {
        value: '001110',
        label: '其他商务服务',
      },
      {
        value: '001111',
        label: '机械设备租赁',
      },
      {
        value: '001112',
        label: '文化用品租赁',
      },
      {
        value: '001113',
        label: '其他租赁',
      }
    ]
  },
  {
    value: '0012',
    label: '医疗医药',
    children: [
      {
        value: '001201',
        label: '基层医疗卫生机构',
      },
      {
        value: '001202',
        label: '民营医院',
      },
      {
        value: '001203',
        label: '公立医院',
      },
      {
        value: '001204',
        label: '社区医院',
      },
      {
        value: '001205',
        label: '医疗软件',
      },
      {
        value: '001206',
        label: '美容整形',
      },
      {
        value: '001207',
        label: '药品器械',
      },
      {
        value: '001208',
        label: '健康管理',
      },
      {
        value: '001209',
        label: '药店连锁',
      },
      {
        value: '001210',
        label: '医疗创新',
      },
      {
        value: '001211',
        label: '疾病防控机构',
      },
      {
        value: '001212',
        label: '护理休养机构',
      }
    ]
  },
  {
    value: '0013',
    label: '政府/事业单位',
    children: [
      {
        value: '001301',
        label: '党群/党委',
      },
      {
        value: '0013012',
        label: '党群/纪委',
      },
      {
        value: '001303',
        label: '党群/团委',
      },
      {
        value: '001304',
        label: '民生/民政',
      },
      {
        value: '001305',
        label: '民生/文化',
      },
      {
        value: '001306',
        label: '民生/广电',
      },
      {
        value: '001307',
        label: '民生/人社',
      },
      {
        value: '001308',
        label: '民生/交通',
      },
      {
        value: '001309',
        label: '民生/卫生',
      },
      {
        value: '001310',
        label: '政法/公安',
      },
      {
        value: '001311',
        label: '政法/检察院',
      },
      {
        value: '001312',
        label: '政法/法院',
      },
      {
        value: '001313',
        label: '政法/司法',
      },
      {
        value: '001314',
        label: '经发/发改委',
      },
      {
        value: '001315',
        label: '经发/经信委',
      },
      {
        value: '001316',
        label: '经发/商务局',
      },
      {
        value: '001317',
        label: '经发/统计局',
      },
      {
        value: '001318',
        label: '城建/规划',
      },
      {
        value: '001319',
        label: '城建/国土',
      },
      {
        value: '001320',
        label: '城建/建设',
      },
      {
        value: '001321',
        label: '监管/税务',
      },
      {
        value: '001322',
        label: '监管/海关',
      },
      {
        value: '001323',
        label: '监管/工商',
      },
      {
        value: '001324',
        label: '监管/环保',
      },
      {
        value: '001325',
        label: '监管/物价',
      },
      {
        value: '001326',
        label: '监管/药品',
      },
      {
        value: '001327',
        label: '市委/市政府',
      },
      {
        value: '001328',
        label: '县委/县政府',
      },
      {
        value: '001329',
        label: '省委/省政府',
      },
      {
        value: '001330',
        label: '镇级政府',
      },
      {
        value: '001331',
        label: '公共设施管理',
      },
      {
        value: '001332',
        label: '其他政府部门',
      },
      {
        value: '001333',
        label: '其他事业单位',
      },
    ]
  },
  {
    value: '0014',
    label: '社会组织',
    children: [
      {
        value: '001401',
        label: '公益机构',
      },
      {
        value: '001402',
        label: '行业协会',
      },
      {
        value: '001403',
        label: '宗教组织',
      },
      {
        value: '001404',
        label: '学生组织',
      },
      {
        value: '001405',
        label: '共青团',
      },
      {
        value: '001406',
        label: '国际机构',
      },
      {
        value: '001407',
        label: '非营利机构',
      },
      {
        value: '001408',
        label: '工会',
      },
      {
        value: '001409',
        label: '基金会',
      },
      {
        value: '001410',
        label: '其他社会组织',
      }
    ]
  },
  {
    value: '0015',
    label: '科研服务',
    children: [
      {
        value: '001501',
        label: '试验/研究所',
      },
      {
        value: '001502',
        label: '专业技术服务',
      },
      {
        value: '001503',
        label: '科技推广应用',
      }
    ]
  },
  {
    value: '0016',
    label: '公共/环境',
    children: [
      {
        value: '001601',
        label: '生态环境治理',
      },
      {
        value: '001602',
        label: '水利管理',
      }
    ]
  },
  {
    value: '0017',
    label: '居民服务',
    children: [
      {
        value: '001701',
        label: '居民服务业',
      },
      {
        value: '001702',
        label: '产品修理业',
      },
      {
        value: '001703',
        label: '其他服务业',
      },
    ]
  },
  {
    value: '0018',
    label: '开采业',
    children: [
      {
        value: '001801',
        label: '煤炭',
      },
      {
        value: '001802',
        label: '石油',
      },
      {
        value: '001803',
        label: '天然气',
      },
      {
        value: '001804',
        label: '炼化',
      },
      {
        value: '001805',
        label: '石化',
      },
      {
        value: '001806',
        label: '黑色金属',
      },
      {
        value: '001807',
        label: '有色金属',
      },
      {
        value: '001808',
        label: '非金属',
      },
      {
        value: '001809',
        label: '开采辅助活动',
      },
      {
        value: '001810',
        label: '其他采矿业',
      }
    ]
  },
  {
    value: '0019',
    label: '农/林/牧/渔',
    children: [
      {
        value: '001901',
        label: '农业',
      },
      {
        value: '001902',
        label: '林业',
      },
      {
        value: '001903',
        label: '畜牧业',
      },
      {
        value: '001904',
        label: '渔业',
      },
      {
        value: '001905',
        label: '农林牧渔服务',
      },
    ]
  },
  {
    value: '0020',
    label: '电/热/燃气/水供应',
    children: [
      {
        value: '002001',
        label: '电/热生产供应',
      },
      {
        value: '002002',
        label: '燃气生产供应',
      },
      {
        value: '002003',
        label: '水生产供应',
      }
    ]
  },
  {
    value: '0021',
    label: '其它',
    children: [],
  }
];

export default industries;