const list = [
    {
        id: "110000",
        name: "北京市",
        center: "116.405285,39.904989",
        level: 1,
        children: [
            {
                id: "110100",
                name: "北京城区",
                center: "116.405285,39.904989",
                level: 2,
                children: [
                    {
                        id: "110116",
                        name: "怀柔区",
                        center: "116.637122,40.324272",
                        level: 3
                    },
                    {
                        id: "110118",
                        name: "密云区",
                        center: "116.843352,40.377362",
                        level: 3
                    },
                    {
                        id: "110119",
                        name: "延庆区",
                        center: "115.985006,40.465325",
                        level: 3
                    },
                    {
                        id: "110109",
                        name: "门头沟区",
                        center: "116.105381,39.937183",
                        level: 3
                    },
                    {
                        id: "110113",
                        name: "顺义区",
                        center: "116.653525,40.128936",
                        level: 3
                    },
                    {
                        id: "110105",
                        name: "朝阳区",
                        center: "116.486409,39.921489",
                        level: 3
                    },
                    {
                        id: "110117",
                        name: "平谷区",
                        center: "117.112335,40.144783",
                        level: 3
                    },
                    {
                        id: "110115",
                        name: "大兴区",
                        center: "116.338033,39.728908",
                        level: 3
                    },
                    {
                        id: "110112",
                        name: "通州区",
                        center: "116.658603,39.902486",
                        level: 3
                    },
                    {
                        id: "110114",
                        name: "昌平区",
                        center: "116.235906,40.218085",
                        level: 3
                    },
                    {
                        id: "110102",
                        name: "西城区",
                        center: "116.366794,39.915309",
                        level: 3
                    },
                    {
                        id: "110101",
                        name: "东城区",
                        center: "116.418757,39.917544",
                        level: 3
                    },
                    {
                        id: "110108",
                        name: "海淀区",
                        center: "116.310316,39.956074",
                        level: 3
                    },
                    {
                        id: "110107",
                        name: "石景山区",
                        center: "116.195445,39.914601",
                        level: 3
                    },
                    {
                        id: "110106",
                        name: "丰台区",
                        center: "116.286968,39.863642",
                        level: 3
                    },
                    {
                        id: "110111",
                        name: "房山区",
                        center: "116.139157,39.735535",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "120000",
        name: "天津市",
        center: "117.190182,39.125596",
        level: 1,
        children: [
            {
                id: "120100",
                name: "天津城区",
                center: "117.190182,39.125596",
                level: 2,
                children: [
                    {
                        id: "120115",
                        name: "宝坻区",
                        center: "117.308094,39.716965",
                        level: 3
                    },
                    {
                        id: "120101",
                        name: "和平区",
                        center: "117.195907,39.118327",
                        level: 3
                    },
                    {
                        id: "120110",
                        name: "东丽区",
                        center: "117.313967,39.087764",
                        level: 3
                    },
                    {
                        id: "120112",
                        name: "津南区",
                        center: "117.382549,38.989577",
                        level: 3
                    },
                    {
                        id: "120102",
                        name: "河东区",
                        center: "117.226568,39.122125",
                        level: 3
                    },
                    {
                        id: "120118",
                        name: "静海区",
                        center: "116.925304,38.935671",
                        level: 3
                    },
                    {
                        id: "120105",
                        name: "河北区",
                        center: "117.201569,39.156632",
                        level: 3
                    },
                    {
                        id: "120116",
                        name: "滨海新区",
                        center: "117.654173,39.032846",
                        level: 3
                    },
                    {
                        id: "120119",
                        name: "蓟州区",
                        center: "117.407449,40.045342",
                        level: 3
                    },
                    {
                        id: "120114",
                        name: "武清区",
                        center: "117.057959,39.376925",
                        level: 3
                    },
                    {
                        id: "120103",
                        name: "河西区",
                        center: "117.217536,39.101897",
                        level: 3
                    },
                    {
                        id: "120104",
                        name: "南开区",
                        center: "117.164143,39.120474",
                        level: 3
                    },
                    {
                        id: "120117",
                        name: "宁河区",
                        center: "117.82828,39.328886",
                        level: 3
                    },
                    {
                        id: "120113",
                        name: "北辰区",
                        center: "117.13482,39.225555",
                        level: 3
                    },
                    {
                        id: "120106",
                        name: "红桥区",
                        center: "117.163301,39.175066",
                        level: 3
                    },
                    {
                        id: "120111",
                        name: "西青区",
                        center: "117.012247,39.139446",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "130000",
        name: "河北省",
        center: "114.502461,38.045474",
        level: 1,
        children: [
            {
                id: "130200",
                name: "唐山市",
                center: "118.175393,39.635113",
                level: 2,
                children: [
                    {
                        id: "130283",
                        name: "迁安市",
                        center: "118.701933,40.012108",
                        level: 3
                    },
                    {
                        id: "130227",
                        name: "迁西县",
                        center: "118.305139,40.146238",
                        level: 3
                    },
                    {
                        id: "130281",
                        name: "遵化市",
                        center: "117.965875,40.188616",
                        level: 3
                    },
                    {
                        id: "130284",
                        name: "滦州市",
                        center: "118.699547,39.744851",
                        level: 3
                    },
                    {
                        id: "130224",
                        name: "滦南县",
                        center: "118.681552,39.506201",
                        level: 3
                    },
                    {
                        id: "130209",
                        name: "曹妃甸区",
                        center: "118.446585,39.278277",
                        level: 3
                    },
                    {
                        id: "130225",
                        name: "乐亭县",
                        center: "118.905341,39.42813",
                        level: 3
                    },
                    {
                        id: "130229",
                        name: "玉田县",
                        center: "117.753665,39.887323",
                        level: 3
                    },
                    {
                        id: "130202",
                        name: "路南区",
                        center: "118.210821,39.615162",
                        level: 3
                    },
                    {
                        id: "130207",
                        name: "丰南区",
                        center: "118.110793,39.56303",
                        level: 3
                    },
                    {
                        id: "130203",
                        name: "路北区",
                        center: "118.174736,39.628538",
                        level: 3
                    },
                    {
                        id: "130208",
                        name: "丰润区",
                        center: "118.155779,39.831363",
                        level: 3
                    },
                    {
                        id: "130205",
                        name: "开平区",
                        center: "118.264425,39.676171",
                        level: 3
                    },
                    {
                        id: "130204",
                        name: "古冶区",
                        center: "118.45429,39.715736",
                        level: 3
                    }
                ]
            },
            {
                id: "130800",
                name: "承德市",
                center: "117.939152,40.976204",
                level: 2,
                children: [
                    {
                        id: "130828",
                        name: "围场满族蒙古族自治县",
                        center: "117.764086,41.949404",
                        level: 3
                    },
                    {
                        id: "130825",
                        name: "隆化县",
                        center: "117.736343,41.316667",
                        level: 3
                    },
                    {
                        id: "130826",
                        name: "丰宁满族自治县",
                        center: "116.65121,41.209903",
                        level: 3
                    },
                    {
                        id: "130827",
                        name: "宽城满族自治县",
                        center: "118.488642,40.607981",
                        level: 3
                    },
                    {
                        id: "130804",
                        name: "鹰手营子矿区",
                        center: "117.661154,40.546956",
                        level: 3
                    },
                    {
                        id: "130822",
                        name: "兴隆县",
                        center: "117.507098,40.418525",
                        level: 3
                    },
                    {
                        id: "130881",
                        name: "平泉市",
                        center: "118.690238,41.00561",
                        level: 3
                    },
                    {
                        id: "130803",
                        name: "双滦区",
                        center: "117.797485,40.959756",
                        level: 3
                    },
                    {
                        id: "130824",
                        name: "滦平县",
                        center: "117.337124,40.936644",
                        level: 3
                    },
                    {
                        id: "130821",
                        name: "承德县",
                        center: "118.172496,40.768637",
                        level: 3
                    },
                    {
                        id: "130802",
                        name: "双桥区",
                        center: "117.939152,40.976204",
                        level: 3
                    }
                ]
            },
            {
                id: "131000",
                name: "廊坊市",
                center: "116.704441,39.523927",
                level: 2,
                children: [
                    {
                        id: "131081",
                        name: "霸州市",
                        center: "116.392021,39.117331",
                        level: 3
                    },
                    {
                        id: "131028",
                        name: "大厂回族自治县",
                        center: "116.986501,39.889266",
                        level: 3
                    },
                    {
                        id: "131025",
                        name: "大城县",
                        center: "116.640735,38.699215",
                        level: 3
                    },
                    {
                        id: "131022",
                        name: "固安县",
                        center: "116.299894,39.436468",
                        level: 3
                    },
                    {
                        id: "131024",
                        name: "香河县",
                        center: "117.007161,39.757212",
                        level: 3
                    },
                    {
                        id: "131023",
                        name: "永清县",
                        center: "116.498089,39.319717",
                        level: 3
                    },
                    {
                        id: "131026",
                        name: "文安县",
                        center: "116.460107,38.866801",
                        level: 3
                    },
                    {
                        id: "131003",
                        name: "广阳区",
                        center: "116.713708,39.521931",
                        level: 3
                    },
                    {
                        id: "131082",
                        name: "三河市",
                        center: "117.077018,39.982778",
                        level: 3
                    },
                    {
                        id: "131002",
                        name: "安次区",
                        center: "116.694544,39.502569",
                        level: 3
                    }
                ]
            },
            {
                id: "130300",
                name: "秦皇岛市",
                center: "119.586579,39.942531",
                level: 2,
                children: [
                    {
                        id: "130321",
                        name: "青龙满族自治县",
                        center: "118.954555,40.406023",
                        level: 3
                    },
                    {
                        id: "130303",
                        name: "山海关区",
                        center: "119.753591,39.998023",
                        level: 3
                    },
                    {
                        id: "130324",
                        name: "卢龙县",
                        center: "118.881809,39.891639",
                        level: 3
                    },
                    {
                        id: "130304",
                        name: "北戴河区",
                        center: "119.486286,39.825121",
                        level: 3
                    },
                    {
                        id: "130306",
                        name: "抚宁区",
                        center: "119.240651,39.887053",
                        level: 3
                    },
                    {
                        id: "130302",
                        name: "海港区",
                        center: "119.596224,39.943458",
                        level: 3
                    },
                    {
                        id: "130322",
                        name: "昌黎县",
                        center: "119.164541,39.709729",
                        level: 3
                    }
                ]
            },
            {
                id: "130700",
                name: "张家口市",
                center: "114.884091,40.811901",
                level: 2,
                children: [
                    {
                        id: "130723",
                        name: "康保县",
                        center: "114.615809,41.850046",
                        level: 3
                    },
                    {
                        id: "130708",
                        name: "万全区",
                        center: "114.736131,40.765136",
                        level: 3
                    },
                    {
                        id: "130732",
                        name: "赤城县",
                        center: "115.832708,40.912081",
                        level: 3
                    },
                    {
                        id: "130706",
                        name: "下花园区",
                        center: "115.281002,40.488645",
                        level: 3
                    },
                    {
                        id: "130731",
                        name: "涿鹿县",
                        center: "115.219246,40.378701",
                        level: 3
                    },
                    {
                        id: "130722",
                        name: "张北县",
                        center: "114.715951,41.151713",
                        level: 3
                    },
                    {
                        id: "130725",
                        name: "尚义县",
                        center: "113.977713,41.080091",
                        level: 3
                    },
                    {
                        id: "130730",
                        name: "怀来县",
                        center: "115.520846,40.405405",
                        level: 3
                    },
                    {
                        id: "130724",
                        name: "沽源县",
                        center: "115.684836,41.667419",
                        level: 3
                    },
                    {
                        id: "130709",
                        name: "崇礼区",
                        center: "115.281652,40.971302",
                        level: 3
                    },
                    {
                        id: "130705",
                        name: "宣化区",
                        center: "115.0632,40.609368",
                        level: 3
                    },
                    {
                        id: "130703",
                        name: "桥西区",
                        center: "114.882127,40.824385",
                        level: 3
                    },
                    {
                        id: "130702",
                        name: "桥东区",
                        center: "114.885658,40.813875",
                        level: 3
                    },
                    {
                        id: "130728",
                        name: "怀安县",
                        center: "114.422364,40.671274",
                        level: 3
                    },
                    {
                        id: "130726",
                        name: "蔚县",
                        center: "114.582695,39.837181",
                        level: 3
                    },
                    {
                        id: "130727",
                        name: "阳原县",
                        center: "114.167343,40.113419",
                        level: 3
                    }
                ]
            },
            {
                id: "130900",
                name: "沧州市",
                center: "116.857461,38.310582",
                level: 2,
                children: [
                    {
                        id: "130903",
                        name: "运河区",
                        center: "116.840063,38.307405",
                        level: 3
                    },
                    {
                        id: "130929",
                        name: "献县",
                        center: "116.123844,38.189661",
                        level: 3
                    },
                    {
                        id: "130925",
                        name: "盐山县",
                        center: "117.229814,38.056141",
                        level: 3
                    },
                    {
                        id: "130982",
                        name: "任丘市",
                        center: "116.106764,38.706513",
                        level: 3
                    },
                    {
                        id: "130927",
                        name: "南皮县",
                        center: "116.709171,38.042439",
                        level: 3
                    },
                    {
                        id: "130902",
                        name: "新华区",
                        center: "116.873049,38.308273",
                        level: 3
                    },
                    {
                        id: "130924",
                        name: "海兴县",
                        center: "117.496606,38.141582",
                        level: 3
                    },
                    {
                        id: "130930",
                        name: "孟村回族自治县",
                        center: "117.105104,38.057953",
                        level: 3
                    },
                    {
                        id: "130928",
                        name: "吴桥县",
                        center: "116.391512,37.628182",
                        level: 3
                    },
                    {
                        id: "130923",
                        name: "东光县",
                        center: "116.542062,37.88655",
                        level: 3
                    },
                    {
                        id: "130983",
                        name: "黄骅市",
                        center: "117.343803,38.369238",
                        level: 3
                    },
                    {
                        id: "130921",
                        name: "沧县",
                        center: "117.007478,38.219856",
                        level: 3
                    },
                    {
                        id: "130922",
                        name: "青县",
                        center: "116.838384,38.569646",
                        level: 3
                    },
                    {
                        id: "130981",
                        name: "泊头市",
                        center: "116.570163,38.073479",
                        level: 3
                    },
                    {
                        id: "130984",
                        name: "河间市",
                        center: "116.089452,38.44149",
                        level: 3
                    },
                    {
                        id: "130926",
                        name: "肃宁县",
                        center: "115.835856,38.427102",
                        level: 3
                    }
                ]
            },
            {
                id: "130600",
                name: "保定市",
                center: "115.482331,38.867657",
                level: 2,
                children: [
                    {
                        id: "130633",
                        name: "易县",
                        center: "115.501146,39.35297",
                        level: 3
                    },
                    {
                        id: "130609",
                        name: "徐水区",
                        center: "115.64941,39.020395",
                        level: 3
                    },
                    {
                        id: "130632",
                        name: "安新县",
                        center: "115.931979,38.929912",
                        level: 3
                    },
                    {
                        id: "130636",
                        name: "顺平县",
                        center: "115.132749,38.845127",
                        level: 3
                    },
                    {
                        id: "130628",
                        name: "高阳县",
                        center: "115.778878,38.690092",
                        level: 3
                    },
                    {
                        id: "130638",
                        name: "雄县",
                        center: "116.107474,38.990819",
                        level: 3
                    },
                    {
                        id: "130629",
                        name: "容城县",
                        center: "115.866247,39.05282",
                        level: 3
                    },
                    {
                        id: "130630",
                        name: "涞源县",
                        center: "114.692567,39.35755",
                        level: 3
                    },
                    {
                        id: "130626",
                        name: "定兴县",
                        center: "115.796895,39.266195",
                        level: 3
                    },
                    {
                        id: "130602",
                        name: "竞秀区",
                        center: "115.470659,38.88662",
                        level: 3
                    },
                    {
                        id: "130634",
                        name: "曲阳县",
                        center: "114.704055,38.619992",
                        level: 3
                    },
                    {
                        id: "130627",
                        name: "唐县",
                        center: "114.981241,38.748542",
                        level: 3
                    },
                    {
                        id: "130607",
                        name: "满城区",
                        center: "115.32442,38.95138",
                        level: 3
                    },
                    {
                        id: "130683",
                        name: "安国市",
                        center: "115.33141,38.421367",
                        level: 3
                    },
                    {
                        id: "130682",
                        name: "定州市",
                        center: "114.991389,38.517602",
                        level: 3
                    },
                    {
                        id: "130606",
                        name: "莲池区",
                        center: "115.500934,38.865005",
                        level: 3
                    },
                    {
                        id: "130623",
                        name: "涞水县",
                        center: "115.711985,39.393148",
                        level: 3
                    },
                    {
                        id: "130635",
                        name: "蠡县",
                        center: "115.583631,38.496429",
                        level: 3
                    },
                    {
                        id: "130637",
                        name: "博野县",
                        center: "115.461798,38.458271",
                        level: 3
                    },
                    {
                        id: "130684",
                        name: "高碑店市",
                        center: "115.882704,39.327689",
                        level: 3
                    },
                    {
                        id: "130681",
                        name: "涿州市",
                        center: "115.973409,39.485765",
                        level: 3
                    },
                    {
                        id: "130624",
                        name: "阜平县",
                        center: "114.198801,38.847276",
                        level: 3
                    },
                    {
                        id: "130608",
                        name: "清苑区",
                        center: "115.492221,38.771012",
                        level: 3
                    },
                    {
                        id: "130631",
                        name: "望都县",
                        center: "115.154009,38.707448",
                        level: 3
                    }
                ]
            },
            {
                id: "130400",
                name: "邯郸市",
                center: "114.490686,36.612273",
                level: 2,
                children: [
                    {
                        id: "130407",
                        name: "肥乡区",
                        center: "114.805154,36.555778",
                        level: 3
                    },
                    {
                        id: "130406",
                        name: "峰峰矿区",
                        center: "114.209936,36.420487",
                        level: 3
                    },
                    {
                        id: "130481",
                        name: "武安市",
                        center: "114.194581,36.696115",
                        level: 3
                    },
                    {
                        id: "130426",
                        name: "涉县",
                        center: "113.673297,36.563143",
                        level: 3
                    },
                    {
                        id: "130425",
                        name: "大名县",
                        center: "115.152586,36.283316",
                        level: 3
                    },
                    {
                        id: "130432",
                        name: "广平县",
                        center: "114.950859,36.483603",
                        level: 3
                    },
                    {
                        id: "130434",
                        name: "魏县",
                        center: "114.93411,36.354248",
                        level: 3
                    },
                    {
                        id: "130423",
                        name: "临漳县",
                        center: "114.610703,36.337604",
                        level: 3
                    },
                    {
                        id: "130431",
                        name: "鸡泽县",
                        center: "114.878517,36.914908",
                        level: 3
                    },
                    {
                        id: "130435",
                        name: "曲周县",
                        center: "114.957588,36.773398",
                        level: 3
                    },
                    {
                        id: "130403",
                        name: "丛台区",
                        center: "114.494703,36.611082",
                        level: 3
                    },
                    {
                        id: "130427",
                        name: "磁县",
                        center: "114.38208,36.367673",
                        level: 3
                    },
                    {
                        id: "130408",
                        name: "永年区",
                        center: "114.496162,36.776413",
                        level: 3
                    },
                    {
                        id: "130404",
                        name: "复兴区",
                        center: "114.458242,36.615484",
                        level: 3
                    },
                    {
                        id: "130424",
                        name: "成安县",
                        center: "114.680356,36.443832",
                        level: 3
                    },
                    {
                        id: "130402",
                        name: "邯山区",
                        center: "114.484989,36.603196",
                        level: 3
                    },
                    {
                        id: "130433",
                        name: "馆陶县",
                        center: "115.289057,36.539461",
                        level: 3
                    },
                    {
                        id: "130430",
                        name: "邱县",
                        center: "115.168584,36.81325",
                        level: 3
                    }
                ]
            },
            {
                id: "130100",
                name: "石家庄市",
                center: "114.502461,38.045474",
                level: 2,
                children: [
                    {
                        id: "130108",
                        name: "裕华区",
                        center: "114.533257,38.027696",
                        level: 3
                    },
                    {
                        id: "130123",
                        name: "正定县",
                        center: "114.569887,38.147835",
                        level: 3
                    },
                    {
                        id: "130131",
                        name: "平山县",
                        center: "114.184144,38.259311",
                        level: 3
                    },
                    {
                        id: "130110",
                        name: "鹿泉区",
                        center: "114.321023,38.093994",
                        level: 3
                    },
                    {
                        id: "130126",
                        name: "灵寿县",
                        center: "114.37946,38.306546",
                        level: 3
                    },
                    {
                        id: "130127",
                        name: "高邑县",
                        center: "114.610699,37.605714",
                        level: 3
                    },
                    {
                        id: "130183",
                        name: "晋州市",
                        center: "115.044886,38.027478",
                        level: 3
                    },
                    {
                        id: "130129",
                        name: "赞皇县",
                        center: "114.387756,37.660199",
                        level: 3
                    },
                    {
                        id: "130181",
                        name: "辛集市",
                        center: "115.217451,37.92904",
                        level: 3
                    },
                    {
                        id: "130107",
                        name: "井陉矿区",
                        center: "114.058178,38.069748",
                        level: 3
                    },
                    {
                        id: "130105",
                        name: "新华区",
                        center: "114.465974,38.067142",
                        level: 3
                    },
                    {
                        id: "130102",
                        name: "长安区",
                        center: "114.548151,38.047501",
                        level: 3
                    },
                    {
                        id: "130104",
                        name: "桥西区",
                        center: "114.462931,38.028383",
                        level: 3
                    },
                    {
                        id: "130132",
                        name: "元氏县",
                        center: "114.52618,37.762514",
                        level: 3
                    },
                    {
                        id: "130121",
                        name: "井陉县",
                        center: "114.144488,38.033614",
                        level: 3
                    },
                    {
                        id: "130109",
                        name: "藁城区",
                        center: "114.849647,38.033767",
                        level: 3
                    },
                    {
                        id: "130130",
                        name: "无极县",
                        center: "114.977845,38.176376",
                        level: 3
                    },
                    {
                        id: "130184",
                        name: "新乐市",
                        center: "114.68578,38.344768",
                        level: 3
                    },
                    {
                        id: "130128",
                        name: "深泽县",
                        center: "115.200207,38.18454",
                        level: 3
                    },
                    {
                        id: "130111",
                        name: "栾城区",
                        center: "114.654281,37.886911",
                        level: 3
                    },
                    {
                        id: "130133",
                        name: "赵县",
                        center: "114.775362,37.754341",
                        level: 3
                    },
                    {
                        id: "130125",
                        name: "行唐县",
                        center: "114.552734,38.437422",
                        level: 3
                    }
                ]
            },
            {
                id: "131100",
                name: "衡水市",
                center: "115.665993,37.735097",
                level: 2,
                children: [
                    {
                        id: "131123",
                        name: "武强县",
                        center: "115.970236,38.03698",
                        level: 3
                    },
                    {
                        id: "131121",
                        name: "枣强县",
                        center: "115.726499,37.511512",
                        level: 3
                    },
                    {
                        id: "131122",
                        name: "武邑县",
                        center: "115.892415,37.803774",
                        level: 3
                    },
                    {
                        id: "131102",
                        name: "桃城区",
                        center: "115.694945,37.732237",
                        level: 3
                    },
                    {
                        id: "131127",
                        name: "景县",
                        center: "116.258446,37.686622",
                        level: 3
                    },
                    {
                        id: "131126",
                        name: "故城县",
                        center: "115.966747,37.350981",
                        level: 3
                    },
                    {
                        id: "131182",
                        name: "深州市",
                        center: "115.554596,38.00347",
                        level: 3
                    },
                    {
                        id: "131128",
                        name: "阜城县",
                        center: "116.164727,37.869945",
                        level: 3
                    },
                    {
                        id: "131124",
                        name: "饶阳县",
                        center: "115.726577,38.232671",
                        level: 3
                    },
                    {
                        id: "131125",
                        name: "安平县",
                        center: "115.519627,38.233511",
                        level: 3
                    },
                    {
                        id: "131103",
                        name: "冀州区",
                        center: "115.579173,37.542788",
                        level: 3
                    }
                ]
            },
            {
                id: "130500",
                name: "邢台市",
                center: "114.508851,37.0682",
                level: 2,
                children: [
                    {
                        id: "130531",
                        name: "广宗县",
                        center: "115.142797,37.075548",
                        level: 3
                    },
                    {
                        id: "130522",
                        name: "临城县",
                        center: "114.506873,37.444009",
                        level: 3
                    },
                    {
                        id: "130582",
                        name: "沙河市",
                        center: "114.504902,36.861903",
                        level: 3
                    },
                    {
                        id: "130524",
                        name: "柏乡县",
                        center: "114.693382,37.483596",
                        level: 3
                    },
                    {
                        id: "130523",
                        name: "内丘县",
                        center: "114.511523,37.287663",
                        level: 3
                    },
                    {
                        id: "130529",
                        name: "巨鹿县",
                        center: "115.038782,37.21768",
                        level: 3
                    },
                    {
                        id: "130527",
                        name: "南和县",
                        center: "114.691377,37.003812",
                        level: 3
                    },
                    {
                        id: "130503",
                        name: "桥西区",
                        center: "114.473687,37.068009",
                        level: 3
                    },
                    {
                        id: "130525",
                        name: "隆尧县",
                        center: "114.776348,37.350925",
                        level: 3
                    },
                    {
                        id: "130534",
                        name: "清河县",
                        center: "115.668999,37.059991",
                        level: 3
                    },
                    {
                        id: "130532",
                        name: "平乡县",
                        center: "115.029218,37.069404",
                        level: 3
                    },
                    {
                        id: "130581",
                        name: "南宫市",
                        center: "115.398102,37.359668",
                        level: 3
                    },
                    {
                        id: "130530",
                        name: "新河县",
                        center: "115.247537,37.526216",
                        level: 3
                    },
                    {
                        id: "130521",
                        name: "邢台县",
                        center: "114.561132,37.05073",
                        level: 3
                    },
                    {
                        id: "130502",
                        name: "桥东区",
                        center: "114.507131,37.064125",
                        level: 3
                    },
                    {
                        id: "130526",
                        name: "任县",
                        center: "114.684469,37.129952",
                        level: 3
                    },
                    {
                        id: "130535",
                        name: "临西县",
                        center: "115.498684,36.8642",
                        level: 3
                    },
                    {
                        id: "130533",
                        name: "威县",
                        center: "115.272749,36.983272",
                        level: 3
                    },
                    {
                        id: "130528",
                        name: "宁晋县",
                        center: "114.921027,37.618956",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "140000",
        name: "山西省",
        center: "112.549248,37.857014",
        level: 1,
        children: [
            {
                id: "140300",
                name: "阳泉市",
                center: "113.583285,37.861188",
                level: 2,
                children: [
                    {
                        id: "140321",
                        name: "平定县",
                        center: "113.631049,37.800289",
                        level: 3
                    },
                    {
                        id: "140303",
                        name: "矿区",
                        center: "113.559066,37.870085",
                        level: 3
                    },
                    {
                        id: "140302",
                        name: "城区",
                        center: "113.586513,37.860938",
                        level: 3
                    },
                    {
                        id: "140311",
                        name: "郊区",
                        center: "113.58664,37.94096",
                        level: 3
                    },
                    {
                        id: "140322",
                        name: "盂县",
                        center: "113.41223,38.086131",
                        level: 3
                    }
                ]
            },
            {
                id: "140100",
                name: "太原市",
                center: "112.549248,37.857014",
                level: 2,
                children: [
                    {
                        id: "140109",
                        name: "万柏林区",
                        center: "112.522258,37.862653",
                        level: 3
                    },
                    {
                        id: "140123",
                        name: "娄烦县",
                        center: "111.793798,38.066035",
                        level: 3
                    },
                    {
                        id: "140110",
                        name: "晋源区",
                        center: "112.477849,37.715619",
                        level: 3
                    },
                    {
                        id: "140108",
                        name: "尖草坪区",
                        center: "112.487122,37.939893",
                        level: 3
                    },
                    {
                        id: "140107",
                        name: "杏花岭区",
                        center: "112.560743,37.879291",
                        level: 3
                    },
                    {
                        id: "140106",
                        name: "迎泽区",
                        center: "112.558851,37.855804",
                        level: 3
                    },
                    {
                        id: "140121",
                        name: "清徐县",
                        center: "112.357961,37.60729",
                        level: 3
                    },
                    {
                        id: "140122",
                        name: "阳曲县",
                        center: "112.673818,38.058797",
                        level: 3
                    },
                    {
                        id: "140105",
                        name: "小店区",
                        center: "112.564273,37.817974",
                        level: 3
                    },
                    {
                        id: "140181",
                        name: "古交市",
                        center: "112.174353,37.908534",
                        level: 3
                    }
                ]
            },
            {
                id: "141000",
                name: "临汾市",
                center: "111.517973,36.08415",
                level: 2,
                children: [
                    {
                        id: "141034",
                        name: "汾西县",
                        center: "111.563021,36.653368",
                        level: 3
                    },
                    {
                        id: "141024",
                        name: "洪洞县",
                        center: "111.673692,36.255742",
                        level: 3
                    },
                    {
                        id: "141028",
                        name: "吉县",
                        center: "110.682853,36.099355",
                        level: 3
                    },
                    {
                        id: "141025",
                        name: "古县",
                        center: "111.920207,36.26855",
                        level: 3
                    },
                    {
                        id: "141002",
                        name: "尧都区",
                        center: "111.522945,36.080366",
                        level: 3
                    },
                    {
                        id: "141031",
                        name: "隰县",
                        center: "110.935809,36.692675",
                        level: 3
                    },
                    {
                        id: "141030",
                        name: "大宁县",
                        center: "110.751283,36.46383",
                        level: 3
                    },
                    {
                        id: "141026",
                        name: "安泽县",
                        center: "112.251372,36.146032",
                        level: 3
                    },
                    {
                        id: "141032",
                        name: "永和县",
                        center: "110.631276,36.760614",
                        level: 3
                    },
                    {
                        id: "141029",
                        name: "乡宁县",
                        center: "110.857365,35.975402",
                        level: 3
                    },
                    {
                        id: "141027",
                        name: "浮山县",
                        center: "111.850039,35.971359",
                        level: 3
                    },
                    {
                        id: "141081",
                        name: "侯马市",
                        center: "111.371272,35.620302",
                        level: 3
                    },
                    {
                        id: "141021",
                        name: "曲沃县",
                        center: "111.475529,35.641387",
                        level: 3
                    },
                    {
                        id: "141082",
                        name: "霍州市",
                        center: "111.723103,36.57202",
                        level: 3
                    },
                    {
                        id: "141023",
                        name: "襄汾县",
                        center: "111.442932,35.876139",
                        level: 3
                    },
                    {
                        id: "141022",
                        name: "翼城县",
                        center: "111.713508,35.738621",
                        level: 3
                    },
                    {
                        id: "141033",
                        name: "蒲县",
                        center: "111.09733,36.411682",
                        level: 3
                    }
                ]
            },
            {
                id: "140800",
                name: "运城市",
                center: "111.003957,35.022778",
                level: 2,
                children: [
                    {
                        id: "140823",
                        name: "闻喜县",
                        center: "111.220306,35.353839",
                        level: 3
                    },
                    {
                        id: "140822",
                        name: "万荣县",
                        center: "110.843561,35.417042",
                        level: 3
                    },
                    {
                        id: "140802",
                        name: "盐湖区",
                        center: "111.000627,35.025643",
                        level: 3
                    },
                    {
                        id: "140830",
                        name: "芮城县",
                        center: "110.69114,34.694769",
                        level: 3
                    },
                    {
                        id: "140881",
                        name: "永济市",
                        center: "110.447984,34.865125",
                        level: 3
                    },
                    {
                        id: "140821",
                        name: "临猗县",
                        center: "110.77493,35.141883",
                        level: 3
                    },
                    {
                        id: "140828",
                        name: "夏县",
                        center: "111.223174,35.140441",
                        level: 3
                    },
                    {
                        id: "140829",
                        name: "平陆县",
                        center: "111.212377,34.837256",
                        level: 3
                    },
                    {
                        id: "140826",
                        name: "绛县",
                        center: "111.576182,35.49045",
                        level: 3
                    },
                    {
                        id: "140827",
                        name: "垣曲县",
                        center: "111.67099,35.298293",
                        level: 3
                    },
                    {
                        id: "140825",
                        name: "新绛县",
                        center: "111.225205,35.613697",
                        level: 3
                    },
                    {
                        id: "140824",
                        name: "稷山县",
                        center: "110.978996,35.600412",
                        level: 3
                    },
                    {
                        id: "140882",
                        name: "河津市",
                        center: "110.710268,35.59715",
                        level: 3
                    }
                ]
            },
            {
                id: "140500",
                name: "晋城市",
                center: "112.851274,35.497553",
                level: 2,
                children: [
                    {
                        id: "140581",
                        name: "高平市",
                        center: "112.930691,35.791355",
                        level: 3
                    },
                    {
                        id: "140502",
                        name: "城区",
                        center: "112.853106,35.496641",
                        level: 3
                    },
                    {
                        id: "140524",
                        name: "陵川县",
                        center: "113.278877,35.775614",
                        level: 3
                    },
                    {
                        id: "140525",
                        name: "泽州县",
                        center: "112.899137,35.617221",
                        level: 3
                    },
                    {
                        id: "140521",
                        name: "沁水县",
                        center: "112.187213,35.689472",
                        level: 3
                    },
                    {
                        id: "140522",
                        name: "阳城县",
                        center: "112.422014,35.482177",
                        level: 3
                    }
                ]
            },
            {
                id: "140400",
                name: "长治市",
                center: "113.113556,36.191112",
                level: 2,
                children: [
                    {
                        id: "140406",
                        name: "潞城区",
                        center: "113.223245,36.332233",
                        level: 3
                    },
                    {
                        id: "140405",
                        name: "屯留区",
                        center: "112.892741,36.314072",
                        level: 3
                    },
                    {
                        id: "140404",
                        name: "上党区",
                        center: "113.056679,36.052438",
                        level: 3
                    },
                    {
                        id: "140426",
                        name: "黎城县",
                        center: "113.387366,36.502971",
                        level: 3
                    },
                    {
                        id: "140427",
                        name: "壶关县",
                        center: "113.206138,36.110938",
                        level: 3
                    },
                    {
                        id: "140428",
                        name: "长子县",
                        center: "112.884656,36.119484",
                        level: 3
                    },
                    {
                        id: "140431",
                        name: "沁源县",
                        center: "112.340878,36.500777",
                        level: 3
                    },
                    {
                        id: "140425",
                        name: "平顺县",
                        center: "113.438791,36.200202",
                        level: 3
                    },
                    {
                        id: "140423",
                        name: "襄垣县",
                        center: "113.050094,36.532854",
                        level: 3
                    },
                    {
                        id: "140403",
                        name: "潞州区",
                        center: "113.114107,36.187896",
                        level: 3
                    },
                    {
                        id: "140429",
                        name: "武乡县",
                        center: "112.8653,36.834315",
                        level: 3
                    },
                    {
                        id: "140430",
                        name: "沁县",
                        center: "112.70138,36.757123",
                        level: 3
                    }
                ]
            },
            {
                id: "140900",
                name: "忻州市",
                center: "112.733538,38.41769",
                level: 2,
                children: [
                    {
                        id: "140925",
                        name: "宁武县",
                        center: "112.307936,39.001718",
                        level: 3
                    },
                    {
                        id: "140929",
                        name: "岢岚县",
                        center: "111.56981,38.705625",
                        level: 3
                    },
                    {
                        id: "140981",
                        name: "原平市",
                        center: "112.713132,38.729186",
                        level: 3
                    },
                    {
                        id: "140921",
                        name: "定襄县",
                        center: "112.963231,38.484948",
                        level: 3
                    },
                    {
                        id: "140926",
                        name: "静乐县",
                        center: "111.940231,38.355947",
                        level: 3
                    },
                    {
                        id: "140902",
                        name: "忻府区",
                        center: "112.734112,38.417743",
                        level: 3
                    },
                    {
                        id: "140922",
                        name: "五台县",
                        center: "113.259012,38.725711",
                        level: 3
                    },
                    {
                        id: "140923",
                        name: "代县",
                        center: "112.962519,39.065138",
                        level: 3
                    },
                    {
                        id: "140932",
                        name: "偏关县",
                        center: "111.500477,39.442153",
                        level: 3
                    },
                    {
                        id: "140930",
                        name: "河曲县",
                        center: "111.146609,39.381895",
                        level: 3
                    },
                    {
                        id: "140928",
                        name: "五寨县",
                        center: "111.841015,38.912761",
                        level: 3
                    },
                    {
                        id: "140927",
                        name: "神池县",
                        center: "112.200438,39.088467",
                        level: 3
                    },
                    {
                        id: "140924",
                        name: "繁峙县",
                        center: "113.267707,39.188104",
                        level: 3
                    },
                    {
                        id: "140931",
                        name: "保德县",
                        center: "111.085688,39.022576",
                        level: 3
                    }
                ]
            },
            {
                id: "140600",
                name: "朔州市",
                center: "112.433387,39.331261",
                level: 2,
                children: [
                    {
                        id: "140603",
                        name: "平鲁区",
                        center: "112.295227,39.515603",
                        level: 3
                    },
                    {
                        id: "140623",
                        name: "右玉县",
                        center: "112.465588,39.988812",
                        level: 3
                    },
                    {
                        id: "140621",
                        name: "山阴县",
                        center: "112.816396,39.52677",
                        level: 3
                    },
                    {
                        id: "140681",
                        name: "怀仁市",
                        center: "113.100512,39.82079",
                        level: 3
                    },
                    {
                        id: "140602",
                        name: "朔城区",
                        center: "112.428676,39.324525",
                        level: 3
                    },
                    {
                        id: "140622",
                        name: "应县",
                        center: "113.187505,39.559187",
                        level: 3
                    }
                ]
            },
            {
                id: "140700",
                name: "晋中市",
                center: "112.736465,37.696495",
                level: 2,
                children: [
                    {
                        id: "140724",
                        name: "昔阳县",
                        center: "113.706166,37.60437",
                        level: 3
                    },
                    {
                        id: "140728",
                        name: "平遥县",
                        center: "112.174059,37.195474",
                        level: 3
                    },
                    {
                        id: "140723",
                        name: "和顺县",
                        center: "113.572919,37.327027",
                        level: 3
                    },
                    {
                        id: "140722",
                        name: "左权县",
                        center: "113.377834,37.079672",
                        level: 3
                    },
                    {
                        id: "140725",
                        name: "寿阳县",
                        center: "113.177708,37.891136",
                        level: 3
                    },
                    {
                        id: "140702",
                        name: "榆次区",
                        center: "112.740056,37.6976",
                        level: 3
                    },
                    {
                        id: "140781",
                        name: "介休市",
                        center: "111.913857,37.027616",
                        level: 3
                    },
                    {
                        id: "140721",
                        name: "榆社县",
                        center: "112.973521,37.069019",
                        level: 3
                    },
                    {
                        id: "140729",
                        name: "灵石县",
                        center: "111.772759,36.847469",
                        level: 3
                    },
                    {
                        id: "140726",
                        name: "太谷区",
                        center: "112.554103,37.424595",
                        level: 3
                    },
                    {
                        id: "140727",
                        name: "祁县",
                        center: "112.330532,37.358739",
                        level: 3
                    }
                ]
            },
            {
                id: "141100",
                name: "吕梁市",
                center: "111.134335,37.524366",
                level: 2,
                children: [
                    {
                        id: "141124",
                        name: "临县",
                        center: "110.995963,37.960806",
                        level: 3
                    },
                    {
                        id: "141125",
                        name: "柳林县",
                        center: "110.89613,37.431664",
                        level: 3
                    },
                    {
                        id: "141127",
                        name: "岚县",
                        center: "111.671555,38.278654",
                        level: 3
                    },
                    {
                        id: "141128",
                        name: "方山县",
                        center: "111.238885,37.892632",
                        level: 3
                    },
                    {
                        id: "141130",
                        name: "交口县",
                        center: "111.183188,36.983068",
                        level: 3
                    },
                    {
                        id: "141129",
                        name: "中阳县",
                        center: "111.193319,37.342054",
                        level: 3
                    },
                    {
                        id: "141102",
                        name: "离石区",
                        center: "111.134462,37.524037",
                        level: 3
                    },
                    {
                        id: "141122",
                        name: "交城县",
                        center: "112.159154,37.555155",
                        level: 3
                    },
                    {
                        id: "141123",
                        name: "兴县",
                        center: "111.124816,38.464136",
                        level: 3
                    },
                    {
                        id: "141126",
                        name: "石楼县",
                        center: "110.837119,36.999426",
                        level: 3
                    },
                    {
                        id: "141121",
                        name: "文水县",
                        center: "112.032595,37.436314",
                        level: 3
                    },
                    {
                        id: "141182",
                        name: "汾阳市",
                        center: "111.785273,37.267742",
                        level: 3
                    },
                    {
                        id: "141181",
                        name: "孝义市",
                        center: "111.781568,37.144474",
                        level: 3
                    }
                ]
            },
            {
                id: "140200",
                name: "大同市",
                center: "113.295259,40.09031",
                level: 2,
                children: [
                    {
                        id: "140223",
                        name: "广灵县",
                        center: "114.279252,39.763051",
                        level: 3
                    },
                    {
                        id: "140224",
                        name: "灵丘县",
                        center: "114.23576,39.438867",
                        level: 3
                    },
                    {
                        id: "140225",
                        name: "浑源县",
                        center: "113.698091,39.699099",
                        level: 3
                    },
                    {
                        id: "140221",
                        name: "阳高县",
                        center: "113.749871,40.364927",
                        level: 3
                    },
                    {
                        id: "140213",
                        name: "平城区",
                        center: "113.298027,40.075667",
                        level: 3
                    },
                    {
                        id: "140214",
                        name: "云冈区",
                        center: "113.149693,40.005405",
                        level: 3
                    },
                    {
                        id: "140215",
                        name: "云州区",
                        center: "113.61244,40.040295",
                        level: 3
                    },
                    {
                        id: "140212",
                        name: "新荣区",
                        center: "113.141044,40.258269",
                        level: 3
                    },
                    {
                        id: "140222",
                        name: "天镇县",
                        center: "114.09112,40.421336",
                        level: 3
                    },
                    {
                        id: "140226",
                        name: "左云县",
                        center: "112.70641,40.012873",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "150000",
        name: "内蒙古自治区",
        center: "111.670801,40.818311",
        level: 1,
        children: [
            {
                id: "150800",
                name: "巴彦淖尔市",
                center: "107.416959,40.757402",
                level: 2,
                children: [
                    {
                        id: "150824",
                        name: "乌拉特中旗",
                        center: "108.515255,41.57254",
                        level: 3
                    },
                    {
                        id: "150821",
                        name: "五原县",
                        center: "108.270658,41.097639",
                        level: 3
                    },
                    {
                        id: "150822",
                        name: "磴口县",
                        center: "107.006056,40.330479",
                        level: 3
                    },
                    {
                        id: "150802",
                        name: "临河区",
                        center: "107.417018,40.757092",
                        level: 3
                    },
                    {
                        id: "150823",
                        name: "乌拉特前旗",
                        center: "108.656816,40.725209",
                        level: 3
                    },
                    {
                        id: "150826",
                        name: "杭锦后旗",
                        center: "107.147682,40.888797",
                        level: 3
                    },
                    {
                        id: "150825",
                        name: "乌拉特后旗",
                        center: "107.074941,41.084307",
                        level: 3
                    }
                ]
            },
            {
                id: "150300",
                name: "乌海市",
                center: "106.825563,39.673734",
                level: 2,
                children: [
                    {
                        id: "150303",
                        name: "海南区",
                        center: "106.884789,39.44153",
                        level: 3
                    },
                    {
                        id: "150302",
                        name: "海勃湾区",
                        center: "106.817762,39.673527",
                        level: 3
                    },
                    {
                        id: "150304",
                        name: "乌达区",
                        center: "106.722711,39.502288",
                        level: 3
                    }
                ]
            },
            {
                id: "150200",
                name: "包头市",
                center: "109.840405,40.658168",
                level: 2,
                children: [
                    {
                        id: "150221",
                        name: "土默特右旗",
                        center: "110.526766,40.566434",
                        level: 3
                    },
                    {
                        id: "150223",
                        name: "达尔罕茂明安联合旗",
                        center: "110.438452,41.702836",
                        level: 3
                    },
                    {
                        id: "150206",
                        name: "白云鄂博矿区",
                        center: "109.97016,41.769246",
                        level: 3
                    },
                    {
                        id: "150207",
                        name: "九原区",
                        center: "109.968122,40.600581",
                        level: 3
                    },
                    {
                        id: "150205",
                        name: "石拐区",
                        center: "110.272565,40.672094",
                        level: 3
                    },
                    {
                        id: "150222",
                        name: "固阳县",
                        center: "110.063421,41.030004",
                        level: 3
                    },
                    {
                        id: "150203",
                        name: "昆都仑区",
                        center: "109.822932,40.661345",
                        level: 3
                    },
                    {
                        id: "150202",
                        name: "东河区",
                        center: "110.026895,40.587056",
                        level: 3
                    },
                    {
                        id: "150204",
                        name: "青山区",
                        center: "109.880049,40.668558",
                        level: 3
                    }
                ]
            },
            {
                id: "150700",
                name: "呼伦贝尔市",
                center: "119.758168,49.215333",
                level: 2,
                children: [
                    {
                        id: "150782",
                        name: "牙克石市",
                        center: "120.729005,49.287024",
                        level: 3
                    },
                    {
                        id: "150785",
                        name: "根河市",
                        center: "121.532724,50.780454",
                        level: 3
                    },
                    {
                        id: "150725",
                        name: "陈巴尔虎旗",
                        center: "119.437609,49.328422",
                        level: 3
                    },
                    {
                        id: "150784",
                        name: "额尔古纳市",
                        center: "120.178636,50.2439",
                        level: 3
                    },
                    {
                        id: "150722",
                        name: "莫力达瓦达斡尔族自治旗",
                        center: "124.507401,48.478385",
                        level: 3
                    },
                    {
                        id: "150702",
                        name: "海拉尔区",
                        center: "119.764923,49.213889",
                        level: 3
                    },
                    {
                        id: "150721",
                        name: "阿荣旗",
                        center: "123.464615,48.130503",
                        level: 3
                    },
                    {
                        id: "150783",
                        name: "扎兰屯市",
                        center: "122.744401,48.007412",
                        level: 3
                    },
                    {
                        id: "150724",
                        name: "鄂温克族自治旗",
                        center: "119.754041,49.143293",
                        level: 3
                    },
                    {
                        id: "150781",
                        name: "满洲里市",
                        center: "117.455561,49.590788",
                        level: 3
                    },
                    {
                        id: "150703",
                        name: "扎赉诺尔区",
                        center: "117.716373,49.456567",
                        level: 3
                    },
                    {
                        id: "150727",
                        name: "新巴尔虎右旗",
                        center: "116.825991,48.669134",
                        level: 3
                    },
                    {
                        id: "150726",
                        name: "新巴尔虎左旗",
                        center: "118.267454,48.216571",
                        level: 3
                    },
                    {
                        id: "150723",
                        name: "鄂伦春自治旗",
                        center: "123.725684,50.590177",
                        level: 3
                    }
                ]
            },
            {
                id: "150600",
                name: "鄂尔多斯市",
                center: "109.99029,39.817179",
                level: 2,
                children: [
                    {
                        id: "150625",
                        name: "杭锦旗",
                        center: "108.736324,39.831789",
                        level: 3
                    },
                    {
                        id: "150621",
                        name: "达拉特旗",
                        center: "110.040281,40.404076",
                        level: 3
                    },
                    {
                        id: "150624",
                        name: "鄂托克旗",
                        center: "107.982604,39.095752",
                        level: 3
                    },
                    {
                        id: "150622",
                        name: "准格尔旗",
                        center: "111.238332,39.865221",
                        level: 3
                    },
                    {
                        id: "150626",
                        name: "乌审旗",
                        center: "108.842454,38.596611",
                        level: 3
                    },
                    {
                        id: "150623",
                        name: "鄂托克前旗",
                        center: "107.48172,38.183257",
                        level: 3
                    },
                    {
                        id: "150627",
                        name: "伊金霍洛旗",
                        center: "109.787402,39.604312",
                        level: 3
                    },
                    {
                        id: "150603",
                        name: "康巴什区",
                        center: "109.790076,39.607472",
                        level: 3
                    },
                    {
                        id: "150602",
                        name: "东胜区",
                        center: "109.98945,39.81788",
                        level: 3
                    }
                ]
            },
            {
                id: "152900",
                name: "阿拉善盟",
                center: "105.706422,38.844814",
                level: 2,
                children: [
                    {
                        id: "152923",
                        name: "额济纳旗",
                        center: "101.06944,41.958813",
                        level: 3
                    },
                    {
                        id: "152922",
                        name: "阿拉善右旗",
                        center: "101.671984,39.21159",
                        level: 3
                    },
                    {
                        id: "152921",
                        name: "阿拉善左旗",
                        center: "105.70192,38.847241",
                        level: 3
                    }
                ]
            },
            {
                id: "150500",
                name: "通辽市",
                center: "122.263119,43.617429",
                level: 2,
                children: [
                    {
                        id: "150581",
                        name: "霍林郭勒市",
                        center: "119.657862,45.532361",
                        level: 3
                    },
                    {
                        id: "150526",
                        name: "扎鲁特旗",
                        center: "120.905275,44.555294",
                        level: 3
                    },
                    {
                        id: "150521",
                        name: "科尔沁左翼中旗",
                        center: "123.313873,44.127166",
                        level: 3
                    },
                    {
                        id: "150525",
                        name: "奈曼旗",
                        center: "120.662543,42.84685",
                        level: 3
                    },
                    {
                        id: "150502",
                        name: "科尔沁区",
                        center: "122.264042,43.617422",
                        level: 3
                    },
                    {
                        id: "150524",
                        name: "库伦旗",
                        center: "121.774886,42.734692",
                        level: 3
                    },
                    {
                        id: "150523",
                        name: "开鲁县",
                        center: "121.308797,43.602432",
                        level: 3
                    },
                    {
                        id: "150522",
                        name: "科尔沁左翼后旗",
                        center: "122.355155,42.954564",
                        level: 3
                    }
                ]
            },
            {
                id: "152200",
                name: "兴安盟",
                center: "122.070317,46.076268",
                level: 2,
                children: [
                    {
                        id: "152224",
                        name: "突泉县",
                        center: "121.564856,45.380986",
                        level: 3
                    },
                    {
                        id: "152222",
                        name: "科尔沁右翼中旗",
                        center: "121.472818,45.059645",
                        level: 3
                    },
                    {
                        id: "152202",
                        name: "阿尔山市",
                        center: "119.943656,47.177",
                        level: 3
                    },
                    {
                        id: "152223",
                        name: "扎赉特旗",
                        center: "122.909332,46.725136",
                        level: 3
                    },
                    {
                        id: "152221",
                        name: "科尔沁右翼前旗",
                        center: "121.957544,46.076497",
                        level: 3
                    },
                    {
                        id: "152201",
                        name: "乌兰浩特市",
                        center: "122.068975,46.077238",
                        level: 3
                    }
                ]
            },
            {
                id: "152500",
                name: "锡林郭勒盟",
                center: "116.090996,43.944018",
                level: 2,
                children: [
                    {
                        id: "152525",
                        name: "东乌珠穆沁旗",
                        center: "116.980022,45.510307",
                        level: 3
                    },
                    {
                        id: "152522",
                        name: "阿巴嘎旗",
                        center: "114.970618,44.022728",
                        level: 3
                    },
                    {
                        id: "152502",
                        name: "锡林浩特市",
                        center: "116.091903,43.944301",
                        level: 3
                    },
                    {
                        id: "152526",
                        name: "西乌珠穆沁旗",
                        center: "117.615249,44.586147",
                        level: 3
                    },
                    {
                        id: "152523",
                        name: "苏尼特左旗",
                        center: "113.653412,43.854108",
                        level: 3
                    },
                    {
                        id: "152524",
                        name: "苏尼特右旗",
                        center: "112.65539,42.746662",
                        level: 3
                    },
                    {
                        id: "152501",
                        name: "二连浩特市",
                        center: "111.97981,43.652895",
                        level: 3
                    },
                    {
                        id: "152530",
                        name: "正蓝旗",
                        center: "116.003311,42.245895",
                        level: 3
                    },
                    {
                        id: "152529",
                        name: "正镶白旗",
                        center: "115.031423,42.286807",
                        level: 3
                    },
                    {
                        id: "152528",
                        name: "镶黄旗",
                        center: "113.843869,42.239229",
                        level: 3
                    },
                    {
                        id: "152531",
                        name: "多伦县",
                        center: "116.477288,42.197962",
                        level: 3
                    },
                    {
                        id: "152527",
                        name: "太仆寺旗",
                        center: "115.28728,41.895199",
                        level: 3
                    }
                ]
            },
            {
                id: "150100",
                name: "呼和浩特市",
                center: "111.670801,40.818311",
                level: 2,
                children: [
                    {
                        id: "150123",
                        name: "和林格尔县",
                        center: "111.824143,40.380288",
                        level: 3
                    },
                    {
                        id: "150104",
                        name: "玉泉区",
                        center: "111.66543,40.799421",
                        level: 3
                    },
                    {
                        id: "150103",
                        name: "回民区",
                        center: "111.662162,40.815149",
                        level: 3
                    },
                    {
                        id: "150125",
                        name: "武川县",
                        center: "111.456563,41.094483",
                        level: 3
                    },
                    {
                        id: "150121",
                        name: "土默特左旗",
                        center: "111.133615,40.720416",
                        level: 3
                    },
                    {
                        id: "150122",
                        name: "托克托县",
                        center: "111.197317,40.276729",
                        level: 3
                    },
                    {
                        id: "150102",
                        name: "新城区",
                        center: "111.685964,40.826225",
                        level: 3
                    },
                    {
                        id: "150105",
                        name: "赛罕区",
                        center: "111.698463,40.807834",
                        level: 3
                    },
                    {
                        id: "150124",
                        name: "清水河县",
                        center: "111.67222,39.912479",
                        level: 3
                    }
                ]
            },
            {
                id: "150400",
                name: "赤峰市",
                center: "118.956806,42.275317",
                level: 2,
                children: [
                    {
                        id: "150422",
                        name: "巴林左旗",
                        center: "119.391737,43.980715",
                        level: 3
                    },
                    {
                        id: "150423",
                        name: "巴林右旗",
                        center: "118.678347,43.528963",
                        level: 3
                    },
                    {
                        id: "150421",
                        name: "阿鲁科尔沁旗",
                        center: "120.094969,43.87877",
                        level: 3
                    },
                    {
                        id: "150424",
                        name: "林西县",
                        center: "118.05775,43.605326",
                        level: 3
                    },
                    {
                        id: "150425",
                        name: "克什克腾旗",
                        center: "117.542465,43.256233",
                        level: 3
                    },
                    {
                        id: "150426",
                        name: "翁牛特旗",
                        center: "119.022619,42.937128",
                        level: 3
                    },
                    {
                        id: "150404",
                        name: "松山区",
                        center: "118.938958,42.281046",
                        level: 3
                    },
                    {
                        id: "150402",
                        name: "红山区",
                        center: "118.961087,42.269732",
                        level: 3
                    },
                    {
                        id: "150403",
                        name: "元宝山区",
                        center: "119.289877,42.041168",
                        level: 3
                    },
                    {
                        id: "150428",
                        name: "喀喇沁旗",
                        center: "118.708572,41.92778",
                        level: 3
                    },
                    {
                        id: "150430",
                        name: "敖汉旗",
                        center: "119.906486,42.287012",
                        level: 3
                    },
                    {
                        id: "150429",
                        name: "宁城县",
                        center: "119.339242,41.598692",
                        level: 3
                    }
                ]
            },
            {
                id: "150900",
                name: "乌兰察布市",
                center: "113.114543,41.034126",
                level: 2,
                children: [
                    {
                        id: "150929",
                        name: "四子王旗",
                        center: "111.70123,41.528114",
                        level: 3
                    },
                    {
                        id: "150922",
                        name: "化德县",
                        center: "114.01008,41.899335",
                        level: 3
                    },
                    {
                        id: "150923",
                        name: "商都县",
                        center: "113.560643,41.560163",
                        level: 3
                    },
                    {
                        id: "150928",
                        name: "察哈尔右翼后旗",
                        center: "113.1906,41.447213",
                        level: 3
                    },
                    {
                        id: "150924",
                        name: "兴和县",
                        center: "113.834009,40.872437",
                        level: 3
                    },
                    {
                        id: "150921",
                        name: "卓资县",
                        center: "112.577702,40.89576",
                        level: 3
                    },
                    {
                        id: "150981",
                        name: "丰镇市",
                        center: "113.163462,40.437534",
                        level: 3
                    },
                    {
                        id: "150927",
                        name: "察哈尔右翼中旗",
                        center: "112.633563,41.274212",
                        level: 3
                    },
                    {
                        id: "150926",
                        name: "察哈尔右翼前旗",
                        center: "113.211958,40.786859",
                        level: 3
                    },
                    {
                        id: "150902",
                        name: "集宁区",
                        center: "113.116453,41.034134",
                        level: 3
                    },
                    {
                        id: "150925",
                        name: "凉城县",
                        center: "112.500911,40.531627",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "210000",
        name: "辽宁省",
        center: "123.429096,41.796767",
        level: 1,
        children: [
            {
                id: "210200",
                name: "大连市",
                center: "121.618622,38.91459",
                level: 2,
                children: [
                    {
                        id: "210211",
                        name: "甘井子区",
                        center: "121.582614,38.975148",
                        level: 3
                    },
                    {
                        id: "210214",
                        name: "普兰店区",
                        center: "121.9705,39.401555",
                        level: 3
                    },
                    {
                        id: "210283",
                        name: "庄河市",
                        center: "122.970612,39.69829",
                        level: 3
                    },
                    {
                        id: "210281",
                        name: "瓦房店市",
                        center: "122.002656,39.63065",
                        level: 3
                    },
                    {
                        id: "210213",
                        name: "金州区",
                        center: "121.789413,39.052745",
                        level: 3
                    },
                    {
                        id: "210202",
                        name: "中山区",
                        center: "121.64376,38.921553",
                        level: 3
                    },
                    {
                        id: "210224",
                        name: "长海县",
                        center: "122.587824,39.272399",
                        level: 3
                    },
                    {
                        id: "210212",
                        name: "旅顺口区",
                        center: "121.26713,38.812043",
                        level: 3
                    },
                    {
                        id: "210203",
                        name: "西岗区",
                        center: "121.616112,38.914266",
                        level: 3
                    },
                    {
                        id: "210204",
                        name: "沙河口区",
                        center: "121.593702,38.912859",
                        level: 3
                    }
                ]
            },
            {
                id: "211400",
                name: "葫芦岛市",
                center: "120.856394,40.755572",
                level: 2,
                children: [
                    {
                        id: "211422",
                        name: "建昌县",
                        center: "119.807776,40.812871",
                        level: 3
                    },
                    {
                        id: "211481",
                        name: "兴城市",
                        center: "120.729365,40.619413",
                        level: 3
                    },
                    {
                        id: "211403",
                        name: "龙港区",
                        center: "120.838569,40.709991",
                        level: 3
                    },
                    {
                        id: "211421",
                        name: "绥中县",
                        center: "120.342112,40.328407",
                        level: 3
                    },
                    {
                        id: "211404",
                        name: "南票区",
                        center: "120.752314,41.098813",
                        level: 3
                    },
                    {
                        id: "211402",
                        name: "连山区",
                        center: "120.85937,40.755143",
                        level: 3
                    }
                ]
            },
            {
                id: "210600",
                name: "丹东市",
                center: "124.383044,40.124296",
                level: 2,
                children: [
                    {
                        id: "210604",
                        name: "振安区",
                        center: "124.427709,40.158557",
                        level: 3
                    },
                    {
                        id: "210682",
                        name: "凤城市",
                        center: "124.071067,40.457567",
                        level: 3
                    },
                    {
                        id: "210681",
                        name: "东港市",
                        center: "124.149437,39.883467",
                        level: 3
                    },
                    {
                        id: "210603",
                        name: "振兴区",
                        center: "124.361153,40.102801",
                        level: 3
                    },
                    {
                        id: "210624",
                        name: "宽甸满族自治县",
                        center: "124.784867,40.730412",
                        level: 3
                    },
                    {
                        id: "210602",
                        name: "元宝区",
                        center: "124.397814,40.136483",
                        level: 3
                    }
                ]
            },
            {
                id: "210700",
                name: "锦州市",
                center: "121.135742,41.119269",
                level: 2,
                children: [
                    {
                        id: "210726",
                        name: "黑山县",
                        center: "122.117915,41.691804",
                        level: 3
                    },
                    {
                        id: "210727",
                        name: "义县",
                        center: "121.242831,41.537224",
                        level: 3
                    },
                    {
                        id: "210711",
                        name: "太和区",
                        center: "121.107297,41.105378",
                        level: 3
                    },
                    {
                        id: "210781",
                        name: "凌海市",
                        center: "121.364236,41.171738",
                        level: 3
                    },
                    {
                        id: "210782",
                        name: "北镇市",
                        center: "121.795962,41.598764",
                        level: 3
                    },
                    {
                        id: "210702",
                        name: "古塔区",
                        center: "121.130085,41.115719",
                        level: 3
                    },
                    {
                        id: "210703",
                        name: "凌河区",
                        center: "121.151304,41.114662",
                        level: 3
                    }
                ]
            },
            {
                id: "210900",
                name: "阜新市",
                center: "121.648962,42.011796",
                level: 2,
                children: [
                    {
                        id: "210904",
                        name: "太平区",
                        center: "121.677575,42.011145",
                        level: 3
                    },
                    {
                        id: "210905",
                        name: "清河门区",
                        center: "121.42018,41.780477",
                        level: 3
                    },
                    {
                        id: "210921",
                        name: "阜新蒙古族自治县",
                        center: "121.743125,42.058607",
                        level: 3
                    },
                    {
                        id: "210922",
                        name: "彰武县",
                        center: "122.537444,42.384823",
                        level: 3
                    },
                    {
                        id: "210903",
                        name: "新邱区",
                        center: "121.790541,42.086603",
                        level: 3
                    },
                    {
                        id: "210902",
                        name: "海州区",
                        center: "121.657639,42.011162",
                        level: 3
                    },
                    {
                        id: "210911",
                        name: "细河区",
                        center: "121.654791,42.019218",
                        level: 3
                    }
                ]
            },
            {
                id: "210100",
                name: "沈阳市",
                center: "123.429096,41.796767",
                level: 2,
                children: [
                    {
                        id: "210181",
                        name: "新民市",
                        center: "122.828868,41.996508",
                        level: 3
                    },
                    {
                        id: "210105",
                        name: "皇姑区",
                        center: "123.405677,41.822336",
                        level: 3
                    },
                    {
                        id: "210114",
                        name: "于洪区",
                        center: "123.310829,41.795833",
                        level: 3
                    },
                    {
                        id: "210111",
                        name: "苏家屯区",
                        center: "123.341604,41.665904",
                        level: 3
                    },
                    {
                        id: "210102",
                        name: "和平区",
                        center: "123.406664,41.788074",
                        level: 3
                    },
                    {
                        id: "210123",
                        name: "康平县",
                        center: "123.352703,42.741533",
                        level: 3
                    },
                    {
                        id: "210124",
                        name: "法库县",
                        center: "123.416722,42.507045",
                        level: 3
                    },
                    {
                        id: "210103",
                        name: "沈河区",
                        center: "123.445696,41.795591",
                        level: 3
                    },
                    {
                        id: "210104",
                        name: "大东区",
                        center: "123.469956,41.808503",
                        level: 3
                    },
                    {
                        id: "210106",
                        name: "铁西区",
                        center: "123.350664,41.787808",
                        level: 3
                    },
                    {
                        id: "210115",
                        name: "辽中区",
                        center: "122.731269,41.512725",
                        level: 3
                    },
                    {
                        id: "210113",
                        name: "沈北新区",
                        center: "123.521471,42.052312",
                        level: 3
                    },
                    {
                        id: "210112",
                        name: "浑南区",
                        center: "123.458981,41.741946",
                        level: 3
                    }
                ]
            },
            {
                id: "210400",
                name: "抚顺市",
                center: "123.921109,41.875956",
                level: 2,
                children: [
                    {
                        id: "210423",
                        name: "清原满族自治县",
                        center: "124.927192,42.10135",
                        level: 3
                    },
                    {
                        id: "210422",
                        name: "新宾满族自治县",
                        center: "125.037547,41.732456",
                        level: 3
                    },
                    {
                        id: "210421",
                        name: "抚顺县",
                        center: "124.097979,41.922644",
                        level: 3
                    },
                    {
                        id: "210404",
                        name: "望花区",
                        center: "123.801509,41.851803",
                        level: 3
                    },
                    {
                        id: "210402",
                        name: "新抚区",
                        center: "123.902858,41.86082",
                        level: 3
                    },
                    {
                        id: "210411",
                        name: "顺城区",
                        center: "123.917165,41.881132",
                        level: 3
                    },
                    {
                        id: "210403",
                        name: "东洲区",
                        center: "124.047219,41.866829",
                        level: 3
                    }
                ]
            },
            {
                id: "210800",
                name: "营口市",
                center: "122.235151,40.667432",
                level: 2,
                children: [
                    {
                        id: "210804",
                        name: "鲅鱼圈区",
                        center: "122.127242,40.263646",
                        level: 3
                    },
                    {
                        id: "210881",
                        name: "盖州市",
                        center: "122.355534,40.405234",
                        level: 3
                    },
                    {
                        id: "210803",
                        name: "西市区",
                        center: "122.210067,40.663086",
                        level: 3
                    },
                    {
                        id: "210882",
                        name: "大石桥市",
                        center: "122.505894,40.633973",
                        level: 3
                    },
                    {
                        id: "210811",
                        name: "老边区",
                        center: "122.382584,40.682723",
                        level: 3
                    },
                    {
                        id: "210802",
                        name: "站前区",
                        center: "122.253235,40.669949",
                        level: 3
                    }
                ]
            },
            {
                id: "211200",
                name: "铁岭市",
                center: "123.844279,42.290585",
                level: 2,
                children: [
                    {
                        id: "211204",
                        name: "清河区",
                        center: "124.14896,42.542978",
                        level: 3
                    },
                    {
                        id: "211281",
                        name: "调兵山市",
                        center: "123.545366,42.450734",
                        level: 3
                    },
                    {
                        id: "211282",
                        name: "开原市",
                        center: "124.045551,42.542141",
                        level: 3
                    },
                    {
                        id: "211224",
                        name: "昌图县",
                        center: "124.11017,42.784441",
                        level: 3
                    },
                    {
                        id: "211223",
                        name: "西丰县",
                        center: "124.72332,42.738091",
                        level: 3
                    },
                    {
                        id: "211202",
                        name: "银州区",
                        center: "123.844877,42.292278",
                        level: 3
                    },
                    {
                        id: "211221",
                        name: "铁岭县",
                        center: "123.725669,42.223316",
                        level: 3
                    }
                ]
            },
            {
                id: "211100",
                name: "盘锦市",
                center: "122.06957,41.124484",
                level: 2,
                children: [
                    {
                        id: "211122",
                        name: "盘山县",
                        center: "121.98528,41.240701",
                        level: 3
                    },
                    {
                        id: "211104",
                        name: "大洼区",
                        center: "122.071708,40.994428",
                        level: 3
                    },
                    {
                        id: "211103",
                        name: "兴隆台区",
                        center: "122.071624,41.122423",
                        level: 3
                    },
                    {
                        id: "211102",
                        name: "双台子区",
                        center: "122.055733,41.190365",
                        level: 3
                    }
                ]
            },
            {
                id: "210500",
                name: "本溪市",
                center: "123.770519,41.297909",
                level: 2,
                children: [
                    {
                        id: "210522",
                        name: "桓仁满族自治县",
                        center: "125.359195,41.268997",
                        level: 3
                    },
                    {
                        id: "210503",
                        name: "溪湖区",
                        center: "123.765226,41.330056",
                        level: 3
                    },
                    {
                        id: "210505",
                        name: "南芬区",
                        center: "123.748381,41.104093",
                        level: 3
                    },
                    {
                        id: "210504",
                        name: "明山区",
                        center: "123.763288,41.302429",
                        level: 3
                    },
                    {
                        id: "210502",
                        name: "平山区",
                        center: "123.761231,41.291581",
                        level: 3
                    },
                    {
                        id: "210521",
                        name: "本溪满族自治县",
                        center: "124.126156,41.300344",
                        level: 3
                    }
                ]
            },
            {
                id: "211000",
                name: "辽阳市",
                center: "123.18152,41.269402",
                level: 2,
                children: [
                    {
                        id: "211081",
                        name: "灯塔市",
                        center: "123.325864,41.427836",
                        level: 3
                    },
                    {
                        id: "211011",
                        name: "太子河区",
                        center: "123.185336,41.251682",
                        level: 3
                    },
                    {
                        id: "211021",
                        name: "辽阳县",
                        center: "123.079674,41.216479",
                        level: 3
                    },
                    {
                        id: "211005",
                        name: "弓长岭区",
                        center: "123.431633,41.157831",
                        level: 3
                    },
                    {
                        id: "211003",
                        name: "文圣区",
                        center: "123.188227,41.266765",
                        level: 3
                    },
                    {
                        id: "211002",
                        name: "白塔区",
                        center: "123.172611,41.26745",
                        level: 3
                    },
                    {
                        id: "211004",
                        name: "宏伟区",
                        center: "123.200461,41.205747",
                        level: 3
                    }
                ]
            },
            {
                id: "210300",
                name: "鞍山市",
                center: "122.995632,41.110626",
                level: 2,
                children: [
                    {
                        id: "210321",
                        name: "台安县",
                        center: "122.429736,41.38686",
                        level: 3
                    },
                    {
                        id: "210323",
                        name: "岫岩满族自治县",
                        center: "123.28833,40.281509",
                        level: 3
                    },
                    {
                        id: "210311",
                        name: "千山区",
                        center: "122.949298,41.068909",
                        level: 3
                    },
                    {
                        id: "210304",
                        name: "立山区",
                        center: "123.024806,41.150622",
                        level: 3
                    },
                    {
                        id: "210303",
                        name: "铁西区",
                        center: "122.971834,41.11069",
                        level: 3
                    },
                    {
                        id: "210302",
                        name: "铁东区",
                        center: "122.994475,41.110344",
                        level: 3
                    },
                    {
                        id: "210381",
                        name: "海城市",
                        center: "122.752199,40.852533",
                        level: 3
                    }
                ]
            },
            {
                id: "211300",
                name: "朝阳市",
                center: "120.451176,41.576758",
                level: 2,
                children: [
                    {
                        id: "211322",
                        name: "建平县",
                        center: "119.642363,41.402576",
                        level: 3
                    },
                    {
                        id: "211381",
                        name: "北票市",
                        center: "120.766951,41.803286",
                        level: 3
                    },
                    {
                        id: "211302",
                        name: "双塔区",
                        center: "120.44877,41.579389",
                        level: 3
                    },
                    {
                        id: "211303",
                        name: "龙城区",
                        center: "120.413376,41.576749",
                        level: 3
                    },
                    {
                        id: "211321",
                        name: "朝阳县",
                        center: "120.404217,41.526342",
                        level: 3
                    },
                    {
                        id: "211324",
                        name: "喀喇沁左翼蒙古族自治县",
                        center: "119.744883,41.125428",
                        level: 3
                    },
                    {
                        id: "211382",
                        name: "凌源市",
                        center: "119.404789,41.243086",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "220000",
        name: "吉林省",
        center: "125.3245,43.886841",
        level: 1,
        children: [
            {
                id: "220400",
                name: "辽源市",
                center: "125.145349,42.902692",
                level: 2,
                children: [
                    {
                        id: "220403",
                        name: "西安区",
                        center: "125.151424,42.920415",
                        level: 3
                    },
                    {
                        id: "220421",
                        name: "东丰县",
                        center: "125.529623,42.675228",
                        level: 3
                    },
                    {
                        id: "220402",
                        name: "龙山区",
                        center: "125.145164,42.902702",
                        level: 3
                    },
                    {
                        id: "220422",
                        name: "东辽县",
                        center: "124.991995,42.927724",
                        level: 3
                    }
                ]
            },
            {
                id: "220700",
                name: "松原市",
                center: "124.823608,45.118243",
                level: 2,
                children: [
                    {
                        id: "220781",
                        name: "扶余市",
                        center: "126.042758,44.986199",
                        level: 3
                    },
                    {
                        id: "220702",
                        name: "宁江区",
                        center: "124.827851,45.176498",
                        level: 3
                    },
                    {
                        id: "220721",
                        name: "前郭尔罗斯蒙古族自治县",
                        center: "124.826808,45.116288",
                        level: 3
                    },
                    {
                        id: "220722",
                        name: "长岭县",
                        center: "123.985184,44.276579",
                        level: 3
                    },
                    {
                        id: "220723",
                        name: "乾安县",
                        center: "124.024361,45.006846",
                        level: 3
                    }
                ]
            },
            {
                id: "220100",
                name: "长春市",
                center: "125.3245,43.886841",
                level: 2,
                children: [
                    {
                        id: "220183",
                        name: "德惠市",
                        center: "125.703327,44.533909",
                        level: 3
                    },
                    {
                        id: "220182",
                        name: "榆树市",
                        center: "126.550107,44.827642",
                        level: 3
                    },
                    {
                        id: "220112",
                        name: "双阳区",
                        center: "125.659018,43.525168",
                        level: 3
                    },
                    {
                        id: "220122",
                        name: "农安县",
                        center: "125.175287,44.431258",
                        level: 3
                    },
                    {
                        id: "220106",
                        name: "绿园区",
                        center: "125.272467,43.892177",
                        level: 3
                    },
                    {
                        id: "220104",
                        name: "朝阳区",
                        center: "125.318042,43.86491",
                        level: 3
                    },
                    {
                        id: "220103",
                        name: "宽城区",
                        center: "125.342828,43.903823",
                        level: 3
                    },
                    {
                        id: "220102",
                        name: "南关区",
                        center: "125.337237,43.890235",
                        level: 3
                    },
                    {
                        id: "220113",
                        name: "九台区",
                        center: "125.844682,44.157155",
                        level: 3
                    },
                    {
                        id: "220105",
                        name: "二道区",
                        center: "125.384727,43.870824",
                        level: 3
                    }
                ]
            },
            {
                id: "220200",
                name: "吉林市",
                center: "126.55302,43.843577",
                level: 2,
                children: [
                    {
                        id: "220283",
                        name: "舒兰市",
                        center: "126.947813,44.410906",
                        level: 3
                    },
                    {
                        id: "220204",
                        name: "船营区",
                        center: "126.55239,43.843804",
                        level: 3
                    },
                    {
                        id: "220211",
                        name: "丰满区",
                        center: "126.560759,43.816594",
                        level: 3
                    },
                    {
                        id: "220203",
                        name: "龙潭区",
                        center: "126.561429,43.909755",
                        level: 3
                    },
                    {
                        id: "220282",
                        name: "桦甸市",
                        center: "126.745445,42.972093",
                        level: 3
                    },
                    {
                        id: "220281",
                        name: "蛟河市",
                        center: "127.342739,43.720579",
                        level: 3
                    },
                    {
                        id: "220202",
                        name: "昌邑区",
                        center: "126.570766,43.851118",
                        level: 3
                    },
                    {
                        id: "220221",
                        name: "永吉县",
                        center: "126.501622,43.667416",
                        level: 3
                    },
                    {
                        id: "220284",
                        name: "磐石市",
                        center: "126.059929,42.942476",
                        level: 3
                    }
                ]
            },
            {
                id: "220800",
                name: "白城市",
                center: "122.841114,45.619026",
                level: 2,
                children: [
                    {
                        id: "220881",
                        name: "洮南市",
                        center: "122.783779,45.339113",
                        level: 3
                    },
                    {
                        id: "220882",
                        name: "大安市",
                        center: "124.291512,45.507648",
                        level: 3
                    },
                    {
                        id: "220822",
                        name: "通榆县",
                        center: "123.088543,44.80915",
                        level: 3
                    },
                    {
                        id: "220802",
                        name: "洮北区",
                        center: "122.842499,45.619253",
                        level: 3
                    },
                    {
                        id: "220821",
                        name: "镇赉县",
                        center: "123.202246,45.846089",
                        level: 3
                    }
                ]
            },
            {
                id: "220300",
                name: "四平市",
                center: "124.370785,43.170344",
                level: 2,
                children: [
                    {
                        id: "220303",
                        name: "铁东区",
                        center: "124.388464,43.16726",
                        level: 3
                    },
                    {
                        id: "220382",
                        name: "双辽市",
                        center: "123.505283,43.518275",
                        level: 3
                    },
                    {
                        id: "220381",
                        name: "公主岭市",
                        center: "124.817588,43.509474",
                        level: 3
                    },
                    {
                        id: "220323",
                        name: "伊通满族自治县",
                        center: "125.303124,43.345464",
                        level: 3
                    },
                    {
                        id: "220302",
                        name: "铁西区",
                        center: "124.360894,43.176263",
                        level: 3
                    },
                    {
                        id: "220322",
                        name: "梨树县",
                        center: "124.335802,43.30831",
                        level: 3
                    }
                ]
            },
            {
                id: "222400",
                name: "延边朝鲜族自治州",
                center: "129.513228,42.904823",
                level: 2,
                children: [
                    {
                        id: "222403",
                        name: "敦化市",
                        center: "128.22986,43.366921",
                        level: 3
                    },
                    {
                        id: "222404",
                        name: "珲春市",
                        center: "130.365787,42.871057",
                        level: 3
                    },
                    {
                        id: "222406",
                        name: "和龙市",
                        center: "129.008748,42.547004",
                        level: 3
                    },
                    {
                        id: "222402",
                        name: "图们市",
                        center: "129.846701,42.966621",
                        level: 3
                    },
                    {
                        id: "222426",
                        name: "安图县",
                        center: "128.901865,43.110994",
                        level: 3
                    },
                    {
                        id: "222424",
                        name: "汪清县",
                        center: "129.766161,43.315426",
                        level: 3
                    },
                    {
                        id: "222401",
                        name: "延吉市",
                        center: "129.51579,42.906964",
                        level: 3
                    },
                    {
                        id: "222405",
                        name: "龙井市",
                        center: "129.425747,42.771029",
                        level: 3
                    }
                ]
            },
            {
                id: "220600",
                name: "白山市",
                center: "126.427839,41.942505",
                level: 2,
                children: [
                    {
                        id: "220605",
                        name: "江源区",
                        center: "126.584229,42.048109",
                        level: 3
                    },
                    {
                        id: "220623",
                        name: "长白朝鲜族自治县",
                        center: "128.203384,41.419361",
                        level: 3
                    },
                    {
                        id: "220622",
                        name: "靖宇县",
                        center: "126.808386,42.389689",
                        level: 3
                    },
                    {
                        id: "220621",
                        name: "抚松县",
                        center: "127.273796,42.332643",
                        level: 3
                    },
                    {
                        id: "220602",
                        name: "浑江区",
                        center: "126.428035,41.943065",
                        level: 3
                    },
                    {
                        id: "220681",
                        name: "临江市",
                        center: "126.919296,41.810689",
                        level: 3
                    }
                ]
            },
            {
                id: "220500",
                name: "通化市",
                center: "125.936501,41.721177",
                level: 2,
                children: [
                    {
                        id: "220503",
                        name: "二道江区",
                        center: "126.045987,41.777564",
                        level: 3
                    },
                    {
                        id: "220502",
                        name: "东昌区",
                        center: "125.936716,41.721233",
                        level: 3
                    },
                    {
                        id: "220521",
                        name: "通化县",
                        center: "125.753121,41.677918",
                        level: 3
                    },
                    {
                        id: "220524",
                        name: "柳河县",
                        center: "125.740536,42.281484",
                        level: 3
                    },
                    {
                        id: "220523",
                        name: "辉南县",
                        center: "126.042821,42.683459",
                        level: 3
                    },
                    {
                        id: "220581",
                        name: "梅河口市",
                        center: "125.687336,42.530002",
                        level: 3
                    },
                    {
                        id: "220582",
                        name: "集安市",
                        center: "126.186204,41.126276",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "230000",
        name: "黑龙江省",
        center: "126.642464,45.756967",
        level: 1,
        children: [
            {
                id: "232700",
                name: "大兴安岭地区",
                center: "124.711526,52.335262",
                level: 2,
                children: [
                    {
                        id: "232701",
                        name: "漠河市",
                        center: "122.536256,52.972074",
                        level: 3
                    },
                    {
                        id: "232721",
                        name: "呼玛县",
                        center: "126.662105,51.726998",
                        level: 3
                    },
                    {
                        id: "232722",
                        name: "塔河县",
                        center: "124.710516,52.335229",
                        level: 3
                    },
                    {
                        id: "232718",
                        name: "加格达奇区",
                        center: "124.126716,50.424654",
                        level: 3
                    }
                ]
            },
            {
                id: "230400",
                name: "鹤岗市",
                center: "130.277487,47.332085",
                level: 2,
                children: [
                    {
                        id: "230422",
                        name: "绥滨县",
                        center: "131.860526,47.289892",
                        level: 3
                    },
                    {
                        id: "230403",
                        name: "工农区",
                        center: "130.276652,47.331678",
                        level: 3
                    },
                    {
                        id: "230407",
                        name: "兴山区",
                        center: "130.30534,47.35997",
                        level: 3
                    },
                    {
                        id: "230402",
                        name: "向阳区",
                        center: "130.292478,47.345372",
                        level: 3
                    },
                    {
                        id: "230404",
                        name: "南山区",
                        center: "130.275533,47.31324",
                        level: 3
                    },
                    {
                        id: "230405",
                        name: "兴安区",
                        center: "130.236169,47.252911",
                        level: 3
                    },
                    {
                        id: "230406",
                        name: "东山区",
                        center: "130.31714,47.337385",
                        level: 3
                    },
                    {
                        id: "230421",
                        name: "萝北县",
                        center: "130.829087,47.577577",
                        level: 3
                    }
                ]
            },
            {
                id: "230900",
                name: "七台河市",
                center: "131.015584,45.771266",
                level: 2,
                children: [
                    {
                        id: "230903",
                        name: "桃山区",
                        center: "131.015848,45.771217",
                        level: 3
                    },
                    {
                        id: "230904",
                        name: "茄子河区",
                        center: "131.071561,45.776587",
                        level: 3
                    },
                    {
                        id: "230902",
                        name: "新兴区",
                        center: "130.889482,45.794258",
                        level: 3
                    },
                    {
                        id: "230921",
                        name: "勃利县",
                        center: "130.575025,45.751573",
                        level: 3
                    }
                ]
            },
            {
                id: "230700",
                name: "伊春市",
                center: "128.899396,47.724775",
                level: 2,
                children: [
                    {
                        id: "230722",
                        name: "嘉荫县",
                        center: "130.397684,48.891378",
                        level: 3
                    },
                    {
                        id: "230726",
                        name: "南岔县",
                        center: "129.28246,47.137314",
                        level: 3
                    },
                    {
                        id: "230751",
                        name: "金林区",
                        center: "129.429117,47.413074",
                        level: 3
                    },
                    {
                        id: "230717",
                        name: "伊美区",
                        center: "128.907302,47.728170",
                        level: 3
                    },
                    {
                        id: "230724",
                        name: "丰林县",
                        center: "129.533598,48.290454",
                        level: 3
                    },
                    {
                        id: "230719",
                        name: "友好区",
                        center: "128.840749,47.853778",
                        level: 3
                    },
                    {
                        id: "230723",
                        name: "汤旺县",
                        center: "129.571108,48.454651",
                        level: 3
                    },
                    {
                        id: "230718",
                        name: "乌翠区",
                        center: "128.669858,47.726727",
                        level: 3
                    },
                    {
                        id: "230725",
                        name: "大箐山县",
                        center: "129.020792,47.028397",
                        level: 3
                    },
                    {
                        id: "230781",
                        name: "铁力市",
                        center: "128.030561,46.985772",
                        level: 3
                    }
                ]
            },
            {
                id: "230100",
                name: "哈尔滨市",
                center: "126.642464,45.756967",
                level: 2,
                children: [
                    {
                        id: "230124",
                        name: "方正县",
                        center: "128.836131,45.839536",
                        level: 3
                    },
                    {
                        id: "230128",
                        name: "通河县",
                        center: "128.747786,45.977618",
                        level: 3
                    },
                    {
                        id: "230123",
                        name: "依兰县",
                        center: "129.565594,46.315105",
                        level: 3
                    },
                    {
                        id: "230126",
                        name: "巴彦县",
                        center: "127.403602,46.081889",
                        level: 3
                    },
                    {
                        id: "230125",
                        name: "宾县",
                        center: "127.48594,45.759369",
                        level: 3
                    },
                    {
                        id: "230127",
                        name: "木兰县",
                        center: "128.042675,45.949826",
                        level: 3
                    },
                    {
                        id: "230108",
                        name: "平房区",
                        center: "126.629257,45.605567",
                        level: 3
                    },
                    {
                        id: "230183",
                        name: "尚志市",
                        center: "127.968539,45.214953",
                        level: 3
                    },
                    {
                        id: "230129",
                        name: "延寿县",
                        center: "128.331886,45.455648",
                        level: 3
                    },
                    {
                        id: "230113",
                        name: "双城区",
                        center: "126.308784,45.377942",
                        level: 3
                    },
                    {
                        id: "230110",
                        name: "香坊区",
                        center: "126.667049,45.713067",
                        level: 3
                    },
                    {
                        id: "230102",
                        name: "道里区",
                        center: "126.612532,45.762035",
                        level: 3
                    },
                    {
                        id: "230184",
                        name: "五常市",
                        center: "127.15759,44.919418",
                        level: 3
                    },
                    {
                        id: "230112",
                        name: "阿城区",
                        center: "126.972726,45.538372",
                        level: 3
                    },
                    {
                        id: "230111",
                        name: "呼兰区",
                        center: "126.603302,45.98423",
                        level: 3
                    },
                    {
                        id: "230109",
                        name: "松北区",
                        center: "126.563066,45.814656",
                        level: 3
                    },
                    {
                        id: "230104",
                        name: "道外区",
                        center: "126.648838,45.78454",
                        level: 3
                    },
                    {
                        id: "230103",
                        name: "南岗区",
                        center: "126.652098,45.755971",
                        level: 3
                    }
                ]
            },
            {
                id: "230200",
                name: "齐齐哈尔市",
                center: "123.95792,47.342081",
                level: 2,
                children: [
                    {
                        id: "230227",
                        name: "富裕县",
                        center: "124.469106,47.797172",
                        level: 3
                    },
                    {
                        id: "230229",
                        name: "克山县",
                        center: "125.874355,48.034342",
                        level: 3
                    },
                    {
                        id: "230281",
                        name: "讷河市",
                        center: "124.882172,48.481133",
                        level: 3
                    },
                    {
                        id: "230225",
                        name: "甘南县",
                        center: "123.506034,47.917838",
                        level: 3
                    },
                    {
                        id: "230230",
                        name: "克东县",
                        center: "126.249094,48.03732",
                        level: 3
                    },
                    {
                        id: "230223",
                        name: "依安县",
                        center: "125.307561,47.890098",
                        level: 3
                    },
                    {
                        id: "230204",
                        name: "铁锋区",
                        center: "123.973555,47.339499",
                        level: 3
                    },
                    {
                        id: "230208",
                        name: "梅里斯达斡尔族区",
                        center: "123.754599,47.311113",
                        level: 3
                    },
                    {
                        id: "230207",
                        name: "碾子山区",
                        center: "122.887972,47.51401",
                        level: 3
                    },
                    {
                        id: "230203",
                        name: "建华区",
                        center: "123.955888,47.354494",
                        level: 3
                    },
                    {
                        id: "230221",
                        name: "龙江县",
                        center: "123.187225,47.336388",
                        level: 3
                    },
                    {
                        id: "230206",
                        name: "富拉尔基区",
                        center: "123.638873,47.20697",
                        level: 3
                    },
                    {
                        id: "230202",
                        name: "龙沙区",
                        center: "123.957338,47.341736",
                        level: 3
                    },
                    {
                        id: "230205",
                        name: "昂昂溪区",
                        center: "123.813181,47.156867",
                        level: 3
                    },
                    {
                        id: "230224",
                        name: "泰来县",
                        center: "123.41953,46.39233",
                        level: 3
                    },
                    {
                        id: "230231",
                        name: "拜泉县",
                        center: "126.091911,47.607363",
                        level: 3
                    }
                ]
            },
            {
                id: "231200",
                name: "绥化市",
                center: "126.99293,46.637393",
                level: 2,
                children: [
                    {
                        id: "231226",
                        name: "绥棱县",
                        center: "127.111121,47.247195",
                        level: 3
                    },
                    {
                        id: "231283",
                        name: "海伦市",
                        center: "126.969383,47.460428",
                        level: 3
                    },
                    {
                        id: "231224",
                        name: "庆安县",
                        center: "127.510024,46.879203",
                        level: 3
                    },
                    {
                        id: "231221",
                        name: "望奎县",
                        center: "126.484191,46.83352",
                        level: 3
                    },
                    {
                        id: "231202",
                        name: "北林区",
                        center: "126.990665,46.634912",
                        level: 3
                    },
                    {
                        id: "231223",
                        name: "青冈县",
                        center: "126.112268,46.686596",
                        level: 3
                    },
                    {
                        id: "231282",
                        name: "肇东市",
                        center: "125.991402,46.069471",
                        level: 3
                    },
                    {
                        id: "231225",
                        name: "明水县",
                        center: "125.907544,47.183527",
                        level: 3
                    },
                    {
                        id: "231222",
                        name: "兰西县",
                        center: "126.289315,46.259037",
                        level: 3
                    },
                    {
                        id: "231281",
                        name: "安达市",
                        center: "125.329926,46.410614",
                        level: 3
                    }
                ]
            },
            {
                id: "231100",
                name: "黑河市",
                center: "127.499023,50.249585",
                level: 2,
                children: [
                    {
                        id: "231102",
                        name: "爱辉区",
                        center: "127.497639,50.249027",
                        level: 3
                    },
                    {
                        id: "231121",
                        name: "嫩江市",
                        center: "125.229904,49.177461",
                        level: 3
                    },
                    {
                        id: "231181",
                        name: "北安市",
                        center: "126.508737,48.245437",
                        level: 3
                    },
                    {
                        id: "231182",
                        name: "五大连池市",
                        center: "126.197694,48.512688",
                        level: 3
                    },
                    {
                        id: "231123",
                        name: "逊克县",
                        center: "128.476152,49.582974",
                        level: 3
                    },
                    {
                        id: "231124",
                        name: "孙吴县",
                        center: "127.327315,49.423941",
                        level: 3
                    }
                ]
            },
            {
                id: "231000",
                name: "牡丹江市",
                center: "129.618602,44.582962",
                level: 2,
                children: [
                    {
                        id: "231004",
                        name: "爱民区",
                        center: "129.601232,44.595443",
                        level: 3
                    },
                    {
                        id: "231081",
                        name: "绥芬河市",
                        center: "131.164856,44.396864",
                        level: 3
                    },
                    {
                        id: "231002",
                        name: "东安区",
                        center: "129.623292,44.582399",
                        level: 3
                    },
                    {
                        id: "231086",
                        name: "东宁市",
                        center: "131.125296,44.063578",
                        level: 3
                    },
                    {
                        id: "231084",
                        name: "宁安市",
                        center: "129.470019,44.346836",
                        level: 3
                    },
                    {
                        id: "231025",
                        name: "林口县",
                        center: "130.268402,45.286645",
                        level: 3
                    },
                    {
                        id: "231003",
                        name: "阳明区",
                        center: "129.634645,44.596328",
                        level: 3
                    },
                    {
                        id: "231085",
                        name: "穆棱市",
                        center: "130.527085,44.91967",
                        level: 3
                    },
                    {
                        id: "231005",
                        name: "西安区",
                        center: "129.61311,44.581032",
                        level: 3
                    },
                    {
                        id: "231083",
                        name: "海林市",
                        center: "129.387902,44.574149",
                        level: 3
                    }
                ]
            },
            {
                id: "230600",
                name: "大庆市",
                center: "125.11272,46.590734",
                level: 2,
                children: [
                    {
                        id: "230604",
                        name: "让胡路区",
                        center: "124.868341,46.653254",
                        level: 3
                    },
                    {
                        id: "230605",
                        name: "红岗区",
                        center: "124.889528,46.403049",
                        level: 3
                    },
                    {
                        id: "230622",
                        name: "肇源县",
                        center: "125.081974,45.518832",
                        level: 3
                    },
                    {
                        id: "230606",
                        name: "大同区",
                        center: "124.818509,46.034304",
                        level: 3
                    },
                    {
                        id: "230623",
                        name: "林甸县",
                        center: "124.877742,47.186411",
                        level: 3
                    },
                    {
                        id: "230624",
                        name: "杜尔伯特蒙古族自治县",
                        center: "124.446259,46.865973",
                        level: 3
                    },
                    {
                        id: "230603",
                        name: "龙凤区",
                        center: "125.145794,46.573948",
                        level: 3
                    },
                    {
                        id: "230602",
                        name: "萨尔图区",
                        center: "125.114643,46.596356",
                        level: 3
                    },
                    {
                        id: "230621",
                        name: "肇州县",
                        center: "125.273254,45.708685",
                        level: 3
                    }
                ]
            },
            {
                id: "230800",
                name: "佳木斯市",
                center: "130.361634,46.809606",
                level: 2,
                children: [
                    {
                        id: "230881",
                        name: "同江市",
                        center: "132.510119,47.651131",
                        level: 3
                    },
                    {
                        id: "230826",
                        name: "桦川县",
                        center: "130.723713,47.023039",
                        level: 3
                    },
                    {
                        id: "230828",
                        name: "汤原县",
                        center: "129.904463,46.730048",
                        level: 3
                    },
                    {
                        id: "230804",
                        name: "前进区",
                        center: "130.377684,46.812345",
                        level: 3
                    },
                    {
                        id: "230803",
                        name: "向阳区",
                        center: "130.361786,46.809645",
                        level: 3
                    },
                    {
                        id: "230805",
                        name: "东风区",
                        center: "130.403297,46.822476",
                        level: 3
                    },
                    {
                        id: "230811",
                        name: "郊区",
                        center: "130.351588,46.80712",
                        level: 3
                    },
                    {
                        id: "230883",
                        name: "抚远市",
                        center: "134.294501,48.364707",
                        level: 3
                    },
                    {
                        id: "230882",
                        name: "富锦市",
                        center: "132.037951,47.250747",
                        level: 3
                    },
                    {
                        id: "230822",
                        name: "桦南县",
                        center: "130.570112,46.240118",
                        level: 3
                    }
                ]
            },
            {
                id: "230500",
                name: "双鸭山市",
                center: "131.157304,46.643442",
                level: 2,
                children: [
                    {
                        id: "230522",
                        name: "友谊县",
                        center: "131.810622,46.775159",
                        level: 3
                    },
                    {
                        id: "230505",
                        name: "四方台区",
                        center: "131.333181,46.594347",
                        level: 3
                    },
                    {
                        id: "230502",
                        name: "尖山区",
                        center: "131.15896,46.642961",
                        level: 3
                    },
                    {
                        id: "230503",
                        name: "岭东区",
                        center: "131.163675,46.591076",
                        level: 3
                    },
                    {
                        id: "230506",
                        name: "宝山区",
                        center: "131.404294,46.573366",
                        level: 3
                    },
                    {
                        id: "230523",
                        name: "宝清县",
                        center: "132.206415,46.328781",
                        level: 3
                    },
                    {
                        id: "230524",
                        name: "饶河县",
                        center: "134.021162,46.801288",
                        level: 3
                    },
                    {
                        id: "230521",
                        name: "集贤县",
                        center: "131.13933,46.72898",
                        level: 3
                    }
                ]
            },
            {
                id: "230300",
                name: "鸡西市",
                center: "130.975966,45.300046",
                level: 2,
                children: [
                    {
                        id: "230304",
                        name: "滴道区",
                        center: "130.846823,45.348812",
                        level: 3
                    },
                    {
                        id: "230306",
                        name: "城子河区",
                        center: "131.010501,45.338248",
                        level: 3
                    },
                    {
                        id: "230302",
                        name: "鸡冠区",
                        center: "130.974374,45.30034",
                        level: 3
                    },
                    {
                        id: "230307",
                        name: "麻山区",
                        center: "130.481126,45.209607",
                        level: 3
                    },
                    {
                        id: "230382",
                        name: "密山市",
                        center: "131.874137,45.54725",
                        level: 3
                    },
                    {
                        id: "230303",
                        name: "恒山区",
                        center: "130.910636,45.213242",
                        level: 3
                    },
                    {
                        id: "230305",
                        name: "梨树区",
                        center: "130.697781,45.092195",
                        level: 3
                    },
                    {
                        id: "230321",
                        name: "鸡东县",
                        center: "131.148907,45.250892",
                        level: 3
                    },
                    {
                        id: "230381",
                        name: "虎林市",
                        center: "132.973881,45.767985",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "310000",
        name: "上海市",
        center: "121.472644,31.231706",
        level: 1,
        children: [
            {
                id: "310100",
                name: "上海城区",
                center: "121.472644,31.231706",
                level: 2,
                children: [
                    {
                        id: "310151",
                        name: "崇明区",
                        center: "121.397516,31.626946",
                        level: 3
                    },
                    {
                        id: "310115",
                        name: "浦东新区",
                        center: "121.567706,31.245944",
                        level: 3
                    },
                    {
                        id: "310120",
                        name: "奉贤区",
                        center: "121.458472,30.912345",
                        level: 3
                    },
                    {
                        id: "310116",
                        name: "金山区",
                        center: "121.330736,30.724697",
                        level: 3
                    },
                    {
                        id: "310114",
                        name: "嘉定区",
                        center: "121.250333,31.383524",
                        level: 3
                    },
                    {
                        id: "310113",
                        name: "宝山区",
                        center: "121.489934,31.398896",
                        level: 3
                    },
                    {
                        id: "310107",
                        name: "普陀区",
                        center: "121.392499,31.241701",
                        level: 3
                    },
                    {
                        id: "310101",
                        name: "黄浦区",
                        center: "121.490317,31.222771",
                        level: 3
                    },
                    {
                        id: "310110",
                        name: "杨浦区",
                        center: "121.522797,31.270755",
                        level: 3
                    },
                    {
                        id: "310117",
                        name: "松江区",
                        center: "121.223543,31.03047",
                        level: 3
                    },
                    {
                        id: "310106",
                        name: "静安区",
                        center: "121.448224,31.229003",
                        level: 3
                    },
                    {
                        id: "310109",
                        name: "虹口区",
                        center: "121.491832,31.26097",
                        level: 3
                    },
                    {
                        id: "310105",
                        name: "长宁区",
                        center: "121.4222,31.218123",
                        level: 3
                    },
                    {
                        id: "310112",
                        name: "闵行区",
                        center: "121.375972,31.111658",
                        level: 3
                    },
                    {
                        id: "310104",
                        name: "徐汇区",
                        center: "121.43752,31.179973",
                        level: 3
                    },
                    {
                        id: "310118",
                        name: "青浦区",
                        center: "121.113021,31.151209",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "320000",
        name: "江苏省",
        center: "118.767413,32.041544",
        level: 1,
        children: [
            {
                id: "320700",
                name: "连云港市",
                center: "119.178821,34.600018",
                level: 2,
                children: [
                    {
                        id: "320703",
                        name: "连云区",
                        center: "119.366487,34.739529",
                        level: 3
                    },
                    {
                        id: "320723",
                        name: "灌云县",
                        center: "119.255741,34.298436",
                        level: 3
                    },
                    {
                        id: "320722",
                        name: "东海县",
                        center: "118.766489,34.522859",
                        level: 3
                    },
                    {
                        id: "320707",
                        name: "赣榆区",
                        center: "119.128774,34.839154",
                        level: 3
                    },
                    {
                        id: "320706",
                        name: "海州区",
                        center: "119.179793,34.601584",
                        level: 3
                    },
                    {
                        id: "320724",
                        name: "灌南县",
                        center: "119.352331,34.092553",
                        level: 3
                    }
                ]
            },
            {
                id: "320100",
                name: "南京市",
                center: "118.767413,32.041544",
                level: 2,
                children: [
                    {
                        id: "320115",
                        name: "江宁区",
                        center: "118.850621,31.953418",
                        level: 3
                    },
                    {
                        id: "320111",
                        name: "浦口区",
                        center: "118.625307,32.05839",
                        level: 3
                    },
                    {
                        id: "320114",
                        name: "雨花台区",
                        center: "118.77207,31.995946",
                        level: 3
                    },
                    {
                        id: "320117",
                        name: "溧水区",
                        center: "119.028732,31.653061",
                        level: 3
                    },
                    {
                        id: "320118",
                        name: "高淳区",
                        center: "118.87589,31.327132",
                        level: 3
                    },
                    {
                        id: "320116",
                        name: "六合区",
                        center: "118.85065,32.340655",
                        level: 3
                    },
                    {
                        id: "320105",
                        name: "建邺区",
                        center: "118.732688,32.004538",
                        level: 3
                    },
                    {
                        id: "320106",
                        name: "鼓楼区",
                        center: "118.769739,32.066966",
                        level: 3
                    },
                    {
                        id: "320104",
                        name: "秦淮区",
                        center: "118.786088,32.033818",
                        level: 3
                    },
                    {
                        id: "320102",
                        name: "玄武区",
                        center: "118.792199,32.050678",
                        level: 3
                    },
                    {
                        id: "320113",
                        name: "栖霞区",
                        center: "118.808702,32.102147",
                        level: 3
                    }
                ]
            },
            {
                id: "321300",
                name: "宿迁市",
                center: "118.275162,33.963008",
                level: 2,
                children: [
                    {
                        id: "321322",
                        name: "沭阳县",
                        center: "118.775889,34.129097",
                        level: 3
                    },
                    {
                        id: "321323",
                        name: "泗阳县",
                        center: "118.681284,33.711433",
                        level: 3
                    },
                    {
                        id: "321324",
                        name: "泗洪县",
                        center: "118.211824,33.456538",
                        level: 3
                    },
                    {
                        id: "321302",
                        name: "宿城区",
                        center: "118.278984,33.937726",
                        level: 3
                    },
                    {
                        id: "321311",
                        name: "宿豫区",
                        center: "118.330012,33.941071",
                        level: 3
                    }
                ]
            },
            {
                id: "320600",
                name: "南通市",
                center: "120.864608,32.016212",
                level: 2,
                children: [
                    {
                        id: "320684",
                        name: "海门市",
                        center: "121.176609,31.893528",
                        level: 3
                    },
                    {
                        id: "320682",
                        name: "如皋市",
                        center: "120.566324,32.391591",
                        level: 3
                    },
                    {
                        id: "320681",
                        name: "启东市",
                        center: "121.659724,31.810158",
                        level: 3
                    },
                    {
                        id: "320685",
                        name: "海安市",
                        center: "120.465995,32.540289",
                        level: 3
                    },
                    {
                        id: "320623",
                        name: "如东县",
                        center: "121.186088,32.311832",
                        level: 3
                    },
                    {
                        id: "320612",
                        name: "通州区",
                        center: "121.073171,32.084287",
                        level: 3
                    },
                    {
                        id: "320602",
                        name: "崇川区",
                        center: "120.86635,32.015278",
                        level: 3
                    },
                    {
                        id: "320611",
                        name: "港闸区",
                        center: "120.8339,32.040299",
                        level: 3
                    }
                ]
            },
            {
                id: "320800",
                name: "淮安市",
                center: "119.021265,33.597506",
                level: 2,
                children: [
                    {
                        id: "320804",
                        name: "淮阴区",
                        center: "119.020817,33.622452",
                        level: 3
                    },
                    {
                        id: "320813",
                        name: "洪泽区",
                        center: "118.867875,33.294975",
                        level: 3
                    },
                    {
                        id: "320831",
                        name: "金湖县",
                        center: "119.016936,33.018162",
                        level: 3
                    },
                    {
                        id: "320830",
                        name: "盱眙县",
                        center: "118.493823,33.00439",
                        level: 3
                    },
                    {
                        id: "320826",
                        name: "涟水县",
                        center: "119.266078,33.771308",
                        level: 3
                    },
                    {
                        id: "320803",
                        name: "淮安区",
                        center: "119.14634,33.507499",
                        level: 3
                    },
                    {
                        id: "320812",
                        name: "清江浦区",
                        center: "119.019454,33.603234",
                        level: 3
                    }
                ]
            },
            {
                id: "320200",
                name: "无锡市",
                center: "120.301663,31.574729",
                level: 2,
                children: [
                    {
                        id: "320213",
                        name: "梁溪区",
                        center: "120.296595,31.575706",
                        level: 3
                    },
                    {
                        id: "320214",
                        name: "新吴区",
                        center: "120.352782,31.550966",
                        level: 3
                    },
                    {
                        id: "320206",
                        name: "惠山区",
                        center: "120.303543,31.681019",
                        level: 3
                    },
                    {
                        id: "320281",
                        name: "江阴市",
                        center: "120.275891,31.910984",
                        level: 3
                    },
                    {
                        id: "320211",
                        name: "滨湖区",
                        center: "120.266053,31.550228",
                        level: 3
                    },
                    {
                        id: "320282",
                        name: "宜兴市",
                        center: "119.820538,31.364384",
                        level: 3
                    },
                    {
                        id: "320205",
                        name: "锡山区",
                        center: "120.357298,31.585559",
                        level: 3
                    }
                ]
            },
            {
                id: "320400",
                name: "常州市",
                center: "119.946973,31.772752",
                level: 2,
                children: [
                    {
                        id: "320402",
                        name: "天宁区",
                        center: "119.963783,31.779632",
                        level: 3
                    },
                    {
                        id: "320404",
                        name: "钟楼区",
                        center: "119.948388,31.78096",
                        level: 3
                    },
                    {
                        id: "320412",
                        name: "武进区",
                        center: "119.958773,31.718566",
                        level: 3
                    },
                    {
                        id: "320413",
                        name: "金坛区",
                        center: "119.573395,31.744399",
                        level: 3
                    },
                    {
                        id: "320481",
                        name: "溧阳市",
                        center: "119.487816,31.427081",
                        level: 3
                    },
                    {
                        id: "320411",
                        name: "新北区",
                        center: "119.974654,31.824664",
                        level: 3
                    }
                ]
            },
            {
                id: "320500",
                name: "苏州市",
                center: "120.619585,31.299379",
                level: 2,
                children: [
                    {
                        id: "320582",
                        name: "张家港市",
                        center: "120.543441,31.865553",
                        level: 3
                    },
                    {
                        id: "320585",
                        name: "太仓市",
                        center: "121.112275,31.452568",
                        level: 3
                    },
                    {
                        id: "320505",
                        name: "虎丘区",
                        center: "120.566833,31.294845",
                        level: 3
                    },
                    {
                        id: "320508",
                        name: "姑苏区",
                        center: "120.622249,31.311414",
                        level: 3
                    },
                    {
                        id: "320509",
                        name: "吴江区",
                        center: "120.641601,31.160404",
                        level: 3
                    },
                    {
                        id: "320507",
                        name: "相城区",
                        center: "120.618956,31.396684",
                        level: 3
                    },
                    {
                        id: "320571",
                        name: "苏州工业园区",
                        center: "120.723343,31.324036",
                        level: 3
                    },
                    {
                        id: "320581",
                        name: "常熟市",
                        center: "120.74852,31.658156",
                        level: 3
                    },
                    {
                        id: "320506",
                        name: "吴中区",
                        center: "120.624621,31.270839",
                        level: 3
                    },
                    {
                        id: "320583",
                        name: "昆山市",
                        center: "120.958137,31.381925",
                        level: 3
                    }
                ]
            },
            {
                id: "320300",
                name: "徐州市",
                center: "117.184811,34.261792",
                level: 2,
                children: [
                    {
                        id: "320381",
                        name: "新沂市",
                        center: "118.345828,34.368779",
                        level: 3
                    },
                    {
                        id: "320311",
                        name: "泉山区",
                        center: "117.182225,34.262249",
                        level: 3
                    },
                    {
                        id: "320312",
                        name: "铜山区",
                        center: "117.183894,34.19288",
                        level: 3
                    },
                    {
                        id: "320303",
                        name: "云龙区",
                        center: "117.194589,34.254805",
                        level: 3
                    },
                    {
                        id: "320305",
                        name: "贾汪区",
                        center: "117.450212,34.441642",
                        level: 3
                    },
                    {
                        id: "320302",
                        name: "鼓楼区",
                        center: "117.192941,34.269397",
                        level: 3
                    },
                    {
                        id: "320321",
                        name: "丰县",
                        center: "116.592888,34.696946",
                        level: 3
                    },
                    {
                        id: "320322",
                        name: "沛县",
                        center: "116.937182,34.729044",
                        level: 3
                    },
                    {
                        id: "320324",
                        name: "睢宁县",
                        center: "117.95066,33.899222",
                        level: 3
                    },
                    {
                        id: "320382",
                        name: "邳州市",
                        center: "117.963923,34.314708",
                        level: 3
                    }
                ]
            },
            {
                id: "321000",
                name: "扬州市",
                center: "119.421003,32.393159",
                level: 2,
                children: [
                    {
                        id: "321003",
                        name: "邗江区",
                        center: "119.397777,32.377899",
                        level: 3
                    },
                    {
                        id: "321002",
                        name: "广陵区",
                        center: "119.442267,32.392154",
                        level: 3
                    },
                    {
                        id: "321081",
                        name: "仪征市",
                        center: "119.182443,32.271965",
                        level: 3
                    },
                    {
                        id: "321084",
                        name: "高邮市",
                        center: "119.443842,32.785164",
                        level: 3
                    },
                    {
                        id: "321023",
                        name: "宝应县",
                        center: "119.321284,33.23694",
                        level: 3
                    },
                    {
                        id: "321012",
                        name: "江都区",
                        center: "119.567481,32.426564",
                        level: 3
                    }
                ]
            },
            {
                id: "321200",
                name: "泰州市",
                center: "119.915176,32.484882",
                level: 2,
                children: [
                    {
                        id: "321282",
                        name: "靖江市",
                        center: "120.26825,32.018168",
                        level: 3
                    },
                    {
                        id: "321283",
                        name: "泰兴市",
                        center: "120.020228,32.168784",
                        level: 3
                    },
                    {
                        id: "321281",
                        name: "兴化市",
                        center: "119.840162,32.938065",
                        level: 3
                    },
                    {
                        id: "321203",
                        name: "高港区",
                        center: "119.88166,32.315701",
                        level: 3
                    },
                    {
                        id: "321204",
                        name: "姜堰区",
                        center: "120.148208,32.508483",
                        level: 3
                    },
                    {
                        id: "321202",
                        name: "海陵区",
                        center: "119.920187,32.488406",
                        level: 3
                    }
                ]
            },
            {
                id: "320900",
                name: "盐城市",
                center: "120.139998,33.377631",
                level: 2,
                children: [
                    {
                        id: "320922",
                        name: "滨海县",
                        center: "119.828434,33.989888",
                        level: 3
                    },
                    {
                        id: "320924",
                        name: "射阳县",
                        center: "120.257444,33.773779",
                        level: 3
                    },
                    {
                        id: "320925",
                        name: "建湖县",
                        center: "119.793105,33.472621",
                        level: 3
                    },
                    {
                        id: "320923",
                        name: "阜宁县",
                        center: "119.805338,33.78573",
                        level: 3
                    },
                    {
                        id: "320902",
                        name: "亭湖区",
                        center: "120.136078,33.383912",
                        level: 3
                    },
                    {
                        id: "320903",
                        name: "盐都区",
                        center: "120.139753,33.341288",
                        level: 3
                    },
                    {
                        id: "320981",
                        name: "东台市",
                        center: "120.314101,32.853174",
                        level: 3
                    },
                    {
                        id: "320904",
                        name: "大丰区",
                        center: "120.470324,33.199531",
                        level: 3
                    },
                    {
                        id: "320921",
                        name: "响水县",
                        center: "119.579573,34.19996",
                        level: 3
                    }
                ]
            },
            {
                id: "321100",
                name: "镇江市",
                center: "119.452753,32.204402",
                level: 2,
                children: [
                    {
                        id: "321182",
                        name: "扬中市",
                        center: "119.828054,32.237266",
                        level: 3
                    },
                    {
                        id: "321111",
                        name: "润州区",
                        center: "119.414877,32.213501",
                        level: 3
                    },
                    {
                        id: "321112",
                        name: "丹徒区",
                        center: "119.433883,32.128972",
                        level: 3
                    },
                    {
                        id: "321102",
                        name: "京口区",
                        center: "119.454571,32.206191",
                        level: 3
                    },
                    {
                        id: "321181",
                        name: "丹阳市",
                        center: "119.581911,31.991459",
                        level: 3
                    },
                    {
                        id: "321183",
                        name: "句容市",
                        center: "119.167135,31.947355",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "330000",
        name: "浙江省",
        center: "120.153576,30.287459",
        level: 1,
        children: [
            {
                id: "330200",
                name: "宁波市",
                center: "121.549792,29.868388",
                level: 2,
                children: [
                    {
                        id: "330206",
                        name: "北仑区",
                        center: "121.831303,29.90944",
                        level: 3
                    },
                    {
                        id: "330225",
                        name: "象山县",
                        center: "121.877091,29.470206",
                        level: 3
                    },
                    {
                        id: "330211",
                        name: "镇海区",
                        center: "121.713162,29.952107",
                        level: 3
                    },
                    {
                        id: "330213",
                        name: "奉化区",
                        center: "121.41089,29.662348",
                        level: 3
                    },
                    {
                        id: "330205",
                        name: "江北区",
                        center: "121.559282,29.888361",
                        level: 3
                    },
                    {
                        id: "330226",
                        name: "宁海县",
                        center: "121.432606,29.299836",
                        level: 3
                    },
                    {
                        id: "330203",
                        name: "海曙区",
                        center: "121.539698,29.874452",
                        level: 3
                    },
                    {
                        id: "330212",
                        name: "鄞州区",
                        center: "121.558436,29.831662",
                        level: 3
                    },
                    {
                        id: "330281",
                        name: "余姚市",
                        center: "121.156294,30.045404",
                        level: 3
                    },
                    {
                        id: "330282",
                        name: "慈溪市",
                        center: "121.248052,30.177142",
                        level: 3
                    }
                ]
            },
            {
                id: "330300",
                name: "温州市",
                center: "120.672111,28.000575",
                level: 2,
                children: [
                    {
                        id: "330324",
                        name: "永嘉县",
                        center: "120.690968,28.153886",
                        level: 3
                    },
                    {
                        id: "330305",
                        name: "洞头区",
                        center: "121.156181,27.836057",
                        level: 3
                    },
                    {
                        id: "330382",
                        name: "乐清市",
                        center: "120.967147,28.116083",
                        level: 3
                    },
                    {
                        id: "330381",
                        name: "瑞安市",
                        center: "120.646171,27.779321",
                        level: 3
                    },
                    {
                        id: "330383",
                        name: "龙港市",
                        center: "120.553036,27.57815",
                        level: 3
                    },
                    {
                        id: "330329",
                        name: "泰顺县",
                        center: "119.71624,27.557309",
                        level: 3
                    },
                    {
                        id: "330326",
                        name: "平阳县",
                        center: "120.564387,27.6693",
                        level: 3
                    },
                    {
                        id: "330327",
                        name: "苍南县",
                        center: "120.406256,27.507743",
                        level: 3
                    },
                    {
                        id: "330328",
                        name: "文成县",
                        center: "120.09245,27.789133",
                        level: 3
                    },
                    {
                        id: "330304",
                        name: "瓯海区",
                        center: "120.637145,28.006444",
                        level: 3
                    },
                    {
                        id: "330302",
                        name: "鹿城区",
                        center: "120.674231,28.003352",
                        level: 3
                    },
                    {
                        id: "330303",
                        name: "龙湾区",
                        center: "120.763469,27.970254",
                        level: 3
                    }
                ]
            },
            {
                id: "330400",
                name: "嘉兴市",
                center: "120.750865,30.762653",
                level: 2,
                children: [
                    {
                        id: "330482",
                        name: "平湖市",
                        center: "121.014666,30.698921",
                        level: 3
                    },
                    {
                        id: "330402",
                        name: "南湖区",
                        center: "120.749953,30.764652",
                        level: 3
                    },
                    {
                        id: "330411",
                        name: "秀洲区",
                        center: "120.720431,30.763323",
                        level: 3
                    },
                    {
                        id: "330421",
                        name: "嘉善县",
                        center: "120.921871,30.841352",
                        level: 3
                    },
                    {
                        id: "330424",
                        name: "海盐县",
                        center: "120.942017,30.522223",
                        level: 3
                    },
                    {
                        id: "330483",
                        name: "桐乡市",
                        center: "120.551085,30.629065",
                        level: 3
                    },
                    {
                        id: "330481",
                        name: "海宁市",
                        center: "120.688821,30.525544",
                        level: 3
                    }
                ]
            },
            {
                id: "330900",
                name: "舟山市",
                center: "122.106863,30.016028",
                level: 2,
                children: [
                    {
                        id: "330922",
                        name: "嵊泗县",
                        center: "122.457809,30.727166",
                        level: 3
                    },
                    {
                        id: "330921",
                        name: "岱山县",
                        center: "122.201132,30.242865",
                        level: 3
                    },
                    {
                        id: "330903",
                        name: "普陀区",
                        center: "122.301953,29.945614",
                        level: 3
                    },
                    {
                        id: "330902",
                        name: "定海区",
                        center: "122.108496,30.016423",
                        level: 3
                    }
                ]
            },
            {
                id: "331000",
                name: "台州市",
                center: "121.428599,28.661378",
                level: 2,
                children: [
                    {
                        id: "331083",
                        name: "玉环市",
                        center: "121.232337,28.12842",
                        level: 3
                    },
                    {
                        id: "331022",
                        name: "三门县",
                        center: "121.376429,29.118955",
                        level: 3
                    },
                    {
                        id: "331082",
                        name: "临海市",
                        center: "121.131229,28.845441",
                        level: 3
                    },
                    {
                        id: "331003",
                        name: "黄岩区",
                        center: "121.262138,28.64488",
                        level: 3
                    },
                    {
                        id: "331002",
                        name: "椒江区",
                        center: "121.431049,28.67615",
                        level: 3
                    },
                    {
                        id: "331023",
                        name: "天台县",
                        center: "121.031227,29.141126",
                        level: 3
                    },
                    {
                        id: "331004",
                        name: "路桥区",
                        center: "121.37292,28.581799",
                        level: 3
                    },
                    {
                        id: "331024",
                        name: "仙居县",
                        center: "120.735074,28.849213",
                        level: 3
                    },
                    {
                        id: "331081",
                        name: "温岭市",
                        center: "121.373611,28.368781",
                        level: 3
                    }
                ]
            },
            {
                id: "331100",
                name: "丽水市",
                center: "119.921786,28.451993",
                level: 2,
                children: [
                    {
                        id: "331122",
                        name: "缙云县",
                        center: "120.078965,28.654208",
                        level: 3
                    },
                    {
                        id: "331124",
                        name: "松阳县",
                        center: "119.485292,28.449937",
                        level: 3
                    },
                    {
                        id: "331123",
                        name: "遂昌县",
                        center: "119.27589,28.5924",
                        level: 3
                    },
                    {
                        id: "331102",
                        name: "莲都区",
                        center: "119.922293,28.451103",
                        level: 3
                    },
                    {
                        id: "331181",
                        name: "龙泉市",
                        center: "119.132319,28.069177",
                        level: 3
                    },
                    {
                        id: "331121",
                        name: "青田县",
                        center: "120.291939,28.135247",
                        level: 3
                    },
                    {
                        id: "331125",
                        name: "云和县",
                        center: "119.569458,28.111077",
                        level: 3
                    },
                    {
                        id: "331127",
                        name: "景宁畲族自治县",
                        center: "119.634669,27.977247",
                        level: 3
                    },
                    {
                        id: "331126",
                        name: "庆元县",
                        center: "119.067233,27.618231",
                        level: 3
                    }
                ]
            },
            {
                id: "330600",
                name: "绍兴市",
                center: "120.582112,29.997117",
                level: 2,
                children: [
                    {
                        id: "330604",
                        name: "上虞区",
                        center: "120.874185,30.016769",
                        level: 3
                    },
                    {
                        id: "330683",
                        name: "嵊州市",
                        center: "120.82888,29.586606",
                        level: 3
                    },
                    {
                        id: "330602",
                        name: "越城区",
                        center: "120.585315,29.996993",
                        level: 3
                    },
                    {
                        id: "330603",
                        name: "柯桥区",
                        center: "120.476075,30.078038",
                        level: 3
                    },
                    {
                        id: "330681",
                        name: "诸暨市",
                        center: "120.244326,29.713662",
                        level: 3
                    },
                    {
                        id: "330624",
                        name: "新昌县",
                        center: "120.905665,29.501205",
                        level: 3
                    }
                ]
            },
            {
                id: "330500",
                name: "湖州市",
                center: "120.102398,30.867198",
                level: 2,
                children: [
                    {
                        id: "330521",
                        name: "德清县",
                        center: "119.967662,30.534927",
                        level: 3
                    },
                    {
                        id: "330523",
                        name: "安吉县",
                        center: "119.687891,30.631974",
                        level: 3
                    },
                    {
                        id: "330502",
                        name: "吴兴区",
                        center: "120.101416,30.867252",
                        level: 3
                    },
                    {
                        id: "330522",
                        name: "长兴县",
                        center: "119.910122,31.00475",
                        level: 3
                    },
                    {
                        id: "330503",
                        name: "南浔区",
                        center: "120.417195,30.872742",
                        level: 3
                    }
                ]
            },
            {
                id: "330800",
                name: "衢州市",
                center: "118.87263,28.941708",
                level: 2,
                children: [
                    {
                        id: "330803",
                        name: "衢江区",
                        center: "118.957683,28.973195",
                        level: 3
                    },
                    {
                        id: "330822",
                        name: "常山县",
                        center: "118.521654,28.900039",
                        level: 3
                    },
                    {
                        id: "330824",
                        name: "开化县",
                        center: "118.414435,29.136503",
                        level: 3
                    },
                    {
                        id: "330802",
                        name: "柯城区",
                        center: "118.873041,28.944539",
                        level: 3
                    },
                    {
                        id: "330881",
                        name: "江山市",
                        center: "118.627879,28.734674",
                        level: 3
                    },
                    {
                        id: "330825",
                        name: "龙游县",
                        center: "119.172525,29.031364",
                        level: 3
                    }
                ]
            },
            {
                id: "330700",
                name: "金华市",
                center: "119.649506,29.089524",
                level: 2,
                children: [
                    {
                        id: "330726",
                        name: "浦江县",
                        center: "119.893363,29.451254",
                        level: 3
                    },
                    {
                        id: "330727",
                        name: "磐安县",
                        center: "120.44513,29.052627",
                        level: 3
                    },
                    {
                        id: "330781",
                        name: "兰溪市",
                        center: "119.460521,29.210065",
                        level: 3
                    },
                    {
                        id: "330784",
                        name: "永康市",
                        center: "120.036328,28.895293",
                        level: 3
                    },
                    {
                        id: "330783",
                        name: "东阳市",
                        center: "120.23334,29.262546",
                        level: 3
                    },
                    {
                        id: "330723",
                        name: "武义县",
                        center: "119.819159,28.896563",
                        level: 3
                    },
                    {
                        id: "330782",
                        name: "义乌市",
                        center: "120.074911,29.306863",
                        level: 3
                    },
                    {
                        id: "330703",
                        name: "金东区",
                        center: "119.681264,29.095835",
                        level: 3
                    },
                    {
                        id: "330702",
                        name: "婺城区",
                        center: "119.652579,29.082607",
                        level: 3
                    }
                ]
            },
            {
                id: "330100",
                name: "杭州市",
                center: "120.153576,30.287459",
                level: 2,
                children: [
                    {
                        id: "330106",
                        name: "西湖区",
                        center: "120.147376,30.272934",
                        level: 3
                    },
                    {
                        id: "330182",
                        name: "建德市",
                        center: "119.279089,29.472284",
                        level: 3
                    },
                    {
                        id: "330102",
                        name: "上城区",
                        center: "120.171465,30.250236",
                        level: 3
                    },
                    {
                        id: "330111",
                        name: "富阳区",
                        center: "119.949869,30.049871",
                        level: 3
                    },
                    {
                        id: "330122",
                        name: "桐庐县",
                        center: "119.685045,29.797437",
                        level: 3
                    },
                    {
                        id: "330110",
                        name: "余杭区",
                        center: "120.301737,30.421187",
                        level: 3
                    },
                    {
                        id: "330108",
                        name: "滨江区",
                        center: "120.21062,30.206615",
                        level: 3
                    },
                    {
                        id: "330127",
                        name: "淳安县",
                        center: "119.044276,29.604177",
                        level: 3
                    },
                    {
                        id: "330112",
                        name: "临安区",
                        center: "119.715101,30.231153",
                        level: 3
                    },
                    {
                        id: "330109",
                        name: "萧山区",
                        center: "120.27069,30.162932",
                        level: 3
                    },
                    {
                        id: "330105",
                        name: "拱墅区",
                        center: "120.150053,30.314697",
                        level: 3
                    },
                    {
                        id: "330113",
                        name: "临平区",
                        center: "120.299222,30.419154",
                        level: 3
                    },
                    {
                        id: "330114",
                        name: "钱塘区",
                        center: "120.493941,30.32304",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "340000",
        name: "安徽省",
        center: "117.283042,31.86119",
        level: 1,
        children: [
            {
                id: "340600",
                name: "淮北市",
                center: "116.794664,33.971707",
                level: 2,
                children: [
                    {
                        id: "340621",
                        name: "濉溪县",
                        center: "116.767435,33.916407",
                        level: 3
                    },
                    {
                        id: "340602",
                        name: "杜集区",
                        center: "116.833925,33.991218",
                        level: 3
                    },
                    {
                        id: "340604",
                        name: "烈山区",
                        center: "116.809465,33.889529",
                        level: 3
                    },
                    {
                        id: "340603",
                        name: "相山区",
                        center: "116.790775,33.970916",
                        level: 3
                    }
                ]
            },
            {
                id: "341200",
                name: "阜阳市",
                center: "115.819729,32.896969",
                level: 2,
                children: [
                    {
                        id: "341202",
                        name: "颍州区",
                        center: "115.813914,32.891238",
                        level: 3
                    },
                    {
                        id: "341222",
                        name: "太和县",
                        center: "115.627243,33.16229",
                        level: 3
                    },
                    {
                        id: "341221",
                        name: "临泉县",
                        center: "115.261688,33.062698",
                        level: 3
                    },
                    {
                        id: "341203",
                        name: "颍东区",
                        center: "115.858747,32.908861",
                        level: 3
                    },
                    {
                        id: "341225",
                        name: "阜南县",
                        center: "115.590534,32.638102",
                        level: 3
                    },
                    {
                        id: "341204",
                        name: "颍泉区",
                        center: "115.804525,32.924797",
                        level: 3
                    },
                    {
                        id: "341226",
                        name: "颍上县",
                        center: "116.259122,32.637065",
                        level: 3
                    },
                    {
                        id: "341282",
                        name: "界首市",
                        center: "115.362117,33.26153",
                        level: 3
                    }
                ]
            },
            {
                id: "340500",
                name: "马鞍山市",
                center: "118.507906,31.689362",
                level: 2,
                children: [
                    {
                        id: "340503",
                        name: "花山区",
                        center: "118.511308,31.69902",
                        level: 3
                    },
                    {
                        id: "340506",
                        name: "博望区",
                        center: "118.843742,31.562321",
                        level: 3
                    },
                    {
                        id: "340521",
                        name: "当涂县",
                        center: "118.489873,31.556167",
                        level: 3
                    },
                    {
                        id: "340504",
                        name: "雨山区",
                        center: "118.493104,31.685912",
                        level: 3
                    },
                    {
                        id: "340523",
                        name: "和县",
                        center: "118.362998,31.716634",
                        level: 3
                    },
                    {
                        id: "340522",
                        name: "含山县",
                        center: "118.105545,31.727758",
                        level: 3
                    }
                ]
            },
            {
                id: "340700",
                name: "铜陵市",
                center: "117.816576,30.929935",
                level: 2,
                children: [
                    {
                        id: "340705",
                        name: "铜官区",
                        center: "117.818427,30.93182",
                        level: 3
                    },
                    {
                        id: "340711",
                        name: "郊区",
                        center: "117.80707,30.908927",
                        level: 3
                    },
                    {
                        id: "340706",
                        name: "义安区",
                        center: "117.792288,30.952338",
                        level: 3
                    },
                    {
                        id: "340722",
                        name: "枞阳县",
                        center: "117.222027,30.700615",
                        level: 3
                    }
                ]
            },
            {
                id: "340800",
                name: "安庆市",
                center: "117.043551,30.50883",
                level: 2,
                children: [
                    {
                        id: "340882",
                        name: "潜山市",
                        center: "116.573666,30.638222",
                        level: 3
                    },
                    {
                        id: "340811",
                        name: "宜秀区",
                        center: "117.070003,30.541323",
                        level: 3
                    },
                    {
                        id: "340826",
                        name: "宿松县",
                        center: "116.120204,30.158327",
                        level: 3
                    },
                    {
                        id: "340827",
                        name: "望江县",
                        center: "116.690927,30.12491",
                        level: 3
                    },
                    {
                        id: "340828",
                        name: "岳西县",
                        center: "116.360482,30.848502",
                        level: 3
                    },
                    {
                        id: "340802",
                        name: "迎江区",
                        center: "117.044965,30.506375",
                        level: 3
                    },
                    {
                        id: "340825",
                        name: "太湖县",
                        center: "116.305225,30.451869",
                        level: 3
                    },
                    {
                        id: "340881",
                        name: "桐城市",
                        center: "116.959656,31.050576",
                        level: 3
                    },
                    {
                        id: "340822",
                        name: "怀宁县",
                        center: "116.828664,30.734994",
                        level: 3
                    },
                    {
                        id: "340803",
                        name: "大观区",
                        center: "117.034512,30.505632",
                        level: 3
                    }
                ]
            },
            {
                id: "341600",
                name: "亳州市",
                center: "115.782939,33.869338",
                level: 2,
                children: [
                    {
                        id: "341621",
                        name: "涡阳县",
                        center: "116.211551,33.502831",
                        level: 3
                    },
                    {
                        id: "341602",
                        name: "谯城区",
                        center: "115.781214,33.869284",
                        level: 3
                    },
                    {
                        id: "341623",
                        name: "利辛县",
                        center: "116.207782,33.143503",
                        level: 3
                    },
                    {
                        id: "341622",
                        name: "蒙城县",
                        center: "116.560337,33.260814",
                        level: 3
                    }
                ]
            },
            {
                id: "341800",
                name: "宣城市",
                center: "118.757995,30.945667",
                level: 2,
                children: [
                    {
                        id: "341822",
                        name: "广德市",
                        center: "119.417521,30.893116",
                        level: 3
                    },
                    {
                        id: "341825",
                        name: "旌德县",
                        center: "118.543081,30.288057",
                        level: 3
                    },
                    {
                        id: "341823",
                        name: "泾县",
                        center: "118.412397,30.685975",
                        level: 3
                    },
                    {
                        id: "341824",
                        name: "绩溪县",
                        center: "118.594705,30.065267",
                        level: 3
                    },
                    {
                        id: "341802",
                        name: "宣州区",
                        center: "118.758412,30.946003",
                        level: 3
                    },
                    {
                        id: "341821",
                        name: "郎溪县",
                        center: "119.185024,31.127834",
                        level: 3
                    },
                    {
                        id: "341881",
                        name: "宁国市",
                        center: "118.983407,30.626529",
                        level: 3
                    }
                ]
            },
            {
                id: "341000",
                name: "黄山市",
                center: "118.317325,29.709239",
                level: 2,
                children: [
                    {
                        id: "341023",
                        name: "黟县",
                        center: "117.942911,29.923812",
                        level: 3
                    },
                    {
                        id: "341003",
                        name: "黄山区",
                        center: "118.136639,30.294517",
                        level: 3
                    },
                    {
                        id: "341024",
                        name: "祁门县",
                        center: "117.717237,29.853472",
                        level: 3
                    },
                    {
                        id: "341021",
                        name: "歙县",
                        center: "118.428025,29.867748",
                        level: 3
                    },
                    {
                        id: "341022",
                        name: "休宁县",
                        center: "118.188531,29.788878",
                        level: 3
                    },
                    {
                        id: "341004",
                        name: "徽州区",
                        center: "118.339743,29.825201",
                        level: 3
                    },
                    {
                        id: "341002",
                        name: "屯溪区",
                        center: "118.317354,29.709186",
                        level: 3
                    }
                ]
            },
            {
                id: "341100",
                name: "滁州市",
                center: "118.316264,32.303627",
                level: 2,
                children: [
                    {
                        id: "341124",
                        name: "全椒县",
                        center: "118.268576,32.09385",
                        level: 3
                    },
                    {
                        id: "341182",
                        name: "明光市",
                        center: "117.998048,32.781206",
                        level: 3
                    },
                    {
                        id: "341181",
                        name: "天长市",
                        center: "119.011212,32.6815",
                        level: 3
                    },
                    {
                        id: "341126",
                        name: "凤阳县",
                        center: "117.562461,32.867146",
                        level: 3
                    },
                    {
                        id: "341125",
                        name: "定远县",
                        center: "117.683713,32.527105",
                        level: 3
                    },
                    {
                        id: "341122",
                        name: "来安县",
                        center: "118.433293,32.450231",
                        level: 3
                    },
                    {
                        id: "341103",
                        name: "南谯区",
                        center: "118.296955,32.329841",
                        level: 3
                    },
                    {
                        id: "341102",
                        name: "琅琊区",
                        center: "118.316475,32.303797",
                        level: 3
                    }
                ]
            },
            {
                id: "341700",
                name: "池州市",
                center: "117.489157,30.656037",
                level: 2,
                children: [
                    {
                        id: "341723",
                        name: "青阳县",
                        center: "117.857395,30.63818",
                        level: 3
                    },
                    {
                        id: "341702",
                        name: "贵池区",
                        center: "117.488342,30.657378",
                        level: 3
                    },
                    {
                        id: "341721",
                        name: "东至县",
                        center: "117.021476,30.096568",
                        level: 3
                    },
                    {
                        id: "341722",
                        name: "石台县",
                        center: "117.482907,30.210324",
                        level: 3
                    }
                ]
            },
            {
                id: "340200",
                name: "芜湖市",
                center: "118.376451,31.326319",
                level: 2,
                children: [
                    {
                        id: "340203",
                        name: "弋江区",
                        center: "118.377476,31.313394",
                        level: 3
                    },
                    {
                        id: "340222",
                        name: "繁昌县",
                        center: "118.201349,31.080896",
                        level: 3
                    },
                    {
                        id: "340225",
                        name: "无为县",
                        center: "117.911432,31.303075",
                        level: 3
                    },
                    {
                        id: "340202",
                        name: "镜湖区",
                        center: "118.376343,31.32559",
                        level: 3
                    },
                    {
                        id: "340223",
                        name: "南陵县",
                        center: "118.337104,30.919638",
                        level: 3
                    },
                    {
                        id: "340208",
                        name: "三山区",
                        center: "118.233987,31.225423",
                        level: 3
                    },
                    {
                        id: "340207",
                        name: "鸠江区",
                        center: "118.400174,31.362716",
                        level: 3
                    },
                    {
                        id: "340221",
                        name: "芜湖县",
                        center: "118.572301,31.145262",
                        level: 3
                    }
                ]
            },
            {
                id: "341500",
                name: "六安市",
                center: "116.507676,31.752889",
                level: 2,
                children: [
                    {
                        id: "341525",
                        name: "霍山县",
                        center: "116.333078,31.402456",
                        level: 3
                    },
                    {
                        id: "341503",
                        name: "裕安区",
                        center: "116.494543,31.750692",
                        level: 3
                    },
                    {
                        id: "341524",
                        name: "金寨县",
                        center: "115.878514,31.681624",
                        level: 3
                    },
                    {
                        id: "341502",
                        name: "金安区",
                        center: "116.503288,31.754491",
                        level: 3
                    },
                    {
                        id: "341504",
                        name: "叶集区",
                        center: "115.913594,31.84768",
                        level: 3
                    },
                    {
                        id: "341522",
                        name: "霍邱县",
                        center: "116.278875,32.341305",
                        level: 3
                    },
                    {
                        id: "341523",
                        name: "舒城县",
                        center: "116.944088,31.462848",
                        level: 3
                    }
                ]
            },
            {
                id: "340100",
                name: "合肥市",
                center: "117.283042,31.86119",
                level: 2,
                children: [
                    {
                        id: "340124",
                        name: "庐江县",
                        center: "117.289844,31.251488",
                        level: 3
                    },
                    {
                        id: "340111",
                        name: "包河区",
                        center: "117.285751,31.82956",
                        level: 3
                    },
                    {
                        id: "340181",
                        name: "巢湖市",
                        center: "117.874155,31.600518",
                        level: 3
                    },
                    {
                        id: "340123",
                        name: "肥西县",
                        center: "117.166118,31.719646",
                        level: 3
                    },
                    {
                        id: "340104",
                        name: "蜀山区",
                        center: "117.262072,31.855868",
                        level: 3
                    },
                    {
                        id: "340122",
                        name: "肥东县",
                        center: "117.463222,31.883992",
                        level: 3
                    },
                    {
                        id: "340102",
                        name: "瑶海区",
                        center: "117.315358,31.86961",
                        level: 3
                    },
                    {
                        id: "340103",
                        name: "庐阳区",
                        center: "117.283776,31.869011",
                        level: 3
                    },
                    {
                        id: "340121",
                        name: "长丰县",
                        center: "117.164699,32.478548",
                        level: 3
                    }
                ]
            },
            {
                id: "340400",
                name: "淮南市",
                center: "117.018329,32.647574",
                level: 2,
                children: [
                    {
                        id: "340402",
                        name: "大通区",
                        center: "117.052927,32.632066",
                        level: 3
                    },
                    {
                        id: "340405",
                        name: "八公山区",
                        center: "116.841111,32.628229",
                        level: 3
                    },
                    {
                        id: "340421",
                        name: "凤台县",
                        center: "116.722769,32.705382",
                        level: 3
                    },
                    {
                        id: "340406",
                        name: "潘集区",
                        center: "116.816879,32.782117",
                        level: 3
                    },
                    {
                        id: "340403",
                        name: "田家庵区",
                        center: "117.018318,32.644342",
                        level: 3
                    },
                    {
                        id: "340404",
                        name: "谢家集区",
                        center: "116.865354,32.598289",
                        level: 3
                    },
                    {
                        id: "340422",
                        name: "寿县",
                        center: "116.785349,32.577304",
                        level: 3
                    }
                ]
            },
            {
                id: "341300",
                name: "宿州市",
                center: "116.984084,33.633891",
                level: 2,
                children: [
                    {
                        id: "341302",
                        name: "埇桥区",
                        center: "116.983309,33.633853",
                        level: 3
                    },
                    {
                        id: "341321",
                        name: "砀山县",
                        center: "116.351113,34.426247",
                        level: 3
                    },
                    {
                        id: "341324",
                        name: "泗县",
                        center: "117.885443,33.47758",
                        level: 3
                    },
                    {
                        id: "341322",
                        name: "萧县",
                        center: "116.945399,34.183266",
                        level: 3
                    },
                    {
                        id: "341323",
                        name: "灵璧县",
                        center: "117.551493,33.540629",
                        level: 3
                    }
                ]
            },
            {
                id: "340300",
                name: "蚌埠市",
                center: "117.363228,32.939667",
                level: 2,
                children: [
                    {
                        id: "340322",
                        name: "五河县",
                        center: "117.888809,33.146202",
                        level: 3
                    },
                    {
                        id: "340311",
                        name: "淮上区",
                        center: "117.34709,32.963147",
                        level: 3
                    },
                    {
                        id: "340304",
                        name: "禹会区",
                        center: "117.35259,32.931933",
                        level: 3
                    },
                    {
                        id: "340321",
                        name: "怀远县",
                        center: "117.200171,32.956934",
                        level: 3
                    },
                    {
                        id: "340302",
                        name: "龙子湖区",
                        center: "117.382312,32.950452",
                        level: 3
                    },
                    {
                        id: "340303",
                        name: "蚌山区",
                        center: "117.355789,32.938066",
                        level: 3
                    },
                    {
                        id: "340323",
                        name: "固镇县",
                        center: "117.315962,33.318679",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "350000",
        name: "福建省",
        center: "119.306239,26.075302",
        level: 1,
        children: [
            {
                id: "350100",
                name: "福州市",
                center: "119.306239,26.075302",
                level: 2,
                children: [
                    {
                        id: "350121",
                        name: "闽侯县",
                        center: "119.145117,26.148567",
                        level: 3
                    },
                    {
                        id: "350123",
                        name: "罗源县",
                        center: "119.552645,26.487234",
                        level: 3
                    },
                    {
                        id: "350124",
                        name: "闽清县",
                        center: "118.868416,26.223793",
                        level: 3
                    },
                    {
                        id: "350122",
                        name: "连江县",
                        center: "119.538365,26.202109",
                        level: 3
                    },
                    {
                        id: "350112",
                        name: "长乐区",
                        center: "119.510849,25.960583",
                        level: 3
                    },
                    {
                        id: "350181",
                        name: "福清市",
                        center: "119.376992,25.720402",
                        level: 3
                    },
                    {
                        id: "350125",
                        name: "永泰县",
                        center: "118.939089,25.864825",
                        level: 3
                    },
                    {
                        id: "350104",
                        name: "仓山区",
                        center: "119.320988,26.038912",
                        level: 3
                    },
                    {
                        id: "350128",
                        name: "平潭县",
                        center: "119.791197,25.503672",
                        level: 3
                    },
                    {
                        id: "350102",
                        name: "鼓楼区",
                        center: "119.29929,26.082284",
                        level: 3
                    },
                    {
                        id: "350103",
                        name: "台江区",
                        center: "119.310156,26.058616",
                        level: 3
                    },
                    {
                        id: "350105",
                        name: "马尾区",
                        center: "119.458725,25.991975",
                        level: 3
                    },
                    {
                        id: "350111",
                        name: "晋安区",
                        center: "119.328597,26.078837",
                        level: 3
                    }
                ]
            },
            {
                id: "350900",
                name: "宁德市",
                center: "119.527082,26.65924",
                level: 2,
                children: [
                    {
                        id: "350924",
                        name: "寿宁县",
                        center: "119.506733,27.457798",
                        level: 3
                    },
                    {
                        id: "350981",
                        name: "福安市",
                        center: "119.650798,27.084246",
                        level: 3
                    },
                    {
                        id: "350921",
                        name: "霞浦县",
                        center: "120.005214,26.882068",
                        level: 3
                    },
                    {
                        id: "350902",
                        name: "蕉城区",
                        center: "119.527225,26.659253",
                        level: 3
                    },
                    {
                        id: "350982",
                        name: "福鼎市",
                        center: "120.219761,27.318884",
                        level: 3
                    },
                    {
                        id: "350926",
                        name: "柘荣县",
                        center: "119.898226,27.236163",
                        level: 3
                    },
                    {
                        id: "350922",
                        name: "古田县",
                        center: "118.743156,26.577491",
                        level: 3
                    },
                    {
                        id: "350925",
                        name: "周宁县",
                        center: "119.338239,27.103106",
                        level: 3
                    },
                    {
                        id: "350923",
                        name: "屏南县",
                        center: "118.987544,26.910826",
                        level: 3
                    }
                ]
            },
            {
                id: "350800",
                name: "龙岩市",
                center: "117.02978,25.091603",
                level: 2,
                children: [
                    {
                        id: "350881",
                        name: "漳平市",
                        center: "117.42073,25.291597",
                        level: 3
                    },
                    {
                        id: "350824",
                        name: "武平县",
                        center: "116.100928,25.08865",
                        level: 3
                    },
                    {
                        id: "350823",
                        name: "上杭县",
                        center: "116.424774,25.050019",
                        level: 3
                    },
                    {
                        id: "350803",
                        name: "永定区",
                        center: "116.732691,24.720442",
                        level: 3
                    },
                    {
                        id: "350802",
                        name: "新罗区",
                        center: "117.030721,25.0918",
                        level: 3
                    },
                    {
                        id: "350825",
                        name: "连城县",
                        center: "116.756687,25.708506",
                        level: 3
                    },
                    {
                        id: "350821",
                        name: "长汀县",
                        center: "116.361007,25.842278",
                        level: 3
                    }
                ]
            },
            {
                id: "350700",
                name: "南平市",
                center: "118.178459,26.635627",
                level: 2,
                children: [
                    {
                        id: "350723",
                        name: "光泽县",
                        center: "117.337897,27.542803",
                        level: 3
                    },
                    {
                        id: "350782",
                        name: "武夷山市",
                        center: "118.032796,27.751733",
                        level: 3
                    },
                    {
                        id: "350725",
                        name: "政和县",
                        center: "118.858661,27.365398",
                        level: 3
                    },
                    {
                        id: "350722",
                        name: "浦城县",
                        center: "118.536822,27.920412",
                        level: 3
                    },
                    {
                        id: "350781",
                        name: "邵武市",
                        center: "117.491544,27.337952",
                        level: 3
                    },
                    {
                        id: "350703",
                        name: "建阳区",
                        center: "118.12267,27.332067",
                        level: 3
                    },
                    {
                        id: "350724",
                        name: "松溪县",
                        center: "118.783491,27.525785",
                        level: 3
                    },
                    {
                        id: "350783",
                        name: "建瓯市",
                        center: "118.321765,27.03502",
                        level: 3
                    },
                    {
                        id: "350721",
                        name: "顺昌县",
                        center: "117.80771,26.792851",
                        level: 3
                    },
                    {
                        id: "350702",
                        name: "延平区",
                        center: "118.178918,26.636079",
                        level: 3
                    }
                ]
            },
            {
                id: "350200",
                name: "厦门市",
                center: "118.11022,24.490474",
                level: 2,
                children: [
                    {
                        id: "350203",
                        name: "思明区",
                        center: "118.087828,24.462059",
                        level: 3
                    },
                    {
                        id: "350213",
                        name: "翔安区",
                        center: "118.242811,24.637479",
                        level: 3
                    },
                    {
                        id: "350206",
                        name: "湖里区",
                        center: "118.10943,24.512764",
                        level: 3
                    },
                    {
                        id: "350211",
                        name: "集美区",
                        center: "118.100869,24.572874",
                        level: 3
                    },
                    {
                        id: "350205",
                        name: "海沧区",
                        center: "118.036364,24.492512",
                        level: 3
                    },
                    {
                        id: "350212",
                        name: "同安区",
                        center: "118.150455,24.729333",
                        level: 3
                    }
                ]
            },
            {
                id: "350600",
                name: "漳州市",
                center: "117.661801,24.510897",
                level: 2,
                children: [
                    {
                        id: "350629",
                        name: "华安县",
                        center: "117.53631,25.001416",
                        level: 3
                    },
                    {
                        id: "350626",
                        name: "东山县",
                        center: "117.427679,23.702845",
                        level: 3
                    },
                    {
                        id: "350681",
                        name: "龙海市",
                        center: "117.817292,24.445341",
                        level: 3
                    },
                    {
                        id: "350627",
                        name: "南靖县",
                        center: "117.365462,24.516425",
                        level: 3
                    },
                    {
                        id: "350623",
                        name: "漳浦县",
                        center: "117.614023,24.117907",
                        level: 3
                    },
                    {
                        id: "350624",
                        name: "诏安县",
                        center: "117.176083,23.710834",
                        level: 3
                    },
                    {
                        id: "350603",
                        name: "龙文区",
                        center: "117.671387,24.515656",
                        level: 3
                    },
                    {
                        id: "350602",
                        name: "芗城区",
                        center: "117.656461,24.509955",
                        level: 3
                    },
                    {
                        id: "350622",
                        name: "云霄县",
                        center: "117.340946,23.950486",
                        level: 3
                    },
                    {
                        id: "350628",
                        name: "平和县",
                        center: "117.313549,24.366158",
                        level: 3
                    },
                    {
                        id: "350625",
                        name: "长泰县",
                        center: "117.755913,24.621475",
                        level: 3
                    }
                ]
            },
            {
                id: "350300",
                name: "莆田市",
                center: "119.007558,25.431011",
                level: 2,
                children: [
                    {
                        id: "350302",
                        name: "城厢区",
                        center: "119.001028,25.433737",
                        level: 3
                    },
                    {
                        id: "350322",
                        name: "仙游县",
                        center: "118.694331,25.356529",
                        level: 3
                    },
                    {
                        id: "350305",
                        name: "秀屿区",
                        center: "119.092607,25.316141",
                        level: 3
                    },
                    {
                        id: "350304",
                        name: "荔城区",
                        center: "119.020047,25.430047",
                        level: 3
                    },
                    {
                        id: "350303",
                        name: "涵江区",
                        center: "119.119102,25.459273",
                        level: 3
                    }
                ]
            },
            {
                id: "350500",
                name: "泉州市",
                center: "118.589421,24.908853",
                level: 2,
                children: [
                    {
                        id: "350526",
                        name: "德化县",
                        center: "118.242986,25.489004",
                        level: 3
                    },
                    {
                        id: "350583",
                        name: "南安市",
                        center: "118.387031,24.959494",
                        level: 3
                    },
                    {
                        id: "350524",
                        name: "安溪县",
                        center: "118.186014,25.056824",
                        level: 3
                    },
                    {
                        id: "350505",
                        name: "泉港区",
                        center: "118.912285,25.126859",
                        level: 3
                    },
                    {
                        id: "350525",
                        name: "永春县",
                        center: "118.29503,25.320721",
                        level: 3
                    },
                    {
                        id: "350504",
                        name: "洛江区",
                        center: "118.670312,24.941153",
                        level: 3
                    },
                    {
                        id: "350581",
                        name: "石狮市",
                        center: "118.628402,24.731978",
                        level: 3
                    },
                    {
                        id: "350527",
                        name: "金门县",
                        center: "118.323221,24.436417",
                        level: 3
                    },
                    {
                        id: "350503",
                        name: "丰泽区",
                        center: "118.605147,24.896041",
                        level: 3
                    },
                    {
                        id: "350521",
                        name: "惠安县",
                        center: "118.798954,25.028718",
                        level: 3
                    },
                    {
                        id: "350582",
                        name: "晋江市",
                        center: "118.577338,24.807322",
                        level: 3
                    },
                    {
                        id: "350502",
                        name: "鲤城区",
                        center: "118.588929,24.907645",
                        level: 3
                    }
                ]
            },
            {
                id: "350400",
                name: "三明市",
                center: "117.635001,26.265444",
                level: 2,
                children: [
                    {
                        id: "350402",
                        name: "梅列区",
                        center: "117.63687,26.269208",
                        level: 3
                    },
                    {
                        id: "350403",
                        name: "三元区",
                        center: "117.607418,26.234191",
                        level: 3
                    },
                    {
                        id: "350429",
                        name: "泰宁县",
                        center: "117.177522,26.897995",
                        level: 3
                    },
                    {
                        id: "350423",
                        name: "清流县",
                        center: "116.815821,26.17761",
                        level: 3
                    },
                    {
                        id: "350481",
                        name: "永安市",
                        center: "117.364447,25.974075",
                        level: 3
                    },
                    {
                        id: "350424",
                        name: "宁化县",
                        center: "116.659725,26.259932",
                        level: 3
                    },
                    {
                        id: "350425",
                        name: "大田县",
                        center: "117.849355,25.690803",
                        level: 3
                    },
                    {
                        id: "350430",
                        name: "建宁县",
                        center: "116.845832,26.831398",
                        level: 3
                    },
                    {
                        id: "350426",
                        name: "尤溪县",
                        center: "118.188577,26.169261",
                        level: 3
                    },
                    {
                        id: "350427",
                        name: "沙县",
                        center: "117.789095,26.397361",
                        level: 3
                    },
                    {
                        id: "350421",
                        name: "明溪县",
                        center: "117.201845,26.357375",
                        level: 3
                    },
                    {
                        id: "350428",
                        name: "将乐县",
                        center: "117.473558,26.728667",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "360000",
        name: "江西省",
        center: "115.892151,28.676493",
        level: 1,
        children: [
            {
                id: "360500",
                name: "新余市",
                center: "114.930835,27.810834",
                level: 2,
                children: [
                    {
                        id: "360521",
                        name: "分宜县",
                        center: "114.675262,27.811301",
                        level: 3
                    },
                    {
                        id: "360502",
                        name: "渝水区",
                        center: "114.923923,27.819171",
                        level: 3
                    }
                ]
            },
            {
                id: "361000",
                name: "抚州市",
                center: "116.358351,27.98385",
                level: 2,
                children: [
                    {
                        id: "361024",
                        name: "崇仁县",
                        center: "116.059109,27.760907",
                        level: 3
                    },
                    {
                        id: "361025",
                        name: "乐安县",
                        center: "115.838432,27.420101",
                        level: 3
                    },
                    {
                        id: "361003",
                        name: "东乡区",
                        center: "116.605341,28.2325",
                        level: 3
                    },
                    {
                        id: "361028",
                        name: "资溪县",
                        center: "117.066095,27.70653",
                        level: 3
                    },
                    {
                        id: "361022",
                        name: "黎川县",
                        center: "116.91457,27.292561",
                        level: 3
                    },
                    {
                        id: "361027",
                        name: "金溪县",
                        center: "116.778751,27.907387",
                        level: 3
                    },
                    {
                        id: "361002",
                        name: "临川区",
                        center: "116.361404,27.981919",
                        level: 3
                    },
                    {
                        id: "361021",
                        name: "南城县",
                        center: "116.63945,27.55531",
                        level: 3
                    },
                    {
                        id: "361026",
                        name: "宜黄县",
                        center: "116.223023,27.546512",
                        level: 3
                    },
                    {
                        id: "361023",
                        name: "南丰县",
                        center: "116.532994,27.210132",
                        level: 3
                    },
                    {
                        id: "361030",
                        name: "广昌县",
                        center: "116.327291,26.838426",
                        level: 3
                    }
                ]
            },
            {
                id: "360400",
                name: "九江市",
                center: "115.992811,29.712034",
                level: 2,
                children: [
                    {
                        id: "360429",
                        name: "湖口县",
                        center: "116.244313,29.7263",
                        level: 3
                    },
                    {
                        id: "360481",
                        name: "瑞昌市",
                        center: "115.669081,29.676599",
                        level: 3
                    },
                    {
                        id: "360430",
                        name: "彭泽县",
                        center: "116.55584,29.898865",
                        level: 3
                    },
                    {
                        id: "360404",
                        name: "柴桑区",
                        center: "115.892977,29.610264",
                        level: 3
                    },
                    {
                        id: "360423",
                        name: "武宁县",
                        center: "115.105646,29.260182",
                        level: 3
                    },
                    {
                        id: "360428",
                        name: "都昌县",
                        center: "116.205114,29.275105",
                        level: 3
                    },
                    {
                        id: "360424",
                        name: "修水县",
                        center: "114.573428,29.032729",
                        level: 3
                    },
                    {
                        id: "360483",
                        name: "庐山市",
                        center: "116.043743,29.456169",
                        level: 3
                    },
                    {
                        id: "360402",
                        name: "濂溪区",
                        center: "115.99012,29.676175",
                        level: 3
                    },
                    {
                        id: "360425",
                        name: "永修县",
                        center: "115.809055,29.018212",
                        level: 3
                    },
                    {
                        id: "360426",
                        name: "德安县",
                        center: "115.762611,29.327474",
                        level: 3
                    },
                    {
                        id: "360403",
                        name: "浔阳区",
                        center: "115.995947,29.72465",
                        level: 3
                    },
                    {
                        id: "360482",
                        name: "共青城市",
                        center: "115.805712,29.247884",
                        level: 3
                    }
                ]
            },
            {
                id: "360600",
                name: "鹰潭市",
                center: "117.033838,28.238638",
                level: 2,
                children: [
                    {
                        id: "360603",
                        name: "余江区",
                        center: "116.822763,28.206177",
                        level: 3
                    },
                    {
                        id: "360681",
                        name: "贵溪市",
                        center: "117.212103,28.283693",
                        level: 3
                    },
                    {
                        id: "360602",
                        name: "月湖区",
                        center: "117.034112,28.239076",
                        level: 3
                    }
                ]
            },
            {
                id: "360700",
                name: "赣州市",
                center: "114.940278,25.85097",
                level: 2,
                children: [
                    {
                        id: "360728",
                        name: "定南县",
                        center: "115.03267,24.774277",
                        level: 3
                    },
                    {
                        id: "360735",
                        name: "石城县",
                        center: "116.342249,26.326582",
                        level: 3
                    },
                    {
                        id: "360730",
                        name: "宁都县",
                        center: "116.018782,26.472054",
                        level: 3
                    },
                    {
                        id: "360733",
                        name: "会昌县",
                        center: "115.791158,25.599125",
                        level: 3
                    },
                    {
                        id: "360781",
                        name: "瑞金市",
                        center: "116.034854,25.875278",
                        level: 3
                    },
                    {
                        id: "360727",
                        name: "龙南县",
                        center: "114.792657,24.90476",
                        level: 3
                    },
                    {
                        id: "360734",
                        name: "寻乌县",
                        center: "115.651399,24.954136",
                        level: 3
                    },
                    {
                        id: "360732",
                        name: "兴国县",
                        center: "115.351896,26.330489",
                        level: 3
                    },
                    {
                        id: "360724",
                        name: "上犹县",
                        center: "114.540537,25.794284",
                        level: 3
                    },
                    {
                        id: "360723",
                        name: "大余县",
                        center: "114.362243,25.395937",
                        level: 3
                    },
                    {
                        id: "360725",
                        name: "崇义县",
                        center: "114.307348,25.687911",
                        level: 3
                    },
                    {
                        id: "360702",
                        name: "章贡区",
                        center: "114.93872,25.851367",
                        level: 3
                    },
                    {
                        id: "360729",
                        name: "全南县",
                        center: "114.531589,24.742651",
                        level: 3
                    },
                    {
                        id: "360722",
                        name: "信丰县",
                        center: "114.930893,25.38023",
                        level: 3
                    },
                    {
                        id: "360726",
                        name: "安远县",
                        center: "115.392328,25.134591",
                        level: 3
                    },
                    {
                        id: "360731",
                        name: "于都县",
                        center: "115.411198,25.955033",
                        level: 3
                    },
                    {
                        id: "360703",
                        name: "南康区",
                        center: "114.756933,25.661721",
                        level: 3
                    },
                    {
                        id: "360704",
                        name: "赣县区",
                        center: "115.018461,25.865432",
                        level: 3
                    }
                ]
            },
            {
                id: "360100",
                name: "南昌市",
                center: "115.892151,28.676493",
                level: 2,
                children: [
                    {
                        id: "360123",
                        name: "安义县",
                        center: "115.553109,28.841334",
                        level: 3
                    },
                    {
                        id: "360124",
                        name: "进贤县",
                        center: "116.267671,28.365681",
                        level: 3
                    },
                    {
                        id: "360104",
                        name: "青云谱区",
                        center: "115.907292,28.635724",
                        level: 3
                    },
                    {
                        id: "360121",
                        name: "南昌县",
                        center: "115.942465,28.543781",
                        level: 3
                    },
                    {
                        id: "360112",
                        name: "新建区",
                        center: "115.820806,28.690788",
                        level: 3
                    },
                    {
                        id: "360105",
                        name: "湾里区",
                        center: "115.731324,28.714803",
                        level: 3
                    },
                    {
                        id: "360103",
                        name: "西湖区",
                        center: "115.91065,28.662901",
                        level: 3
                    },
                    {
                        id: "360102",
                        name: "东湖区",
                        center: "115.889675,28.682988",
                        level: 3
                    },
                    {
                        id: "360111",
                        name: "青山湖区",
                        center: "115.949044,28.689292",
                        level: 3
                    },
                    {
                        id: "360113",
                        name: "红谷滩区",
                        center: "115.858127,28.698188",
                        level: 3
                    }
                ]
            },
            {
                id: "360900",
                name: "宜春市",
                center: "114.391136,27.8043",
                level: 2,
                children: [
                    {
                        id: "360925",
                        name: "靖安县",
                        center: "115.361744,28.86054",
                        level: 3
                    },
                    {
                        id: "360921",
                        name: "奉新县",
                        center: "115.389899,28.700672",
                        level: 3
                    },
                    {
                        id: "360924",
                        name: "宜丰县",
                        center: "114.787381,28.388289",
                        level: 3
                    },
                    {
                        id: "360983",
                        name: "高安市",
                        center: "115.381527,28.420951",
                        level: 3
                    },
                    {
                        id: "360981",
                        name: "丰城市",
                        center: "115.786005,28.191584",
                        level: 3
                    },
                    {
                        id: "360923",
                        name: "上高县",
                        center: "114.932653,28.234789",
                        level: 3
                    },
                    {
                        id: "360926",
                        name: "铜鼓县",
                        center: "114.37014,28.520956",
                        level: 3
                    },
                    {
                        id: "360902",
                        name: "袁州区",
                        center: "114.387379,27.800117",
                        level: 3
                    },
                    {
                        id: "360922",
                        name: "万载县",
                        center: "114.449012,28.104528",
                        level: 3
                    },
                    {
                        id: "360982",
                        name: "樟树市",
                        center: "115.543388,28.055898",
                        level: 3
                    }
                ]
            },
            {
                id: "360800",
                name: "吉安市",
                center: "114.986373,27.111699",
                level: 2,
                children: [
                    {
                        id: "360825",
                        name: "永丰县",
                        center: "115.435559,27.321087",
                        level: 3
                    },
                    {
                        id: "360821",
                        name: "吉安县",
                        center: "114.905117,27.040042",
                        level: 3
                    },
                    {
                        id: "360822",
                        name: "吉水县",
                        center: "115.134569,27.213445",
                        level: 3
                    },
                    {
                        id: "360823",
                        name: "峡江县",
                        center: "115.319331,27.580862",
                        level: 3
                    },
                    {
                        id: "360802",
                        name: "吉州区",
                        center: "114.987331,27.112367",
                        level: 3
                    },
                    {
                        id: "360881",
                        name: "井冈山市",
                        center: "114.284421,26.745919",
                        level: 3
                    },
                    {
                        id: "360830",
                        name: "永新县",
                        center: "114.242534,26.944721",
                        level: 3
                    },
                    {
                        id: "360803",
                        name: "青原区",
                        center: "115.016306,27.105879",
                        level: 3
                    },
                    {
                        id: "360828",
                        name: "万安县",
                        center: "114.784694,26.462085",
                        level: 3
                    },
                    {
                        id: "360827",
                        name: "遂川县",
                        center: "114.51689,26.323705",
                        level: 3
                    },
                    {
                        id: "360826",
                        name: "泰和县",
                        center: "114.901393,26.790164",
                        level: 3
                    },
                    {
                        id: "360824",
                        name: "新干县",
                        center: "115.399294,27.755758",
                        level: 3
                    },
                    {
                        id: "360829",
                        name: "安福县",
                        center: "114.61384,27.382746",
                        level: 3
                    }
                ]
            },
            {
                id: "360200",
                name: "景德镇市",
                center: "117.214664,29.29256",
                level: 2,
                children: [
                    {
                        id: "360203",
                        name: "珠山区",
                        center: "117.214814,29.292812",
                        level: 3
                    },
                    {
                        id: "360202",
                        name: "昌江区",
                        center: "117.195023,29.288465",
                        level: 3
                    },
                    {
                        id: "360222",
                        name: "浮梁县",
                        center: "117.217611,29.352251",
                        level: 3
                    },
                    {
                        id: "360281",
                        name: "乐平市",
                        center: "117.129376,28.967361",
                        level: 3
                    }
                ]
            },
            {
                id: "361100",
                name: "上饶市",
                center: "117.971185,28.44442",
                level: 2,
                children: [
                    {
                        id: "361130",
                        name: "婺源县",
                        center: "117.86219,29.254015",
                        level: 3
                    },
                    {
                        id: "361181",
                        name: "德兴市",
                        center: "117.578732,28.945034",
                        level: 3
                    },
                    {
                        id: "361127",
                        name: "余干县",
                        center: "116.691072,28.69173",
                        level: 3
                    },
                    {
                        id: "361126",
                        name: "弋阳县",
                        center: "117.435002,28.402391",
                        level: 3
                    },
                    {
                        id: "361102",
                        name: "信州区",
                        center: "117.970522,28.445378",
                        level: 3
                    },
                    {
                        id: "361121",
                        name: "广信区",
                        center: "117.90612,28.453897",
                        level: 3
                    },
                    {
                        id: "361125",
                        name: "横峰县",
                        center: "117.608247,28.415103",
                        level: 3
                    },
                    {
                        id: "361103",
                        name: "广丰区",
                        center: "118.189852,28.440285",
                        level: 3
                    },
                    {
                        id: "361124",
                        name: "铅山县",
                        center: "117.711906,28.310892",
                        level: 3
                    },
                    {
                        id: "361123",
                        name: "玉山县",
                        center: "118.244408,28.673479",
                        level: 3
                    },
                    {
                        id: "361129",
                        name: "万年县",
                        center: "117.07015,28.692589",
                        level: 3
                    },
                    {
                        id: "361128",
                        name: "鄱阳县",
                        center: "116.673748,28.993374",
                        level: 3
                    }
                ]
            },
            {
                id: "360300",
                name: "萍乡市",
                center: "113.852186,27.622946",
                level: 2,
                children: [
                    {
                        id: "360302",
                        name: "安源区",
                        center: "113.855044,27.625826",
                        level: 3
                    },
                    {
                        id: "360322",
                        name: "上栗县",
                        center: "113.800525,27.877041",
                        level: 3
                    },
                    {
                        id: "360321",
                        name: "莲花县",
                        center: "113.955582,27.127807",
                        level: 3
                    },
                    {
                        id: "360323",
                        name: "芦溪县",
                        center: "114.041206,27.633633",
                        level: 3
                    },
                    {
                        id: "360313",
                        name: "湘东区",
                        center: "113.7456,27.639319",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "370000",
        name: "山东省",
        center: "117.000923,36.675807",
        level: 1,
        children: [
            {
                id: "370600",
                name: "烟台市",
                center: "121.391382,37.539297",
                level: 2,
                children: [
                    {
                        id: "370634",
                        name: "长岛县",
                        center: "120.738345,37.916194",
                        level: 3
                    },
                    {
                        id: "370683",
                        name: "莱州市",
                        center: "119.942135,37.182725",
                        level: 3
                    },
                    {
                        id: "370602",
                        name: "芝罘区",
                        center: "121.385877,37.540925",
                        level: 3
                    },
                    {
                        id: "370687",
                        name: "海阳市",
                        center: "121.168392,36.780657",
                        level: 3
                    },
                    {
                        id: "370681",
                        name: "龙口市",
                        center: "120.528328,37.648446",
                        level: 3
                    },
                    {
                        id: "370682",
                        name: "莱阳市",
                        center: "120.711151,36.977037",
                        level: 3
                    },
                    {
                        id: "370684",
                        name: "蓬莱市",
                        center: "120.762689,37.811168",
                        level: 3
                    },
                    {
                        id: "370685",
                        name: "招远市",
                        center: "120.403142,37.364919",
                        level: 3
                    },
                    {
                        id: "370611",
                        name: "福山区",
                        center: "121.264741,37.496875",
                        level: 3
                    },
                    {
                        id: "370613",
                        name: "莱山区",
                        center: "121.448866,37.473549",
                        level: 3
                    },
                    {
                        id: "370686",
                        name: "栖霞市",
                        center: "120.834097,37.305854",
                        level: 3
                    },
                    {
                        id: "370612",
                        name: "牟平区",
                        center: "121.60151,37.388356",
                        level: 3
                    }
                ]
            },
            {
                id: "370200",
                name: "青岛市",
                center: "120.355173,36.082982",
                level: 2,
                children: [
                    {
                        id: "370285",
                        name: "莱西市",
                        center: "120.526226,36.86509",
                        level: 3
                    },
                    {
                        id: "370283",
                        name: "平度市",
                        center: "119.959012,36.788828",
                        level: 3
                    },
                    {
                        id: "370215",
                        name: "即墨区",
                        center: "120.447352,36.390847",
                        level: 3
                    },
                    {
                        id: "370213",
                        name: "李沧区",
                        center: "120.421236,36.160023",
                        level: 3
                    },
                    {
                        id: "370214",
                        name: "城阳区",
                        center: "120.389135,36.306833",
                        level: 3
                    },
                    {
                        id: "370212",
                        name: "崂山区",
                        center: "120.467393,36.102569",
                        level: 3
                    },
                    {
                        id: "370211",
                        name: "黄岛区",
                        center: "119.995518,35.875138",
                        level: 3
                    },
                    {
                        id: "370281",
                        name: "胶州市",
                        center: "120.006202,36.285878",
                        level: 3
                    },
                    {
                        id: "370203",
                        name: "市北区",
                        center: "120.355026,36.083819",
                        level: 3
                    },
                    {
                        id: "370202",
                        name: "市南区",
                        center: "120.395966,36.070892",
                        level: 3
                    }
                ]
            },
            {
                id: "371000",
                name: "威海市",
                center: "122.116394,37.509691",
                level: 2,
                children: [
                    {
                        id: "371002",
                        name: "环翠区",
                        center: "122.116189,37.510754",
                        level: 3
                    },
                    {
                        id: "371082",
                        name: "荣成市",
                        center: "122.422896,37.160134",
                        level: 3
                    },
                    {
                        id: "371003",
                        name: "文登区",
                        center: "122.057139,37.196211",
                        level: 3
                    },
                    {
                        id: "371083",
                        name: "乳山市",
                        center: "121.536346,36.919622",
                        level: 3
                    }
                ]
            },
            {
                id: "371500",
                name: "聊城市",
                center: "115.980367,36.456013",
                level: 2,
                children: [
                    {
                        id: "371525",
                        name: "冠县",
                        center: "115.444808,36.483753",
                        level: 3
                    },
                    {
                        id: "371522",
                        name: "莘县",
                        center: "115.667291,36.237597",
                        level: 3
                    },
                    {
                        id: "371521",
                        name: "阳谷县",
                        center: "115.784287,36.113708",
                        level: 3
                    },
                    {
                        id: "371523",
                        name: "茌平区",
                        center: "116.25335,36.591934",
                        level: 3
                    },
                    {
                        id: "371502",
                        name: "东昌府区",
                        center: "115.980023,36.45606",
                        level: 3
                    },
                    {
                        id: "371581",
                        name: "临清市",
                        center: "115.713462,36.842598",
                        level: 3
                    },
                    {
                        id: "371526",
                        name: "高唐县",
                        center: "116.229662,36.859755",
                        level: 3
                    },
                    {
                        id: "371524",
                        name: "东阿县",
                        center: "116.248855,36.336004",
                        level: 3
                    }
                ]
            },
            {
                id: "370300",
                name: "淄博市",
                center: "118.047648,36.814939",
                level: 2,
                children: [
                    {
                        id: "370322",
                        name: "高青县",
                        center: "117.829839,37.169581",
                        level: 3
                    },
                    {
                        id: "370305",
                        name: "临淄区",
                        center: "118.306018,36.816657",
                        level: 3
                    },
                    {
                        id: "370321",
                        name: "桓台县",
                        center: "118.101556,36.959773",
                        level: 3
                    },
                    {
                        id: "370303",
                        name: "张店区",
                        center: "118.053521,36.807049",
                        level: 3
                    },
                    {
                        id: "370304",
                        name: "博山区",
                        center: "117.85823,36.497567",
                        level: 3
                    },
                    {
                        id: "370323",
                        name: "沂源县",
                        center: "118.166161,36.186282",
                        level: 3
                    },
                    {
                        id: "370302",
                        name: "淄川区",
                        center: "117.967696,36.647272",
                        level: 3
                    },
                    {
                        id: "370306",
                        name: "周村区",
                        center: "117.851036,36.803699",
                        level: 3
                    }
                ]
            },
            {
                id: "371300",
                name: "临沂市",
                center: "118.326443,35.065282",
                level: 2,
                children: [
                    {
                        id: "371323",
                        name: "沂水县",
                        center: "118.634543,35.787029",
                        level: 3
                    },
                    {
                        id: "371326",
                        name: "平邑县",
                        center: "117.631884,35.511519",
                        level: 3
                    },
                    {
                        id: "371328",
                        name: "蒙阴县",
                        center: "117.943271,35.712435",
                        level: 3
                    },
                    {
                        id: "371321",
                        name: "沂南县",
                        center: "118.455395,35.547002",
                        level: 3
                    },
                    {
                        id: "371322",
                        name: "郯城县",
                        center: "118.342963,34.614741",
                        level: 3
                    },
                    {
                        id: "371302",
                        name: "兰山区",
                        center: "118.327667,35.061631",
                        level: 3
                    },
                    {
                        id: "371327",
                        name: "莒南县",
                        center: "118.838322,35.175911",
                        level: 3
                    },
                    {
                        id: "371311",
                        name: "罗庄区",
                        center: "118.284795,34.997204",
                        level: 3
                    },
                    {
                        id: "371329",
                        name: "临沭县",
                        center: "118.648379,34.917062",
                        level: 3
                    },
                    {
                        id: "371312",
                        name: "河东区",
                        center: "118.398296,35.085004",
                        level: 3
                    },
                    {
                        id: "371325",
                        name: "费县",
                        center: "117.968869,35.269174",
                        level: 3
                    },
                    {
                        id: "371324",
                        name: "兰陵县",
                        center: "118.049968,34.855573",
                        level: 3
                    }
                ]
            },
            {
                id: "370700",
                name: "潍坊市",
                center: "119.107078,36.70925",
                level: 2,
                children: [
                    {
                        id: "370724",
                        name: "临朐县",
                        center: "118.539876,36.516371",
                        level: 3
                    },
                    {
                        id: "370782",
                        name: "诸城市",
                        center: "119.403182,35.997093",
                        level: 3
                    },
                    {
                        id: "370702",
                        name: "潍城区",
                        center: "119.103784,36.710062",
                        level: 3
                    },
                    {
                        id: "370705",
                        name: "奎文区",
                        center: "119.137357,36.709494",
                        level: 3
                    },
                    {
                        id: "370784",
                        name: "安丘市",
                        center: "119.206886,36.427417",
                        level: 3
                    },
                    {
                        id: "370783",
                        name: "寿光市",
                        center: "118.736451,36.874411",
                        level: 3
                    },
                    {
                        id: "370786",
                        name: "昌邑市",
                        center: "119.394502,36.854937",
                        level: 3
                    },
                    {
                        id: "370703",
                        name: "寒亭区",
                        center: "119.207866,36.772103",
                        level: 3
                    },
                    {
                        id: "370781",
                        name: "青州市",
                        center: "118.484693,36.697855",
                        level: 3
                    },
                    {
                        id: "370725",
                        name: "昌乐县",
                        center: "118.839995,36.703253",
                        level: 3
                    },
                    {
                        id: "370785",
                        name: "高密市",
                        center: "119.757033,36.37754",
                        level: 3
                    },
                    {
                        id: "370704",
                        name: "坊子区",
                        center: "119.166326,36.654616",
                        level: 3
                    }
                ]
            },
            {
                id: "371600",
                name: "滨州市",
                center: "118.016974,37.383542",
                level: 2,
                children: [
                    {
                        id: "371603",
                        name: "沾化区",
                        center: "118.129902,37.698456",
                        level: 3
                    },
                    {
                        id: "371602",
                        name: "滨城区",
                        center: "118.020149,37.384842",
                        level: 3
                    },
                    {
                        id: "371625",
                        name: "博兴县",
                        center: "118.123096,37.147002",
                        level: 3
                    },
                    {
                        id: "371681",
                        name: "邹平市",
                        center: "117.736807,36.87803",
                        level: 3
                    },
                    {
                        id: "371623",
                        name: "无棣县",
                        center: "117.616325,37.740848",
                        level: 3
                    },
                    {
                        id: "371622",
                        name: "阳信县",
                        center: "117.581326,37.640492",
                        level: 3
                    },
                    {
                        id: "371621",
                        name: "惠民县",
                        center: "117.508941,37.483876",
                        level: 3
                    }
                ]
            },
            {
                id: "371100",
                name: "日照市",
                center: "119.461208,35.428588",
                level: 2,
                children: [
                    {
                        id: "371121",
                        name: "五莲县",
                        center: "119.206745,35.751936",
                        level: 3
                    },
                    {
                        id: "371102",
                        name: "东港区",
                        center: "119.457703,35.426152",
                        level: 3
                    },
                    {
                        id: "371122",
                        name: "莒县",
                        center: "118.832859,35.588115",
                        level: 3
                    },
                    {
                        id: "371103",
                        name: "岚山区",
                        center: "119.315844,35.119794",
                        level: 3
                    }
                ]
            },
            {
                id: "370500",
                name: "东营市",
                center: "118.66471,37.434564",
                level: 2,
                children: [
                    {
                        id: "370522",
                        name: "利津县",
                        center: "118.248854,37.493365",
                        level: 3
                    },
                    {
                        id: "370523",
                        name: "广饶县",
                        center: "118.407522,37.05161",
                        level: 3
                    },
                    {
                        id: "370502",
                        name: "东营区",
                        center: "118.507543,37.461567",
                        level: 3
                    },
                    {
                        id: "370503",
                        name: "河口区",
                        center: "118.529613,37.886015",
                        level: 3
                    },
                    {
                        id: "370505",
                        name: "垦利区",
                        center: "118.551314,37.588679",
                        level: 3
                    }
                ]
            },
            {
                id: "370900",
                name: "泰安市",
                center: "117.129063,36.194968",
                level: 2,
                children: [
                    {
                        id: "370982",
                        name: "新泰市",
                        center: "117.766092,35.910387",
                        level: 3
                    },
                    {
                        id: "370983",
                        name: "肥城市",
                        center: "116.763703,36.1856",
                        level: 3
                    },
                    {
                        id: "370923",
                        name: "东平县",
                        center: "116.461052,35.930467",
                        level: 3
                    },
                    {
                        id: "370921",
                        name: "宁阳县",
                        center: "116.799297,35.76754",
                        level: 3
                    },
                    {
                        id: "370911",
                        name: "岱岳区",
                        center: "117.04353,36.1841",
                        level: 3
                    },
                    {
                        id: "370902",
                        name: "泰山区",
                        center: "117.129984,36.189313",
                        level: 3
                    }
                ]
            },
            {
                id: "371700",
                name: "菏泽市",
                center: "115.469381,35.246531",
                level: 2,
                children: [
                    {
                        id: "371726",
                        name: "鄄城县",
                        center: "115.51434,35.560257",
                        level: 3
                    },
                    {
                        id: "371728",
                        name: "东明县",
                        center: "115.098412,35.289637",
                        level: 3
                    },
                    {
                        id: "371702",
                        name: "牡丹区",
                        center: "115.470946,35.24311",
                        level: 3
                    },
                    {
                        id: "371722",
                        name: "单县",
                        center: "116.08262,34.790851",
                        level: 3
                    },
                    {
                        id: "371703",
                        name: "定陶区",
                        center: "115.569601,35.072701",
                        level: 3
                    },
                    {
                        id: "371721",
                        name: "曹县",
                        center: "115.549482,34.823253",
                        level: 3
                    },
                    {
                        id: "371725",
                        name: "郓城县",
                        center: "115.93885,35.594773",
                        level: 3
                    },
                    {
                        id: "371724",
                        name: "巨野县",
                        center: "116.089341,35.390999",
                        level: 3
                    },
                    {
                        id: "371723",
                        name: "成武县",
                        center: "115.897349,34.947366",
                        level: 3
                    }
                ]
            },
            {
                id: "370800",
                name: "济宁市",
                center: "116.587245,35.415393",
                level: 2,
                children: [
                    {
                        id: "370831",
                        name: "泗水县",
                        center: "117.273605,35.653216",
                        level: 3
                    },
                    {
                        id: "370830",
                        name: "汶上县",
                        center: "116.487146,35.721746",
                        level: 3
                    },
                    {
                        id: "370881",
                        name: "曲阜市",
                        center: "116.991885,35.592788",
                        level: 3
                    },
                    {
                        id: "370883",
                        name: "邹城市",
                        center: "116.96673,35.405259",
                        level: 3
                    },
                    {
                        id: "370827",
                        name: "鱼台县",
                        center: "116.650023,34.997706",
                        level: 3
                    },
                    {
                        id: "370811",
                        name: "任城区",
                        center: "116.595261,35.414828",
                        level: 3
                    },
                    {
                        id: "370829",
                        name: "嘉祥县",
                        center: "116.342885,35.398098",
                        level: 3
                    },
                    {
                        id: "370812",
                        name: "兖州区",
                        center: "116.828996,35.556445",
                        level: 3
                    },
                    {
                        id: "370828",
                        name: "金乡县",
                        center: "116.310364,35.06977",
                        level: 3
                    },
                    {
                        id: "370832",
                        name: "梁山县",
                        center: "116.08963,35.801843",
                        level: 3
                    },
                    {
                        id: "370826",
                        name: "微山县",
                        center: "117.12861,34.809525",
                        level: 3
                    }
                ]
            },
            {
                id: "370100",
                name: "济南市",
                center: "117.000923,36.675807",
                level: 2,
                children: [
                    {
                        id: "370113",
                        name: "长清区",
                        center: "116.74588,36.561049",
                        level: 3
                    },
                    {
                        id: "370116",
                        name: "莱芜区",
                        center: "117.678351,36.213662",
                        level: 3
                    },
                    {
                        id: "370117",
                        name: "钢城区",
                        center: "117.82033,36.058038",
                        level: 3
                    },
                    {
                        id: "370124",
                        name: "平阴县",
                        center: "116.455054,36.286923",
                        level: 3
                    },
                    {
                        id: "370114",
                        name: "章丘区",
                        center: "117.54069,36.71209",
                        level: 3
                    },
                    {
                        id: "370126",
                        name: "商河县",
                        center: "117.156369,37.310544",
                        level: 3
                    },
                    {
                        id: "370105",
                        name: "天桥区",
                        center: "116.996086,36.693374",
                        level: 3
                    },
                    {
                        id: "370104",
                        name: "槐荫区",
                        center: "116.947921,36.668205",
                        level: 3
                    },
                    {
                        id: "370103",
                        name: "市中区",
                        center: "116.99898,36.657354",
                        level: 3
                    },
                    {
                        id: "370115",
                        name: "济阳区",
                        center: "117.176035,36.976772",
                        level: 3
                    },
                    {
                        id: "370112",
                        name: "历城区",
                        center: "117.063744,36.681744",
                        level: 3
                    },
                    {
                        id: "370102",
                        name: "历下区",
                        center: "117.03862,36.664169",
                        level: 3
                    }
                ]
            },
            {
                id: "370400",
                name: "枣庄市",
                center: "117.557964,34.856424",
                level: 2,
                children: [
                    {
                        id: "370406",
                        name: "山亭区",
                        center: "117.458968,35.096077",
                        level: 3
                    },
                    {
                        id: "370403",
                        name: "薛城区",
                        center: "117.265293,34.79789",
                        level: 3
                    },
                    {
                        id: "370402",
                        name: "市中区",
                        center: "117.557281,34.856651",
                        level: 3
                    },
                    {
                        id: "370404",
                        name: "峄城区",
                        center: "117.586316,34.767713",
                        level: 3
                    },
                    {
                        id: "370481",
                        name: "滕州市",
                        center: "117.162098,35.088498",
                        level: 3
                    },
                    {
                        id: "370405",
                        name: "台儿庄区",
                        center: "117.734747,34.564815",
                        level: 3
                    }
                ]
            },
            {
                id: "371400",
                name: "德州市",
                center: "116.307428,37.453968",
                level: 2,
                children: [
                    {
                        id: "371481",
                        name: "乐陵市",
                        center: "117.216657,37.729115",
                        level: 3
                    },
                    {
                        id: "371422",
                        name: "宁津县",
                        center: "116.79372,37.649619",
                        level: 3
                    },
                    {
                        id: "371402",
                        name: "德城区",
                        center: "116.307076,37.453923",
                        level: 3
                    },
                    {
                        id: "371428",
                        name: "武城县",
                        center: "116.078627,37.209527",
                        level: 3
                    },
                    {
                        id: "371423",
                        name: "庆云县",
                        center: "117.390507,37.777724",
                        level: 3
                    },
                    {
                        id: "371427",
                        name: "夏津县",
                        center: "116.003816,36.950501",
                        level: 3
                    },
                    {
                        id: "371424",
                        name: "临邑县",
                        center: "116.867028,37.192044",
                        level: 3
                    },
                    {
                        id: "371482",
                        name: "禹城市",
                        center: "116.642554,36.934485",
                        level: 3
                    },
                    {
                        id: "371403",
                        name: "陵城区",
                        center: "116.574929,37.332848",
                        level: 3
                    },
                    {
                        id: "371426",
                        name: "平原县",
                        center: "116.433904,37.164465",
                        level: 3
                    },
                    {
                        id: "371425",
                        name: "齐河县",
                        center: "116.758394,36.795497",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "410000",
        name: "河南省",
        center: "113.665412,34.757975",
        level: 1,
        children: [
            {
                id: "411200",
                name: "三门峡市",
                center: "111.194099,34.777338",
                level: 2,
                children: [
                    {
                        id: "411221",
                        name: "渑池县",
                        center: "111.762992,34.763487",
                        level: 3
                    },
                    {
                        id: "411224",
                        name: "卢氏县",
                        center: "111.052649,34.053995",
                        level: 3
                    },
                    {
                        id: "411202",
                        name: "湖滨区",
                        center: "111.19487,34.77812",
                        level: 3
                    },
                    {
                        id: "411281",
                        name: "义马市",
                        center: "111.869417,34.746868",
                        level: 3
                    },
                    {
                        id: "411203",
                        name: "陕州区",
                        center: "111.103851,34.720244",
                        level: 3
                    },
                    {
                        id: "411282",
                        name: "灵宝市",
                        center: "110.88577,34.521264",
                        level: 3
                    }
                ]
            },
            {
                id: "410300",
                name: "洛阳市",
                center: "112.434468,34.663041",
                level: 2,
                children: [
                    {
                        id: "410324",
                        name: "栾川县",
                        center: "111.618386,33.783195",
                        level: 3
                    },
                    {
                        id: "410323",
                        name: "新安县",
                        center: "112.141403,34.728679",
                        level: 3
                    },
                    {
                        id: "410326",
                        name: "汝阳县",
                        center: "112.473789,34.15323",
                        level: 3
                    },
                    {
                        id: "410329",
                        name: "伊川县",
                        center: "112.429384,34.423416",
                        level: 3
                    },
                    {
                        id: "410306",
                        name: "吉利区",
                        center: "112.584796,34.899093",
                        level: 3
                    },
                    {
                        id: "410327",
                        name: "宜阳县",
                        center: "112.179989,34.516478",
                        level: 3
                    },
                    {
                        id: "410328",
                        name: "洛宁县",
                        center: "111.655399,34.387179",
                        level: 3
                    },
                    {
                        id: "410381",
                        name: "偃师市",
                        center: "112.787739,34.723042",
                        level: 3
                    },
                    {
                        id: "410304",
                        name: "瀍河回族区",
                        center: "112.491625,34.684738",
                        level: 3
                    },
                    {
                        id: "410302",
                        name: "老城区",
                        center: "112.477298,34.682945",
                        level: 3
                    },
                    {
                        id: "410303",
                        name: "西工区",
                        center: "112.443232,34.667847",
                        level: 3
                    },
                    {
                        id: "410322",
                        name: "孟津县",
                        center: "112.443892,34.826485",
                        level: 3
                    },
                    {
                        id: "410325",
                        name: "嵩县",
                        center: "112.087765,34.131563",
                        level: 3
                    },
                    {
                        id: "410305",
                        name: "涧西区",
                        center: "112.399243,34.654251",
                        level: 3
                    },
                    {
                        id: "410311",
                        name: "洛龙区",
                        center: "112.456634,34.618557",
                        level: 3
                    }
                ]
            },
            {
                id: "411000",
                name: "许昌市",
                center: "113.826063,34.022956",
                level: 2,
                children: [
                    {
                        id: "411003",
                        name: "建安区",
                        center: "113.842898,34.005018",
                        level: 3
                    },
                    {
                        id: "411002",
                        name: "魏都区",
                        center: "113.828307,34.02711",
                        level: 3
                    },
                    {
                        id: "411082",
                        name: "长葛市",
                        center: "113.768912,34.219257",
                        level: 3
                    },
                    {
                        id: "411081",
                        name: "禹州市",
                        center: "113.471316,34.154403",
                        level: 3
                    },
                    {
                        id: "411024",
                        name: "鄢陵县",
                        center: "114.188507,34.100502",
                        level: 3
                    },
                    {
                        id: "411025",
                        name: "襄城县",
                        center: "113.493166,33.855943",
                        level: 3
                    }
                ]
            },
            {
                id: "411300",
                name: "南阳市",
                center: "112.540918,32.999082",
                level: 2,
                children: [
                    {
                        id: "411323",
                        name: "西峡县",
                        center: "111.485772,33.302981",
                        level: 3
                    },
                    {
                        id: "411303",
                        name: "卧龙区",
                        center: "112.528789,32.989877",
                        level: 3
                    },
                    {
                        id: "411321",
                        name: "南召县",
                        center: "112.435583,33.488617",
                        level: 3
                    },
                    {
                        id: "411327",
                        name: "社旗县",
                        center: "112.938279,33.056126",
                        level: 3
                    },
                    {
                        id: "411328",
                        name: "唐河县",
                        center: "112.838492,32.687892",
                        level: 3
                    },
                    {
                        id: "411330",
                        name: "桐柏县",
                        center: "113.406059,32.367153",
                        level: 3
                    },
                    {
                        id: "411322",
                        name: "方城县",
                        center: "113.010933,33.255138",
                        level: 3
                    },
                    {
                        id: "411381",
                        name: "邓州市",
                        center: "112.092716,32.681642",
                        level: 3
                    },
                    {
                        id: "411329",
                        name: "新野县",
                        center: "112.365624,32.524006",
                        level: 3
                    },
                    {
                        id: "411302",
                        name: "宛城区",
                        center: "112.544591,32.994857",
                        level: 3
                    },
                    {
                        id: "411325",
                        name: "内乡县",
                        center: "111.843801,33.046358",
                        level: 3
                    },
                    {
                        id: "411324",
                        name: "镇平县",
                        center: "112.232722,33.036651",
                        level: 3
                    },
                    {
                        id: "411326",
                        name: "淅川县",
                        center: "111.489026,33.136106",
                        level: 3
                    }
                ]
            },
            {
                id: "411100",
                name: "漯河市",
                center: "114.026405,33.575855",
                level: 2,
                children: [
                    {
                        id: "411121",
                        name: "舞阳县",
                        center: "113.610565,33.436278",
                        level: 3
                    },
                    {
                        id: "411104",
                        name: "召陵区",
                        center: "114.051686,33.567555",
                        level: 3
                    },
                    {
                        id: "411122",
                        name: "临颍县",
                        center: "113.938891,33.80609",
                        level: 3
                    },
                    {
                        id: "411102",
                        name: "源汇区",
                        center: "114.017948,33.565441",
                        level: 3
                    },
                    {
                        id: "411103",
                        name: "郾城区",
                        center: "114.016813,33.588897",
                        level: 3
                    }
                ]
            },
            {
                id: "411500",
                name: "信阳市",
                center: "114.075031,32.123274",
                level: 2,
                children: [
                    {
                        id: "411526",
                        name: "潢川县",
                        center: "115.050123,32.134024",
                        level: 3
                    },
                    {
                        id: "411527",
                        name: "淮滨县",
                        center: "115.415451,32.452639",
                        level: 3
                    },
                    {
                        id: "411521",
                        name: "罗山县",
                        center: "114.533414,32.203206",
                        level: 3
                    },
                    {
                        id: "411522",
                        name: "光山县",
                        center: "114.903577,32.010398",
                        level: 3
                    },
                    {
                        id: "411525",
                        name: "固始县",
                        center: "115.667328,32.183074",
                        level: 3
                    },
                    {
                        id: "411524",
                        name: "商城县",
                        center: "115.406297,31.799982",
                        level: 3
                    },
                    {
                        id: "411502",
                        name: "浉河区",
                        center: "114.075031,32.123274",
                        level: 3
                    },
                    {
                        id: "411523",
                        name: "新县",
                        center: "114.87705,31.63515",
                        level: 3
                    },
                    {
                        id: "411528",
                        name: "息县",
                        center: "114.740713,32.344744",
                        level: 3
                    },
                    {
                        id: "411503",
                        name: "平桥区",
                        center: "114.126027,32.098395",
                        level: 3
                    }
                ]
            },
            {
                id: "411700",
                name: "驻马店市",
                center: "114.024736,32.980169",
                level: 2,
                children: [
                    {
                        id: "411721",
                        name: "西平县",
                        center: "114.026864,33.382315",
                        level: 3
                    },
                    {
                        id: "411722",
                        name: "上蔡县",
                        center: "114.266892,33.264719",
                        level: 3
                    },
                    {
                        id: "411726",
                        name: "泌阳县",
                        center: "113.32605,32.725129",
                        level: 3
                    },
                    {
                        id: "411727",
                        name: "汝南县",
                        center: "114.359495,33.004535",
                        level: 3
                    },
                    {
                        id: "411728",
                        name: "遂平县",
                        center: "114.00371,33.14698",
                        level: 3
                    },
                    {
                        id: "411729",
                        name: "新蔡县",
                        center: "114.975246,32.749948",
                        level: 3
                    },
                    {
                        id: "411723",
                        name: "平舆县",
                        center: "114.637105,32.955626",
                        level: 3
                    },
                    {
                        id: "411702",
                        name: "驿城区",
                        center: "114.029149,32.977559",
                        level: 3
                    },
                    {
                        id: "411725",
                        name: "确山县",
                        center: "114.026679,32.801538",
                        level: 3
                    },
                    {
                        id: "411724",
                        name: "正阳县",
                        center: "114.38948,32.601826",
                        level: 3
                    }
                ]
            },
            {
                id: "410800",
                name: "焦作市",
                center: "113.238266,35.23904",
                level: 2,
                children: [
                    {
                        id: "410825",
                        name: "温县",
                        center: "113.079118,34.941233",
                        level: 3
                    },
                    {
                        id: "410804",
                        name: "马村区",
                        center: "113.321703,35.265453",
                        level: 3
                    },
                    {
                        id: "410882",
                        name: "沁阳市",
                        center: "112.934538,35.08901",
                        level: 3
                    },
                    {
                        id: "410883",
                        name: "孟州市",
                        center: "112.78708,34.90963",
                        level: 3
                    },
                    {
                        id: "410822",
                        name: "博爱县",
                        center: "113.069313,35.170351",
                        level: 3
                    },
                    {
                        id: "410823",
                        name: "武陟县",
                        center: "113.408334,35.09885",
                        level: 3
                    },
                    {
                        id: "410821",
                        name: "修武县",
                        center: "113.447465,35.229923",
                        level: 3
                    },
                    {
                        id: "410803",
                        name: "中站区",
                        center: "113.175485,35.236145",
                        level: 3
                    },
                    {
                        id: "410811",
                        name: "山阳区",
                        center: "113.26766,35.21476",
                        level: 3
                    },
                    {
                        id: "410802",
                        name: "解放区",
                        center: "113.226126,35.241353",
                        level: 3
                    }
                ]
            },
            {
                id: "410900",
                name: "濮阳市",
                center: "115.041299,35.768234",
                level: 2,
                children: [
                    {
                        id: "410923",
                        name: "南乐县",
                        center: "115.204336,36.075204",
                        level: 3
                    },
                    {
                        id: "410927",
                        name: "台前县",
                        center: "115.855681,35.996474",
                        level: 3
                    },
                    {
                        id: "410928",
                        name: "濮阳县",
                        center: "115.023844,35.710349",
                        level: 3
                    },
                    {
                        id: "410926",
                        name: "范县",
                        center: "115.504212,35.851977",
                        level: 3
                    },
                    {
                        id: "410922",
                        name: "清丰县",
                        center: "115.107287,35.902413",
                        level: 3
                    },
                    {
                        id: "410902",
                        name: "华龙区",
                        center: "115.03184,35.760473",
                        level: 3
                    }
                ]
            },
            {
                id: "419001",
                name: "济源市",
                center: "112.590047,35.090378",
                level: 2,
                children: [
                    {
                        id: "419001001",
                        name: "沁园街道",
                        center: "112.60301,35.08432",
                        level: 4
                    },
                    {
                        id: "419001002",
                        name: "济水街道",
                        center: "112.61101,35.0918",
                        level: 4
                    },
                    {
                        id: "419001003",
                        name: "北海街道",
                        center: "112.575379,35.099062",
                        level: 4
                    },
                    {
                        id: "419001004",
                        name: "天坛街道",
                        center: "112.56665,35.10246",
                        level: 4
                    },
                    {
                        id: "419001005",
                        name: "玉泉街道",
                        center: "112.61546,35.10806",
                        level: 4
                    },
                    {
                        id: "419001100",
                        name: "克井镇",
                        center: "112.54312,35.1603",
                        level: 4
                    },
                    {
                        id: "419001101",
                        name: "五龙口镇",
                        center: "112.68905,35.13559",
                        level: 4
                    },
                    {
                        id: "419001102",
                        name: "轵城镇",
                        center: "112.60094,35.04604",
                        level: 4
                    },
                    {
                        id: "419001103",
                        name: "承留镇",
                        center: "112.49929,35.07709",
                        level: 4
                    },
                    {
                        id: "419001104",
                        name: "邵原镇",
                        center: "112.1335,35.16092",
                        level: 4
                    },
                    {
                        id: "419001105",
                        name: "坡头镇",
                        center: "112.471698,34.954927",
                        level: 4
                    },
                    {
                        id: "419001106",
                        name: "梨林镇",
                        center: "112.71351,35.07763",
                        level: 4
                    },
                    {
                        id: "419001107",
                        name: "大峪镇",
                        center: "112.39611,34.93763",
                        level: 4
                    },
                    {
                        id: "419001108",
                        name: "思礼镇",
                        center: "112.50739,35.10112",
                        level: 4
                    },
                    {
                        id: "419001109",
                        name: "王屋镇",
                        center: "112.27194,35.11408",
                        level: 4
                    },
                    {
                        id: "419001110",
                        name: "下冶镇",
                        center: "112.20241,35.0335",
                        level: 4
                    }
                ]
            },
            {
                id: "410600",
                name: "鹤壁市",
                center: "114.295444,35.748236",
                level: 2,
                children: [
                    {
                        id: "410602",
                        name: "鹤山区",
                        center: "114.166551,35.936128",
                        level: 3
                    },
                    {
                        id: "410603",
                        name: "山城区",
                        center: "114.184202,35.896058",
                        level: 3
                    },
                    {
                        id: "410621",
                        name: "浚县",
                        center: "114.550162,35.671282",
                        level: 3
                    },
                    {
                        id: "410611",
                        name: "淇滨区",
                        center: "114.293917,35.748382",
                        level: 3
                    },
                    {
                        id: "410622",
                        name: "淇县",
                        center: "114.200379,35.609478",
                        level: 3
                    }
                ]
            },
            {
                id: "410700",
                name: "新乡市",
                center: "113.883991,35.302616",
                level: 2,
                children: [
                    {
                        id: "410782",
                        name: "辉县市",
                        center: "113.802518,35.461318",
                        level: 3
                    },
                    {
                        id: "410724",
                        name: "获嘉县",
                        center: "113.657249,35.261685",
                        level: 3
                    },
                    {
                        id: "410704",
                        name: "凤泉区",
                        center: "113.906712,35.379855",
                        level: 3
                    },
                    {
                        id: "410727",
                        name: "封丘县",
                        center: "114.423405,35.04057",
                        level: 3
                    },
                    {
                        id: "410726",
                        name: "延津县",
                        center: "114.200982,35.149515",
                        level: 3
                    },
                    {
                        id: "410728",
                        name: "长垣市",
                        center: "114.673807,35.19615",
                        level: 3
                    },
                    {
                        id: "410725",
                        name: "原阳县",
                        center: "113.965966,35.054001",
                        level: 3
                    },
                    {
                        id: "410781",
                        name: "卫辉市",
                        center: "114.065855,35.404295",
                        level: 3
                    },
                    {
                        id: "410711",
                        name: "牧野区",
                        center: "113.89716,35.312974",
                        level: 3
                    },
                    {
                        id: "410721",
                        name: "新乡县",
                        center: "113.806186,35.190021",
                        level: 3
                    },
                    {
                        id: "410702",
                        name: "红旗区",
                        center: "113.878158,35.302684",
                        level: 3
                    },
                    {
                        id: "410703",
                        name: "卫滨区",
                        center: "113.866065,35.304905",
                        level: 3
                    }
                ]
            },
            {
                id: "410400",
                name: "平顶山市",
                center: "113.307718,33.735241",
                level: 2,
                children: [
                    {
                        id: "410404",
                        name: "石龙区",
                        center: "112.889885,33.901538",
                        level: 3
                    },
                    {
                        id: "410403",
                        name: "卫东区",
                        center: "113.310327,33.739285",
                        level: 3
                    },
                    {
                        id: "410411",
                        name: "湛河区",
                        center: "113.320873,33.725681",
                        level: 3
                    },
                    {
                        id: "410423",
                        name: "鲁山县",
                        center: "112.906703,33.740325",
                        level: 3
                    },
                    {
                        id: "410422",
                        name: "叶县",
                        center: "113.358298,33.621252",
                        level: 3
                    },
                    {
                        id: "410481",
                        name: "舞钢市",
                        center: "113.52625,33.302082",
                        level: 3
                    },
                    {
                        id: "410425",
                        name: "郏县",
                        center: "113.220451,33.971993",
                        level: 3
                    },
                    {
                        id: "410402",
                        name: "新华区",
                        center: "113.299061,33.737579",
                        level: 3
                    },
                    {
                        id: "410482",
                        name: "汝州市",
                        center: "112.845336,34.167408",
                        level: 3
                    },
                    {
                        id: "410421",
                        name: "宝丰县",
                        center: "113.066812,33.866359",
                        level: 3
                    }
                ]
            },
            {
                id: "411600",
                name: "周口市",
                center: "114.649653,33.620357",
                level: 2,
                children: [
                    {
                        id: "411624",
                        name: "沈丘县",
                        center: "115.078375,33.395514",
                        level: 3
                    },
                    {
                        id: "411621",
                        name: "扶沟县",
                        center: "114.392008,34.054061",
                        level: 3
                    },
                    {
                        id: "411681",
                        name: "项城市",
                        center: "114.899521,33.443085",
                        level: 3
                    },
                    {
                        id: "411602",
                        name: "川汇区",
                        center: "114.652136,33.614836",
                        level: 3
                    },
                    {
                        id: "411623",
                        name: "商水县",
                        center: "114.60927,33.543845",
                        level: 3
                    },
                    {
                        id: "411622",
                        name: "西华县",
                        center: "114.530067,33.784378",
                        level: 3
                    },
                    {
                        id: "411626",
                        name: "淮阳区",
                        center: "114.870166,33.732547",
                        level: 3
                    },
                    {
                        id: "411628",
                        name: "鹿邑县",
                        center: "115.486386,33.861067",
                        level: 3
                    },
                    {
                        id: "411625",
                        name: "郸城县",
                        center: "115.189,33.643852",
                        level: 3
                    },
                    {
                        id: "411627",
                        name: "太康县",
                        center: "114.853834,34.065312",
                        level: 3
                    }
                ]
            },
            {
                id: "410500",
                name: "安阳市",
                center: "114.352482,36.103442",
                level: 2,
                children: [
                    {
                        id: "410523",
                        name: "汤阴县",
                        center: "114.362357,35.922349",
                        level: 3
                    },
                    {
                        id: "410505",
                        name: "殷都区",
                        center: "114.300098,36.108974",
                        level: 3
                    },
                    {
                        id: "410526",
                        name: "滑县",
                        center: "114.524,35.574628",
                        level: 3
                    },
                    {
                        id: "410581",
                        name: "林州市",
                        center: "113.823767,36.063403",
                        level: 3
                    },
                    {
                        id: "410506",
                        name: "龙安区",
                        center: "114.323522,36.095568",
                        level: 3
                    },
                    {
                        id: "410527",
                        name: "内黄县",
                        center: "114.904582,35.953702",
                        level: 3
                    },
                    {
                        id: "410503",
                        name: "北关区",
                        center: "114.352646,36.10978",
                        level: 3
                    },
                    {
                        id: "410502",
                        name: "文峰区",
                        center: "114.352562,36.098101",
                        level: 3
                    },
                    {
                        id: "410522",
                        name: "安阳县",
                        center: "114.130207,36.130585",
                        level: 3
                    }
                ]
            },
            {
                id: "410100",
                name: "郑州市",
                center: "113.665412,34.757975",
                level: 2,
                children: [
                    {
                        id: "410185",
                        name: "登封市",
                        center: "113.037768,34.459939",
                        level: 3
                    },
                    {
                        id: "410105",
                        name: "金水区",
                        center: "113.686037,34.775838",
                        level: 3
                    },
                    {
                        id: "410108",
                        name: "惠济区",
                        center: "113.61836,34.828591",
                        level: 3
                    },
                    {
                        id: "410102",
                        name: "中原区",
                        center: "113.611576,34.748286",
                        level: 3
                    },
                    {
                        id: "410103",
                        name: "二七区",
                        center: "113.645422,34.730936",
                        level: 3
                    },
                    {
                        id: "410183",
                        name: "新密市",
                        center: "113.380616,34.537846",
                        level: 3
                    },
                    {
                        id: "410104",
                        name: "管城回族区",
                        center: "113.685313,34.746453",
                        level: 3
                    },
                    {
                        id: "410184",
                        name: "新郑市",
                        center: "113.73967,34.394219",
                        level: 3
                    },
                    {
                        id: "410122",
                        name: "中牟县",
                        center: "114.022521,34.721976",
                        level: 3
                    },
                    {
                        id: "410182",
                        name: "荥阳市",
                        center: "113.391523,34.789077",
                        level: 3
                    },
                    {
                        id: "410106",
                        name: "上街区",
                        center: "113.298282,34.808689",
                        level: 3
                    },
                    {
                        id: "410181",
                        name: "巩义市",
                        center: "112.98283,34.75218",
                        level: 3
                    }
                ]
            },
            {
                id: "410200",
                name: "开封市",
                center: "114.341447,34.797049",
                level: 2,
                children: [
                    {
                        id: "410205",
                        name: "禹王台区",
                        center: "114.350246,34.779727",
                        level: 3
                    },
                    {
                        id: "410212",
                        name: "祥符区",
                        center: "114.437622,34.756476",
                        level: 3
                    },
                    {
                        id: "410203",
                        name: "顺河回族区",
                        center: "114.364875,34.800459",
                        level: 3
                    },
                    {
                        id: "410225",
                        name: "兰考县",
                        center: "114.820572,34.829899",
                        level: 3
                    },
                    {
                        id: "410222",
                        name: "通许县",
                        center: "114.467734,34.477302",
                        level: 3
                    },
                    {
                        id: "410204",
                        name: "鼓楼区",
                        center: "114.3485,34.792383",
                        level: 3
                    },
                    {
                        id: "410202",
                        name: "龙亭区",
                        center: "114.353348,34.799833",
                        level: 3
                    },
                    {
                        id: "410223",
                        name: "尉氏县",
                        center: "114.193927,34.412256",
                        level: 3
                    },
                    {
                        id: "410221",
                        name: "杞县",
                        center: "114.770472,34.554585",
                        level: 3
                    }
                ]
            },
            {
                id: "411400",
                name: "商丘市",
                center: "115.650497,34.437054",
                level: 2,
                children: [
                    {
                        id: "411424",
                        name: "柘城县",
                        center: "115.307433,34.075277",
                        level: 3
                    },
                    {
                        id: "411423",
                        name: "宁陵县",
                        center: "115.320055,34.449299",
                        level: 3
                    },
                    {
                        id: "411402",
                        name: "梁园区",
                        center: "115.65459,34.436553",
                        level: 3
                    },
                    {
                        id: "411403",
                        name: "睢阳区",
                        center: "115.653813,34.390536",
                        level: 3
                    },
                    {
                        id: "411421",
                        name: "民权县",
                        center: "115.148146,34.648455",
                        level: 3
                    },
                    {
                        id: "411426",
                        name: "夏邑县",
                        center: "116.13989,34.240894",
                        level: 3
                    },
                    {
                        id: "411425",
                        name: "虞城县",
                        center: "115.863811,34.399634",
                        level: 3
                    },
                    {
                        id: "411422",
                        name: "睢县",
                        center: "115.070109,34.428433",
                        level: 3
                    },
                    {
                        id: "411481",
                        name: "永城市",
                        center: "116.449672,33.931318",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "420000",
        name: "湖北省",
        center: "114.298572,30.584355",
        level: 1,
        children: [
            {
                id: "420100",
                name: "武汉市",
                center: "114.298572,30.584355",
                level: 2,
                children: [
                    {
                        id: "420117",
                        name: "新洲区",
                        center: "114.802108,30.842149",
                        level: 3
                    },
                    {
                        id: "420116",
                        name: "黄陂区",
                        center: "114.374025,30.874155",
                        level: 3
                    },
                    {
                        id: "420114",
                        name: "蔡甸区",
                        center: "114.029341,30.582186",
                        level: 3
                    },
                    {
                        id: "420113",
                        name: "汉南区",
                        center: "114.08124,30.309637",
                        level: 3
                    },
                    {
                        id: "420115",
                        name: "江夏区",
                        center: "114.313961,30.349045",
                        level: 3
                    },
                    {
                        id: "420106",
                        name: "武昌区",
                        center: "114.307344,30.546536",
                        level: 3
                    },
                    {
                        id: "420112",
                        name: "东西湖区",
                        center: "114.142483,30.622467",
                        level: 3
                    },
                    {
                        id: "420104",
                        name: "硚口区",
                        center: "114.264568,30.57061",
                        level: 3
                    },
                    {
                        id: "420105",
                        name: "汉阳区",
                        center: "114.265807,30.549326",
                        level: 3
                    },
                    {
                        id: "420111",
                        name: "洪山区",
                        center: "114.400718,30.504259",
                        level: 3
                    },
                    {
                        id: "420107",
                        name: "青山区",
                        center: "114.39707,30.634215",
                        level: 3
                    },
                    {
                        id: "420102",
                        name: "江岸区",
                        center: "114.30304,30.594911",
                        level: 3
                    },
                    {
                        id: "420103",
                        name: "江汉区",
                        center: "114.283109,30.578771",
                        level: 3
                    }
                ]
            },
            {
                id: "420900",
                name: "孝感市",
                center: "113.926655,30.926423",
                level: 2,
                children: [
                    {
                        id: "420982",
                        name: "安陆市",
                        center: "113.690401,31.26174",
                        level: 3
                    },
                    {
                        id: "420981",
                        name: "应城市",
                        center: "113.573842,30.939038",
                        level: 3
                    },
                    {
                        id: "420923",
                        name: "云梦县",
                        center: "113.750616,31.021691",
                        level: 3
                    },
                    {
                        id: "420984",
                        name: "汉川市",
                        center: "113.835301,30.652165",
                        level: 3
                    },
                    {
                        id: "420902",
                        name: "孝南区",
                        center: "113.925849,30.925966",
                        level: 3
                    },
                    {
                        id: "420921",
                        name: "孝昌县",
                        center: "113.988964,31.251618",
                        level: 3
                    },
                    {
                        id: "420922",
                        name: "大悟县",
                        center: "114.126249,31.565483",
                        level: 3
                    }
                ]
            },
            {
                id: "421100",
                name: "黄冈市",
                center: "114.879365,30.447711",
                level: 2,
                children: [
                    {
                        id: "421181",
                        name: "麻城市",
                        center: "115.02541,31.177906",
                        level: 3
                    },
                    {
                        id: "421121",
                        name: "团风县",
                        center: "114.872029,30.63569",
                        level: 3
                    },
                    {
                        id: "421126",
                        name: "蕲春县",
                        center: "115.433964,30.234927",
                        level: 3
                    },
                    {
                        id: "421127",
                        name: "黄梅县",
                        center: "115.942548,30.075113",
                        level: 3
                    },
                    {
                        id: "421102",
                        name: "黄州区",
                        center: "114.878934,30.447435",
                        level: 3
                    },
                    {
                        id: "421182",
                        name: "武穴市",
                        center: "115.56242,29.849342",
                        level: 3
                    },
                    {
                        id: "421125",
                        name: "浠水县",
                        center: "115.26344,30.454837",
                        level: 3
                    },
                    {
                        id: "421123",
                        name: "罗田县",
                        center: "115.398984,30.781679",
                        level: 3
                    },
                    {
                        id: "421122",
                        name: "红安县",
                        center: "114.615095,31.284777",
                        level: 3
                    },
                    {
                        id: "421124",
                        name: "英山县",
                        center: "115.67753,30.735794",
                        level: 3
                    }
                ]
            },
            {
                id: "420800",
                name: "荆门市",
                center: "112.204251,31.03542",
                level: 2,
                children: [
                    {
                        id: "420882",
                        name: "京山市",
                        center: "113.114595,31.022458",
                        level: 3
                    },
                    {
                        id: "420802",
                        name: "东宝区",
                        center: "112.204804,31.033461",
                        level: 3
                    },
                    {
                        id: "420881",
                        name: "钟祥市",
                        center: "112.587267,31.165573",
                        level: 3
                    },
                    {
                        id: "420822",
                        name: "沙洋县",
                        center: "112.595218,30.70359",
                        level: 3
                    },
                    {
                        id: "420804",
                        name: "掇刀区",
                        center: "112.198413,30.980798",
                        level: 3
                    }
                ]
            },
            {
                id: "420300",
                name: "十堰市",
                center: "110.787916,32.646907",
                level: 2,
                children: [
                    {
                        id: "420304",
                        name: "郧阳区",
                        center: "110.812099,32.838267",
                        level: 3
                    },
                    {
                        id: "420381",
                        name: "丹江口市",
                        center: "111.513793,32.538839",
                        level: 3
                    },
                    {
                        id: "420303",
                        name: "张湾区",
                        center: "110.772365,32.652516",
                        level: 3
                    },
                    {
                        id: "420302",
                        name: "茅箭区",
                        center: "110.78621,32.644463",
                        level: 3
                    },
                    {
                        id: "420324",
                        name: "竹溪县",
                        center: "109.717196,32.315342",
                        level: 3
                    },
                    {
                        id: "420325",
                        name: "房县",
                        center: "110.741966,32.055002",
                        level: 3
                    },
                    {
                        id: "420323",
                        name: "竹山县",
                        center: "110.2296,32.22586",
                        level: 3
                    },
                    {
                        id: "420322",
                        name: "郧西县",
                        center: "110.426472,32.991457",
                        level: 3
                    }
                ]
            },
            {
                id: "420500",
                name: "宜昌市",
                center: "111.290843,30.702636",
                level: 2,
                children: [
                    {
                        id: "420525",
                        name: "远安县",
                        center: "111.64331,31.059626",
                        level: 3
                    },
                    {
                        id: "420526",
                        name: "兴山县",
                        center: "110.754499,31.34795",
                        level: 3
                    },
                    {
                        id: "420582",
                        name: "当阳市",
                        center: "111.793419,30.824492",
                        level: 3
                    },
                    {
                        id: "420527",
                        name: "秭归县",
                        center: "110.976785,30.823908",
                        level: 3
                    },
                    {
                        id: "420504",
                        name: "点军区",
                        center: "111.268163,30.692322",
                        level: 3
                    },
                    {
                        id: "420503",
                        name: "伍家岗区",
                        center: "111.307215,30.679053",
                        level: 3
                    },
                    {
                        id: "420528",
                        name: "长阳土家族自治县",
                        center: "111.198475,30.466534",
                        level: 3
                    },
                    {
                        id: "420583",
                        name: "枝江市",
                        center: "111.751799,30.425364",
                        level: 3
                    },
                    {
                        id: "420505",
                        name: "猇亭区",
                        center: "111.427642,30.530744",
                        level: 3
                    },
                    {
                        id: "420581",
                        name: "宜都市",
                        center: "111.454367,30.387234",
                        level: 3
                    },
                    {
                        id: "420529",
                        name: "五峰土家族自治县",
                        center: "110.674938,30.199252",
                        level: 3
                    },
                    {
                        id: "420506",
                        name: "夷陵区",
                        center: "111.326747,30.770199",
                        level: 3
                    },
                    {
                        id: "420502",
                        name: "西陵区",
                        center: "111.295468,30.702476",
                        level: 3
                    }
                ]
            },
            {
                id: "429006",
                name: "天门市",
                center: "113.165862,30.653061",
                level: 2,
                children: [
                    {
                        id: "429006001",
                        name: "竟陵街道",
                        center: "113.183332,30.650406",
                        level: 4
                    },
                    {
                        id: "429006002",
                        name: "侨乡街道开发区",
                        center: "113.143991,30.622681",
                        level: 4
                    },
                    {
                        id: "429006003",
                        name: "杨林街道",
                        center: "113.194881,30.639918",
                        level: 4
                    },
                    {
                        id: "429006100",
                        name: "多宝镇",
                        center: "112.69769,30.66814",
                        level: 4
                    },
                    {
                        id: "429006101",
                        name: "拖市镇",
                        center: "112.83903,30.72701",
                        level: 4
                    },
                    {
                        id: "429006102",
                        name: "张港镇",
                        center: "112.88148,30.56766",
                        level: 4
                    },
                    {
                        id: "429006103",
                        name: "蒋场镇",
                        center: "112.96715,30.57918",
                        level: 4
                    },
                    {
                        id: "429006104",
                        name: "汪场镇",
                        center: "113.04099,30.61376",
                        level: 4
                    },
                    {
                        id: "429006105",
                        name: "渔薪镇",
                        center: "112.94503,30.67632",
                        level: 4
                    },
                    {
                        id: "429006106",
                        name: "黄潭镇",
                        center: "113.0907,30.65948",
                        level: 4
                    },
                    {
                        id: "429006107",
                        name: "岳口镇",
                        center: "113.10117,30.51754",
                        level: 4
                    },
                    {
                        id: "429006108",
                        name: "横林镇",
                        center: "113.20052,30.53865",
                        level: 4
                    },
                    {
                        id: "429006109",
                        name: "彭市镇",
                        center: "113.18789,30.45659",
                        level: 4
                    },
                    {
                        id: "429006110",
                        name: "麻洋镇",
                        center: "113.26774,30.43514",
                        level: 4
                    },
                    {
                        id: "429006111",
                        name: "多祥镇",
                        center: "113.44548,30.40209",
                        level: 4
                    },
                    {
                        id: "429006112",
                        name: "干驿镇",
                        center: "113.39724,30.55872",
                        level: 4
                    },
                    {
                        id: "429006113",
                        name: "马湾镇",
                        center: "113.33224,30.57428",
                        level: 4
                    },
                    {
                        id: "429006114",
                        name: "卢市镇",
                        center: "113.42672,30.71855",
                        level: 4
                    },
                    {
                        id: "429006115",
                        name: "小板镇",
                        center: "113.1873,30.60648",
                        level: 4
                    },
                    {
                        id: "429006116",
                        name: "九真镇",
                        center: "113.23373,30.72936",
                        level: 4
                    },
                    {
                        id: "429006118",
                        name: "皂市镇",
                        center: "113.29328,30.84329",
                        level: 4
                    },
                    {
                        id: "429006119",
                        name: "胡市镇",
                        center: "113.38871,30.7711",
                        level: 4
                    },
                    {
                        id: "429006120",
                        name: "石河镇",
                        center: "113.15295,30.70484",
                        level: 4
                    },
                    {
                        id: "429006121",
                        name: "佛子山镇",
                        center: "113.01125,30.75248",
                        level: 4
                    },
                    {
                        id: "429006201",
                        name: "净潭乡",
                        center: "113.40059,30.65516",
                        level: 4
                    },
                    {
                        id: "429006450",
                        name: "蒋湖农场",
                        center: "112.779269,30.606662",
                        level: 4
                    },
                    {
                        id: "429006451",
                        name: "白茅湖农场",
                        center: "113.101479,30.60746",
                        level: 4
                    },
                    {
                        id: "429006452",
                        name: "沉湖管委会",
                        center: "113.398455,30.485174",
                        level: 4
                    }
                ]
            },
            {
                id: "420600",
                name: "襄阳市",
                center: "112.144146,32.042426",
                level: 2,
                children: [
                    {
                        id: "420682",
                        name: "老河口市",
                        center: "111.675732,32.385438",
                        level: 3
                    },
                    {
                        id: "420684",
                        name: "宜城市",
                        center: "112.261441,31.709203",
                        level: 3
                    },
                    {
                        id: "420683",
                        name: "枣阳市",
                        center: "112.765268,32.123083",
                        level: 3
                    },
                    {
                        id: "420625",
                        name: "谷城县",
                        center: "111.640147,32.262676",
                        level: 3
                    },
                    {
                        id: "420626",
                        name: "保康县",
                        center: "111.262235,31.873507",
                        level: 3
                    },
                    {
                        id: "420624",
                        name: "南漳县",
                        center: "111.844424,31.77692",
                        level: 3
                    },
                    {
                        id: "420602",
                        name: "襄城区",
                        center: "112.150327,32.015088",
                        level: 3
                    },
                    {
                        id: "420606",
                        name: "樊城区",
                        center: "112.13957,32.058589",
                        level: 3
                    },
                    {
                        id: "420607",
                        name: "襄州区",
                        center: "112.197378,32.085517",
                        level: 3
                    }
                ]
            },
            {
                id: "429004",
                name: "仙桃市",
                center: "113.453974,30.364953",
                level: 2,
                children: [
                    {
                        id: "429004001",
                        name: "沙嘴街道",
                        center: "113.45801,30.33062",
                        level: 4
                    },
                    {
                        id: "429004002",
                        name: "干河街道",
                        center: "113.42263,30.3789",
                        level: 4
                    },
                    {
                        id: "429004003",
                        name: "龙华山街道",
                        center: "113.461174,30.368949",
                        level: 4
                    },
                    {
                        id: "429004100",
                        name: "郑场镇",
                        center: "113.03372,30.48717",
                        level: 4
                    },
                    {
                        id: "429004101",
                        name: "毛嘴镇",
                        center: "113.03549,30.41736",
                        level: 4
                    },
                    {
                        id: "429004102",
                        name: "豆河镇",
                        center: "113.086444,30.33136",
                        level: 4
                    },
                    {
                        id: "429004103",
                        name: "三伏潭镇",
                        center: "113.20154,30.37532",
                        level: 4
                    },
                    {
                        id: "429004104",
                        name: "胡场镇",
                        center: "113.32961,30.37325",
                        level: 4
                    },
                    {
                        id: "429004105",
                        name: "长倘口镇",
                        center: "113.60543,30.400335",
                        level: 4
                    },
                    {
                        id: "429004106",
                        name: "西流河镇",
                        center: "113.6777,30.31453",
                        level: 4
                    },
                    {
                        id: "429004107",
                        name: "沙湖镇",
                        center: "113.67514,30.16938",
                        level: 4
                    },
                    {
                        id: "429004108",
                        name: "杨林尾镇",
                        center: "113.5093,30.137",
                        level: 4
                    },
                    {
                        id: "429004109",
                        name: "彭场镇",
                        center: "113.50642,30.2634",
                        level: 4
                    },
                    {
                        id: "429004110",
                        name: "张沟镇",
                        center: "113.38056,30.25015",
                        level: 4
                    },
                    {
                        id: "429004111",
                        name: "郭河镇",
                        center: "113.29354,30.24031",
                        level: 4
                    },
                    {
                        id: "429004112",
                        name: "沔城回族镇",
                        center: "113.23222,30.19133",
                        level: 4
                    },
                    {
                        id: "429004113",
                        name: "通海口镇",
                        center: "113.15288,30.19897",
                        level: 4
                    },
                    {
                        id: "429004114",
                        name: "陈场镇",
                        center: "113.0878,30.23509",
                        level: 4
                    },
                    {
                        id: "429004400",
                        name: "工业园区",
                        center: "113.424488,30.320714",
                        level: 4
                    },
                    {
                        id: "429004401",
                        name: "九合垸原种场",
                        center: "113.013911,30.248676",
                        level: 4
                    },
                    {
                        id: "429004404",
                        name: "五湖渔场",
                        center: "113.792696,30.179392",
                        level: 4
                    },
                    {
                        id: "429004405",
                        name: "赵西垸林场",
                        center: "113.016064,30.291641",
                        level: 4
                    },
                    {
                        id: "429004407",
                        name: "畜禽良种场",
                        center: "113.75823,30.183088",
                        level: 4
                    }
                ]
            },
            {
                id: "429005",
                name: "潜江市",
                center: "112.896866,30.421215",
                level: 2,
                children: [
                    {
                        id: "429005001",
                        name: "园林街道",
                        center: "112.896971,30.429074",
                        level: 4
                    },
                    {
                        id: "429005003",
                        name: "周矶街道",
                        center: "112.80766,30.412461",
                        level: 4
                    },
                    {
                        id: "429005004",
                        name: "广华街道",
                        center: "112.667353,30.4734",
                        level: 4
                    },
                    {
                        id: "429005005",
                        name: "泰丰街道",
                        center: "112.903028,30.413919",
                        level: 4
                    },
                    {
                        id: "429005006",
                        name: "高场街道",
                        center: "112.72322,30.402845",
                        level: 4
                    },
                    {
                        id: "429005100",
                        name: "竹根滩镇",
                        center: "112.90677,30.49361",
                        level: 4
                    },
                    {
                        id: "429005101",
                        name: "渔洋镇",
                        center: "112.90896,30.17244",
                        level: 4
                    },
                    {
                        id: "429005102",
                        name: "王场镇",
                        center: "112.77438,30.50497",
                        level: 4
                    },
                    {
                        id: "429005103",
                        name: "高石碑镇",
                        center: "112.67335,30.54743",
                        level: 4
                    },
                    {
                        id: "429005104",
                        name: "熊口镇",
                        center: "112.78131,30.30601",
                        level: 4
                    },
                    {
                        id: "429005105",
                        name: "老新镇",
                        center: "112.85707,30.18882",
                        level: 4
                    },
                    {
                        id: "429005106",
                        name: "浩口镇",
                        center: "112.64981,30.37879",
                        level: 4
                    },
                    {
                        id: "429005107",
                        name: "积玉口镇",
                        center: "112.59684,30.45237",
                        level: 4
                    },
                    {
                        id: "429005108",
                        name: "张金镇",
                        center: "112.5966,30.19196",
                        level: 4
                    },
                    {
                        id: "429005109",
                        name: "龙湾镇",
                        center: "112.71709,30.22948",
                        level: 4
                    },
                    {
                        id: "429005451",
                        name: "后湖管理区",
                        center: "112.711152,30.351716",
                        level: 4
                    },
                    {
                        id: "429005452",
                        name: "熊口管理区",
                        center: "112.756944,30.28181",
                        level: 4
                    },
                    {
                        id: "429005453",
                        name: "总口管理区",
                        center: "112.92288,30.28839",
                        level: 4
                    },
                    {
                        id: "429005454",
                        name: "白鹭湖管理区",
                        center: "112.76611,30.107631",
                        level: 4
                    },
                    {
                        id: "429005455",
                        name: "运粮湖管理区",
                        center: "112.55847,30.28301",
                        level: 4
                    },
                    {
                        id: "429005900",
                        name: "杨市街道",
                        center: "112.906788,30.368451",
                        level: 4
                    },
                    {
                        id: "429005950",
                        name: "广华寺农场",
                        center: "112.7418,30.449729",
                        level: 4
                    }
                ]
            },
            {
                id: "421000",
                name: "荆州市",
                center: "112.23813,30.326857",
                level: 2,
                children: [
                    {
                        id: "421003",
                        name: "荆州区",
                        center: "112.195354,30.350674",
                        level: 3
                    },
                    {
                        id: "421083",
                        name: "洪湖市",
                        center: "113.470304,29.81297",
                        level: 3
                    },
                    {
                        id: "421024",
                        name: "江陵县",
                        center: "112.41735,30.033919",
                        level: 3
                    },
                    {
                        id: "421023",
                        name: "监利县",
                        center: "112.904344,29.820079",
                        level: 3
                    },
                    {
                        id: "421087",
                        name: "松滋市",
                        center: "111.77818,30.176037",
                        level: 3
                    },
                    {
                        id: "421002",
                        name: "沙市区",
                        center: "112.257433,30.315895",
                        level: 3
                    },
                    {
                        id: "421081",
                        name: "石首市",
                        center: "112.40887,29.716437",
                        level: 3
                    },
                    {
                        id: "421022",
                        name: "公安县",
                        center: "112.230179,30.059065",
                        level: 3
                    }
                ]
            },
            {
                id: "421300",
                name: "随州市",
                center: "113.37377,31.717497",
                level: 2,
                children: [
                    {
                        id: "421303",
                        name: "曾都区",
                        center: "113.374519,31.717521",
                        level: 3
                    },
                    {
                        id: "421381",
                        name: "广水市",
                        center: "113.826601,31.617731",
                        level: 3
                    },
                    {
                        id: "421321",
                        name: "随县",
                        center: "113.301384,31.854246",
                        level: 3
                    }
                ]
            },
            {
                id: "422800",
                name: "恩施土家族苗族自治州",
                center: "109.48699,30.283114",
                level: 2,
                children: [
                    {
                        id: "422822",
                        name: "建始县",
                        center: "109.723822,30.601632",
                        level: 3
                    },
                    {
                        id: "422801",
                        name: "恩施市",
                        center: "109.486761,30.282406",
                        level: 3
                    },
                    {
                        id: "422802",
                        name: "利川市",
                        center: "108.943491,30.294247",
                        level: 3
                    },
                    {
                        id: "422828",
                        name: "鹤峰县",
                        center: "110.033699,29.887298",
                        level: 3
                    },
                    {
                        id: "422827",
                        name: "来凤县",
                        center: "109.408328,29.506945",
                        level: 3
                    },
                    {
                        id: "422825",
                        name: "宣恩县",
                        center: "109.482819,29.98867",
                        level: 3
                    },
                    {
                        id: "422823",
                        name: "巴东县",
                        center: "110.336665,31.041403",
                        level: 3
                    },
                    {
                        id: "422826",
                        name: "咸丰县",
                        center: "109.15041,29.678967",
                        level: 3
                    }
                ]
            },
            {
                id: "421200",
                name: "咸宁市",
                center: "114.328963,29.832798",
                level: 2,
                children: [
                    {
                        id: "421221",
                        name: "嘉鱼县",
                        center: "113.921547,29.973363",
                        level: 3
                    },
                    {
                        id: "421202",
                        name: "咸安区",
                        center: "114.333894,29.824716",
                        level: 3
                    },
                    {
                        id: "421224",
                        name: "通山县",
                        center: "114.493163,29.604455",
                        level: 3
                    },
                    {
                        id: "421223",
                        name: "崇阳县",
                        center: "114.049958,29.54101",
                        level: 3
                    },
                    {
                        id: "421281",
                        name: "赤壁市",
                        center: "113.88366,29.716879",
                        level: 3
                    },
                    {
                        id: "421222",
                        name: "通城县",
                        center: "113.814131,29.246076",
                        level: 3
                    }
                ]
            },
            {
                id: "429021",
                name: "神农架林区",
                center: "110.671525,31.744449",
                level: 2,
                children: [
                    {
                        id: "429021100",
                        name: "松柏镇",
                        center: "110.66165,31.74688",
                        level: 4
                    },
                    {
                        id: "429021101",
                        name: "阳日镇",
                        center: "110.8194,31.73742",
                        level: 4
                    },
                    {
                        id: "429021102",
                        name: "木鱼镇",
                        center: "110.48307,31.40151",
                        level: 4
                    },
                    {
                        id: "429021103",
                        name: "红坪镇",
                        center: "110.42904,31.67303",
                        level: 4
                    },
                    {
                        id: "429021104",
                        name: "新华镇",
                        center: "110.89155,31.59301",
                        level: 4
                    },
                    {
                        id: "429021105",
                        name: "九湖镇",
                        center: "110.106354,31.50507",
                        level: 4
                    },
                    {
                        id: "429021200",
                        name: "宋洛乡",
                        center: "110.60814,31.661",
                        level: 4
                    },
                    {
                        id: "429021202",
                        name: "下谷坪土家族乡",
                        center: "110.24514,31.36569",
                        level: 4
                    }
                ]
            },
            {
                id: "420700",
                name: "鄂州市",
                center: "114.890593,30.396536",
                level: 2,
                children: [
                    {
                        id: "420702",
                        name: "梁子湖区",
                        center: "114.681967,30.098191",
                        level: 3
                    },
                    {
                        id: "420703",
                        name: "华容区",
                        center: "114.74148,30.534468",
                        level: 3
                    },
                    {
                        id: "420704",
                        name: "鄂城区",
                        center: "114.890012,30.39669",
                        level: 3
                    }
                ]
            },
            {
                id: "420200",
                name: "黄石市",
                center: "115.077048,30.220074",
                level: 2,
                children: [
                    {
                        id: "420205",
                        name: "铁山区",
                        center: "114.901366,30.20601",
                        level: 3
                    },
                    {
                        id: "420204",
                        name: "下陆区",
                        center: "114.975755,30.177845",
                        level: 3
                    },
                    {
                        id: "420203",
                        name: "西塞山区",
                        center: "115.093354,30.205365",
                        level: 3
                    },
                    {
                        id: "420222",
                        name: "阳新县",
                        center: "115.212883,29.841572",
                        level: 3
                    },
                    {
                        id: "420281",
                        name: "大冶市",
                        center: "114.974842,30.098804",
                        level: 3
                    },
                    {
                        id: "420202",
                        name: "黄石港区",
                        center: "115.090164,30.212086",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "430000",
        name: "湖南省",
        center: "112.982279,28.19409",
        level: 1,
        children: [
            {
                id: "430400",
                name: "衡阳市",
                center: "112.607693,26.900358",
                level: 2,
                children: [
                    {
                        id: "430423",
                        name: "衡山县",
                        center: "112.86971,27.234808",
                        level: 3
                    },
                    {
                        id: "430422",
                        name: "衡南县",
                        center: "112.677459,26.739973",
                        level: 3
                    },
                    {
                        id: "430412",
                        name: "南岳区",
                        center: "112.734147,27.240536",
                        level: 3
                    },
                    {
                        id: "430407",
                        name: "石鼓区",
                        center: "112.607635,26.903908",
                        level: 3
                    },
                    {
                        id: "430405",
                        name: "珠晖区",
                        center: "112.626324,26.891063",
                        level: 3
                    },
                    {
                        id: "430426",
                        name: "祁东县",
                        center: "112.111192,26.787109",
                        level: 3
                    },
                    {
                        id: "430424",
                        name: "衡东县",
                        center: "112.950412,27.083531",
                        level: 3
                    },
                    {
                        id: "430421",
                        name: "衡阳县",
                        center: "112.379643,26.962388",
                        level: 3
                    },
                    {
                        id: "430406",
                        name: "雁峰区",
                        center: "112.612241,26.893694",
                        level: 3
                    },
                    {
                        id: "430481",
                        name: "耒阳市",
                        center: "112.847215,26.414162",
                        level: 3
                    },
                    {
                        id: "430408",
                        name: "蒸湘区",
                        center: "112.570608,26.89087",
                        level: 3
                    },
                    {
                        id: "430482",
                        name: "常宁市",
                        center: "112.396821,26.406773",
                        level: 3
                    }
                ]
            },
            {
                id: "430900",
                name: "益阳市",
                center: "112.355042,28.570066",
                level: 2,
                children: [
                    {
                        id: "430981",
                        name: "沅江市",
                        center: "112.361088,28.839713",
                        level: 3
                    },
                    {
                        id: "430902",
                        name: "资阳区",
                        center: "112.33084,28.592771",
                        level: 3
                    },
                    {
                        id: "430922",
                        name: "桃江县",
                        center: "112.139732,28.520993",
                        level: 3
                    },
                    {
                        id: "430921",
                        name: "南县",
                        center: "112.410399,29.372181",
                        level: 3
                    },
                    {
                        id: "430903",
                        name: "赫山区",
                        center: "112.360946,28.568327",
                        level: 3
                    },
                    {
                        id: "430923",
                        name: "安化县",
                        center: "111.221824,28.377421",
                        level: 3
                    }
                ]
            },
            {
                id: "430600",
                name: "岳阳市",
                center: "113.132855,29.37029",
                level: 2,
                children: [
                    {
                        id: "430603",
                        name: "云溪区",
                        center: "113.27387,29.473395",
                        level: 3
                    },
                    {
                        id: "430611",
                        name: "君山区",
                        center: "113.004082,29.438062",
                        level: 3
                    },
                    {
                        id: "430602",
                        name: "岳阳楼区",
                        center: "113.120751,29.366784",
                        level: 3
                    },
                    {
                        id: "430623",
                        name: "华容县",
                        center: "112.559369,29.524107",
                        level: 3
                    },
                    {
                        id: "430681",
                        name: "汨罗市",
                        center: "113.079419,28.803149",
                        level: 3
                    },
                    {
                        id: "430624",
                        name: "湘阴县",
                        center: "112.889748,28.677498",
                        level: 3
                    },
                    {
                        id: "430621",
                        name: "岳阳县",
                        center: "113.116073,29.144843",
                        level: 3
                    },
                    {
                        id: "430626",
                        name: "平江县",
                        center: "113.593751,28.701523",
                        level: 3
                    },
                    {
                        id: "430682",
                        name: "临湘市",
                        center: "113.450809,29.471594",
                        level: 3
                    }
                ]
            },
            {
                id: "431300",
                name: "娄底市",
                center: "112.008497,27.728136",
                level: 2,
                children: [
                    {
                        id: "431381",
                        name: "冷水江市",
                        center: "111.434674,27.685759",
                        level: 3
                    },
                    {
                        id: "431322",
                        name: "新化县",
                        center: "111.306747,27.737456",
                        level: 3
                    },
                    {
                        id: "431382",
                        name: "涟源市",
                        center: "111.670847,27.692301",
                        level: 3
                    },
                    {
                        id: "431302",
                        name: "娄星区",
                        center: "112.008486,27.726643",
                        level: 3
                    },
                    {
                        id: "431321",
                        name: "双峰县",
                        center: "112.198245,27.459126",
                        level: 3
                    }
                ]
            },
            {
                id: "430800",
                name: "张家界市",
                center: "110.479921,29.127401",
                level: 2,
                children: [
                    {
                        id: "430821",
                        name: "慈利县",
                        center: "111.132702,29.423876",
                        level: 3
                    },
                    {
                        id: "430811",
                        name: "武陵源区",
                        center: "110.54758,29.347827",
                        level: 3
                    },
                    {
                        id: "430822",
                        name: "桑植县",
                        center: "110.164039,29.399939",
                        level: 3
                    },
                    {
                        id: "430802",
                        name: "永定区",
                        center: "110.484559,29.125961",
                        level: 3
                    }
                ]
            },
            {
                id: "430700",
                name: "常德市",
                center: "111.691347,29.040225",
                level: 2,
                children: [
                    {
                        id: "430723",
                        name: "澧县",
                        center: "111.761682,29.64264",
                        level: 3
                    },
                    {
                        id: "430726",
                        name: "石门县",
                        center: "111.379087,29.584703",
                        level: 3
                    },
                    {
                        id: "430703",
                        name: "鼎城区",
                        center: "111.685327,29.014426",
                        level: 3
                    },
                    {
                        id: "430724",
                        name: "临澧县",
                        center: "111.645602,29.443217",
                        level: 3
                    },
                    {
                        id: "430722",
                        name: "汉寿县",
                        center: "111.968506,28.907319",
                        level: 3
                    },
                    {
                        id: "430702",
                        name: "武陵区",
                        center: "111.690718,29.040477",
                        level: 3
                    },
                    {
                        id: "430781",
                        name: "津市市",
                        center: "111.879609,29.630867",
                        level: 3
                    },
                    {
                        id: "430725",
                        name: "桃源县",
                        center: "111.484503,28.902734",
                        level: 3
                    },
                    {
                        id: "430721",
                        name: "安乡县",
                        center: "112.172289,29.414483",
                        level: 3
                    }
                ]
            },
            {
                id: "433100",
                name: "湘西土家族苗族自治州",
                center: "109.739735,28.314296",
                level: 2,
                children: [
                    {
                        id: "433101",
                        name: "吉首市",
                        center: "109.738273,28.314827",
                        level: 3
                    },
                    {
                        id: "433124",
                        name: "花垣县",
                        center: "109.479063,28.581352",
                        level: 3
                    },
                    {
                        id: "433127",
                        name: "永顺县",
                        center: "109.853292,28.998068",
                        level: 3
                    },
                    {
                        id: "433125",
                        name: "保靖县",
                        center: "109.651445,28.709605",
                        level: 3
                    },
                    {
                        id: "433126",
                        name: "古丈县",
                        center: "109.949592,28.616973",
                        level: 3
                    },
                    {
                        id: "433122",
                        name: "泸溪县",
                        center: "110.214428,28.214516",
                        level: 3
                    },
                    {
                        id: "433123",
                        name: "凤凰县",
                        center: "109.599191,27.948308",
                        level: 3
                    },
                    {
                        id: "433130",
                        name: "龙山县",
                        center: "109.441189,29.453438",
                        level: 3
                    }
                ]
            },
            {
                id: "431200",
                name: "怀化市",
                center: "109.97824,27.550082",
                level: 2,
                children: [
                    {
                        id: "431281",
                        name: "洪江市",
                        center: "109.831765,27.201876",
                        level: 3
                    },
                    {
                        id: "431228",
                        name: "芷江侗族自治县",
                        center: "109.687777,27.437996",
                        level: 3
                    },
                    {
                        id: "431230",
                        name: "通道侗族自治县",
                        center: "109.783359,26.158349",
                        level: 3
                    },
                    {
                        id: "431225",
                        name: "会同县",
                        center: "109.720785,26.870789",
                        level: 3
                    },
                    {
                        id: "431229",
                        name: "靖州苗族侗族自治县",
                        center: "109.691159,26.573511",
                        level: 3
                    },
                    {
                        id: "431223",
                        name: "辰溪县",
                        center: "110.196953,28.005474",
                        level: 3
                    },
                    {
                        id: "431222",
                        name: "沅陵县",
                        center: "110.399161,28.455554",
                        level: 3
                    },
                    {
                        id: "431224",
                        name: "溆浦县",
                        center: "110.593373,27.903802",
                        level: 3
                    },
                    {
                        id: "431227",
                        name: "新晃侗族自治县",
                        center: "109.174443,27.359897",
                        level: 3
                    },
                    {
                        id: "431226",
                        name: "麻阳苗族自治县",
                        center: "109.802807,27.865991",
                        level: 3
                    },
                    {
                        id: "431221",
                        name: "中方县",
                        center: "109.948061,27.43736",
                        level: 3
                    },
                    {
                        id: "431202",
                        name: "鹤城区",
                        center: "109.982242,27.548474",
                        level: 3
                    }
                ]
            },
            {
                id: "430100",
                name: "长沙市",
                center: "112.982279,28.19409",
                level: 2,
                children: [
                    {
                        id: "430105",
                        name: "开福区",
                        center: "112.985525,28.201336",
                        level: 3
                    },
                    {
                        id: "430104",
                        name: "岳麓区",
                        center: "112.911591,28.213044",
                        level: 3
                    },
                    {
                        id: "430181",
                        name: "浏阳市",
                        center: "113.633301,28.141112",
                        level: 3
                    },
                    {
                        id: "430103",
                        name: "天心区",
                        center: "112.97307,28.192375",
                        level: 3
                    },
                    {
                        id: "430121",
                        name: "长沙县",
                        center: "113.080098,28.237888",
                        level: 3
                    },
                    {
                        id: "430182",
                        name: "宁乡市",
                        center: "112.553182,28.253928",
                        level: 3
                    },
                    {
                        id: "430112",
                        name: "望城区",
                        center: "112.819549,28.347458",
                        level: 3
                    },
                    {
                        id: "430102",
                        name: "芙蓉区",
                        center: "112.988094,28.193106",
                        level: 3
                    },
                    {
                        id: "430111",
                        name: "雨花区",
                        center: "113.016337,28.109937",
                        level: 3
                    }
                ]
            },
            {
                id: "430300",
                name: "湘潭市",
                center: "112.944052,27.82973",
                level: 2,
                children: [
                    {
                        id: "430321",
                        name: "湘潭县",
                        center: "112.952829,27.778601",
                        level: 3
                    },
                    {
                        id: "430304",
                        name: "岳塘区",
                        center: "112.927707,27.828854",
                        level: 3
                    },
                    {
                        id: "430302",
                        name: "雨湖区",
                        center: "112.907427,27.86077",
                        level: 3
                    },
                    {
                        id: "430382",
                        name: "韶山市",
                        center: "112.52848,27.922682",
                        level: 3
                    },
                    {
                        id: "430381",
                        name: "湘乡市",
                        center: "112.525217,27.734918",
                        level: 3
                    }
                ]
            },
            {
                id: "431000",
                name: "郴州市",
                center: "113.032067,25.793589",
                level: 2,
                children: [
                    {
                        id: "431028",
                        name: "安仁县",
                        center: "113.27217,26.708625",
                        level: 3
                    },
                    {
                        id: "431081",
                        name: "资兴市",
                        center: "113.23682,25.974152",
                        level: 3
                    },
                    {
                        id: "431026",
                        name: "汝城县",
                        center: "113.685686,25.553759",
                        level: 3
                    },
                    {
                        id: "431027",
                        name: "桂东县",
                        center: "113.945879,26.073917",
                        level: 3
                    },
                    {
                        id: "431023",
                        name: "永兴县",
                        center: "113.114819,26.129392",
                        level: 3
                    },
                    {
                        id: "431002",
                        name: "北湖区",
                        center: "113.032208,25.792628",
                        level: 3
                    },
                    {
                        id: "431003",
                        name: "苏仙区",
                        center: "113.038698,25.793157",
                        level: 3
                    },
                    {
                        id: "431025",
                        name: "临武县",
                        center: "112.564589,25.279119",
                        level: 3
                    },
                    {
                        id: "431021",
                        name: "桂阳县",
                        center: "112.734466,25.737447",
                        level: 3
                    },
                    {
                        id: "431022",
                        name: "宜章县",
                        center: "112.947884,25.394345",
                        level: 3
                    },
                    {
                        id: "431024",
                        name: "嘉禾县",
                        center: "112.370618,25.587309",
                        level: 3
                    }
                ]
            },
            {
                id: "431100",
                name: "永州市",
                center: "111.608019,26.434516",
                level: 2,
                children: [
                    {
                        id: "431122",
                        name: "东安县",
                        center: "111.313035,26.397278",
                        level: 3
                    },
                    {
                        id: "431103",
                        name: "冷水滩区",
                        center: "111.607156,26.434364",
                        level: 3
                    },
                    {
                        id: "431121",
                        name: "祁阳县",
                        center: "111.85734,26.585929",
                        level: 3
                    },
                    {
                        id: "431102",
                        name: "零陵区",
                        center: "111.626348,26.223347",
                        level: 3
                    },
                    {
                        id: "431125",
                        name: "江永县",
                        center: "111.346803,25.268154",
                        level: 3
                    },
                    {
                        id: "431128",
                        name: "新田县",
                        center: "112.220341,25.906927",
                        level: 3
                    },
                    {
                        id: "431129",
                        name: "江华瑶族自治县",
                        center: "111.577276,25.182596",
                        level: 3
                    },
                    {
                        id: "431124",
                        name: "道县",
                        center: "111.591614,25.518444",
                        level: 3
                    },
                    {
                        id: "431126",
                        name: "宁远县",
                        center: "111.944529,25.584112",
                        level: 3
                    },
                    {
                        id: "431123",
                        name: "双牌县",
                        center: "111.662146,25.959397",
                        level: 3
                    },
                    {
                        id: "431127",
                        name: "蓝山县",
                        center: "112.194195,25.375255",
                        level: 3
                    }
                ]
            },
            {
                id: "430200",
                name: "株洲市",
                center: "113.151737,27.835806",
                level: 2,
                children: [
                    {
                        id: "430202",
                        name: "荷塘区",
                        center: "113.162548,27.833036",
                        level: 3
                    },
                    {
                        id: "430223",
                        name: "攸县",
                        center: "113.345774,27.000071",
                        level: 3
                    },
                    {
                        id: "430224",
                        name: "茶陵县",
                        center: "113.546509,26.789534",
                        level: 3
                    },
                    {
                        id: "430211",
                        name: "天元区",
                        center: "113.136252,27.826909",
                        level: 3
                    },
                    {
                        id: "430203",
                        name: "芦淞区",
                        center: "113.155169,27.827246",
                        level: 3
                    },
                    {
                        id: "430225",
                        name: "炎陵县",
                        center: "113.776884,26.489459",
                        level: 3
                    },
                    {
                        id: "430204",
                        name: "石峰区",
                        center: "113.11295,27.871945",
                        level: 3
                    },
                    {
                        id: "430212",
                        name: "渌口区",
                        center: "113.146176,27.705844",
                        level: 3
                    },
                    {
                        id: "430281",
                        name: "醴陵市",
                        center: "113.507157,27.657873",
                        level: 3
                    }
                ]
            },
            {
                id: "430500",
                name: "邵阳市",
                center: "111.46923,27.237842",
                level: 2,
                children: [
                    {
                        id: "430524",
                        name: "隆回县",
                        center: "111.038785,27.116002",
                        level: 3
                    },
                    {
                        id: "430511",
                        name: "北塔区",
                        center: "111.452315,27.245688",
                        level: 3
                    },
                    {
                        id: "430527",
                        name: "绥宁县",
                        center: "110.155075,26.580622",
                        level: 3
                    },
                    {
                        id: "430502",
                        name: "双清区",
                        center: "111.479756,27.240001",
                        level: 3
                    },
                    {
                        id: "430523",
                        name: "邵阳县",
                        center: "111.2757,26.989713",
                        level: 3
                    },
                    {
                        id: "430503",
                        name: "大祥区",
                        center: "111.462968,27.233593",
                        level: 3
                    },
                    {
                        id: "430529",
                        name: "城步苗族自治县",
                        center: "110.313226,26.363575",
                        level: 3
                    },
                    {
                        id: "430525",
                        name: "洞口县",
                        center: "110.579212,27.062286",
                        level: 3
                    },
                    {
                        id: "430581",
                        name: "武冈市",
                        center: "110.636804,26.732086",
                        level: 3
                    },
                    {
                        id: "430521",
                        name: "邵东市",
                        center: "111.743168,27.257273",
                        level: 3
                    },
                    {
                        id: "430522",
                        name: "新邵县",
                        center: "111.459762,27.311429",
                        level: 3
                    },
                    {
                        id: "430528",
                        name: "新宁县",
                        center: "110.859115,26.438912",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "440000",
        name: "广东省",
        center: "113.280637,23.125178",
        level: 1,
        children: [
            {
                id: "441200",
                name: "肇庆市",
                center: "112.472529,23.051546",
                level: 2,
                children: [
                    {
                        id: "441224",
                        name: "怀集县",
                        center: "112.182466,23.913072",
                        level: 3
                    },
                    {
                        id: "441223",
                        name: "广宁县",
                        center: "112.440419,23.631486",
                        level: 3
                    },
                    {
                        id: "441225",
                        name: "封开县",
                        center: "111.502973,23.434731",
                        level: 3
                    },
                    {
                        id: "441284",
                        name: "四会市",
                        center: "112.695028,23.340324",
                        level: 3
                    },
                    {
                        id: "441226",
                        name: "德庆县",
                        center: "111.78156,23.141711",
                        level: 3
                    },
                    {
                        id: "441203",
                        name: "鼎湖区",
                        center: "112.565249,23.155822",
                        level: 3
                    },
                    {
                        id: "441202",
                        name: "端州区",
                        center: "112.472329,23.052662",
                        level: 3
                    },
                    {
                        id: "441204",
                        name: "高要区",
                        center: "112.460846,23.027694",
                        level: 3
                    }
                ]
            },
            {
                id: "440600",
                name: "佛山市",
                center: "113.122717,23.028762",
                level: 2,
                children: [
                    {
                        id: "440607",
                        name: "三水区",
                        center: "112.899414,23.16504",
                        level: 3
                    },
                    {
                        id: "440608",
                        name: "高明区",
                        center: "112.882123,22.893855",
                        level: 3
                    },
                    {
                        id: "440606",
                        name: "顺德区",
                        center: "113.281826,22.75851",
                        level: 3
                    },
                    {
                        id: "440604",
                        name: "禅城区",
                        center: "113.112414,23.019643",
                        level: 3
                    },
                    {
                        id: "440605",
                        name: "南海区",
                        center: "113.145577,23.031562",
                        level: 3
                    }
                ]
            },
            {
                id: "440500",
                name: "汕头市",
                center: "116.708463,23.37102",
                level: 2,
                children: [
                    {
                        id: "440513",
                        name: "潮阳区",
                        center: "116.602602,23.262336",
                        level: 3
                    },
                    {
                        id: "440512",
                        name: "濠江区",
                        center: "116.729528,23.279345",
                        level: 3
                    },
                    {
                        id: "440523",
                        name: "南澳县",
                        center: "117.027105,23.419562",
                        level: 3
                    },
                    {
                        id: "440515",
                        name: "澄海区",
                        center: "116.76336,23.46844",
                        level: 3
                    },
                    {
                        id: "440514",
                        name: "潮南区",
                        center: "116.423607,23.249798",
                        level: 3
                    },
                    {
                        id: "440511",
                        name: "金平区",
                        center: "116.703583,23.367071",
                        level: 3
                    },
                    {
                        id: "440507",
                        name: "龙湖区",
                        center: "116.732015,23.373754",
                        level: 3
                    }
                ]
            },
            {
                id: "441300",
                name: "惠州市",
                center: "114.412599,23.079404",
                level: 2,
                children: [
                    {
                        id: "441324",
                        name: "龙门县",
                        center: "114.259986,23.723894",
                        level: 3
                    },
                    {
                        id: "441322",
                        name: "博罗县",
                        center: "114.284254,23.167575",
                        level: 3
                    },
                    {
                        id: "441323",
                        name: "惠东县",
                        center: "114.723092,22.983036",
                        level: 3
                    },
                    {
                        id: "441303",
                        name: "惠阳区",
                        center: "114.469444,22.78851",
                        level: 3
                    },
                    {
                        id: "441302",
                        name: "惠城区",
                        center: "114.413978,23.079883",
                        level: 3
                    }
                ]
            },
            {
                id: "440300",
                name: "深圳市",
                center: "114.085947,22.547",
                level: 2,
                children: [
                    {
                        id: "440306",
                        name: "宝安区",
                        center: "113.828671,22.754741",
                        level: 3
                    },
                    {
                        id: "440305",
                        name: "南山区",
                        center: "113.92943,22.531221",
                        level: 3
                    },
                    {
                        id: "440304",
                        name: "福田区",
                        center: "114.05096,22.541009",
                        level: 3
                    },
                    {
                        id: "440308",
                        name: "盐田区",
                        center: "114.235366,22.555069",
                        level: 3
                    },
                    {
                        id: "440303",
                        name: "罗湖区",
                        center: "114.123885,22.555341",
                        level: 3
                    },
                    {
                        id: "440307",
                        name: "龙岗区",
                        center: "114.251372,22.721511",
                        level: 3
                    },
                    {
                        id: "440309",
                        name: "龙华区",
                        center: "114.044346,22.691963",
                        level: 3
                    },
                    {
                        id: "440310",
                        name: "坪山区",
                        center: "114.338441,22.69423",
                        level: 3
                    },
                    {
                        id: "440311",
                        name: "光明区",
                        center: "113.935895,22.748816",
                        level: 3
                    }
                ]
            },
            {
                id: "440400",
                name: "珠海市",
                center: "113.553986,22.224979",
                level: 2,
                children: [
                    {
                        id: "440402",
                        name: "香洲区",
                        center: "113.55027,22.271249",
                        level: 3
                    },
                    {
                        id: "440403",
                        name: "斗门区",
                        center: "113.297739,22.209117",
                        level: 3
                    },
                    {
                        id: "440404",
                        name: "金湾区",
                        center: "113.345071,22.139122",
                        level: 3
                    }
                ]
            },
            {
                id: "440800",
                name: "湛江市",
                center: "110.364977,21.274898",
                level: 2,
                children: [
                    {
                        id: "440883",
                        name: "吴川市",
                        center: "110.780508,21.428453",
                        level: 3
                    },
                    {
                        id: "440881",
                        name: "廉江市",
                        center: "110.284961,21.611281",
                        level: 3
                    },
                    {
                        id: "440882",
                        name: "雷州市",
                        center: "110.088275,20.908523",
                        level: 3
                    },
                    {
                        id: "440825",
                        name: "徐闻县",
                        center: "110.175718,20.326083",
                        level: 3
                    },
                    {
                        id: "440811",
                        name: "麻章区",
                        center: "110.329167,21.265997",
                        level: 3
                    },
                    {
                        id: "440804",
                        name: "坡头区",
                        center: "110.455632,21.24441",
                        level: 3
                    },
                    {
                        id: "440803",
                        name: "霞山区",
                        center: "110.406382,21.194229",
                        level: 3
                    },
                    {
                        id: "440823",
                        name: "遂溪县",
                        center: "110.255321,21.376915",
                        level: 3
                    },
                    {
                        id: "440802",
                        name: "赤坎区",
                        center: "110.361634,21.273365",
                        level: 3
                    }
                ]
            },
            {
                id: "441700",
                name: "阳江市",
                center: "111.975107,21.859222",
                level: 2,
                children: [
                    {
                        id: "441781",
                        name: "阳春市",
                        center: "111.7905,22.169598",
                        level: 3
                    },
                    {
                        id: "441702",
                        name: "江城区",
                        center: "111.968909,21.859182",
                        level: 3
                    },
                    {
                        id: "441721",
                        name: "阳西县",
                        center: "111.617556,21.75367",
                        level: 3
                    },
                    {
                        id: "441704",
                        name: "阳东区",
                        center: "112.011267,21.864728",
                        level: 3
                    }
                ]
            },
            {
                id: "441500",
                name: "汕尾市",
                center: "115.364238,22.774485",
                level: 2,
                children: [
                    {
                        id: "441523",
                        name: "陆河县",
                        center: "115.657565,23.302682",
                        level: 3
                    },
                    {
                        id: "441502",
                        name: "城区",
                        center: "115.363667,22.776227",
                        level: 3
                    },
                    {
                        id: "441521",
                        name: "海丰县",
                        center: "115.337324,22.971042",
                        level: 3
                    },
                    {
                        id: "441581",
                        name: "陆丰市",
                        center: "115.644203,22.946104",
                        level: 3
                    }
                ]
            },
            {
                id: "445100",
                name: "潮州市",
                center: "116.632301,23.661701",
                level: 2,
                children: [
                    {
                        id: "445122",
                        name: "饶平县",
                        center: "117.00205,23.668171",
                        level: 3
                    },
                    {
                        id: "445102",
                        name: "湘桥区",
                        center: "116.63365,23.664675",
                        level: 3
                    },
                    {
                        id: "445103",
                        name: "潮安区",
                        center: "116.67931,23.461012",
                        level: 3
                    }
                ]
            },
            {
                id: "440700",
                name: "江门市",
                center: "113.094942,22.590431",
                level: 2,
                children: [
                    {
                        id: "440784",
                        name: "鹤山市",
                        center: "112.961795,22.768104",
                        level: 3
                    },
                    {
                        id: "440783",
                        name: "开平市",
                        center: "112.692262,22.366286",
                        level: 3
                    },
                    {
                        id: "440704",
                        name: "江海区",
                        center: "113.120601,22.572211",
                        level: 3
                    },
                    {
                        id: "440781",
                        name: "台山市",
                        center: "112.793414,22.250713",
                        level: 3
                    },
                    {
                        id: "440785",
                        name: "恩平市",
                        center: "112.314051,22.182956",
                        level: 3
                    },
                    {
                        id: "440705",
                        name: "新会区",
                        center: "113.038584,22.520247",
                        level: 3
                    },
                    {
                        id: "440703",
                        name: "蓬江区",
                        center: "113.07859,22.59677",
                        level: 3
                    }
                ]
            },
            {
                id: "445300",
                name: "云浮市",
                center: "112.044439,22.929801",
                level: 2,
                children: [
                    {
                        id: "445322",
                        name: "郁南县",
                        center: "111.535921,23.237709",
                        level: 3
                    },
                    {
                        id: "445381",
                        name: "罗定市",
                        center: "111.578201,22.765415",
                        level: 3
                    },
                    {
                        id: "445321",
                        name: "新兴县",
                        center: "112.23083,22.703204",
                        level: 3
                    },
                    {
                        id: "445302",
                        name: "云城区",
                        center: "112.04471,22.930827",
                        level: 3
                    },
                    {
                        id: "445303",
                        name: "云安区",
                        center: "112.005609,23.073152",
                        level: 3
                    }
                ]
            },
            {
                id: "442100",
                name: "东沙群岛",
                center: "116.887312,20.617512",
                level: 2
            },
            {
                id: "440900",
                name: "茂名市",
                center: "110.919229,21.659751",
                level: 2,
                children: [
                    {
                        id: "440983",
                        name: "信宜市",
                        center: "110.941656,22.352681",
                        level: 3
                    },
                    {
                        id: "440981",
                        name: "高州市",
                        center: "110.853251,21.915153",
                        level: 3
                    },
                    {
                        id: "440982",
                        name: "化州市",
                        center: "110.63839,21.654953",
                        level: 3
                    },
                    {
                        id: "440904",
                        name: "电白区",
                        center: "111.007264,21.507219",
                        level: 3
                    },
                    {
                        id: "440902",
                        name: "茂南区",
                        center: "110.920542,21.660425",
                        level: 3
                    }
                ]
            },
            {
                id: "441600",
                name: "河源市",
                center: "114.697802,23.746266",
                level: 2,
                children: [
                    {
                        id: "441622",
                        name: "龙川县",
                        center: "115.256415,24.101174",
                        level: 3
                    },
                    {
                        id: "441624",
                        name: "和平县",
                        center: "114.941473,24.44318",
                        level: 3
                    },
                    {
                        id: "441625",
                        name: "东源县",
                        center: "114.742711,23.789093",
                        level: 3
                    },
                    {
                        id: "441623",
                        name: "连平县",
                        center: "114.495952,24.364227",
                        level: 3
                    },
                    {
                        id: "441602",
                        name: "源城区",
                        center: "114.696828,23.746255",
                        level: 3
                    },
                    {
                        id: "441621",
                        name: "紫金县",
                        center: "115.184383,23.633744",
                        level: 3
                    }
                ]
            },
            {
                id: "441400",
                name: "梅州市",
                center: "116.117582,24.299112",
                level: 2,
                children: [
                    {
                        id: "441427",
                        name: "蕉岭县",
                        center: "116.170531,24.653313",
                        level: 3
                    },
                    {
                        id: "441426",
                        name: "平远县",
                        center: "115.891729,24.569651",
                        level: 3
                    },
                    {
                        id: "441481",
                        name: "兴宁市",
                        center: "115.731648,24.138077",
                        level: 3
                    },
                    {
                        id: "441424",
                        name: "五华县",
                        center: "115.775004,23.925424",
                        level: 3
                    },
                    {
                        id: "441402",
                        name: "梅江区",
                        center: "116.12116,24.302593",
                        level: 3
                    },
                    {
                        id: "441423",
                        name: "丰顺县",
                        center: "116.184419,23.752771",
                        level: 3
                    },
                    {
                        id: "441403",
                        name: "梅县区",
                        center: "116.083482,24.267825",
                        level: 3
                    },
                    {
                        id: "441422",
                        name: "大埔县",
                        center: "116.69552,24.351587",
                        level: 3
                    }
                ]
            },
            {
                id: "441900",
                name: "东莞市",
                center: "113.746262,23.046237",
                level: 2,
                children: [
                    {
                        id: "441900003",
                        name: "东城街道",
                        center: "113.79447,22.98663",
                        level: 4
                    },
                    {
                        id: "441900004",
                        name: "南城街道",
                        center: "113.75151,22.98628",
                        level: 4
                    },
                    {
                        id: "441900005",
                        name: "万江街道",
                        center: "113.73833,23.04676",
                        level: 4
                    },
                    {
                        id: "441900006",
                        name: "莞城街道",
                        center: "113.74935,23.03859",
                        level: 4
                    },
                    {
                        id: "441900101",
                        name: "石碣镇",
                        center: "113.81325,23.09933",
                        level: 4
                    },
                    {
                        id: "441900102",
                        name: "石龙镇",
                        center: "113.873815,23.104676",
                        level: 4
                    },
                    {
                        id: "441900103",
                        name: "茶山镇",
                        center: "113.87403,23.07213",
                        level: 4
                    },
                    {
                        id: "441900104",
                        name: "石排镇",
                        center: "113.93913,23.09044",
                        level: 4
                    },
                    {
                        id: "441900105",
                        name: "企石镇",
                        center: "114.05704,23.03604",
                        level: 4
                    },
                    {
                        id: "441900106",
                        name: "横沥镇",
                        center: "113.96596,23.019875",
                        level: 4
                    },
                    {
                        id: "441900107",
                        name: "桥头镇",
                        center: "114.10322,23.02307",
                        level: 4
                    },
                    {
                        id: "441900108",
                        name: "谢岗镇",
                        center: "114.14863,22.96119",
                        level: 4
                    },
                    {
                        id: "441900109",
                        name: "东坑镇",
                        center: "113.93068,22.99774",
                        level: 4
                    },
                    {
                        id: "441900110",
                        name: "常平镇",
                        center: "114.00587,22.97666",
                        level: 4
                    },
                    {
                        id: "441900111",
                        name: "寮步镇",
                        center: "113.87373,22.99712",
                        level: 4
                    },
                    {
                        id: "441900112",
                        name: "樟木头镇",
                        center: "114.08326,22.91495",
                        level: 4
                    },
                    {
                        id: "441900113",
                        name: "大朗镇",
                        center: "113.95128,22.94248",
                        level: 4
                    },
                    {
                        id: "441900114",
                        name: "黄江镇",
                        center: "114.00356,22.91528",
                        level: 4
                    },
                    {
                        id: "441900115",
                        name: "清溪镇",
                        center: "114.159856,22.824373",
                        level: 4
                    },
                    {
                        id: "441900116",
                        name: "塘厦镇",
                        center: "114.07256,22.80706",
                        level: 4
                    },
                    {
                        id: "441900117",
                        name: "凤岗镇",
                        center: "114.1368,22.74652",
                        level: 4
                    },
                    {
                        id: "441900118",
                        name: "大岭山镇",
                        center: "113.83881,22.89828",
                        level: 4
                    },
                    {
                        id: "441900119",
                        name: "长安镇",
                        center: "113.80124,22.816",
                        level: 4
                    },
                    {
                        id: "441900121",
                        name: "虎门镇",
                        center: "113.64537,22.8117",
                        level: 4
                    },
                    {
                        id: "441900122",
                        name: "厚街镇",
                        center: "113.67029,22.93528",
                        level: 4
                    },
                    {
                        id: "441900123",
                        name: "沙田镇",
                        center: "113.61824,22.91973",
                        level: 4
                    },
                    {
                        id: "441900124",
                        name: "道滘镇",
                        center: "113.676298,23.005191",
                        level: 4
                    },
                    {
                        id: "441900125",
                        name: "洪梅镇",
                        center: "113.60895,22.99467",
                        level: 4
                    },
                    {
                        id: "441900126",
                        name: "麻涌镇",
                        center: "113.57941,23.05139",
                        level: 4
                    },
                    {
                        id: "441900127",
                        name: "望牛墩镇",
                        center: "113.65861,23.05579",
                        level: 4
                    },
                    {
                        id: "441900128",
                        name: "中堂镇",
                        center: "113.65783,23.09328",
                        level: 4
                    },
                    {
                        id: "441900129",
                        name: "高埗镇",
                        center: "113.745,23.09296",
                        level: 4
                    },
                    {
                        id: "441900401",
                        name: "松山湖管委会",
                        center: "113.887354,22.91247",
                        level: 4
                    },
                    {
                        id: "441900402",
                        name: "虎门港管委会",
                        center: "113.571403,22.905564",
                        level: 4
                    },
                    {
                        id: "441900403",
                        name: "东莞生态园",
                        center: "113.905882,23.08118",
                        level: 4
                    }
                ]
            },
            {
                id: "445200",
                name: "揭阳市",
                center: "116.355733,23.543778",
                level: 2,
                children: [
                    {
                        id: "445222",
                        name: "揭西县",
                        center: "115.838708,23.4273",
                        level: 3
                    },
                    {
                        id: "445281",
                        name: "普宁市",
                        center: "116.165082,23.29788",
                        level: 3
                    },
                    {
                        id: "445224",
                        name: "惠来县",
                        center: "116.295832,23.029834",
                        level: 3
                    },
                    {
                        id: "445203",
                        name: "揭东区",
                        center: "116.412947,23.569887",
                        level: 3
                    },
                    {
                        id: "445202",
                        name: "榕城区",
                        center: "116.357045,23.535524",
                        level: 3
                    }
                ]
            },
            {
                id: "441800",
                name: "清远市",
                center: "113.051227,23.685022",
                level: 2,
                children: [
                    {
                        id: "441882",
                        name: "连州市",
                        center: "112.379271,24.783966",
                        level: 3
                    },
                    {
                        id: "441826",
                        name: "连南瑶族自治县",
                        center: "112.290808,24.719097",
                        level: 3
                    },
                    {
                        id: "441825",
                        name: "连山壮族瑶族自治县",
                        center: "112.086555,24.567271",
                        level: 3
                    },
                    {
                        id: "441881",
                        name: "英德市",
                        center: "113.405404,24.18612",
                        level: 3
                    },
                    {
                        id: "441821",
                        name: "佛冈县",
                        center: "113.534094,23.866739",
                        level: 3
                    },
                    {
                        id: "441823",
                        name: "阳山县",
                        center: "112.634019,24.470286",
                        level: 3
                    },
                    {
                        id: "441803",
                        name: "清新区",
                        center: "113.015203,23.736949",
                        level: 3
                    },
                    {
                        id: "441802",
                        name: "清城区",
                        center: "113.048698,23.688976",
                        level: 3
                    }
                ]
            },
            {
                id: "440200",
                name: "韶关市",
                center: "113.591544,24.801322",
                level: 2,
                children: [
                    {
                        id: "440282",
                        name: "南雄市",
                        center: "114.311231,25.115328",
                        level: 3
                    },
                    {
                        id: "440224",
                        name: "仁化县",
                        center: "113.748627,25.088226",
                        level: 3
                    },
                    {
                        id: "440232",
                        name: "乳源瑶族自治县",
                        center: "113.278417,24.776109",
                        level: 3
                    },
                    {
                        id: "440204",
                        name: "浈江区",
                        center: "113.599224,24.803977",
                        level: 3
                    },
                    {
                        id: "440203",
                        name: "武江区",
                        center: "113.588289,24.80016",
                        level: 3
                    },
                    {
                        id: "440222",
                        name: "始兴县",
                        center: "114.067205,24.948364",
                        level: 3
                    },
                    {
                        id: "440205",
                        name: "曲江区",
                        center: "113.605582,24.680195",
                        level: 3
                    },
                    {
                        id: "440233",
                        name: "新丰县",
                        center: "114.207034,24.055412",
                        level: 3
                    },
                    {
                        id: "440229",
                        name: "翁源县",
                        center: "114.131289,24.353887",
                        level: 3
                    },
                    {
                        id: "440281",
                        name: "乐昌市",
                        center: "113.352413,25.128445",
                        level: 3
                    }
                ]
            },
            {
                id: "440100",
                name: "广州市",
                center: "113.280637,23.125178",
                level: 2,
                children: [
                    {
                        id: "440117",
                        name: "从化区",
                        center: "113.587386,23.545283",
                        level: 3
                    },
                    {
                        id: "440115",
                        name: "南沙区",
                        center: "113.53738,22.794531",
                        level: 3
                    },
                    {
                        id: "440114",
                        name: "花都区",
                        center: "113.211184,23.39205",
                        level: 3
                    },
                    {
                        id: "440113",
                        name: "番禺区",
                        center: "113.364619,22.938582",
                        level: 3
                    },
                    {
                        id: "440103",
                        name: "荔湾区",
                        center: "113.243038,23.124943",
                        level: 3
                    },
                    {
                        id: "440105",
                        name: "海珠区",
                        center: "113.262008,23.103131",
                        level: 3
                    },
                    {
                        id: "440111",
                        name: "白云区",
                        center: "113.262831,23.162281",
                        level: 3
                    },
                    {
                        id: "440118",
                        name: "增城区",
                        center: "113.829579,23.290497",
                        level: 3
                    },
                    {
                        id: "440112",
                        name: "黄埔区",
                        center: "113.450761,23.103239",
                        level: 3
                    },
                    {
                        id: "440106",
                        name: "天河区",
                        center: "113.335367,23.13559",
                        level: 3
                    },
                    {
                        id: "440104",
                        name: "越秀区",
                        center: "113.280714,23.125624",
                        level: 3
                    }
                ]
            },
            {
                id: "442000",
                name: "中山市",
                center: "113.382391,22.521113",
                level: 2,
                children: [
                    {
                        id: "442000001",
                        name: "石岐区街道",
                        center: "113.380204,22.536309",
                        level: 4
                    },
                    {
                        id: "442000002",
                        name: "东区街道",
                        center: "113.426555,22.48949",
                        level: 4
                    },
                    {
                        id: "442000003",
                        name: "火炬开发区",
                        center: "113.485859,22.547239",
                        level: 4
                    },
                    {
                        id: "442000004",
                        name: "西区街道",
                        center: "113.357624,22.519422",
                        level: 4
                    },
                    {
                        id: "442000005",
                        name: "南区街道",
                        center: "113.340136,22.454589",
                        level: 4
                    },
                    {
                        id: "442000006",
                        name: "五桂山街道",
                        center: "113.402396,22.446195",
                        level: 4
                    },
                    {
                        id: "442000100",
                        name: "小榄镇",
                        center: "113.25108,22.67189",
                        level: 4
                    },
                    {
                        id: "442000101",
                        name: "黄圃镇",
                        center: "113.33921,22.71111",
                        level: 4
                    },
                    {
                        id: "442000102",
                        name: "民众镇",
                        center: "113.49456,22.62233",
                        level: 4
                    },
                    {
                        id: "442000103",
                        name: "东凤镇",
                        center: "113.25741,22.70212",
                        level: 4
                    },
                    {
                        id: "442000104",
                        name: "东升镇",
                        center: "113.29225,22.62303",
                        level: 4
                    },
                    {
                        id: "442000105",
                        name: "古镇镇",
                        center: "113.19065,22.61264",
                        level: 4
                    },
                    {
                        id: "442000106",
                        name: "沙溪镇",
                        center: "113.32236,22.50706",
                        level: 4
                    },
                    {
                        id: "442000107",
                        name: "坦洲镇",
                        center: "113.46794,22.25467",
                        level: 4
                    },
                    {
                        id: "442000108",
                        name: "港口镇",
                        center: "113.38506,22.58551",
                        level: 4
                    },
                    {
                        id: "442000109",
                        name: "三角镇",
                        center: "113.41802,22.67629",
                        level: 4
                    },
                    {
                        id: "442000110",
                        name: "横栏镇",
                        center: "113.24956,22.53445",
                        level: 4
                    },
                    {
                        id: "442000111",
                        name: "南头镇",
                        center: "113.29182,22.71724",
                        level: 4
                    },
                    {
                        id: "442000112",
                        name: "阜沙镇",
                        center: "113.34987,22.66722",
                        level: 4
                    },
                    {
                        id: "442000113",
                        name: "南朗镇",
                        center: "113.53139,22.49867",
                        level: 4
                    },
                    {
                        id: "442000114",
                        name: "三乡镇",
                        center: "113.44142,22.35798",
                        level: 4
                    },
                    {
                        id: "442000115",
                        name: "板芙镇",
                        center: "113.32235,22.41686",
                        level: 4
                    },
                    {
                        id: "442000116",
                        name: "大涌镇",
                        center: "113.3007,22.46508",
                        level: 4
                    },
                    {
                        id: "442000117",
                        name: "神湾镇",
                        center: "113.36373,22.30247",
                        level: 4
                    }
                ]
            }
        ]
    },
    {
        id: "450000",
        name: "广西壮族自治区",
        center: "108.320004,22.82402",
        level: 1,
        children: [
            {
                id: "450500",
                name: "北海市",
                center: "109.119254,21.473343",
                level: 2,
                children: [
                    {
                        id: "450512",
                        name: "铁山港区",
                        center: "109.450573,21.5928",
                        level: 3
                    },
                    {
                        id: "450503",
                        name: "银海区",
                        center: "109.118707,21.444909",
                        level: 3
                    },
                    {
                        id: "450502",
                        name: "海城区",
                        center: "109.107529,21.468443",
                        level: 3
                    },
                    {
                        id: "450521",
                        name: "合浦县",
                        center: "109.200695,21.663554",
                        level: 3
                    }
                ]
            },
            {
                id: "450700",
                name: "钦州市",
                center: "108.624175,21.967127",
                level: 2,
                children: [
                    {
                        id: "450702",
                        name: "钦南区",
                        center: "108.626629,21.966808",
                        level: 3
                    },
                    {
                        id: "450703",
                        name: "钦北区",
                        center: "108.44911,22.132761",
                        level: 3
                    },
                    {
                        id: "450722",
                        name: "浦北县",
                        center: "109.556341,22.268335",
                        level: 3
                    },
                    {
                        id: "450721",
                        name: "灵山县",
                        center: "109.293468,22.418041",
                        level: 3
                    }
                ]
            },
            {
                id: "451000",
                name: "百色市",
                center: "106.616285,23.897742",
                level: 2,
                children: [
                    {
                        id: "451031",
                        name: "隆林各族自治县",
                        center: "105.342363,24.774318",
                        level: 3
                    },
                    {
                        id: "451028",
                        name: "乐业县",
                        center: "106.559638,24.782204",
                        level: 3
                    },
                    {
                        id: "451027",
                        name: "凌云县",
                        center: "106.56487,24.345643",
                        level: 3
                    },
                    {
                        id: "451030",
                        name: "西林县",
                        center: "105.095025,24.492041",
                        level: 3
                    },
                    {
                        id: "451002",
                        name: "右江区",
                        center: "106.615727,23.897675",
                        level: 3
                    },
                    {
                        id: "451022",
                        name: "田东县",
                        center: "107.12426,23.600444",
                        level: 3
                    },
                    {
                        id: "451021",
                        name: "田阳区",
                        center: "106.904315,23.736079",
                        level: 3
                    },
                    {
                        id: "451024",
                        name: "德保县",
                        center: "106.618164,23.321464",
                        level: 3
                    },
                    {
                        id: "451023",
                        name: "平果县",
                        center: "107.580403,23.320479",
                        level: 3
                    },
                    {
                        id: "451026",
                        name: "那坡县",
                        center: "105.833553,23.400785",
                        level: 3
                    },
                    {
                        id: "451081",
                        name: "靖西市",
                        center: "106.417549,23.134766",
                        level: 3
                    },
                    {
                        id: "451029",
                        name: "田林县",
                        center: "106.235047,24.290262",
                        level: 3
                    }
                ]
            },
            {
                id: "451200",
                name: "河池市",
                center: "108.062105,24.695899",
                level: 2,
                children: [
                    {
                        id: "451222",
                        name: "天峨县",
                        center: "107.174939,24.985964",
                        level: 3
                    },
                    {
                        id: "451226",
                        name: "环江毛南族自治县",
                        center: "108.258669,24.827628",
                        level: 3
                    },
                    {
                        id: "451221",
                        name: "南丹县",
                        center: "107.546605,24.983192",
                        level: 3
                    },
                    {
                        id: "451225",
                        name: "罗城仫佬族自治县",
                        center: "108.902453,24.779327",
                        level: 3
                    },
                    {
                        id: "451203",
                        name: "宜州区",
                        center: "108.653965,24.492193",
                        level: 3
                    },
                    {
                        id: "451202",
                        name: "金城江区",
                        center: "108.062131,24.695625",
                        level: 3
                    },
                    {
                        id: "451223",
                        name: "凤山县",
                        center: "107.044592,24.544561",
                        level: 3
                    },
                    {
                        id: "451228",
                        name: "都安瑶族自治县",
                        center: "108.102761,23.934964",
                        level: 3
                    },
                    {
                        id: "451227",
                        name: "巴马瑶族自治县",
                        center: "107.253126,24.139538",
                        level: 3
                    },
                    {
                        id: "451229",
                        name: "大化瑶族自治县",
                        center: "107.9945,23.739596",
                        level: 3
                    },
                    {
                        id: "451224",
                        name: "东兰县",
                        center: "107.373696,24.509367",
                        level: 3
                    }
                ]
            },
            {
                id: "450100",
                name: "南宁市",
                center: "108.320004,22.82402",
                level: 2,
                children: [
                    {
                        id: "450124",
                        name: "马山县",
                        center: "108.172903,23.711758",
                        level: 3
                    },
                    {
                        id: "450125",
                        name: "上林县",
                        center: "108.603937,23.431769",
                        level: 3
                    },
                    {
                        id: "450110",
                        name: "武鸣区",
                        center: "108.280717,23.157163",
                        level: 3
                    },
                    {
                        id: "450127",
                        name: "横县",
                        center: "109.270987,22.68743",
                        level: 3
                    },
                    {
                        id: "450126",
                        name: "宾阳县",
                        center: "108.816735,23.216884",
                        level: 3
                    },
                    {
                        id: "450109",
                        name: "邕宁区",
                        center: "108.484251,22.756598",
                        level: 3
                    },
                    {
                        id: "450105",
                        name: "江南区",
                        center: "108.310478,22.799593",
                        level: 3
                    },
                    {
                        id: "450108",
                        name: "良庆区",
                        center: "108.322102,22.75909",
                        level: 3
                    },
                    {
                        id: "450107",
                        name: "西乡塘区",
                        center: "108.306903,22.832779",
                        level: 3
                    },
                    {
                        id: "450103",
                        name: "青秀区",
                        center: "108.346113,22.816614",
                        level: 3
                    },
                    {
                        id: "450123",
                        name: "隆安县",
                        center: "107.688661,23.174763",
                        level: 3
                    },
                    {
                        id: "450102",
                        name: "兴宁区",
                        center: "108.320189,22.819511",
                        level: 3
                    }
                ]
            },
            {
                id: "451300",
                name: "来宾市",
                center: "109.229772,23.733766",
                level: 2,
                children: [
                    {
                        id: "451324",
                        name: "金秀瑶族自治县",
                        center: "110.188556,24.134941",
                        level: 3
                    },
                    {
                        id: "451322",
                        name: "象州县",
                        center: "109.684555,23.959824",
                        level: 3
                    },
                    {
                        id: "451321",
                        name: "忻城县",
                        center: "108.667361,24.064779",
                        level: 3
                    },
                    {
                        id: "451302",
                        name: "兴宾区",
                        center: "109.230541,23.732926",
                        level: 3
                    },
                    {
                        id: "451323",
                        name: "武宣县",
                        center: "109.66287,23.604162",
                        level: 3
                    },
                    {
                        id: "451381",
                        name: "合山市",
                        center: "108.88858,23.81311",
                        level: 3
                    }
                ]
            },
            {
                id: "450200",
                name: "柳州市",
                center: "109.411703,24.314617",
                level: 2,
                children: [
                    {
                        id: "450226",
                        name: "三江侗族自治县",
                        center: "109.614846,25.78553",
                        level: 3
                    },
                    {
                        id: "450225",
                        name: "融水苗族自治县",
                        center: "109.252744,25.068812",
                        level: 3
                    },
                    {
                        id: "450224",
                        name: "融安县",
                        center: "109.403621,25.214703",
                        level: 3
                    },
                    {
                        id: "450202",
                        name: "城中区",
                        center: "109.411749,24.312324",
                        level: 3
                    },
                    {
                        id: "450223",
                        name: "鹿寨县",
                        center: "109.740805,24.483405",
                        level: 3
                    },
                    {
                        id: "450205",
                        name: "柳北区",
                        center: "109.406577,24.359145",
                        level: 3
                    },
                    {
                        id: "450222",
                        name: "柳城县",
                        center: "109.245812,24.655121",
                        level: 3
                    },
                    {
                        id: "450203",
                        name: "鱼峰区",
                        center: "109.415364,24.303848",
                        level: 3
                    },
                    {
                        id: "450206",
                        name: "柳江区",
                        center: "109.334503,24.257512",
                        level: 3
                    },
                    {
                        id: "450204",
                        name: "柳南区",
                        center: "109.395936,24.287013",
                        level: 3
                    }
                ]
            },
            {
                id: "451400",
                name: "崇左市",
                center: "107.353926,22.404108",
                level: 2,
                children: [
                    {
                        id: "451402",
                        name: "江州区",
                        center: "107.354443,22.40469",
                        level: 3
                    },
                    {
                        id: "451424",
                        name: "大新县",
                        center: "107.200803,22.833369",
                        level: 3
                    },
                    {
                        id: "451425",
                        name: "天等县",
                        center: "107.142441,23.082484",
                        level: 3
                    },
                    {
                        id: "451421",
                        name: "扶绥县",
                        center: "107.911533,22.635821",
                        level: 3
                    },
                    {
                        id: "451481",
                        name: "凭祥市",
                        center: "106.759038,22.108882",
                        level: 3
                    },
                    {
                        id: "451423",
                        name: "龙州县",
                        center: "106.857502,22.343716",
                        level: 3
                    },
                    {
                        id: "451422",
                        name: "宁明县",
                        center: "107.067616,22.131353",
                        level: 3
                    }
                ]
            },
            {
                id: "450600",
                name: "防城港市",
                center: "108.345478,21.614631",
                level: 2,
                children: [
                    {
                        id: "450603",
                        name: "防城区",
                        center: "108.358426,21.764758",
                        level: 3
                    },
                    {
                        id: "450621",
                        name: "上思县",
                        center: "107.982139,22.151423",
                        level: 3
                    },
                    {
                        id: "450602",
                        name: "港口区",
                        center: "108.346281,21.614406",
                        level: 3
                    },
                    {
                        id: "450681",
                        name: "东兴市",
                        center: "107.97017,21.541172",
                        level: 3
                    }
                ]
            },
            {
                id: "451100",
                name: "贺州市",
                center: "111.552056,24.414141",
                level: 2,
                children: [
                    {
                        id: "451123",
                        name: "富川瑶族自治县",
                        center: "111.277228,24.81896",
                        level: 3
                    },
                    {
                        id: "451121",
                        name: "昭平县",
                        center: "110.810865,24.172958",
                        level: 3
                    },
                    {
                        id: "451103",
                        name: "平桂区",
                        center: "111.524014,24.417148",
                        level: 3
                    },
                    {
                        id: "451122",
                        name: "钟山县",
                        center: "111.303629,24.528566",
                        level: 3
                    },
                    {
                        id: "451102",
                        name: "八步区",
                        center: "111.551991,24.412446",
                        level: 3
                    }
                ]
            },
            {
                id: "450900",
                name: "玉林市",
                center: "110.154393,22.63136",
                level: 2,
                children: [
                    {
                        id: "450902",
                        name: "玉州区",
                        center: "110.154912,22.632132",
                        level: 3
                    },
                    {
                        id: "450924",
                        name: "兴业县",
                        center: "109.877768,22.74187",
                        level: 3
                    },
                    {
                        id: "450903",
                        name: "福绵区",
                        center: "110.054155,22.58163",
                        level: 3
                    },
                    {
                        id: "450922",
                        name: "陆川县",
                        center: "110.264842,22.321054",
                        level: 3
                    },
                    {
                        id: "450923",
                        name: "博白县",
                        center: "109.980004,22.271285",
                        level: 3
                    },
                    {
                        id: "450981",
                        name: "北流市",
                        center: "110.348052,22.701648",
                        level: 3
                    },
                    {
                        id: "450921",
                        name: "容县",
                        center: "110.552467,22.856435",
                        level: 3
                    }
                ]
            },
            {
                id: "450800",
                name: "贵港市",
                center: "109.602146,23.0936",
                level: 2,
                children: [
                    {
                        id: "450804",
                        name: "覃塘区",
                        center: "109.415697,23.132815",
                        level: 3
                    },
                    {
                        id: "450802",
                        name: "港北区",
                        center: "109.59481,23.107677",
                        level: 3
                    },
                    {
                        id: "450803",
                        name: "港南区",
                        center: "109.604665,23.067516",
                        level: 3
                    },
                    {
                        id: "450821",
                        name: "平南县",
                        center: "110.397485,23.544546",
                        level: 3
                    },
                    {
                        id: "450881",
                        name: "桂平市",
                        center: "110.074668,23.382473",
                        level: 3
                    }
                ]
            },
            {
                id: "450400",
                name: "梧州市",
                center: "111.297604,23.474803",
                level: 2,
                children: [
                    {
                        id: "450423",
                        name: "蒙山县",
                        center: "110.5226,24.199829",
                        level: 3
                    },
                    {
                        id: "450405",
                        name: "长洲区",
                        center: "111.275678,23.4777",
                        level: 3
                    },
                    {
                        id: "450406",
                        name: "龙圩区",
                        center: "111.246035,23.40996",
                        level: 3
                    },
                    {
                        id: "450481",
                        name: "岑溪市",
                        center: "110.998114,22.918406",
                        level: 3
                    },
                    {
                        id: "450403",
                        name: "万秀区",
                        center: "111.315817,23.471318",
                        level: 3
                    },
                    {
                        id: "450421",
                        name: "苍梧县",
                        center: "111.544008,23.845097",
                        level: 3
                    },
                    {
                        id: "450422",
                        name: "藤县",
                        center: "110.931826,23.373963",
                        level: 3
                    }
                ]
            },
            {
                id: "450300",
                name: "桂林市",
                center: "110.299121,25.274215",
                level: 2,
                children: [
                    {
                        id: "450326",
                        name: "永福县",
                        center: "109.989208,24.986692",
                        level: 3
                    },
                    {
                        id: "450312",
                        name: "临桂区",
                        center: "110.205487,25.246257",
                        level: 3
                    },
                    {
                        id: "450325",
                        name: "兴安县",
                        center: "110.670783,25.609554",
                        level: 3
                    },
                    {
                        id: "450321",
                        name: "阳朔县",
                        center: "110.494699,24.77534",
                        level: 3
                    },
                    {
                        id: "450332",
                        name: "恭城瑶族自治县",
                        center: "110.82952,24.833612",
                        level: 3
                    },
                    {
                        id: "450305",
                        name: "七星区",
                        center: "110.317577,25.254339",
                        level: 3
                    },
                    {
                        id: "450311",
                        name: "雁山区",
                        center: "110.305667,25.077646",
                        level: 3
                    },
                    {
                        id: "450330",
                        name: "平乐县",
                        center: "110.642821,24.632216",
                        level: 3
                    },
                    {
                        id: "450381",
                        name: "荔浦市",
                        center: "110.400149,24.497786",
                        level: 3
                    },
                    {
                        id: "450328",
                        name: "龙胜各族自治县",
                        center: "110.009423,25.796428",
                        level: 3
                    },
                    {
                        id: "450304",
                        name: "象山区",
                        center: "110.284882,25.261986",
                        level: 3
                    },
                    {
                        id: "450302",
                        name: "秀峰区",
                        center: "110.292445,25.278544",
                        level: 3
                    },
                    {
                        id: "450324",
                        name: "全州县",
                        center: "111.072989,25.929897",
                        level: 3
                    },
                    {
                        id: "450327",
                        name: "灌阳县",
                        center: "111.160248,25.489098",
                        level: 3
                    },
                    {
                        id: "450303",
                        name: "叠彩区",
                        center: "110.300783,25.301334",
                        level: 3
                    },
                    {
                        id: "450323",
                        name: "灵川县",
                        center: "110.325712,25.408541",
                        level: 3
                    },
                    {
                        id: "450329",
                        name: "资源县",
                        center: "110.642587,26.0342",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "460000",
        name: "海南省",
        center: "110.33119,20.031971",
        level: 1,
        children: [
            {
                id: "469025",
                name: "白沙黎族自治县",
                center: "109.452606,19.224584",
                level: 2,
                children: [
                    {
                        id: "469025100",
                        name: "牙叉镇",
                        center: "109.44542,19.21277",
                        level: 4
                    },
                    {
                        id: "469025101",
                        name: "七坊镇",
                        center: "109.24007,19.29558",
                        level: 4
                    },
                    {
                        id: "469025102",
                        name: "邦溪镇",
                        center: "109.10357,19.36937",
                        level: 4
                    },
                    {
                        id: "469025103",
                        name: "打安镇",
                        center: "109.37364,19.28388",
                        level: 4
                    },
                    {
                        id: "469025200",
                        name: "细水乡",
                        center: "109.56447,19.20975",
                        level: 4
                    },
                    {
                        id: "469025201",
                        name: "元门乡",
                        center: "109.48648,19.15819",
                        level: 4
                    },
                    {
                        id: "469025202",
                        name: "南开乡",
                        center: "109.41468,19.07781",
                        level: 4
                    },
                    {
                        id: "469025203",
                        name: "阜龙乡",
                        center: "109.46087,19.32249",
                        level: 4
                    },
                    {
                        id: "469025204",
                        name: "青松乡",
                        center: "109.27094,19.15519",
                        level: 4
                    },
                    {
                        id: "469025205",
                        name: "金波乡",
                        center: "109.17374,19.23836",
                        level: 4
                    },
                    {
                        id: "469025206",
                        name: "荣邦乡",
                        center: "109.059933,19.471195",
                        level: 4
                    },
                    {
                        id: "469025401",
                        name: "国营白沙农场",
                        center: "109.490021,19.236345",
                        level: 4
                    },
                    {
                        id: "469025404",
                        name: "国营龙江农场",
                        center: "109.2424,19.299394",
                        level: 4
                    },
                    {
                        id: "469025950",
                        name: "卫星农场",
                        center: "109.38988,19.334978",
                        level: 4
                    }
                ]
            },
            {
                id: "469030",
                name: "琼中黎族苗族自治县",
                center: "109.839996,19.03557",
                level: 2,
                children: [
                    {
                        id: "469030100",
                        name: "营根镇",
                        center: "109.83164,19.03615",
                        level: 4
                    },
                    {
                        id: "469030101",
                        name: "湾岭镇",
                        center: "109.90053,19.14116",
                        level: 4
                    },
                    {
                        id: "469030102",
                        name: "黎母山镇",
                        center: "109.78685,19.26769",
                        level: 4
                    },
                    {
                        id: "469030103",
                        name: "和平镇",
                        center: "110.02333,18.90047",
                        level: 4
                    },
                    {
                        id: "469030104",
                        name: "长征镇",
                        center: "109.87804,18.95863",
                        level: 4
                    },
                    {
                        id: "469030105",
                        name: "红毛镇",
                        center: "109.68992,19.03069",
                        level: 4
                    },
                    {
                        id: "469030106",
                        name: "中平镇",
                        center: "110.06023,19.06118",
                        level: 4
                    },
                    {
                        id: "469030200",
                        name: "吊罗山乡",
                        center: "109.87821,18.79263",
                        level: 4
                    },
                    {
                        id: "469030201",
                        name: "上安乡",
                        center: "109.83721,18.87676",
                        level: 4
                    },
                    {
                        id: "469030202",
                        name: "什运乡",
                        center: "109.60813,18.99211",
                        level: 4
                    },
                    {
                        id: "469030402",
                        name: "阳江农场",
                        center: "109.720008,19.345067",
                        level: 4
                    },
                    {
                        id: "469030403",
                        name: "乌石农场",
                        center: "109.887815,19.172991",
                        level: 4
                    },
                    {
                        id: "469030950",
                        name: "岭头茶场",
                        center: "109.93383,19.03486",
                        level: 4
                    },
                    {
                        id: "469030951",
                        name: "南方农场",
                        center: "110.053021,19.05462",
                        level: 4
                    }
                ]
            },
            {
                id: "469002",
                name: "琼海市",
                center: "110.466785,19.246011",
                level: 2,
                children: [
                    {
                        id: "469002100",
                        name: "嘉积镇",
                        center: "110.48578,19.24336",
                        level: 4
                    },
                    {
                        id: "469002101",
                        name: "万泉镇",
                        center: "110.40959,19.24302",
                        level: 4
                    },
                    {
                        id: "469002102",
                        name: "石壁镇",
                        center: "110.30861,19.16332",
                        level: 4
                    },
                    {
                        id: "469002103",
                        name: "中原镇",
                        center: "110.46849,19.15175",
                        level: 4
                    },
                    {
                        id: "469002104",
                        name: "博鳌镇",
                        center: "110.58645,19.15976",
                        level: 4
                    },
                    {
                        id: "469002105",
                        name: "阳江镇",
                        center: "110.34927,19.09947",
                        level: 4
                    },
                    {
                        id: "469002106",
                        name: "龙江镇",
                        center: "110.32498,19.1468",
                        level: 4
                    },
                    {
                        id: "469002107",
                        name: "潭门镇",
                        center: "110.61689,19.24179",
                        level: 4
                    },
                    {
                        id: "469002108",
                        name: "塔洋镇",
                        center: "110.50843,19.28819",
                        level: 4
                    },
                    {
                        id: "469002109",
                        name: "长坡镇",
                        center: "110.60686,19.33567",
                        level: 4
                    },
                    {
                        id: "469002110",
                        name: "大路镇",
                        center: "110.47655,19.38066",
                        level: 4
                    },
                    {
                        id: "469002111",
                        name: "会山镇",
                        center: "110.170954,19.052832",
                        level: 4
                    },
                    {
                        id: "469002400",
                        name: "东太农场",
                        center: "110.219783,19.122067",
                        level: 4
                    },
                    {
                        id: "469002401",
                        name: "南俸农场",
                        center: "110.22287,19.171729",
                        level: 4
                    },
                    {
                        id: "469002402",
                        name: "东红农场",
                        center: "110.43931,19.42771",
                        level: 4
                    },
                    {
                        id: "469002500",
                        name: "彬村山华侨农场",
                        center: "110.571208,19.309458",
                        level: 4
                    },
                    {
                        id: "469002953",
                        name: "东平农场",
                        center: "110.301541,19.054251",
                        level: 4
                    }
                ]
            },
            {
                id: "469026",
                name: "昌江黎族自治县",
                center: "109.053351,19.260968",
                level: 2,
                children: [
                    {
                        id: "469026100",
                        name: "石碌镇",
                        center: "109.05581,19.27762",
                        level: 4
                    },
                    {
                        id: "469026101",
                        name: "叉河镇",
                        center: "108.95721,19.23894",
                        level: 4
                    },
                    {
                        id: "469026102",
                        name: "十月田镇",
                        center: "108.95217,19.32822",
                        level: 4
                    },
                    {
                        id: "469026103",
                        name: "乌烈镇",
                        center: "108.79207,19.28857",
                        level: 4
                    },
                    {
                        id: "469026104",
                        name: "昌化镇",
                        center: "108.68499,19.32916",
                        level: 4
                    },
                    {
                        id: "469026105",
                        name: "海尾镇",
                        center: "108.8183,19.42199",
                        level: 4
                    },
                    {
                        id: "469026106",
                        name: "七叉镇",
                        center: "109.05096,19.11495",
                        level: 4
                    },
                    {
                        id: "469026200",
                        name: "王下乡",
                        center: "109.14722,19.0074",
                        level: 4
                    },
                    {
                        id: "469026401",
                        name: "国营红林农场",
                        center: "108.968327,19.294378",
                        level: 4
                    },
                    {
                        id: "469026500",
                        name: "国营霸王岭林场",
                        center: "109.089019,19.113568",
                        level: 4
                    }
                ]
            },
            {
                id: "469022",
                name: "屯昌县",
                center: "110.102773,19.362916",
                level: 2,
                children: [
                    {
                        id: "469022100",
                        name: "屯城镇",
                        center: "110.10511,19.37163",
                        level: 4
                    },
                    {
                        id: "469022101",
                        name: "新兴镇",
                        center: "110.18284,19.50677",
                        level: 4
                    },
                    {
                        id: "469022102",
                        name: "枫木镇",
                        center: "110.01963,19.21465",
                        level: 4
                    },
                    {
                        id: "469022103",
                        name: "乌坡镇",
                        center: "110.07592,19.18012",
                        level: 4
                    },
                    {
                        id: "469022104",
                        name: "南吕镇",
                        center: "110.08253,19.2507",
                        level: 4
                    },
                    {
                        id: "469022105",
                        name: "南坤镇",
                        center: "109.94825,19.31842",
                        level: 4
                    },
                    {
                        id: "469022106",
                        name: "坡心镇",
                        center: "110.08987,19.30706",
                        level: 4
                    },
                    {
                        id: "469022107",
                        name: "西昌镇",
                        center: "110.04566,19.43366",
                        level: 4
                    },
                    {
                        id: "469022400",
                        name: "国营中瑞农场",
                        center: "110.012324,19.263306",
                        level: 4
                    },
                    {
                        id: "469022401",
                        name: "国营中坤农场",
                        center: "109.954097,19.317126",
                        level: 4
                    },
                    {
                        id: "469022950",
                        name: "国营中建农场",
                        center: "110.17367,19.271411",
                        level: 4
                    },
                    {
                        id: "469022951",
                        name: "晨星农场",
                        center: "109.972434,19.42887",
                        level: 4
                    },
                    {
                        id: "469022952",
                        name: "黄岭农场",
                        center: "109.95874,19.373591",
                        level: 4
                    },
                    {
                        id: "469022954",
                        name: "广青农场",
                        center: "110.159078,19.456372",
                        level: 4
                    }
                ]
            },
            {
                id: "469007",
                name: "东方市",
                center: "108.653789,19.10198",
                level: 2,
                children: [
                    {
                        id: "469007100",
                        name: "八所镇",
                        center: "108.67024,19.0914",
                        level: 4
                    },
                    {
                        id: "469007101",
                        name: "东河镇",
                        center: "108.93808,19.04805",
                        level: 4
                    },
                    {
                        id: "469007102",
                        name: "大田镇",
                        center: "108.87488,19.16508",
                        level: 4
                    },
                    {
                        id: "469007103",
                        name: "感城镇",
                        center: "108.64816,18.84989",
                        level: 4
                    },
                    {
                        id: "469007104",
                        name: "板桥镇",
                        center: "108.68866,18.79435",
                        level: 4
                    },
                    {
                        id: "469007105",
                        name: "三家镇",
                        center: "108.75851,19.24128",
                        level: 4
                    },
                    {
                        id: "469007106",
                        name: "四更镇",
                        center: "108.6821,19.2256",
                        level: 4
                    },
                    {
                        id: "469007107",
                        name: "新龙镇",
                        center: "108.68452,18.95197",
                        level: 4
                    },
                    {
                        id: "469007200",
                        name: "天安乡",
                        center: "108.91277,19.01747",
                        level: 4
                    },
                    {
                        id: "469007201",
                        name: "江边乡",
                        center: "108.97675,18.89442",
                        level: 4
                    },
                    {
                        id: "469007400",
                        name: "国营广坝农场",
                        center: "108.95365,18.989002",
                        level: 4
                    },
                    {
                        id: "469007500",
                        name: "东方华侨农场",
                        center: "108.690697,18.982529",
                        level: 4
                    },
                    {
                        id: "469007950",
                        name: "东方农场",
                        center: "108.929577,19.065154",
                        level: 4
                    }
                ]
            },
            {
                id: "469021",
                name: "定安县",
                center: "110.349235,19.684966",
                level: 2,
                children: [
                    {
                        id: "469021100",
                        name: "定城镇",
                        center: "110.368,19.67981",
                        level: 4
                    },
                    {
                        id: "469021101",
                        name: "新竹镇",
                        center: "110.20878,19.61807",
                        level: 4
                    },
                    {
                        id: "469021102",
                        name: "龙湖镇",
                        center: "110.40036,19.57325",
                        level: 4
                    },
                    {
                        id: "469021103",
                        name: "黄竹镇",
                        center: "110.406761,19.428387",
                        level: 4
                    },
                    {
                        id: "469021104",
                        name: "雷鸣镇",
                        center: "110.32627,19.55358",
                        level: 4
                    },
                    {
                        id: "469021105",
                        name: "龙门镇",
                        center: "110.32793,19.43934",
                        level: 4
                    },
                    {
                        id: "469021106",
                        name: "龙河镇",
                        center: "110.21897,19.3818",
                        level: 4
                    },
                    {
                        id: "469021107",
                        name: "岭口镇",
                        center: "110.30815,19.34277",
                        level: 4
                    },
                    {
                        id: "469021108",
                        name: "翰林镇",
                        center: "110.26477,19.33374",
                        level: 4
                    },
                    {
                        id: "469021109",
                        name: "富文镇",
                        center: "110.26285,19.55117",
                        level: 4
                    },
                    {
                        id: "469021400",
                        name: "国营中瑞农场",
                        center: "110.307332,19.250472",
                        level: 4
                    },
                    {
                        id: "469021401",
                        name: "国营南海农场",
                        center: "110.446064,19.472582",
                        level: 4
                    },
                    {
                        id: "469021402",
                        name: "国营金鸡岭农场",
                        center: "110.260688,19.593328",
                        level: 4
                    },
                    {
                        id: "469021403",
                        name: "国营东升农场",
                        center: "110.361406,19.293284",
                        level: 4
                    }
                ]
            },
            {
                id: "469006",
                name: "万宁市",
                center: "110.388793,18.796216",
                level: 2,
                children: [
                    {
                        id: "469006100",
                        name: "万城镇",
                        center: "110.3969,18.79454",
                        level: 4
                    },
                    {
                        id: "469006101",
                        name: "龙滚镇",
                        center: "110.51921,19.05857",
                        level: 4
                    },
                    {
                        id: "469006102",
                        name: "和乐镇",
                        center: "110.4776,18.90372",
                        level: 4
                    },
                    {
                        id: "469006103",
                        name: "后安镇",
                        center: "110.4437,18.86802",
                        level: 4
                    },
                    {
                        id: "469006104",
                        name: "大茂镇",
                        center: "110.39644,18.84897",
                        level: 4
                    },
                    {
                        id: "469006105",
                        name: "东澳镇",
                        center: "110.40166,18.71506",
                        level: 4
                    },
                    {
                        id: "469006106",
                        name: "礼纪镇",
                        center: "110.31756,18.75057",
                        level: 4
                    },
                    {
                        id: "469006107",
                        name: "长丰镇",
                        center: "110.32765,18.80059",
                        level: 4
                    },
                    {
                        id: "469006108",
                        name: "山根镇",
                        center: "110.48651,18.96741",
                        level: 4
                    },
                    {
                        id: "469006109",
                        name: "北大镇",
                        center: "110.33561,18.91799",
                        level: 4
                    },
                    {
                        id: "469006110",
                        name: "南桥镇",
                        center: "110.15279,18.68026",
                        level: 4
                    },
                    {
                        id: "469006111",
                        name: "三更罗镇",
                        center: "110.18663,18.8612",
                        level: 4
                    },
                    {
                        id: "469006400",
                        name: "国营东兴农场",
                        center: "110.376044,18.898238",
                        level: 4
                    },
                    {
                        id: "469006500",
                        name: "兴隆华侨农场",
                        center: "110.159782,18.746171",
                        level: 4
                    },
                    {
                        id: "469006501",
                        name: "地方国营六连林场",
                        center: "110.500487,18.99198",
                        level: 4
                    },
                    {
                        id: "469006951",
                        name: "东岭农场",
                        center: "110.424874,19.024596",
                        level: 4
                    }
                ]
            },
            {
                id: "469024",
                name: "临高县",
                center: "109.687697,19.908293",
                level: 2,
                children: [
                    {
                        id: "469024100",
                        name: "临城镇",
                        center: "109.69654,19.89648",
                        level: 4
                    },
                    {
                        id: "469024101",
                        name: "波莲镇",
                        center: "109.64847,19.86821",
                        level: 4
                    },
                    {
                        id: "469024102",
                        name: "东英镇",
                        center: "109.64867,19.95903",
                        level: 4
                    },
                    {
                        id: "469024103",
                        name: "博厚镇",
                        center: "109.74541,19.88376",
                        level: 4
                    },
                    {
                        id: "469024104",
                        name: "皇桐镇",
                        center: "109.84958,19.83289",
                        level: 4
                    },
                    {
                        id: "469024105",
                        name: "多文镇",
                        center: "109.77098,19.77375",
                        level: 4
                    },
                    {
                        id: "469024106",
                        name: "和舍镇",
                        center: "109.72758,19.5963",
                        level: 4
                    },
                    {
                        id: "469024107",
                        name: "南宝镇",
                        center: "109.59947,19.68123",
                        level: 4
                    },
                    {
                        id: "469024108",
                        name: "新盈镇",
                        center: "109.53644,19.89509",
                        level: 4
                    },
                    {
                        id: "469024109",
                        name: "调楼镇",
                        center: "109.54648,19.92865",
                        level: 4
                    },
                    {
                        id: "469024400",
                        name: "国营红华农场",
                        center: "109.774558,19.774451",
                        level: 4
                    },
                    {
                        id: "469024401",
                        name: "国营加来农场",
                        center: "109.698099,19.708159",
                        level: 4
                    }
                ]
            },
            {
                id: "469001",
                name: "五指山市",
                center: "109.516662,18.776921",
                level: 2,
                children: [
                    {
                        id: "469001100",
                        name: "通什镇",
                        center: "109.51721,18.78066",
                        level: 4
                    },
                    {
                        id: "469001101",
                        name: "南圣镇",
                        center: "109.59273,18.73846",
                        level: 4
                    },
                    {
                        id: "469001102",
                        name: "毛阳镇",
                        center: "109.50803,18.937",
                        level: 4
                    },
                    {
                        id: "469001103",
                        name: "番阳镇",
                        center: "109.39744,18.87509",
                        level: 4
                    },
                    {
                        id: "469001200",
                        name: "畅好乡",
                        center: "109.48221,18.73495",
                        level: 4
                    },
                    {
                        id: "469001201",
                        name: "毛道乡",
                        center: "109.41073,18.79125",
                        level: 4
                    },
                    {
                        id: "469001202",
                        name: "水满乡",
                        center: "109.66705,18.88106",
                        level: 4
                    },
                    {
                        id: "469001400",
                        name: "国营畅好农场",
                        center: "109.555074,18.748562",
                        level: 4
                    }
                ]
            },
            {
                id: "469027",
                name: "乐东黎族自治县",
                center: "109.175444,18.74758",
                level: 2,
                children: [
                    {
                        id: "469027100",
                        name: "抱由镇",
                        center: "109.17947,18.74479",
                        level: 4
                    },
                    {
                        id: "469027101",
                        name: "万冲镇",
                        center: "109.32317,18.84318",
                        level: 4
                    },
                    {
                        id: "469027102",
                        name: "大安镇",
                        center: "109.21482,18.69119",
                        level: 4
                    },
                    {
                        id: "469027103",
                        name: "志仲镇",
                        center: "109.26514,18.62921",
                        level: 4
                    },
                    {
                        id: "469027104",
                        name: "千家镇",
                        center: "109.08615,18.56554",
                        level: 4
                    },
                    {
                        id: "469027105",
                        name: "九所镇",
                        center: "108.95392,18.4535",
                        level: 4
                    },
                    {
                        id: "469027106",
                        name: "利国镇",
                        center: "108.89679,18.47226",
                        level: 4
                    },
                    {
                        id: "469027107",
                        name: "黄流镇",
                        center: "108.7946,18.50662",
                        level: 4
                    },
                    {
                        id: "469027108",
                        name: "佛罗镇",
                        center: "108.73634,18.57854",
                        level: 4
                    },
                    {
                        id: "469027109",
                        name: "尖峰镇",
                        center: "108.79241,18.69046",
                        level: 4
                    },
                    {
                        id: "469027110",
                        name: "莺歌海镇",
                        center: "108.69734,18.51007",
                        level: 4
                    },
                    {
                        id: "469027401",
                        name: "国营山荣农场",
                        center: "109.16416,18.76951",
                        level: 4
                    },
                    {
                        id: "469027402",
                        name: "国营乐光农场",
                        center: "109.013617,18.544317",
                        level: 4
                    },
                    {
                        id: "469027405",
                        name: "国营保国农场",
                        center: "109.287733,18.567328",
                        level: 4
                    },
                    {
                        id: "469027951",
                        name: "福报农场",
                        center: "109.130614,18.477572",
                        level: 4
                    }
                ]
            },
            {
                id: "469029",
                name: "保亭黎族苗族自治县",
                center: "109.70245,18.636371",
                level: 2,
                children: [
                    {
                        id: "469029100",
                        name: "保城镇",
                        center: "109.70348,18.64141",
                        level: 4
                    },
                    {
                        id: "469029101",
                        name: "什玲镇",
                        center: "109.78174,18.66392",
                        level: 4
                    },
                    {
                        id: "469029102",
                        name: "加茂镇",
                        center: "109.70506,18.55456",
                        level: 4
                    },
                    {
                        id: "469029103",
                        name: "响水镇",
                        center: "109.61627,18.59191",
                        level: 4
                    },
                    {
                        id: "469029104",
                        name: "新政镇",
                        center: "109.63065,18.54187",
                        level: 4
                    },
                    {
                        id: "469029105",
                        name: "三道镇",
                        center: "109.66868,18.46461",
                        level: 4
                    },
                    {
                        id: "469029200",
                        name: "六弓乡",
                        center: "109.78506,18.53984",
                        level: 4
                    },
                    {
                        id: "469029201",
                        name: "南林乡",
                        center: "109.61681,18.40529",
                        level: 4
                    },
                    {
                        id: "469029202",
                        name: "毛感乡",
                        center: "109.50901,18.61303",
                        level: 4
                    },
                    {
                        id: "469029401",
                        name: "新星农场",
                        center: "109.771548,18.641259",
                        level: 4
                    },
                    {
                        id: "469029402",
                        name: "海南保亭热带作物研究所",
                        center: "109.72015,18.61563",
                        level: 4
                    },
                    {
                        id: "469029403",
                        name: "国营金江农场",
                        center: "109.621704,18.585854",
                        level: 4
                    },
                    {
                        id: "469029950",
                        name: "南茂农场",
                        center: "109.757692,18.56942",
                        level: 4
                    },
                    {
                        id: "469029952",
                        name: "通什茶场",
                        center: "109.587411,18.691186",
                        level: 4
                    }
                ]
            },
            {
                id: "469005",
                name: "文昌市",
                center: "110.753975,19.612986",
                level: 2,
                children: [
                    {
                        id: "469005100",
                        name: "文城镇",
                        center: "110.75389,19.61387",
                        level: 4
                    },
                    {
                        id: "469005101",
                        name: "重兴镇",
                        center: "110.5968,19.41165",
                        level: 4
                    },
                    {
                        id: "469005102",
                        name: "蓬莱镇",
                        center: "110.54105,19.53674",
                        level: 4
                    },
                    {
                        id: "469005103",
                        name: "会文镇",
                        center: "110.73176,19.46261",
                        level: 4
                    },
                    {
                        id: "469005104",
                        name: "东路镇",
                        center: "110.70635,19.79217",
                        level: 4
                    },
                    {
                        id: "469005105",
                        name: "潭牛镇",
                        center: "110.73593,19.71024",
                        level: 4
                    },
                    {
                        id: "469005106",
                        name: "东阁镇",
                        center: "110.84921,19.65693",
                        level: 4
                    },
                    {
                        id: "469005107",
                        name: "文教镇",
                        center: "110.90782,19.66768",
                        level: 4
                    },
                    {
                        id: "469005108",
                        name: "东郊镇",
                        center: "110.85159,19.57391",
                        level: 4
                    },
                    {
                        id: "469005109",
                        name: "龙楼镇",
                        center: "110.96756,19.65325",
                        level: 4
                    },
                    {
                        id: "469005110",
                        name: "昌洒镇",
                        center: "110.90066,19.76172",
                        level: 4
                    },
                    {
                        id: "469005111",
                        name: "翁田镇",
                        center: "110.88015,19.93147",
                        level: 4
                    },
                    {
                        id: "469005112",
                        name: "抱罗镇",
                        center: "110.7489,19.84347",
                        level: 4
                    },
                    {
                        id: "469005113",
                        name: "冯坡镇",
                        center: "110.78398,19.96466",
                        level: 4
                    },
                    {
                        id: "469005114",
                        name: "锦山镇",
                        center: "110.69748,19.99583",
                        level: 4
                    },
                    {
                        id: "469005115",
                        name: "铺前镇",
                        center: "110.58007,20.02372",
                        level: 4
                    },
                    {
                        id: "469005116",
                        name: "公坡镇",
                        center: "110.80854,19.78922",
                        level: 4
                    },
                    {
                        id: "469005401",
                        name: "国营南阳农场",
                        center: "110.65808,19.529876",
                        level: 4
                    },
                    {
                        id: "469005402",
                        name: "国营罗豆农场",
                        center: "110.641659,19.972489",
                        level: 4
                    }
                ]
            },
            {
                id: "469028",
                name: "陵水黎族自治县",
                center: "110.037218,18.505006",
                level: 2,
                children: [
                    {
                        id: "469028100",
                        name: "椰林镇",
                        center: "110.04525,18.52232",
                        level: 4
                    },
                    {
                        id: "469028101",
                        name: "光坡镇",
                        center: "110.04879,18.54322",
                        level: 4
                    },
                    {
                        id: "469028102",
                        name: "三才镇",
                        center: "110.00329,18.47464",
                        level: 4
                    },
                    {
                        id: "469028103",
                        name: "英州镇",
                        center: "109.85906,18.4266",
                        level: 4
                    },
                    {
                        id: "469028104",
                        name: "隆广镇",
                        center: "109.89942,18.50198",
                        level: 4
                    },
                    {
                        id: "469028105",
                        name: "文罗镇",
                        center: "109.95723,18.51571",
                        level: 4
                    },
                    {
                        id: "469028106",
                        name: "本号镇",
                        center: "109.96248,18.6089",
                        level: 4
                    },
                    {
                        id: "469028107",
                        name: "新村镇",
                        center: "109.97141,18.41248",
                        level: 4
                    },
                    {
                        id: "469028108",
                        name: "黎安镇",
                        center: "110.06915,18.4371",
                        level: 4
                    },
                    {
                        id: "469028200",
                        name: "提蒙乡",
                        center: "110.01303,18.5649",
                        level: 4
                    },
                    {
                        id: "469028201",
                        name: "群英乡",
                        center: "109.87882,18.58299",
                        level: 4
                    },
                    {
                        id: "469028400",
                        name: "岭门农场",
                        center: "110.05176,18.5903",
                        level: 4
                    },
                    {
                        id: "469028401",
                        name: "国营南平农场",
                        center: "109.911857,18.583833",
                        level: 4
                    }
                ]
            },
            {
                id: "460400",
                name: "儋州市",
                center: "109.576782,19.517486",
                level: 2,
                children: [
                    {
                        id: "460400100",
                        name: "那大镇",
                        center: "109.5467,19.51503",
                        level: 4
                    },
                    {
                        id: "460400101",
                        name: "和庆镇",
                        center: "109.64081,19.524909",
                        level: 4
                    },
                    {
                        id: "460400102",
                        name: "南丰镇",
                        center: "109.55144,19.41804",
                        level: 4
                    },
                    {
                        id: "460400103",
                        name: "大成镇",
                        center: "109.39955,19.50808",
                        level: 4
                    },
                    {
                        id: "460400104",
                        name: "雅星镇",
                        center: "109.26912,19.44407",
                        level: 4
                    },
                    {
                        id: "460400105",
                        name: "兰洋镇",
                        center: "109.66446,19.46037",
                        level: 4
                    },
                    {
                        id: "460400106",
                        name: "光村镇",
                        center: "109.48132,19.8181",
                        level: 4
                    },
                    {
                        id: "460400107",
                        name: "木棠镇",
                        center: "109.35023,19.80393",
                        level: 4
                    },
                    {
                        id: "460400108",
                        name: "海头镇",
                        center: "108.95322,19.50339",
                        level: 4
                    },
                    {
                        id: "460400109",
                        name: "峨蔓镇",
                        center: "109.26675,19.85469",
                        level: 4
                    },
                    {
                        id: "460400110",
                        name: "三都镇",
                        center: "109.21924,19.78692",
                        level: 4
                    },
                    {
                        id: "460400111",
                        name: "王五镇",
                        center: "109.29572,19.65371",
                        level: 4
                    },
                    {
                        id: "460400112",
                        name: "白马井镇",
                        center: "109.21904,19.69659",
                        level: 4
                    },
                    {
                        id: "460400113",
                        name: "中和镇",
                        center: "109.34958,19.74346",
                        level: 4
                    },
                    {
                        id: "460400114",
                        name: "排浦镇",
                        center: "109.16325,19.63879",
                        level: 4
                    },
                    {
                        id: "460400115",
                        name: "东成镇",
                        center: "109.46154,19.70378",
                        level: 4
                    },
                    {
                        id: "460400116",
                        name: "新州镇",
                        center: "109.31626,19.71428",
                        level: 4
                    },
                    {
                        id: "460400400",
                        name: "国营西培农场",
                        center: "109.393058,19.555481",
                        level: 4
                    },
                    {
                        id: "460400404",
                        name: "国营西联农场",
                        center: "109.568046,19.652597",
                        level: 4
                    },
                    {
                        id: "460400405",
                        name: "国营蓝洋农场",
                        center: "109.618437,19.488747",
                        level: 4
                    },
                    {
                        id: "460400407",
                        name: "国营八一农场",
                        center: "109.185796,19.520782",
                        level: 4
                    },
                    {
                        id: "460400499",
                        name: "洋浦经济开发区",
                        center: "109.194626,19.748865",
                        level: 4
                    },
                    {
                        id: "460400500",
                        name: "华南热作学院",
                        center: "109.496151,19.524539",
                        level: 4
                    },
                    {
                        id: "460400950",
                        name: "红岭农场",
                        center: "109.1318,19.496555",
                        level: 4
                    }
                ]
            },
            {
                id: "460200",
                name: "三亚市",
                center: "109.508268,18.247872",
                level: 2,
                children: [
                    {
                        id: "460202",
                        name: "海棠区",
                        center: "109.760778,18.407516",
                        level: 3
                    },
                    {
                        id: "460204",
                        name: "天涯区",
                        center: "109.506357,18.24734",
                        level: 3
                    },
                    {
                        id: "460205",
                        name: "崖州区",
                        center: "109.174306,18.352192",
                        level: 3
                    },
                    {
                        id: "460203",
                        name: "吉阳区",
                        center: "109.512081,18.247436",
                        level: 3
                    }
                ]
            },
            {
                id: "460300",
                name: "三沙市",
                center: "112.34882,16.831039",
                level: 2,
                children: [
                    {
                        id: "460322",
                        name: "南沙群岛",
                        center: "116.749998,11.471888",
                        level: 3
                    },
                    {
                        id: "460321",
                        name: "西沙群岛",
                        center: "111.792944,16.204546",
                        level: 3
                    },
                    {
                        id: "460323",
                        name: "中沙群岛的岛礁及其海域",
                        center: "117.740071,15.112856",
                        level: 3
                    }
                ]
            },
            {
                id: "469023",
                name: "澄迈县",
                center: "110.007147,19.737095",
                level: 2,
                children: [
                    {
                        id: "469023100",
                        name: "金江镇",
                        center: "110.0102,19.73677",
                        level: 4
                    },
                    {
                        id: "469023101",
                        name: "老城镇",
                        center: "110.12662,19.95296",
                        level: 4
                    },
                    {
                        id: "469023102",
                        name: "瑞溪镇",
                        center: "110.12908,19.73183",
                        level: 4
                    },
                    {
                        id: "469023103",
                        name: "永发镇",
                        center: "110.19536,19.74686",
                        level: 4
                    },
                    {
                        id: "469023104",
                        name: "加乐镇",
                        center: "110.00112,19.58511",
                        level: 4
                    },
                    {
                        id: "469023105",
                        name: "文儒镇",
                        center: "110.05338,19.53698",
                        level: 4
                    },
                    {
                        id: "469023106",
                        name: "中兴镇",
                        center: "109.8591,19.56694",
                        level: 4
                    },
                    {
                        id: "469023107",
                        name: "仁兴镇",
                        center: "109.895389,19.43166",
                        level: 4
                    },
                    {
                        id: "469023108",
                        name: "福山镇",
                        center: "109.92986,19.83004",
                        level: 4
                    },
                    {
                        id: "469023109",
                        name: "桥头镇",
                        center: "109.93368,19.95424",
                        level: 4
                    },
                    {
                        id: "469023110",
                        name: "大丰镇",
                        center: "110.03805,19.85472",
                        level: 4
                    },
                    {
                        id: "469023400",
                        name: "国营红光农场",
                        center: "109.920632,19.837845",
                        level: 4
                    },
                    {
                        id: "469023401",
                        name: "红岗农场",
                        center: "110.094323,19.515644",
                        level: 4
                    },
                    {
                        id: "469023402",
                        name: "国营西达农场",
                        center: "109.88263,19.485191",
                        level: 4
                    },
                    {
                        id: "469023405",
                        name: "国营金安农场",
                        center: "110.122973,19.754587",
                        level: 4
                    }
                ]
            },
            {
                id: "460100",
                name: "海口市",
                center: "110.33119,20.031971",
                level: 2,
                children: [
                    {
                        id: "460108",
                        name: "美兰区",
                        center: "110.356566,20.03074",
                        level: 3
                    },
                    {
                        id: "460107",
                        name: "琼山区",
                        center: "110.354722,20.001051",
                        level: 3
                    },
                    {
                        id: "460106",
                        name: "龙华区",
                        center: "110.330373,20.031026",
                        level: 3
                    },
                    {
                        id: "460105",
                        name: "秀英区",
                        center: "110.282393,20.008145",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "500000",
        name: "重庆市",
        center: "106.504962,29.533155",
        level: 1,
        children: [
            {
                id: "500200",
                name: "重庆郊县",
                center: "108.170255,29.291965",
                level: 2,
                children: [
                    {
                        id: "500236",
                        name: "奉节县",
                        center: "109.465774,31.019967",
                        level: 3
                    },
                    {
                        id: "500238",
                        name: "巫溪县",
                        center: "109.628912,31.3966",
                        level: 3
                    },
                    {
                        id: "500229",
                        name: "城口县",
                        center: "108.6649,31.946293",
                        level: 3
                    },
                    {
                        id: "500230",
                        name: "丰都县",
                        center: "107.73248,29.866424",
                        level: 3
                    },
                    {
                        id: "500241",
                        name: "秀山土家族苗族自治县",
                        center: "108.996043,28.444772",
                        level: 3
                    },
                    {
                        id: "500243",
                        name: "彭水苗族土家族自治县",
                        center: "108.166551,29.293856",
                        level: 3
                    },
                    {
                        id: "500231",
                        name: "垫江县",
                        center: "107.348692,30.330012",
                        level: 3
                    },
                    {
                        id: "500235",
                        name: "云阳县",
                        center: "108.697698,30.930529",
                        level: 3
                    },
                    {
                        id: "500237",
                        name: "巫山县",
                        center: "109.878928,31.074843",
                        level: 3
                    },
                    {
                        id: "500242",
                        name: "酉阳土家族苗族自治县",
                        center: "108.767201,28.839828",
                        level: 3
                    },
                    {
                        id: "500240",
                        name: "石柱土家族自治县",
                        center: "108.112448,29.99853",
                        level: 3
                    },
                    {
                        id: "500233",
                        name: "忠县",
                        center: "108.037518,30.291537",
                        level: 3
                    }
                ]
            },
            {
                id: "500100",
                name: "重庆城区",
                center: "106.504962,29.533155",
                level: 2,
                children: [
                    {
                        id: "500151",
                        name: "铜梁区",
                        center: "106.054948,29.839944",
                        level: 3
                    },
                    {
                        id: "500153",
                        name: "荣昌区",
                        center: "105.594061,29.403627",
                        level: 3
                    },
                    {
                        id: "500107",
                        name: "九龙坡区",
                        center: "106.480989,29.523492",
                        level: 3
                    },
                    {
                        id: "500156",
                        name: "武隆区",
                        center: "107.75655,29.32376",
                        level: 3
                    },
                    {
                        id: "500115",
                        name: "长寿区",
                        center: "107.074854,29.833671",
                        level: 3
                    },
                    {
                        id: "500111",
                        name: "大足区",
                        center: "105.715319,29.700498",
                        level: 3
                    },
                    {
                        id: "500117",
                        name: "合川区",
                        center: "106.265554,29.990993",
                        level: 3
                    },
                    {
                        id: "500119",
                        name: "南川区",
                        center: "107.098153,29.156646",
                        level: 3
                    },
                    {
                        id: "500103",
                        name: "渝中区",
                        center: "106.56288,29.556742",
                        level: 3
                    },
                    {
                        id: "500152",
                        name: "潼南区",
                        center: "105.841818,30.189554",
                        level: 3
                    },
                    {
                        id: "500120",
                        name: "璧山区",
                        center: "106.231126,29.593581",
                        level: 3
                    },
                    {
                        id: "500118",
                        name: "永川区",
                        center: "105.894714,29.348748",
                        level: 3
                    },
                    {
                        id: "500104",
                        name: "大渡口区",
                        center: "106.48613,29.481002",
                        level: 3
                    },
                    {
                        id: "500155",
                        name: "梁平区",
                        center: "107.800034,30.672168",
                        level: 3
                    },
                    {
                        id: "500102",
                        name: "涪陵区",
                        center: "107.394905,29.703652",
                        level: 3
                    },
                    {
                        id: "500110",
                        name: "綦江区",
                        center: "106.651417,29.028091",
                        level: 3
                    },
                    {
                        id: "500154",
                        name: "开州区",
                        center: "108.413317,31.167735",
                        level: 3
                    },
                    {
                        id: "500101",
                        name: "万州区",
                        center: "108.380246,30.807807",
                        level: 3
                    },
                    {
                        id: "500114",
                        name: "黔江区",
                        center: "108.782577,29.527548",
                        level: 3
                    },
                    {
                        id: "500116",
                        name: "江津区",
                        center: "106.253156,29.283387",
                        level: 3
                    },
                    {
                        id: "500106",
                        name: "沙坪坝区",
                        center: "106.4542,29.541224",
                        level: 3
                    },
                    {
                        id: "500113",
                        name: "巴南区",
                        center: "106.519423,29.381919",
                        level: 3
                    },
                    {
                        id: "500108",
                        name: "南岸区",
                        center: "106.560813,29.523992",
                        level: 3
                    },
                    {
                        id: "500109",
                        name: "北碚区",
                        center: "106.437868,29.82543",
                        level: 3
                    },
                    {
                        id: "500105",
                        name: "江北区",
                        center: "106.532844,29.575352",
                        level: 3
                    },
                    {
                        id: "500112",
                        name: "渝北区",
                        center: "106.512851,29.601451",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "510000",
        name: "四川省",
        center: "104.065735,30.659462",
        level: 1,
        children: [
            {
                id: "510800",
                name: "广元市",
                center: "105.829757,32.433668",
                level: 2,
                children: [
                    {
                        id: "510812",
                        name: "朝天区",
                        center: "105.88917,32.642632",
                        level: 3
                    },
                    {
                        id: "510824",
                        name: "苍溪县",
                        center: "105.939706,31.732251",
                        level: 3
                    },
                    {
                        id: "510823",
                        name: "剑阁县",
                        center: "105.527035,32.286517",
                        level: 3
                    },
                    {
                        id: "510811",
                        name: "昭化区",
                        center: "105.964121,32.322788",
                        level: 3
                    },
                    {
                        id: "510821",
                        name: "旺苍县",
                        center: "106.290426,32.22833",
                        level: 3
                    },
                    {
                        id: "510822",
                        name: "青川县",
                        center: "105.238847,32.585655",
                        level: 3
                    },
                    {
                        id: "510802",
                        name: "利州区",
                        center: "105.826194,32.432276",
                        level: 3
                    }
                ]
            },
            {
                id: "511900",
                name: "巴中市",
                center: "106.753669,31.858809",
                level: 2,
                children: [
                    {
                        id: "511902",
                        name: "巴州区",
                        center: "106.753671,31.858366",
                        level: 3
                    },
                    {
                        id: "511922",
                        name: "南江县",
                        center: "106.843418,32.353164",
                        level: 3
                    },
                    {
                        id: "511921",
                        name: "通江县",
                        center: "107.247621,31.91212",
                        level: 3
                    },
                    {
                        id: "511923",
                        name: "平昌县",
                        center: "107.101937,31.562814",
                        level: 3
                    },
                    {
                        id: "511903",
                        name: "恩阳区",
                        center: "106.486515,31.816336",
                        level: 3
                    }
                ]
            },
            {
                id: "510600",
                name: "德阳市",
                center: "104.398651,31.127991",
                level: 2,
                children: [
                    {
                        id: "510623",
                        name: "中江县",
                        center: "104.677831,31.03681",
                        level: 3
                    },
                    {
                        id: "510681",
                        name: "广汉市",
                        center: "104.281903,30.97715",
                        level: 3
                    },
                    {
                        id: "510603",
                        name: "旌阳区",
                        center: "104.389648,31.130428",
                        level: 3
                    },
                    {
                        id: "510682",
                        name: "什邡市",
                        center: "104.173653,31.126881",
                        level: 3
                    },
                    {
                        id: "510604",
                        name: "罗江区",
                        center: "104.507126,31.303281",
                        level: 3
                    },
                    {
                        id: "510683",
                        name: "绵竹市",
                        center: "104.200162,31.343084",
                        level: 3
                    }
                ]
            },
            {
                id: "511300",
                name: "南充市",
                center: "106.082974,30.795281",
                level: 2,
                children: [
                    {
                        id: "511322",
                        name: "营山县",
                        center: "106.564893,31.075907",
                        level: 3
                    },
                    {
                        id: "511323",
                        name: "蓬安县",
                        center: "106.413488,31.027978",
                        level: 3
                    },
                    {
                        id: "511325",
                        name: "西充县",
                        center: "105.893021,30.994616",
                        level: 3
                    },
                    {
                        id: "511321",
                        name: "南部县",
                        center: "106.061138,31.349407",
                        level: 3
                    },
                    {
                        id: "511304",
                        name: "嘉陵区",
                        center: "106.067027,30.762976",
                        level: 3
                    },
                    {
                        id: "511324",
                        name: "仪陇县",
                        center: "106.297083,31.271261",
                        level: 3
                    },
                    {
                        id: "511381",
                        name: "阆中市",
                        center: "105.975266,31.580466",
                        level: 3
                    },
                    {
                        id: "511302",
                        name: "顺庆区",
                        center: "106.084091,30.795572",
                        level: 3
                    },
                    {
                        id: "511303",
                        name: "高坪区",
                        center: "106.108996,30.781809",
                        level: 3
                    }
                ]
            },
            {
                id: "510700",
                name: "绵阳市",
                center: "104.741722,31.46402",
                level: 2,
                children: [
                    {
                        id: "510725",
                        name: "梓潼县",
                        center: "105.16353,31.635225",
                        level: 3
                    },
                    {
                        id: "510704",
                        name: "游仙区",
                        center: "104.770006,31.484772",
                        level: 3
                    },
                    {
                        id: "510722",
                        name: "三台县",
                        center: "105.090316,31.090909",
                        level: 3
                    },
                    {
                        id: "510781",
                        name: "江油市",
                        center: "104.744431,31.776386",
                        level: 3
                    },
                    {
                        id: "510726",
                        name: "北川羌族自治县",
                        center: "104.468069,31.615863",
                        level: 3
                    },
                    {
                        id: "510705",
                        name: "安州区",
                        center: "104.560341,31.53894",
                        level: 3
                    },
                    {
                        id: "510703",
                        name: "涪城区",
                        center: "104.740971,31.463557",
                        level: 3
                    },
                    {
                        id: "510723",
                        name: "盐亭县",
                        center: "105.391991,31.22318",
                        level: 3
                    },
                    {
                        id: "510727",
                        name: "平武县",
                        center: "104.530555,32.407588",
                        level: 3
                    }
                ]
            },
            {
                id: "511400",
                name: "眉山市",
                center: "103.831788,30.048318",
                level: 2,
                children: [
                    {
                        id: "511424",
                        name: "丹棱县",
                        center: "103.518333,30.012751",
                        level: 3
                    },
                    {
                        id: "511421",
                        name: "仁寿县",
                        center: "104.147646,29.996721",
                        level: 3
                    },
                    {
                        id: "511423",
                        name: "洪雅县",
                        center: "103.375006,29.904867",
                        level: 3
                    },
                    {
                        id: "511403",
                        name: "彭山区",
                        center: "103.8701,30.192298",
                        level: 3
                    },
                    {
                        id: "511402",
                        name: "东坡区",
                        center: "103.831553,30.048128",
                        level: 3
                    },
                    {
                        id: "511425",
                        name: "青神县",
                        center: "103.846131,29.831469",
                        level: 3
                    }
                ]
            },
            {
                id: "511700",
                name: "达州市",
                center: "107.502262,31.209484",
                level: 2,
                children: [
                    {
                        id: "511724",
                        name: "大竹县",
                        center: "107.20742,30.736289",
                        level: 3
                    },
                    {
                        id: "511725",
                        name: "渠县",
                        center: "106.970746,30.836348",
                        level: 3
                    },
                    {
                        id: "511722",
                        name: "宣汉县",
                        center: "107.722254,31.355025",
                        level: 3
                    },
                    {
                        id: "511703",
                        name: "达川区",
                        center: "107.507926,31.199062",
                        level: 3
                    },
                    {
                        id: "511723",
                        name: "开江县",
                        center: "107.864135,31.085537",
                        level: 3
                    },
                    {
                        id: "511781",
                        name: "万源市",
                        center: "108.037548,32.06777",
                        level: 3
                    },
                    {
                        id: "511702",
                        name: "通川区",
                        center: "107.501062,31.213522",
                        level: 3
                    }
                ]
            },
            {
                id: "510300",
                name: "自贡市",
                center: "104.773447,29.352765",
                level: 2,
                children: [
                    {
                        id: "510321",
                        name: "荣县",
                        center: "104.423932,29.454851",
                        level: 3
                    },
                    {
                        id: "510302",
                        name: "自流井区",
                        center: "104.778188,29.343231",
                        level: 3
                    },
                    {
                        id: "510303",
                        name: "贡井区",
                        center: "104.714372,29.345675",
                        level: 3
                    },
                    {
                        id: "510311",
                        name: "沿滩区",
                        center: "104.876417,29.272521",
                        level: 3
                    },
                    {
                        id: "510304",
                        name: "大安区",
                        center: "104.783229,29.367136",
                        level: 3
                    },
                    {
                        id: "510322",
                        name: "富顺县",
                        center: "104.984256,29.181282",
                        level: 3
                    }
                ]
            },
            {
                id: "511600",
                name: "广安市",
                center: "106.633369,30.456398",
                level: 2,
                children: [
                    {
                        id: "511622",
                        name: "武胜县",
                        center: "106.292473,30.344291",
                        level: 3
                    },
                    {
                        id: "511623",
                        name: "邻水县",
                        center: "106.934968,30.334323",
                        level: 3
                    },
                    {
                        id: "511602",
                        name: "广安区",
                        center: "106.632907,30.456462",
                        level: 3
                    },
                    {
                        id: "511681",
                        name: "华蓥市",
                        center: "106.777882,30.380574",
                        level: 3
                    },
                    {
                        id: "511603",
                        name: "前锋区",
                        center: "106.893277,30.4963",
                        level: 3
                    },
                    {
                        id: "511621",
                        name: "岳池县",
                        center: "106.444451,30.533538",
                        level: 3
                    }
                ]
            },
            {
                id: "511000",
                name: "内江市",
                center: "105.066138,29.58708",
                level: 2,
                children: [
                    {
                        id: "511025",
                        name: "资中县",
                        center: "104.852463,29.775295",
                        level: 3
                    },
                    {
                        id: "511024",
                        name: "威远县",
                        center: "104.668327,29.52686",
                        level: 3
                    },
                    {
                        id: "511011",
                        name: "东兴区",
                        center: "105.067203,29.600107",
                        level: 3
                    },
                    {
                        id: "511002",
                        name: "市中区",
                        center: "105.065467,29.585265",
                        level: 3
                    },
                    {
                        id: "511083",
                        name: "隆昌市",
                        center: "105.288074,29.338162",
                        level: 3
                    }
                ]
            },
            {
                id: "510900",
                name: "遂宁市",
                center: "105.571331,30.513311",
                level: 2,
                children: [
                    {
                        id: "510921",
                        name: "蓬溪县",
                        center: "105.713699,30.774883",
                        level: 3
                    },
                    {
                        id: "510922",
                        name: "射洪市",
                        center: "105.381849,30.868752",
                        level: 3
                    },
                    {
                        id: "510904",
                        name: "安居区",
                        center: "105.459383,30.346121",
                        level: 3
                    },
                    {
                        id: "510903",
                        name: "船山区",
                        center: "105.582215,30.502647",
                        level: 3
                    },
                    {
                        id: "510923",
                        name: "大英县",
                        center: "105.252187,30.581571",
                        level: 3
                    }
                ]
            },
            {
                id: "512000",
                name: "资阳市",
                center: "104.641917,30.122211",
                level: 2,
                children: [
                    {
                        id: "512022",
                        name: "乐至县",
                        center: "105.031142,30.275619",
                        level: 3
                    },
                    {
                        id: "512021",
                        name: "安岳县",
                        center: "105.336764,30.099206",
                        level: 3
                    },
                    {
                        id: "512002",
                        name: "雁江区",
                        center: "104.642338,30.121686",
                        level: 3
                    }
                ]
            },
            {
                id: "510100",
                name: "成都市",
                center: "104.065735,30.659462",
                level: 2,
                children: [
                    {
                        id: "510129",
                        name: "大邑县",
                        center: "103.522397,30.586602",
                        level: 3
                    },
                    {
                        id: "510181",
                        name: "都江堰市",
                        center: "103.627898,30.99114",
                        level: 3
                    },
                    {
                        id: "510113",
                        name: "青白江区",
                        center: "104.25494,30.883438",
                        level: 3
                    },
                    {
                        id: "510182",
                        name: "彭州市",
                        center: "103.941173,30.985161",
                        level: 3
                    },
                    {
                        id: "510185",
                        name: "简阳市",
                        center: "104.550339,30.390666",
                        level: 3
                    },
                    {
                        id: "510184",
                        name: "崇州市",
                        center: "103.671049,30.631478",
                        level: 3
                    },
                    {
                        id: "510131",
                        name: "蒲江县",
                        center: "103.511541,30.194359",
                        level: 3
                    },
                    {
                        id: "510121",
                        name: "金堂县",
                        center: "104.415604,30.858417",
                        level: 3
                    },
                    {
                        id: "510183",
                        name: "邛崃市",
                        center: "103.46143,30.413271",
                        level: 3
                    },
                    {
                        id: "510132",
                        name: "新津县",
                        center: "103.812449,30.414284",
                        level: 3
                    },
                    {
                        id: "510115",
                        name: "温江区",
                        center: "103.836776,30.697996",
                        level: 3
                    },
                    {
                        id: "510107",
                        name: "武侯区",
                        center: "104.05167,30.630862",
                        level: 3
                    },
                    {
                        id: "510116",
                        name: "双流区",
                        center: "103.922706,30.573243",
                        level: 3
                    },
                    {
                        id: "510117",
                        name: "郫都区",
                        center: "103.887842,30.808752",
                        level: 3
                    },
                    {
                        id: "510106",
                        name: "金牛区",
                        center: "104.043487,30.692058",
                        level: 3
                    },
                    {
                        id: "510114",
                        name: "新都区",
                        center: "104.16022,30.824223",
                        level: 3
                    },
                    {
                        id: "510112",
                        name: "龙泉驿区",
                        center: "104.269181,30.56065",
                        level: 3
                    },
                    {
                        id: "510108",
                        name: "成华区",
                        center: "104.103077,30.660275",
                        level: 3
                    },
                    {
                        id: "510104",
                        name: "锦江区",
                        center: "104.080989,30.657689",
                        level: 3
                    },
                    {
                        id: "510105",
                        name: "青羊区",
                        center: "104.055731,30.667648",
                        level: 3
                    }
                ]
            },
            {
                id: "511100",
                name: "乐山市",
                center: "103.761263,29.582024",
                level: 2,
                children: [
                    {
                        id: "511126",
                        name: "夹江县",
                        center: "103.578862,29.741019",
                        level: 3
                    },
                    {
                        id: "511112",
                        name: "五通桥区",
                        center: "103.816837,29.406186",
                        level: 3
                    },
                    {
                        id: "511111",
                        name: "沙湾区",
                        center: "103.549961,29.416536",
                        level: 3
                    },
                    {
                        id: "511124",
                        name: "井研县",
                        center: "104.06885,29.651645",
                        level: 3
                    },
                    {
                        id: "511132",
                        name: "峨边彝族自治县",
                        center: "103.262148,29.230271",
                        level: 3
                    },
                    {
                        id: "511113",
                        name: "金口河区",
                        center: "103.077831,29.24602",
                        level: 3
                    },
                    {
                        id: "511133",
                        name: "马边彝族自治县",
                        center: "103.546851,28.838933",
                        level: 3
                    },
                    {
                        id: "511123",
                        name: "犍为县",
                        center: "103.944266,29.209782",
                        level: 3
                    },
                    {
                        id: "511129",
                        name: "沐川县",
                        center: "103.90211,28.956338",
                        level: 3
                    },
                    {
                        id: "511102",
                        name: "市中区",
                        center: "103.75539,29.588327",
                        level: 3
                    },
                    {
                        id: "511181",
                        name: "峨眉山市",
                        center: "103.492488,29.597478",
                        level: 3
                    }
                ]
            },
            {
                id: "511800",
                name: "雅安市",
                center: "103.001033,29.987722",
                level: 2,
                children: [
                    {
                        id: "511825",
                        name: "天全县",
                        center: "102.763462,30.059955",
                        level: 3
                    },
                    {
                        id: "511826",
                        name: "芦山县",
                        center: "102.924016,30.152907",
                        level: 3
                    },
                    {
                        id: "511827",
                        name: "宝兴县",
                        center: "102.813377,30.369026",
                        level: 3
                    },
                    {
                        id: "511802",
                        name: "雨城区",
                        center: "103.003398,29.981831",
                        level: 3
                    },
                    {
                        id: "511822",
                        name: "荥经县",
                        center: "102.844674,29.795529",
                        level: 3
                    },
                    {
                        id: "511824",
                        name: "石棉县",
                        center: "102.35962,29.234063",
                        level: 3
                    },
                    {
                        id: "511823",
                        name: "汉源县",
                        center: "102.677145,29.349915",
                        level: 3
                    },
                    {
                        id: "511803",
                        name: "名山区",
                        center: "103.112214,30.084718",
                        level: 3
                    }
                ]
            },
            {
                id: "510500",
                name: "泸州市",
                center: "105.443348,28.889138",
                level: 2,
                children: [
                    {
                        id: "510504",
                        name: "龙马潭区",
                        center: "105.435228,28.897572",
                        level: 3
                    },
                    {
                        id: "510524",
                        name: "叙永县",
                        center: "105.437775,28.167919",
                        level: 3
                    },
                    {
                        id: "510503",
                        name: "纳溪区",
                        center: "105.37721,28.77631",
                        level: 3
                    },
                    {
                        id: "510521",
                        name: "泸县",
                        center: "105.376335,29.151288",
                        level: 3
                    },
                    {
                        id: "510525",
                        name: "古蔺县",
                        center: "105.813359,28.03948",
                        level: 3
                    },
                    {
                        id: "510502",
                        name: "江阳区",
                        center: "105.445131,28.882889",
                        level: 3
                    },
                    {
                        id: "510522",
                        name: "合江县",
                        center: "105.834098,28.810325",
                        level: 3
                    }
                ]
            },
            {
                id: "513400",
                name: "凉山彝族自治州",
                center: "102.258746,27.886762",
                level: 2,
                children: [
                    {
                        id: "513431",
                        name: "昭觉县",
                        center: "102.843991,28.010554",
                        level: 3
                    },
                    {
                        id: "513436",
                        name: "美姑县",
                        center: "103.132007,28.327946",
                        level: 3
                    },
                    {
                        id: "513435",
                        name: "甘洛县",
                        center: "102.775924,28.977094",
                        level: 3
                    },
                    {
                        id: "513426",
                        name: "会东县",
                        center: "102.578985,26.630713",
                        level: 3
                    },
                    {
                        id: "513430",
                        name: "金阳县",
                        center: "103.248704,27.695916",
                        level: 3
                    },
                    {
                        id: "513432",
                        name: "喜德县",
                        center: "102.412342,28.305486",
                        level: 3
                    },
                    {
                        id: "513401",
                        name: "西昌市",
                        center: "102.258758,27.885786",
                        level: 3
                    },
                    {
                        id: "513433",
                        name: "冕宁县",
                        center: "102.170046,28.550844",
                        level: 3
                    },
                    {
                        id: "513434",
                        name: "越西县",
                        center: "102.508875,28.639632",
                        level: 3
                    },
                    {
                        id: "513423",
                        name: "盐源县",
                        center: "101.508909,27.423415",
                        level: 3
                    },
                    {
                        id: "513422",
                        name: "木里藏族自治县",
                        center: "101.280184,27.926859",
                        level: 3
                    },
                    {
                        id: "513428",
                        name: "普格县",
                        center: "102.541082,27.376828",
                        level: 3
                    },
                    {
                        id: "513425",
                        name: "会理县",
                        center: "102.249548,26.658702",
                        level: 3
                    },
                    {
                        id: "513437",
                        name: "雷波县",
                        center: "103.571584,28.262946",
                        level: 3
                    },
                    {
                        id: "513429",
                        name: "布拖县",
                        center: "102.808801,27.709062",
                        level: 3
                    },
                    {
                        id: "513424",
                        name: "德昌县",
                        center: "102.178845,27.403827",
                        level: 3
                    },
                    {
                        id: "513427",
                        name: "宁南县",
                        center: "102.757374,27.065205",
                        level: 3
                    }
                ]
            },
            {
                id: "511500",
                name: "宜宾市",
                center: "104.630825,28.760189",
                level: 2,
                children: [
                    {
                        id: "511523",
                        name: "江安县",
                        center: "105.068697,28.728102",
                        level: 3
                    },
                    {
                        id: "511526",
                        name: "珙县",
                        center: "104.712268,28.449041",
                        level: 3
                    },
                    {
                        id: "511529",
                        name: "屏山县",
                        center: "104.162617,28.64237",
                        level: 3
                    },
                    {
                        id: "511503",
                        name: "南溪区",
                        center: "104.981133,28.839806",
                        level: 3
                    },
                    {
                        id: "511502",
                        name: "翠屏区",
                        center: "104.630231,28.760179",
                        level: 3
                    },
                    {
                        id: "511524",
                        name: "长宁县",
                        center: "104.921116,28.577271",
                        level: 3
                    },
                    {
                        id: "511525",
                        name: "高县",
                        center: "104.519187,28.435676",
                        level: 3
                    },
                    {
                        id: "511528",
                        name: "兴文县",
                        center: "105.236549,28.302988",
                        level: 3
                    },
                    {
                        id: "511527",
                        name: "筠连县",
                        center: "104.507848,28.162017",
                        level: 3
                    },
                    {
                        id: "511504",
                        name: "叙州区",
                        center: "104.541489,28.695678",
                        level: 3
                    }
                ]
            },
            {
                id: "510400",
                name: "攀枝花市",
                center: "101.716007,26.580446",
                level: 2,
                children: [
                    {
                        id: "510421",
                        name: "米易县",
                        center: "102.109877,26.887474",
                        level: 3
                    },
                    {
                        id: "510411",
                        name: "仁和区",
                        center: "101.737916,26.497185",
                        level: 3
                    },
                    {
                        id: "510403",
                        name: "西区",
                        center: "101.637969,26.596776",
                        level: 3
                    },
                    {
                        id: "510402",
                        name: "东区",
                        center: "101.715134,26.580887",
                        level: 3
                    },
                    {
                        id: "510422",
                        name: "盐边县",
                        center: "101.851848,26.677619",
                        level: 3
                    }
                ]
            },
            {
                id: "513200",
                name: "阿坝藏族羌族自治州",
                center: "102.221374,31.899792",
                level: 2,
                children: [
                    {
                        id: "513231",
                        name: "阿坝县",
                        center: "101.700985,32.904223",
                        level: 3
                    },
                    {
                        id: "513232",
                        name: "若尔盖县",
                        center: "102.963726,33.575934",
                        level: 3
                    },
                    {
                        id: "513225",
                        name: "九寨沟县",
                        center: "104.236344,33.262097",
                        level: 3
                    },
                    {
                        id: "513233",
                        name: "红原县",
                        center: "102.544906,32.793902",
                        level: 3
                    },
                    {
                        id: "513201",
                        name: "马尔康市",
                        center: "102.221187,31.899761",
                        level: 3
                    },
                    {
                        id: "513222",
                        name: "理县",
                        center: "103.165486,31.436764",
                        level: 3
                    },
                    {
                        id: "513228",
                        name: "黑水县",
                        center: "102.990805,32.061721",
                        level: 3
                    },
                    {
                        id: "513221",
                        name: "汶川县",
                        center: "103.580675,31.47463",
                        level: 3
                    },
                    {
                        id: "513226",
                        name: "金川县",
                        center: "102.064647,31.476356",
                        level: 3
                    },
                    {
                        id: "513224",
                        name: "松潘县",
                        center: "103.599177,32.63838",
                        level: 3
                    },
                    {
                        id: "513223",
                        name: "茂县",
                        center: "103.850684,31.680407",
                        level: 3
                    },
                    {
                        id: "513227",
                        name: "小金县",
                        center: "102.363193,30.999016",
                        level: 3
                    },
                    {
                        id: "513230",
                        name: "壤塘县",
                        center: "100.979136,32.264887",
                        level: 3
                    }
                ]
            },
            {
                id: "513300",
                name: "甘孜藏族自治州",
                center: "101.963815,30.050663",
                level: 2,
                children: [
                    {
                        id: "513332",
                        name: "石渠县",
                        center: "98.100887,32.975302",
                        level: 3
                    },
                    {
                        id: "513328",
                        name: "甘孜县",
                        center: "99.991753,31.61975",
                        level: 3
                    },
                    {
                        id: "513335",
                        name: "巴塘县",
                        center: "99.109037,30.005723",
                        level: 3
                    },
                    {
                        id: "513329",
                        name: "新龙县",
                        center: "100.312094,30.93896",
                        level: 3
                    },
                    {
                        id: "513326",
                        name: "道孚县",
                        center: "101.123327,30.978767",
                        level: 3
                    },
                    {
                        id: "513325",
                        name: "雅江县",
                        center: "101.015735,30.03225",
                        level: 3
                    },
                    {
                        id: "513331",
                        name: "白玉县",
                        center: "98.824343,31.208805",
                        level: 3
                    },
                    {
                        id: "513334",
                        name: "理塘县",
                        center: "100.269862,29.991807",
                        level: 3
                    },
                    {
                        id: "513301",
                        name: "康定市",
                        center: "101.964057,30.050738",
                        level: 3
                    },
                    {
                        id: "513330",
                        name: "德格县",
                        center: "98.57999,31.806729",
                        level: 3
                    },
                    {
                        id: "513327",
                        name: "炉霍县",
                        center: "100.679495,31.392674",
                        level: 3
                    },
                    {
                        id: "513336",
                        name: "乡城县",
                        center: "99.799943,28.930855",
                        level: 3
                    },
                    {
                        id: "513337",
                        name: "稻城县",
                        center: "100.296689,29.037544",
                        level: 3
                    },
                    {
                        id: "513338",
                        name: "得荣县",
                        center: "99.288036,28.71134",
                        level: 3
                    },
                    {
                        id: "513324",
                        name: "九龙县",
                        center: "101.506942,29.001975",
                        level: 3
                    },
                    {
                        id: "513333",
                        name: "色达县",
                        center: "100.331657,32.268777",
                        level: 3
                    },
                    {
                        id: "513323",
                        name: "丹巴县",
                        center: "101.886125,30.877083",
                        level: 3
                    },
                    {
                        id: "513322",
                        name: "泸定县",
                        center: "102.233225,29.912482",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "520000",
        name: "贵州省",
        center: "106.713478,26.578343",
        level: 1,
        children: [
            {
                id: "520200",
                name: "六盘水市",
                center: "104.846743,26.584643",
                level: 2,
                children: [
                    {
                        id: "520201",
                        name: "钟山区",
                        center: "104.846244,26.584805",
                        level: 3
                    },
                    {
                        id: "520203",
                        name: "六枝特区",
                        center: "105.474235,26.210662",
                        level: 3
                    },
                    {
                        id: "520281",
                        name: "盘州市",
                        center: "104.468367,25.706966",
                        level: 3
                    },
                    {
                        id: "520221",
                        name: "水城县",
                        center: "104.95685,26.540478",
                        level: 3
                    }
                ]
            },
            {
                id: "520600",
                name: "铜仁市",
                center: "109.191555,27.718346",
                level: 2,
                children: [
                    {
                        id: "520625",
                        name: "印江土家族苗族自治县",
                        center: "108.405517,27.997976",
                        level: 3
                    },
                    {
                        id: "520623",
                        name: "石阡县",
                        center: "108.229854,27.519386",
                        level: 3
                    },
                    {
                        id: "520622",
                        name: "玉屏侗族自治县",
                        center: "108.917882,27.238024",
                        level: 3
                    },
                    {
                        id: "520602",
                        name: "碧江区",
                        center: "109.192117,27.718745",
                        level: 3
                    },
                    {
                        id: "520627",
                        name: "沿河土家族自治县",
                        center: "108.495746,28.560487",
                        level: 3
                    },
                    {
                        id: "520624",
                        name: "思南县",
                        center: "108.255827,27.941331",
                        level: 3
                    },
                    {
                        id: "520626",
                        name: "德江县",
                        center: "108.117317,28.26094",
                        level: 3
                    },
                    {
                        id: "520621",
                        name: "江口县",
                        center: "108.848427,27.691904",
                        level: 3
                    },
                    {
                        id: "520603",
                        name: "万山区",
                        center: "109.21199,27.51903",
                        level: 3
                    },
                    {
                        id: "520628",
                        name: "松桃苗族自治县",
                        center: "109.202627,28.165419",
                        level: 3
                    }
                ]
            },
            {
                id: "520300",
                name: "遵义市",
                center: "106.937265,27.706626",
                level: 2,
                children: [
                    {
                        id: "520329",
                        name: "余庆县",
                        center: "107.892566,27.221552",
                        level: 3
                    },
                    {
                        id: "520323",
                        name: "绥阳县",
                        center: "107.191024,27.951342",
                        level: 3
                    },
                    {
                        id: "520328",
                        name: "湄潭县",
                        center: "107.485723,27.765839",
                        level: 3
                    },
                    {
                        id: "520322",
                        name: "桐梓县",
                        center: "106.826591,28.131559",
                        level: 3
                    },
                    {
                        id: "520302",
                        name: "红花岗区",
                        center: "106.943784,27.694395",
                        level: 3
                    },
                    {
                        id: "520303",
                        name: "汇川区",
                        center: "106.937265,27.706626",
                        level: 3
                    },
                    {
                        id: "520327",
                        name: "凤冈县",
                        center: "107.722021,27.960858",
                        level: 3
                    },
                    {
                        id: "520326",
                        name: "务川仡佬族苗族自治县",
                        center: "107.887857,28.521567",
                        level: 3
                    },
                    {
                        id: "520382",
                        name: "仁怀市",
                        center: "106.412476,27.803377",
                        level: 3
                    },
                    {
                        id: "520304",
                        name: "播州区",
                        center: "106.831668,27.535288",
                        level: 3
                    },
                    {
                        id: "520381",
                        name: "赤水市",
                        center: "105.698116,28.587057",
                        level: 3
                    },
                    {
                        id: "520330",
                        name: "习水县",
                        center: "106.200954,28.327826",
                        level: 3
                    },
                    {
                        id: "520325",
                        name: "道真仡佬族苗族自治县",
                        center: "107.605342,28.880088",
                        level: 3
                    },
                    {
                        id: "520324",
                        name: "正安县",
                        center: "107.441872,28.550337",
                        level: 3
                    }
                ]
            },
            {
                id: "522700",
                name: "黔南布依族苗族自治州",
                center: "107.517156,26.258219",
                level: 2,
                children: [
                    {
                        id: "522726",
                        name: "独山县",
                        center: "107.542757,25.826283",
                        level: 3
                    },
                    {
                        id: "522731",
                        name: "惠水县",
                        center: "106.657848,26.128637",
                        level: 3
                    },
                    {
                        id: "522727",
                        name: "平塘县",
                        center: "107.32405,25.831803",
                        level: 3
                    },
                    {
                        id: "522701",
                        name: "都匀市",
                        center: "107.517021,26.258205",
                        level: 3
                    },
                    {
                        id: "522729",
                        name: "长顺县",
                        center: "106.447376,26.022116",
                        level: 3
                    },
                    {
                        id: "522722",
                        name: "荔波县",
                        center: "107.8838,25.412239",
                        level: 3
                    },
                    {
                        id: "522728",
                        name: "罗甸县",
                        center: "106.750006,25.429894",
                        level: 3
                    },
                    {
                        id: "522723",
                        name: "贵定县",
                        center: "107.233588,26.580807",
                        level: 3
                    },
                    {
                        id: "522730",
                        name: "龙里县",
                        center: "106.977733,26.448809",
                        level: 3
                    },
                    {
                        id: "522702",
                        name: "福泉市",
                        center: "107.513508,26.702508",
                        level: 3
                    },
                    {
                        id: "522725",
                        name: "瓮安县",
                        center: "107.478417,27.066339",
                        level: 3
                    },
                    {
                        id: "522732",
                        name: "三都水族自治县",
                        center: "107.87747,25.985183",
                        level: 3
                    }
                ]
            },
            {
                id: "520500",
                name: "毕节市",
                center: "105.28501,27.301693",
                level: 2,
                children: [
                    {
                        id: "520523",
                        name: "金沙县",
                        center: "106.222103,27.459693",
                        level: 3
                    },
                    {
                        id: "520502",
                        name: "七星关区",
                        center: "105.284852,27.302085",
                        level: 3
                    },
                    {
                        id: "520521",
                        name: "大方县",
                        center: "105.609254,27.143521",
                        level: 3
                    },
                    {
                        id: "520522",
                        name: "黔西县",
                        center: "106.038299,27.024923",
                        level: 3
                    },
                    {
                        id: "520524",
                        name: "织金县",
                        center: "105.768997,26.668497",
                        level: 3
                    },
                    {
                        id: "520525",
                        name: "纳雍县",
                        center: "105.375322,26.769875",
                        level: 3
                    },
                    {
                        id: "520526",
                        name: "威宁彝族回族苗族自治县",
                        center: "104.286523,26.859099",
                        level: 3
                    },
                    {
                        id: "520527",
                        name: "赫章县",
                        center: "104.726438,27.119243",
                        level: 3
                    }
                ]
            },
            {
                id: "522600",
                name: "黔东南苗族侗族自治州",
                center: "107.977488,26.583352",
                level: 2,
                children: [
                    {
                        id: "522624",
                        name: "三穗县",
                        center: "108.681121,26.959884",
                        level: 3
                    },
                    {
                        id: "522634",
                        name: "雷山县",
                        center: "108.079613,26.381027",
                        level: 3
                    },
                    {
                        id: "522632",
                        name: "榕江县",
                        center: "108.521026,25.931085",
                        level: 3
                    },
                    {
                        id: "522623",
                        name: "施秉县",
                        center: "108.12678,27.034657",
                        level: 3
                    },
                    {
                        id: "522627",
                        name: "天柱县",
                        center: "109.212798,26.909684",
                        level: 3
                    },
                    {
                        id: "522625",
                        name: "镇远县",
                        center: "108.423656,27.050233",
                        level: 3
                    },
                    {
                        id: "522635",
                        name: "麻江县",
                        center: "107.593172,26.494803",
                        level: 3
                    },
                    {
                        id: "522636",
                        name: "丹寨县",
                        center: "107.794808,26.199497",
                        level: 3
                    },
                    {
                        id: "522622",
                        name: "黄平县",
                        center: "107.901337,26.896973",
                        level: 3
                    },
                    {
                        id: "522601",
                        name: "凯里市",
                        center: "107.977541,26.582964",
                        level: 3
                    },
                    {
                        id: "522630",
                        name: "台江县",
                        center: "108.314637,26.669138",
                        level: 3
                    },
                    {
                        id: "522626",
                        name: "岑巩县",
                        center: "108.816459,27.173244",
                        level: 3
                    },
                    {
                        id: "522631",
                        name: "黎平县",
                        center: "109.136504,26.230636",
                        level: 3
                    },
                    {
                        id: "522633",
                        name: "从江县",
                        center: "108.912648,25.747058",
                        level: 3
                    },
                    {
                        id: "522628",
                        name: "锦屏县",
                        center: "109.20252,26.680625",
                        level: 3
                    },
                    {
                        id: "522629",
                        name: "剑河县",
                        center: "108.440499,26.727349",
                        level: 3
                    }
                ]
            },
            {
                id: "520400",
                name: "安顺市",
                center: "105.932188,26.245544",
                level: 2,
                children: [
                    {
                        id: "520402",
                        name: "西秀区",
                        center: "105.946169,26.248323",
                        level: 3
                    },
                    {
                        id: "520425",
                        name: "紫云苗族布依族自治县",
                        center: "106.084515,25.751567",
                        level: 3
                    },
                    {
                        id: "520423",
                        name: "镇宁布依族苗族自治县",
                        center: "105.768656,26.056096",
                        level: 3
                    },
                    {
                        id: "520424",
                        name: "关岭布依族苗族自治县",
                        center: "105.618454,25.944248",
                        level: 3
                    },
                    {
                        id: "520403",
                        name: "平坝区",
                        center: "106.259942,26.40608",
                        level: 3
                    },
                    {
                        id: "520422",
                        name: "普定县",
                        center: "105.745609,26.305794",
                        level: 3
                    }
                ]
            },
            {
                id: "522300",
                name: "黔西南布依族苗族自治州",
                center: "104.897971,25.08812",
                level: 2,
                children: [
                    {
                        id: "522301",
                        name: "兴义市",
                        center: "104.897982,25.088599",
                        level: 3
                    },
                    {
                        id: "522327",
                        name: "册亨县",
                        center: "105.81241,24.983338",
                        level: 3
                    },
                    {
                        id: "522302",
                        name: "兴仁市",
                        center: "105.192778,25.431378",
                        level: 3
                    },
                    {
                        id: "522328",
                        name: "安龙县",
                        center: "105.471498,25.108959",
                        level: 3
                    },
                    {
                        id: "522323",
                        name: "普安县",
                        center: "104.955347,25.786404",
                        level: 3
                    },
                    {
                        id: "522325",
                        name: "贞丰县",
                        center: "105.650133,25.385752",
                        level: 3
                    },
                    {
                        id: "522324",
                        name: "晴隆县",
                        center: "105.218773,25.832881",
                        level: 3
                    },
                    {
                        id: "522326",
                        name: "望谟县",
                        center: "106.091563,25.166667",
                        level: 3
                    }
                ]
            },
            {
                id: "520100",
                name: "贵阳市",
                center: "106.713478,26.578343",
                level: 2,
                children: [
                    {
                        id: "520121",
                        name: "开阳县",
                        center: "106.969438,27.056793",
                        level: 3
                    },
                    {
                        id: "520112",
                        name: "乌当区",
                        center: "106.762123,26.630928",
                        level: 3
                    },
                    {
                        id: "520115",
                        name: "观山湖区",
                        center: "106.626323,26.646358",
                        level: 3
                    },
                    {
                        id: "520113",
                        name: "白云区",
                        center: "106.633037,26.676849",
                        level: 3
                    },
                    {
                        id: "520122",
                        name: "息烽县",
                        center: "106.737693,27.092665",
                        level: 3
                    },
                    {
                        id: "520123",
                        name: "修文县",
                        center: "106.599218,26.840672",
                        level: 3
                    },
                    {
                        id: "520181",
                        name: "清镇市",
                        center: "106.470278,26.551289",
                        level: 3
                    },
                    {
                        id: "520102",
                        name: "南明区",
                        center: "106.715963,26.573743",
                        level: 3
                    },
                    {
                        id: "520111",
                        name: "花溪区",
                        center: "106.670791,26.410464",
                        level: 3
                    },
                    {
                        id: "520103",
                        name: "云岩区",
                        center: "106.713397,26.58301",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "530000",
        name: "云南省",
        center: "102.712251,25.040609",
        level: 1,
        children: [
            {
                id: "532500",
                name: "红河哈尼族彝族自治州",
                center: "103.384182,23.366775",
                level: 2,
                children: [
                    {
                        id: "532527",
                        name: "泸西县",
                        center: "103.759622,24.532368",
                        level: 3
                    },
                    {
                        id: "532504",
                        name: "弥勒市",
                        center: "103.436988,24.40837",
                        level: 3
                    },
                    {
                        id: "532524",
                        name: "建水县",
                        center: "102.820493,23.618387",
                        level: 3
                    },
                    {
                        id: "532502",
                        name: "开远市",
                        center: "103.258679,23.713832",
                        level: 3
                    },
                    {
                        id: "532525",
                        name: "石屏县",
                        center: "102.484469,23.712569",
                        level: 3
                    },
                    {
                        id: "532501",
                        name: "个旧市",
                        center: "103.154752,23.360383",
                        level: 3
                    },
                    {
                        id: "532523",
                        name: "屏边苗族自治县",
                        center: "103.687229,22.987013",
                        level: 3
                    },
                    {
                        id: "532503",
                        name: "蒙自市",
                        center: "103.385005,23.366843",
                        level: 3
                    },
                    {
                        id: "532529",
                        name: "红河县",
                        center: "102.42121,23.369191",
                        level: 3
                    },
                    {
                        id: "532528",
                        name: "元阳县",
                        center: "102.837056,23.219773",
                        level: 3
                    },
                    {
                        id: "532531",
                        name: "绿春县",
                        center: "102.39286,22.99352",
                        level: 3
                    },
                    {
                        id: "532530",
                        name: "金平苗族瑶族傣族自治县",
                        center: "103.228359,22.779982",
                        level: 3
                    },
                    {
                        id: "532532",
                        name: "河口瑶族自治县",
                        center: "103.961593,22.507563",
                        level: 3
                    }
                ]
            },
            {
                id: "530300",
                name: "曲靖市",
                center: "103.797851,25.501557",
                level: 2,
                children: [
                    {
                        id: "530326",
                        name: "会泽县",
                        center: "103.300041,26.412861",
                        level: 3
                    },
                    {
                        id: "530304",
                        name: "马龙区",
                        center: "103.578755,25.429451",
                        level: 3
                    },
                    {
                        id: "530324",
                        name: "罗平县",
                        center: "104.309263,24.885708",
                        level: 3
                    },
                    {
                        id: "530322",
                        name: "陆良县",
                        center: "103.655233,25.022878",
                        level: 3
                    },
                    {
                        id: "530323",
                        name: "师宗县",
                        center: "103.993808,24.825681",
                        level: 3
                    },
                    {
                        id: "530325",
                        name: "富源县",
                        center: "104.25692,25.67064",
                        level: 3
                    },
                    {
                        id: "530381",
                        name: "宣威市",
                        center: "104.09554,26.227777",
                        level: 3
                    },
                    {
                        id: "530303",
                        name: "沾益区",
                        center: "103.819262,25.600878",
                        level: 3
                    },
                    {
                        id: "530302",
                        name: "麒麟区",
                        center: "103.798054,25.501269",
                        level: 3
                    }
                ]
            },
            {
                id: "533300",
                name: "怒江傈僳族自治州",
                center: "98.854304,25.850949",
                level: 2,
                children: [
                    {
                        id: "533324",
                        name: "贡山独龙族怒族自治县",
                        center: "98.666141,27.738054",
                        level: 3
                    },
                    {
                        id: "533323",
                        name: "福贡县",
                        center: "98.867413,26.902738",
                        level: 3
                    },
                    {
                        id: "533325",
                        name: "兰坪白族普米族自治县",
                        center: "99.421378,26.453839",
                        level: 3
                    },
                    {
                        id: "533301",
                        name: "泸水市",
                        center: "98.854063,25.851142",
                        level: 3
                    }
                ]
            },
            {
                id: "530600",
                name: "昭通市",
                center: "103.717216,27.336999",
                level: 2,
                children: [
                    {
                        id: "530629",
                        name: "威信县",
                        center: "105.04869,27.843381",
                        level: 3
                    },
                    {
                        id: "530626",
                        name: "绥江县",
                        center: "103.961095,28.599953",
                        level: 3
                    },
                    {
                        id: "530681",
                        name: "水富市",
                        center: "104.415376,28.629688",
                        level: 3
                    },
                    {
                        id: "530624",
                        name: "大关县",
                        center: "103.891608,27.747114",
                        level: 3
                    },
                    {
                        id: "530625",
                        name: "永善县",
                        center: "103.63732,28.231526",
                        level: 3
                    },
                    {
                        id: "530622",
                        name: "巧家县",
                        center: "102.929284,26.9117",
                        level: 3
                    },
                    {
                        id: "530621",
                        name: "鲁甸县",
                        center: "103.549333,27.191637",
                        level: 3
                    },
                    {
                        id: "530602",
                        name: "昭阳区",
                        center: "103.717267,27.336636",
                        level: 3
                    },
                    {
                        id: "530627",
                        name: "镇雄县",
                        center: "104.873055,27.436267",
                        level: 3
                    },
                    {
                        id: "530628",
                        name: "彝良县",
                        center: "104.048492,27.627425",
                        level: 3
                    },
                    {
                        id: "530623",
                        name: "盐津县",
                        center: "104.23506,28.106923",
                        level: 3
                    }
                ]
            },
            {
                id: "532800",
                name: "西双版纳傣族自治州",
                center: "100.797941,22.001724",
                level: 2,
                children: [
                    {
                        id: "532822",
                        name: "勐海县",
                        center: "100.448288,21.955866",
                        level: 3
                    },
                    {
                        id: "532823",
                        name: "勐腊县",
                        center: "101.567051,21.479449",
                        level: 3
                    },
                    {
                        id: "532801",
                        name: "景洪市",
                        center: "100.797947,22.002087",
                        level: 3
                    }
                ]
            },
            {
                id: "532300",
                name: "楚雄彝族自治州",
                center: "101.546046,25.041988",
                level: 2,
                children: [
                    {
                        id: "532326",
                        name: "大姚县",
                        center: "101.323602,25.722348",
                        level: 3
                    },
                    {
                        id: "532328",
                        name: "元谋县",
                        center: "101.870837,25.703313",
                        level: 3
                    },
                    {
                        id: "532325",
                        name: "姚安县",
                        center: "101.238399,25.505403",
                        level: 3
                    },
                    {
                        id: "532323",
                        name: "牟定县",
                        center: "101.543044,25.312111",
                        level: 3
                    },
                    {
                        id: "532331",
                        name: "禄丰县",
                        center: "102.075694,25.14327",
                        level: 3
                    },
                    {
                        id: "532324",
                        name: "南华县",
                        center: "101.274991,25.192408",
                        level: 3
                    },
                    {
                        id: "532301",
                        name: "楚雄市",
                        center: "101.546145,25.040912",
                        level: 3
                    },
                    {
                        id: "532322",
                        name: "双柏县",
                        center: "101.63824,24.685094",
                        level: 3
                    },
                    {
                        id: "532329",
                        name: "武定县",
                        center: "102.406785,25.5301",
                        level: 3
                    },
                    {
                        id: "532327",
                        name: "永仁县",
                        center: "101.671175,26.056316",
                        level: 3
                    }
                ]
            },
            {
                id: "530800",
                name: "普洱市",
                center: "100.972344,22.777321",
                level: 2,
                children: [
                    {
                        id: "530823",
                        name: "景东彝族自治县",
                        center: "100.840011,24.448523",
                        level: 3
                    },
                    {
                        id: "530825",
                        name: "镇沅彝族哈尼族拉祜族自治县",
                        center: "101.108512,24.005712",
                        level: 3
                    },
                    {
                        id: "530822",
                        name: "墨江哈尼族自治县",
                        center: "101.687606,23.428165",
                        level: 3
                    },
                    {
                        id: "530824",
                        name: "景谷傣族彝族自治县",
                        center: "100.701425,23.500278",
                        level: 3
                    },
                    {
                        id: "530802",
                        name: "思茅区",
                        center: "100.973227,22.776595",
                        level: 3
                    },
                    {
                        id: "530829",
                        name: "西盟佤族自治县",
                        center: "99.594372,22.644423",
                        level: 3
                    },
                    {
                        id: "530828",
                        name: "澜沧拉祜族自治县",
                        center: "99.931201,22.553083",
                        level: 3
                    },
                    {
                        id: "530821",
                        name: "宁洱哈尼族彝族自治县",
                        center: "101.04524,23.062507",
                        level: 3
                    },
                    {
                        id: "530826",
                        name: "江城哈尼族彝族自治县",
                        center: "101.859144,22.58336",
                        level: 3
                    },
                    {
                        id: "530827",
                        name: "孟连傣族拉祜族佤族自治县",
                        center: "99.585406,22.325924",
                        level: 3
                    }
                ]
            },
            {
                id: "530100",
                name: "昆明市",
                center: "102.712251,25.040609",
                level: 2,
                children: [
                    {
                        id: "530113",
                        name: "东川区",
                        center: "103.182,26.08349",
                        level: 3
                    },
                    {
                        id: "530129",
                        name: "寻甸回族彝族自治县",
                        center: "103.257588,25.559474",
                        level: 3
                    },
                    {
                        id: "530112",
                        name: "西山区",
                        center: "102.705904,25.02436",
                        level: 3
                    },
                    {
                        id: "530102",
                        name: "五华区",
                        center: "102.704412,25.042165",
                        level: 3
                    },
                    {
                        id: "530125",
                        name: "宜良县",
                        center: "103.145989,24.918215",
                        level: 3
                    },
                    {
                        id: "530114",
                        name: "呈贡区",
                        center: "102.801382,24.889275",
                        level: 3
                    },
                    {
                        id: "530126",
                        name: "石林彝族自治县",
                        center: "103.271962,24.754545",
                        level: 3
                    },
                    {
                        id: "530115",
                        name: "晋宁区",
                        center: "102.594987,24.666944",
                        level: 3
                    },
                    {
                        id: "530181",
                        name: "安宁市",
                        center: "102.485544,24.921785",
                        level: 3
                    },
                    {
                        id: "530124",
                        name: "富民县",
                        center: "102.497888,25.219667",
                        level: 3
                    },
                    {
                        id: "530128",
                        name: "禄劝彝族苗族自治县",
                        center: "102.46905,25.556533",
                        level: 3
                    },
                    {
                        id: "530111",
                        name: "官渡区",
                        center: "102.723437,25.021211",
                        level: 3
                    },
                    {
                        id: "530103",
                        name: "盘龙区",
                        center: "102.729044,25.070239",
                        level: 3
                    },
                    {
                        id: "530127",
                        name: "嵩明县",
                        center: "103.038777,25.335087",
                        level: 3
                    }
                ]
            },
            {
                id: "530700",
                name: "丽江市",
                center: "100.233026,26.872108",
                level: 2,
                children: [
                    {
                        id: "530702",
                        name: "古城区",
                        center: "100.234412,26.872229",
                        level: 3
                    },
                    {
                        id: "530721",
                        name: "玉龙纳西族自治县",
                        center: "100.238312,26.830593",
                        level: 3
                    },
                    {
                        id: "530724",
                        name: "宁蒗彝族自治县",
                        center: "100.852427,27.281109",
                        level: 3
                    },
                    {
                        id: "530723",
                        name: "华坪县",
                        center: "101.267796,26.628834",
                        level: 3
                    },
                    {
                        id: "530722",
                        name: "永胜县",
                        center: "100.750901,26.685623",
                        level: 3
                    }
                ]
            },
            {
                id: "532600",
                name: "文山壮族苗族自治州",
                center: "104.24401,23.36951",
                level: 2,
                children: [
                    {
                        id: "532627",
                        name: "广南县",
                        center: "105.056684,24.050272",
                        level: 3
                    },
                    {
                        id: "532626",
                        name: "丘北县",
                        center: "104.194366,24.040982",
                        level: 3
                    },
                    {
                        id: "532601",
                        name: "文山市",
                        center: "104.244277,23.369216",
                        level: 3
                    },
                    {
                        id: "532622",
                        name: "砚山县",
                        center: "104.343989,23.612301",
                        level: 3
                    },
                    {
                        id: "532623",
                        name: "西畴县",
                        center: "104.675711,23.437439",
                        level: 3
                    },
                    {
                        id: "532624",
                        name: "麻栗坡县",
                        center: "104.701899,23.124202",
                        level: 3
                    },
                    {
                        id: "532625",
                        name: "马关县",
                        center: "104.398619,23.011723",
                        level: 3
                    },
                    {
                        id: "532628",
                        name: "富宁县",
                        center: "105.62856,23.626494",
                        level: 3
                    }
                ]
            },
            {
                id: "532900",
                name: "大理白族自治州",
                center: "100.225668,25.589449",
                level: 2,
                children: [
                    {
                        id: "532932",
                        name: "鹤庆县",
                        center: "100.173375,26.55839",
                        level: 3
                    },
                    {
                        id: "532929",
                        name: "云龙县",
                        center: "99.369402,25.884955",
                        level: 3
                    },
                    {
                        id: "532931",
                        name: "剑川县",
                        center: "99.905887,26.530066",
                        level: 3
                    },
                    {
                        id: "532930",
                        name: "洱源县",
                        center: "99.951708,26.111184",
                        level: 3
                    },
                    {
                        id: "532901",
                        name: "大理市",
                        center: "100.241369,25.593067",
                        level: 3
                    },
                    {
                        id: "532924",
                        name: "宾川县",
                        center: "100.578957,25.825904",
                        level: 3
                    },
                    {
                        id: "532922",
                        name: "漾濞彝族自治县",
                        center: "99.95797,25.669543",
                        level: 3
                    },
                    {
                        id: "532923",
                        name: "祥云县",
                        center: "100.554025,25.477072",
                        level: 3
                    },
                    {
                        id: "532927",
                        name: "巍山彝族回族自治县",
                        center: "100.30793,25.230909",
                        level: 3
                    },
                    {
                        id: "532928",
                        name: "永平县",
                        center: "99.533536,25.461281",
                        level: 3
                    },
                    {
                        id: "532925",
                        name: "弥渡县",
                        center: "100.490669,25.342594",
                        level: 3
                    },
                    {
                        id: "532926",
                        name: "南涧彝族自治县",
                        center: "100.518683,25.041279",
                        level: 3
                    }
                ]
            },
            {
                id: "530400",
                name: "玉溪市",
                center: "102.543907,24.350461",
                level: 2,
                children: [
                    {
                        id: "530425",
                        name: "易门县",
                        center: "102.16211,24.669598",
                        level: 3
                    },
                    {
                        id: "530422",
                        name: "澄江县",
                        center: "102.916652,24.669679",
                        level: 3
                    },
                    {
                        id: "530402",
                        name: "红塔区",
                        center: "102.543468,24.350753",
                        level: 3
                    },
                    {
                        id: "530424",
                        name: "华宁县",
                        center: "102.928982,24.189807",
                        level: 3
                    },
                    {
                        id: "530426",
                        name: "峨山彝族自治县",
                        center: "102.404358,24.173256",
                        level: 3
                    },
                    {
                        id: "530403",
                        name: "江川区",
                        center: "102.749839,24.291006",
                        level: 3
                    },
                    {
                        id: "530427",
                        name: "新平彝族傣族自治县",
                        center: "101.990903,24.0664",
                        level: 3
                    },
                    {
                        id: "530423",
                        name: "通海县",
                        center: "102.760039,24.112205",
                        level: 3
                    },
                    {
                        id: "530428",
                        name: "元江哈尼族彝族傣族自治县",
                        center: "101.999658,23.597618",
                        level: 3
                    }
                ]
            },
            {
                id: "533400",
                name: "迪庆藏族自治州",
                center: "99.706463,27.826853",
                level: 2,
                children: [
                    {
                        id: "533401",
                        name: "香格里拉市",
                        center: "99.708667,27.825804",
                        level: 3
                    },
                    {
                        id: "533422",
                        name: "德钦县",
                        center: "98.91506,28.483272",
                        level: 3
                    },
                    {
                        id: "533423",
                        name: "维西傈僳族自治县",
                        center: "99.286355,27.180948",
                        level: 3
                    }
                ]
            },
            {
                id: "530500",
                name: "保山市",
                center: "99.167133,25.111802",
                level: 2,
                children: [
                    {
                        id: "530581",
                        name: "腾冲市",
                        center: "98.497292,25.01757",
                        level: 3
                    },
                    {
                        id: "530502",
                        name: "隆阳区",
                        center: "99.165825,25.112144",
                        level: 3
                    },
                    {
                        id: "530524",
                        name: "昌宁县",
                        center: "99.612344,24.823662",
                        level: 3
                    },
                    {
                        id: "530521",
                        name: "施甸县",
                        center: "99.183758,24.730847",
                        level: 3
                    },
                    {
                        id: "530523",
                        name: "龙陵县",
                        center: "98.693567,24.591912",
                        level: 3
                    }
                ]
            },
            {
                id: "530900",
                name: "临沧市",
                center: "100.08697,23.886567",
                level: 2,
                children: [
                    {
                        id: "530921",
                        name: "凤庆县",
                        center: "99.91871,24.592738",
                        level: 3
                    },
                    {
                        id: "530922",
                        name: "云县",
                        center: "100.125637,24.439026",
                        level: 3
                    },
                    {
                        id: "530924",
                        name: "镇康县",
                        center: "98.82743,23.761415",
                        level: 3
                    },
                    {
                        id: "530923",
                        name: "永德县",
                        center: "99.253679,24.028159",
                        level: 3
                    },
                    {
                        id: "530902",
                        name: "临翔区",
                        center: "100.086486,23.886562",
                        level: 3
                    },
                    {
                        id: "530926",
                        name: "耿马傣族佤族自治县",
                        center: "99.402495,23.534579",
                        level: 3
                    },
                    {
                        id: "530925",
                        name: "双江拉祜族佤族布朗族傣族自治县",
                        center: "99.824419,23.477476",
                        level: 3
                    },
                    {
                        id: "530927",
                        name: "沧源佤族自治县",
                        center: "99.2474,23.146887",
                        level: 3
                    }
                ]
            },
            {
                id: "533100",
                name: "德宏傣族景颇族自治州",
                center: "98.578363,24.436694",
                level: 2,
                children: [
                    {
                        id: "533123",
                        name: "盈江县",
                        center: "97.93393,24.709541",
                        level: 3
                    },
                    {
                        id: "533122",
                        name: "梁河县",
                        center: "98.298196,24.80742",
                        level: 3
                    },
                    {
                        id: "533124",
                        name: "陇川县",
                        center: "97.794441,24.184065",
                        level: 3
                    },
                    {
                        id: "533103",
                        name: "芒市",
                        center: "98.577608,24.436699",
                        level: 3
                    },
                    {
                        id: "533102",
                        name: "瑞丽市",
                        center: "97.855883,24.010734",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "540000",
        name: "西藏自治区",
        center: "91.132212,29.660361",
        level: 1,
        children: [
            {
                id: "542500",
                name: "阿里地区",
                center: "80.105498,32.503187",
                level: 2,
                children: [
                    {
                        id: "542526",
                        name: "改则县",
                        center: "84.062384,32.302076",
                        level: 3
                    },
                    {
                        id: "542522",
                        name: "札达县",
                        center: "79.803191,31.478587",
                        level: 3
                    },
                    {
                        id: "542527",
                        name: "措勤县",
                        center: "85.159254,31.016774",
                        level: 3
                    },
                    {
                        id: "542521",
                        name: "普兰县",
                        center: "81.177588,30.291896",
                        level: 3
                    },
                    {
                        id: "542524",
                        name: "日土县",
                        center: "79.731937,33.382454",
                        level: 3
                    },
                    {
                        id: "542523",
                        name: "噶尔县",
                        center: "80.105005,32.503373",
                        level: 3
                    },
                    {
                        id: "542525",
                        name: "革吉县",
                        center: "81.142896,32.389192",
                        level: 3
                    }
                ]
            },
            {
                id: "540400",
                name: "林芝市",
                center: "94.362348,29.654693",
                level: 2,
                children: [
                    {
                        id: "540421",
                        name: "工布江达县",
                        center: "93.246515,29.88447",
                        level: 3
                    },
                    {
                        id: "540424",
                        name: "波密县",
                        center: "95.768151,29.858771",
                        level: 3
                    },
                    {
                        id: "540402",
                        name: "巴宜区",
                        center: "94.360987,29.653732",
                        level: 3
                    },
                    {
                        id: "540423",
                        name: "墨脱县",
                        center: "95.332245,29.32573",
                        level: 3
                    },
                    {
                        id: "540422",
                        name: "米林县",
                        center: "94.213679,29.213811",
                        level: 3
                    },
                    {
                        id: "540425",
                        name: "察隅县",
                        center: "97.465002,28.660244",
                        level: 3
                    },
                    {
                        id: "540426",
                        name: "朗县",
                        center: "93.073429,29.0446",
                        level: 3
                    }
                ]
            },
            {
                id: "540600",
                name: "那曲市",
                center: "92.060214,31.476004",
                level: 2,
                children: [
                    {
                        id: "540624",
                        name: "安多县",
                        center: "91.681879,32.260299",
                        level: 3
                    },
                    {
                        id: "540628",
                        name: "巴青县",
                        center: "94.054049,31.918691",
                        level: 3
                    },
                    {
                        id: "540627",
                        name: "班戈县",
                        center: "90.011822,31.394578",
                        level: 3
                    },
                    {
                        id: "540623",
                        name: "聂荣县",
                        center: "92.303659,32.107855",
                        level: 3
                    },
                    {
                        id: "540622",
                        name: "比如县",
                        center: "93.68044,31.479917",
                        level: 3
                    },
                    {
                        id: "540625",
                        name: "申扎县",
                        center: "88.709777,30.929056",
                        level: 3
                    },
                    {
                        id: "540621",
                        name: "嘉黎县",
                        center: "93.232907,30.640846",
                        level: 3
                    },
                    {
                        id: "540602",
                        name: "色尼区",
                        center: "92.061862,31.475756",
                        level: 3
                    },
                    {
                        id: "540626",
                        name: "索县",
                        center: "93.784964,31.886173",
                        level: 3
                    },
                    {
                        id: "540629",
                        name: "尼玛县",
                        center: "87.236646,31.784979",
                        level: 3
                    },
                    {
                        id: "540630",
                        name: "双湖县",
                        center: "88.838578,33.18698",
                        level: 3
                    }
                ]
            },
            {
                id: "540500",
                name: "山南市",
                center: "91.766529,29.236023",
                level: 2,
                children: [
                    {
                        id: "540525",
                        name: "曲松县",
                        center: "92.201066,29.063656",
                        level: 3
                    },
                    {
                        id: "540528",
                        name: "加查县",
                        center: "92.591043,29.140921",
                        level: 3
                    },
                    {
                        id: "540531",
                        name: "浪卡子县",
                        center: "90.398747,28.96836",
                        level: 3
                    },
                    {
                        id: "540523",
                        name: "桑日县",
                        center: "92.015732,29.259774",
                        level: 3
                    },
                    {
                        id: "540521",
                        name: "扎囊县",
                        center: "91.338,29.246476",
                        level: 3
                    },
                    {
                        id: "540502",
                        name: "乃东区",
                        center: "91.76525,29.236106",
                        level: 3
                    },
                    {
                        id: "540522",
                        name: "贡嘎县",
                        center: "90.985271,29.289078",
                        level: 3
                    },
                    {
                        id: "540524",
                        name: "琼结县",
                        center: "91.683753,29.025242",
                        level: 3
                    },
                    {
                        id: "540527",
                        name: "洛扎县",
                        center: "90.858243,28.385765",
                        level: 3
                    },
                    {
                        id: "540530",
                        name: "错那县",
                        center: "91.960132,27.991707",
                        level: 3
                    },
                    {
                        id: "540526",
                        name: "措美县",
                        center: "91.432347,28.437353",
                        level: 3
                    },
                    {
                        id: "540529",
                        name: "隆子县",
                        center: "92.463309,28.408548",
                        level: 3
                    }
                ]
            },
            {
                id: "540100",
                name: "拉萨市",
                center: "91.132212,29.660361",
                level: 2,
                children: [
                    {
                        id: "540122",
                        name: "当雄县",
                        center: "91.103551,30.474819",
                        level: 3
                    },
                    {
                        id: "540121",
                        name: "林周县",
                        center: "91.261842,29.895754",
                        level: 3
                    },
                    {
                        id: "540103",
                        name: "堆龙德庆区",
                        center: "91.002823,29.647347",
                        level: 3
                    },
                    {
                        id: "540104",
                        name: "达孜区",
                        center: "91.350976,29.670314",
                        level: 3
                    },
                    {
                        id: "540127",
                        name: "墨竹工卡县",
                        center: "91.731158,29.834657",
                        level: 3
                    },
                    {
                        id: "540123",
                        name: "尼木县",
                        center: "90.165545,29.431346",
                        level: 3
                    },
                    {
                        id: "540102",
                        name: "城关区",
                        center: "91.132911,29.659472",
                        level: 3
                    },
                    {
                        id: "540124",
                        name: "曲水县",
                        center: "90.738051,29.349895",
                        level: 3
                    }
                ]
            },
            {
                id: "540300",
                name: "昌都市",
                center: "97.178452,31.136875",
                level: 2,
                children: [
                    {
                        id: "540321",
                        name: "江达县",
                        center: "98.218351,31.499534",
                        level: 3
                    },
                    {
                        id: "540324",
                        name: "丁青县",
                        center: "95.597748,31.410681",
                        level: 3
                    },
                    {
                        id: "540302",
                        name: "卡若区",
                        center: "97.178255,31.137035",
                        level: 3
                    },
                    {
                        id: "540323",
                        name: "类乌齐县",
                        center: "96.601259,31.213048",
                        level: 3
                    },
                    {
                        id: "540330",
                        name: "边坝县",
                        center: "94.707504,30.933849",
                        level: 3
                    },
                    {
                        id: "540322",
                        name: "贡觉县",
                        center: "98.271191,30.859206",
                        level: 3
                    },
                    {
                        id: "540325",
                        name: "察雅县",
                        center: "97.565701,30.653038",
                        level: 3
                    },
                    {
                        id: "540329",
                        name: "洛隆县",
                        center: "95.823418,30.741947",
                        level: 3
                    },
                    {
                        id: "540326",
                        name: "八宿县",
                        center: "96.917893,30.053408",
                        level: 3
                    },
                    {
                        id: "540327",
                        name: "左贡县",
                        center: "97.840532,29.671335",
                        level: 3
                    },
                    {
                        id: "540328",
                        name: "芒康县",
                        center: "98.596444,29.686615",
                        level: 3
                    }
                ]
            },
            {
                id: "540200",
                name: "日喀则市",
                center: "88.885148,29.267519",
                level: 2,
                children: [
                    {
                        id: "540232",
                        name: "仲巴县",
                        center: "84.032826,29.768336",
                        level: 3
                    },
                    {
                        id: "540226",
                        name: "昂仁县",
                        center: "87.23578,29.294758",
                        level: 3
                    },
                    {
                        id: "540227",
                        name: "谢通门县",
                        center: "88.260517,29.431597",
                        level: 3
                    },
                    {
                        id: "540221",
                        name: "南木林县",
                        center: "89.099434,29.680459",
                        level: 3
                    },
                    {
                        id: "540236",
                        name: "萨嘎县",
                        center: "85.234622,29.328194",
                        level: 3
                    },
                    {
                        id: "540234",
                        name: "吉隆县",
                        center: "85.298349,28.852416",
                        level: 3
                    },
                    {
                        id: "540229",
                        name: "仁布县",
                        center: "89.843207,29.230299",
                        level: 3
                    },
                    {
                        id: "540224",
                        name: "萨迦县",
                        center: "88.023007,28.901077",
                        level: 3
                    },
                    {
                        id: "540228",
                        name: "白朗县",
                        center: "89.263618,29.106627",
                        level: 3
                    },
                    {
                        id: "540202",
                        name: "桑珠孜区",
                        center: "88.88667,29.267003",
                        level: 3
                    },
                    {
                        id: "540222",
                        name: "江孜县",
                        center: "89.605044,28.908845",
                        level: 3
                    },
                    {
                        id: "540225",
                        name: "拉孜县",
                        center: "87.63743,29.085136",
                        level: 3
                    },
                    {
                        id: "540230",
                        name: "康马县",
                        center: "89.683406,28.554719",
                        level: 3
                    },
                    {
                        id: "540235",
                        name: "聂拉木县",
                        center: "85.981953,28.15595",
                        level: 3
                    },
                    {
                        id: "540233",
                        name: "亚东县",
                        center: "88.906806,27.482772",
                        level: 3
                    },
                    {
                        id: "540223",
                        name: "定日县",
                        center: "87.123887,28.656667",
                        level: 3
                    },
                    {
                        id: "540231",
                        name: "定结县",
                        center: "87.767723,28.36409",
                        level: 3
                    },
                    {
                        id: "540237",
                        name: "岗巴县",
                        center: "88.518903,28.274371",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "610000",
        name: "陕西省",
        center: "108.948024,34.263161",
        level: 1,
        children: [
            {
                id: "611000",
                name: "商洛市",
                center: "109.939776,33.868319",
                level: 2,
                children: [
                    {
                        id: "611026",
                        name: "柞水县",
                        center: "109.111249,33.682773",
                        level: 3
                    },
                    {
                        id: "611022",
                        name: "丹凤县",
                        center: "110.33191,33.694711",
                        level: 3
                    },
                    {
                        id: "611024",
                        name: "山阳县",
                        center: "109.880435,33.530411",
                        level: 3
                    },
                    {
                        id: "611023",
                        name: "商南县",
                        center: "110.885437,33.526367",
                        level: 3
                    },
                    {
                        id: "611025",
                        name: "镇安县",
                        center: "109.151075,33.423981",
                        level: 3
                    },
                    {
                        id: "611021",
                        name: "洛南县",
                        center: "110.145716,34.088502",
                        level: 3
                    },
                    {
                        id: "611002",
                        name: "商州区",
                        center: "109.937685,33.869208",
                        level: 3
                    }
                ]
            },
            {
                id: "610100",
                name: "西安市",
                center: "108.948024,34.263161",
                level: 2,
                children: [
                    {
                        id: "610115",
                        name: "临潼区",
                        center: "109.213986,34.372065",
                        level: 3
                    },
                    {
                        id: "610114",
                        name: "阎良区",
                        center: "109.22802,34.662141",
                        level: 3
                    },
                    {
                        id: "610117",
                        name: "高陵区",
                        center: "109.088896,34.535065",
                        level: 3
                    },
                    {
                        id: "610118",
                        name: "鄠邑区",
                        center: "108.607385,34.108668",
                        level: 3
                    },
                    {
                        id: "610122",
                        name: "蓝田县",
                        center: "109.317634,34.156189",
                        level: 3
                    },
                    {
                        id: "610116",
                        name: "长安区",
                        center: "108.941579,34.157097",
                        level: 3
                    },
                    {
                        id: "610112",
                        name: "未央区",
                        center: "108.946022,34.30823",
                        level: 3
                    },
                    {
                        id: "610104",
                        name: "莲湖区",
                        center: "108.933194,34.2656",
                        level: 3
                    },
                    {
                        id: "610111",
                        name: "灞桥区",
                        center: "109.067261,34.267453",
                        level: 3
                    },
                    {
                        id: "610102",
                        name: "新城区",
                        center: "108.959903,34.26927",
                        level: 3
                    },
                    {
                        id: "610103",
                        name: "碑林区",
                        center: "108.946994,34.251061",
                        level: 3
                    },
                    {
                        id: "610124",
                        name: "周至县",
                        center: "108.216465,34.161532",
                        level: 3
                    },
                    {
                        id: "610113",
                        name: "雁塔区",
                        center: "108.926593,34.213389",
                        level: 3
                    }
                ]
            },
            {
                id: "610900",
                name: "安康市",
                center: "109.029273,32.6903",
                level: 2,
                children: [
                    {
                        id: "610923",
                        name: "宁陕县",
                        center: "108.313714,33.312184",
                        level: 3
                    },
                    {
                        id: "610922",
                        name: "石泉县",
                        center: "108.250512,33.038512",
                        level: 3
                    },
                    {
                        id: "610902",
                        name: "汉滨区",
                        center: "109.029098,32.690817",
                        level: 3
                    },
                    {
                        id: "610927",
                        name: "镇坪县",
                        center: "109.526437,31.883395",
                        level: 3
                    },
                    {
                        id: "610925",
                        name: "岚皋县",
                        center: "108.900663,32.31069",
                        level: 3
                    },
                    {
                        id: "610921",
                        name: "汉阴县",
                        center: "108.510946,32.891121",
                        level: 3
                    },
                    {
                        id: "610924",
                        name: "紫阳县",
                        center: "108.537788,32.520176",
                        level: 3
                    },
                    {
                        id: "610928",
                        name: "旬阳县",
                        center: "109.368149,32.833567",
                        level: 3
                    },
                    {
                        id: "610926",
                        name: "平利县",
                        center: "109.361865,32.387933",
                        level: 3
                    },
                    {
                        id: "610929",
                        name: "白河县",
                        center: "110.114186,32.809484",
                        level: 3
                    }
                ]
            },
            {
                id: "610700",
                name: "汉中市",
                center: "107.028621,33.077668",
                level: 2,
                children: [
                    {
                        id: "610729",
                        name: "留坝县",
                        center: "106.924377,33.61334",
                        level: 3
                    },
                    {
                        id: "610730",
                        name: "佛坪县",
                        center: "107.988582,33.520745",
                        level: 3
                    },
                    {
                        id: "610727",
                        name: "略阳县",
                        center: "106.153899,33.329638",
                        level: 3
                    },
                    {
                        id: "610723",
                        name: "洋县",
                        center: "107.549962,33.223283",
                        level: 3
                    },
                    {
                        id: "610724",
                        name: "西乡县",
                        center: "107.765858,32.987961",
                        level: 3
                    },
                    {
                        id: "610728",
                        name: "镇巴县",
                        center: "107.89531,32.535854",
                        level: 3
                    },
                    {
                        id: "610726",
                        name: "宁强县",
                        center: "106.25739,32.830806",
                        level: 3
                    },
                    {
                        id: "610725",
                        name: "勉县",
                        center: "106.680175,33.155618",
                        level: 3
                    },
                    {
                        id: "610703",
                        name: "南郑区",
                        center: "106.942393,33.003341",
                        level: 3
                    },
                    {
                        id: "610702",
                        name: "汉台区",
                        center: "107.028233,33.077674",
                        level: 3
                    },
                    {
                        id: "610722",
                        name: "城固县",
                        center: "107.329887,33.153098",
                        level: 3
                    }
                ]
            },
            {
                id: "610200",
                name: "铜川市",
                center: "108.979608,34.916582",
                level: 2,
                children: [
                    {
                        id: "610222",
                        name: "宜君县",
                        center: "109.118278,35.398766",
                        level: 3
                    },
                    {
                        id: "610203",
                        name: "印台区",
                        center: "109.100814,35.111927",
                        level: 3
                    },
                    {
                        id: "610202",
                        name: "王益区",
                        center: "109.075862,35.069098",
                        level: 3
                    },
                    {
                        id: "610204",
                        name: "耀州区",
                        center: "108.962538,34.910206",
                        level: 3
                    }
                ]
            },
            {
                id: "610800",
                name: "榆林市",
                center: "109.741193,38.290162",
                level: 2,
                children: [
                    {
                        id: "610822",
                        name: "府谷县",
                        center: "111.069645,39.029243",
                        level: 3
                    },
                    {
                        id: "610802",
                        name: "榆阳区",
                        center: "109.74791,38.299267",
                        level: 3
                    },
                    {
                        id: "610881",
                        name: "神木市",
                        center: "110.497005,38.835641",
                        level: 3
                    },
                    {
                        id: "610828",
                        name: "佳县",
                        center: "110.493367,38.021597",
                        level: 3
                    },
                    {
                        id: "610827",
                        name: "米脂县",
                        center: "110.178683,37.759081",
                        level: 3
                    },
                    {
                        id: "610803",
                        name: "横山区",
                        center: "109.292596,37.964048",
                        level: 3
                    },
                    {
                        id: "610831",
                        name: "子洲县",
                        center: "110.03457,37.611573",
                        level: 3
                    },
                    {
                        id: "610826",
                        name: "绥德县",
                        center: "110.265377,37.507701",
                        level: 3
                    },
                    {
                        id: "610829",
                        name: "吴堡县",
                        center: "110.739315,37.451925",
                        level: 3
                    },
                    {
                        id: "610830",
                        name: "清涧县",
                        center: "110.12146,37.087702",
                        level: 3
                    },
                    {
                        id: "610824",
                        name: "靖边县",
                        center: "108.80567,37.596084",
                        level: 3
                    },
                    {
                        id: "610825",
                        name: "定边县",
                        center: "107.601284,37.59523",
                        level: 3
                    }
                ]
            },
            {
                id: "610400",
                name: "咸阳市",
                center: "108.705117,34.333439",
                level: 2,
                children: [
                    {
                        id: "610424",
                        name: "乾县",
                        center: "108.247406,34.527261",
                        level: 3
                    },
                    {
                        id: "610482",
                        name: "彬州市",
                        center: "108.083674,35.034233",
                        level: 3
                    },
                    {
                        id: "610429",
                        name: "旬邑县",
                        center: "108.337231,35.112234",
                        level: 3
                    },
                    {
                        id: "610426",
                        name: "永寿县",
                        center: "108.143129,34.692619",
                        level: 3
                    },
                    {
                        id: "610430",
                        name: "淳化县",
                        center: "108.581173,34.79797",
                        level: 3
                    },
                    {
                        id: "610404",
                        name: "渭城区",
                        center: "108.730957,34.336847",
                        level: 3
                    },
                    {
                        id: "610431",
                        name: "武功县",
                        center: "108.212857,34.259732",
                        level: 3
                    },
                    {
                        id: "610423",
                        name: "泾阳县",
                        center: "108.83784,34.528493",
                        level: 3
                    },
                    {
                        id: "610428",
                        name: "长武县",
                        center: "107.795835,35.206122",
                        level: 3
                    },
                    {
                        id: "610402",
                        name: "秦都区",
                        center: "108.698636,34.329801",
                        level: 3
                    },
                    {
                        id: "610425",
                        name: "礼泉县",
                        center: "108.428317,34.482583",
                        level: 3
                    },
                    {
                        id: "610403",
                        name: "杨陵区",
                        center: "108.086348,34.27135",
                        level: 3
                    },
                    {
                        id: "610481",
                        name: "兴平市",
                        center: "108.488493,34.297134",
                        level: 3
                    },
                    {
                        id: "610422",
                        name: "三原县",
                        center: "108.943481,34.613996",
                        level: 3
                    }
                ]
            },
            {
                id: "610600",
                name: "延安市",
                center: "109.49081,36.596537",
                level: 2,
                children: [
                    {
                        id: "610626",
                        name: "吴起县",
                        center: "108.176976,36.924852",
                        level: 3
                    },
                    {
                        id: "610623",
                        name: "子长市",
                        center: "109.675968,37.14207",
                        level: 3
                    },
                    {
                        id: "610603",
                        name: "安塞区",
                        center: "109.325341,36.86441",
                        level: 3
                    },
                    {
                        id: "610628",
                        name: "富县",
                        center: "109.384136,35.996495",
                        level: 3
                    },
                    {
                        id: "610627",
                        name: "甘泉县",
                        center: "109.34961,36.277729",
                        level: 3
                    },
                    {
                        id: "610630",
                        name: "宜川县",
                        center: "110.175537,36.050391",
                        level: 3
                    },
                    {
                        id: "610622",
                        name: "延川县",
                        center: "110.190314,36.882066",
                        level: 3
                    },
                    {
                        id: "610621",
                        name: "延长县",
                        center: "110.012961,36.578306",
                        level: 3
                    },
                    {
                        id: "610625",
                        name: "志丹县",
                        center: "108.768898,36.823031",
                        level: 3
                    },
                    {
                        id: "610632",
                        name: "黄陵县",
                        center: "109.262469,35.580165",
                        level: 3
                    },
                    {
                        id: "610602",
                        name: "宝塔区",
                        center: "109.49069,36.596291",
                        level: 3
                    },
                    {
                        id: "610629",
                        name: "洛川县",
                        center: "109.435712,35.762133",
                        level: 3
                    },
                    {
                        id: "610631",
                        name: "黄龙县",
                        center: "109.83502,35.583276",
                        level: 3
                    }
                ]
            },
            {
                id: "610500",
                name: "渭南市",
                center: "109.502882,34.499381",
                level: 2,
                children: [
                    {
                        id: "610581",
                        name: "韩城市",
                        center: "110.452391,35.475238",
                        level: 3
                    },
                    {
                        id: "610527",
                        name: "白水县",
                        center: "109.594309,35.177291",
                        level: 3
                    },
                    {
                        id: "610503",
                        name: "华州区",
                        center: "109.76141,34.511958",
                        level: 3
                    },
                    {
                        id: "610523",
                        name: "大荔县",
                        center: "109.943123,34.795011",
                        level: 3
                    },
                    {
                        id: "610582",
                        name: "华阴市",
                        center: "110.08952,34.565359",
                        level: 3
                    },
                    {
                        id: "610522",
                        name: "潼关县",
                        center: "110.24726,34.544515",
                        level: 3
                    },
                    {
                        id: "610525",
                        name: "澄城县",
                        center: "109.937609,35.184",
                        level: 3
                    },
                    {
                        id: "610502",
                        name: "临渭区",
                        center: "109.503299,34.501271",
                        level: 3
                    },
                    {
                        id: "610526",
                        name: "蒲城县",
                        center: "109.589653,34.956034",
                        level: 3
                    },
                    {
                        id: "610528",
                        name: "富平县",
                        center: "109.187174,34.746679",
                        level: 3
                    },
                    {
                        id: "610524",
                        name: "合阳县",
                        center: "110.147979,35.237098",
                        level: 3
                    }
                ]
            },
            {
                id: "610300",
                name: "宝鸡市",
                center: "107.14487,34.369315",
                level: 2,
                children: [
                    {
                        id: "610328",
                        name: "千阳县",
                        center: "107.132987,34.642584",
                        level: 3
                    },
                    {
                        id: "610327",
                        name: "陇县",
                        center: "106.857066,34.893262",
                        level: 3
                    },
                    {
                        id: "610303",
                        name: "金台区",
                        center: "107.149943,34.375192",
                        level: 3
                    },
                    {
                        id: "610323",
                        name: "岐山县",
                        center: "107.624464,34.44296",
                        level: 3
                    },
                    {
                        id: "610329",
                        name: "麟游县",
                        center: "107.796608,34.677714",
                        level: 3
                    },
                    {
                        id: "610330",
                        name: "凤县",
                        center: "106.525212,33.912464",
                        level: 3
                    },
                    {
                        id: "610302",
                        name: "渭滨区",
                        center: "107.144467,34.371008",
                        level: 3
                    },
                    {
                        id: "610331",
                        name: "太白县",
                        center: "107.316533,34.059215",
                        level: 3
                    },
                    {
                        id: "610304",
                        name: "陈仓区",
                        center: "107.383645,34.352747",
                        level: 3
                    },
                    {
                        id: "610322",
                        name: "凤翔县",
                        center: "107.400577,34.521668",
                        level: 3
                    },
                    {
                        id: "610324",
                        name: "扶风县",
                        center: "107.891419,34.375497",
                        level: 3
                    },
                    {
                        id: "610326",
                        name: "眉县",
                        center: "107.752371,34.272137",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "620000",
        name: "甘肃省",
        center: "103.823557,36.058039",
        level: 1,
        children: [
            {
                id: "620900",
                name: "酒泉市",
                center: "98.510795,39.744023",
                level: 2,
                children: [
                    {
                        id: "620923",
                        name: "肃北蒙古族自治县",
                        center: "94.87728,39.51224",
                        level: 3
                    },
                    {
                        id: "620981",
                        name: "玉门市",
                        center: "97.037206,40.28682",
                        level: 3
                    },
                    {
                        id: "620921",
                        name: "金塔县",
                        center: "98.902959,39.983036",
                        level: 3
                    },
                    {
                        id: "620982",
                        name: "敦煌市",
                        center: "94.664279,40.141119",
                        level: 3
                    },
                    {
                        id: "620902",
                        name: "肃州区",
                        center: "98.511155,39.743858",
                        level: 3
                    },
                    {
                        id: "620922",
                        name: "瓜州县",
                        center: "95.780591,40.516525",
                        level: 3
                    },
                    {
                        id: "620924",
                        name: "阿克塞哈萨克族自治县",
                        center: "94.337642,39.631642",
                        level: 3
                    }
                ]
            },
            {
                id: "620200",
                name: "嘉峪关市",
                center: "98.277304,39.786529",
                level: 2,
                children: [
                    {
                        id: "620200100",
                        name: "新城镇",
                        center: "98.45154,39.87439",
                        level: 4
                    },
                    {
                        id: "620200101",
                        name: "峪泉镇",
                        center: "98.23137,39.80818",
                        level: 4
                    },
                    {
                        id: "620200102",
                        name: "文殊镇",
                        center: "98.37494,39.69432",
                        level: 4
                    },
                    {
                        id: "620200401",
                        name: "雄关区",
                        center: "98.257021,39.881029",
                        level: 4
                    },
                    {
                        id: "620200402",
                        name: "镜铁区",
                        center: "98.314534,39.757741",
                        level: 4
                    },
                    {
                        id: "620200403",
                        name: "长城区",
                        center: "98.290969,39.822944",
                        level: 4
                    }
                ]
            },
            {
                id: "620300",
                name: "金昌市",
                center: "102.187888,38.514238",
                level: 2,
                children: [
                    {
                        id: "620321",
                        name: "永昌县",
                        center: "101.971957,38.247354",
                        level: 3
                    },
                    {
                        id: "620302",
                        name: "金川区",
                        center: "102.187683,38.513793",
                        level: 3
                    }
                ]
            },
            {
                id: "620100",
                name: "兰州市",
                center: "103.823557,36.058039",
                level: 2,
                children: [
                    {
                        id: "620121",
                        name: "永登县",
                        center: "103.262203,36.734428",
                        level: 3
                    },
                    {
                        id: "620122",
                        name: "皋兰县",
                        center: "103.94933,36.331254",
                        level: 3
                    },
                    {
                        id: "620104",
                        name: "西固区",
                        center: "103.622331,36.100369",
                        level: 3
                    },
                    {
                        id: "620111",
                        name: "红古区",
                        center: "102.861814,36.344177",
                        level: 3
                    },
                    {
                        id: "620105",
                        name: "安宁区",
                        center: "103.724038,36.10329",
                        level: 3
                    },
                    {
                        id: "620103",
                        name: "七里河区",
                        center: "103.784326,36.06673",
                        level: 3
                    },
                    {
                        id: "620102",
                        name: "城关区",
                        center: "103.841032,36.049115",
                        level: 3
                    },
                    {
                        id: "620123",
                        name: "榆中县",
                        center: "104.114975,35.84443",
                        level: 3
                    }
                ]
            },
            {
                id: "620600",
                name: "武威市",
                center: "102.634697,37.929996",
                level: 2,
                children: [
                    {
                        id: "620602",
                        name: "凉州区",
                        center: "102.634492,37.93025",
                        level: 3
                    },
                    {
                        id: "620622",
                        name: "古浪县",
                        center: "102.898047,37.470571",
                        level: 3
                    },
                    {
                        id: "620623",
                        name: "天祝藏族自治县",
                        center: "103.142034,36.971678",
                        level: 3
                    },
                    {
                        id: "620621",
                        name: "民勤县",
                        center: "103.090654,38.624621",
                        level: 3
                    }
                ]
            },
            {
                id: "621200",
                name: "陇南市",
                center: "104.929379,33.388598",
                level: 2,
                children: [
                    {
                        id: "621228",
                        name: "两当县",
                        center: "106.306959,33.910729",
                        level: 3
                    },
                    {
                        id: "621221",
                        name: "成县",
                        center: "105.734434,33.739863",
                        level: 3
                    },
                    {
                        id: "621224",
                        name: "康县",
                        center: "105.609534,33.328266",
                        level: 3
                    },
                    {
                        id: "621226",
                        name: "礼县",
                        center: "105.181616,34.189387",
                        level: 3
                    },
                    {
                        id: "621223",
                        name: "宕昌县",
                        center: "104.394475,34.042655",
                        level: 3
                    },
                    {
                        id: "621222",
                        name: "文县",
                        center: "104.682448,32.942171",
                        level: 3
                    },
                    {
                        id: "621202",
                        name: "武都区",
                        center: "104.929866,33.388155",
                        level: 3
                    },
                    {
                        id: "621227",
                        name: "徽县",
                        center: "106.085632,33.767785",
                        level: 3
                    },
                    {
                        id: "621225",
                        name: "西和县",
                        center: "105.299737,34.013718",
                        level: 3
                    }
                ]
            },
            {
                id: "620500",
                name: "天水市",
                center: "105.724998,34.578529",
                level: 2,
                children: [
                    {
                        id: "620522",
                        name: "秦安县",
                        center: "105.6733,34.862354",
                        level: 3
                    },
                    {
                        id: "620524",
                        name: "武山县",
                        center: "104.891696,34.721955",
                        level: 3
                    },
                    {
                        id: "620502",
                        name: "秦州区",
                        center: "105.724477,34.578645",
                        level: 3
                    },
                    {
                        id: "620503",
                        name: "麦积区",
                        center: "105.897631,34.563504",
                        level: 3
                    },
                    {
                        id: "620523",
                        name: "甘谷县",
                        center: "105.332347,34.747327",
                        level: 3
                    },
                    {
                        id: "620521",
                        name: "清水县",
                        center: "106.139878,34.75287",
                        level: 3
                    },
                    {
                        id: "620525",
                        name: "张家川回族自治县",
                        center: "106.212416,34.993237",
                        level: 3
                    }
                ]
            },
            {
                id: "620400",
                name: "白银市",
                center: "104.173606,36.54568",
                level: 2,
                children: [
                    {
                        id: "620402",
                        name: "白银区",
                        center: "104.17425,36.545649",
                        level: 3
                    },
                    {
                        id: "620421",
                        name: "靖远县",
                        center: "104.686972,36.561424",
                        level: 3
                    },
                    {
                        id: "620403",
                        name: "平川区",
                        center: "104.819207,36.72921",
                        level: 3
                    },
                    {
                        id: "620422",
                        name: "会宁县",
                        center: "105.054337,35.692486",
                        level: 3
                    },
                    {
                        id: "620423",
                        name: "景泰县",
                        center: "104.066394,37.193519",
                        level: 3
                    }
                ]
            },
            {
                id: "620800",
                name: "平凉市",
                center: "106.684691,35.54279",
                level: 2,
                children: [
                    {
                        id: "620825",
                        name: "庄浪县",
                        center: "106.041979,35.203428",
                        level: 3
                    },
                    {
                        id: "620822",
                        name: "灵台县",
                        center: "107.620587,35.064009",
                        level: 3
                    },
                    {
                        id: "620802",
                        name: "崆峒区",
                        center: "106.684223,35.54173",
                        level: 3
                    },
                    {
                        id: "620823",
                        name: "崇信县",
                        center: "107.031253,35.304533",
                        level: 3
                    },
                    {
                        id: "620826",
                        name: "静宁县",
                        center: "105.733489,35.525243",
                        level: 3
                    },
                    {
                        id: "620881",
                        name: "华亭市",
                        center: "106.649308,35.215342",
                        level: 3
                    },
                    {
                        id: "620821",
                        name: "泾川县",
                        center: "107.365218,35.335283",
                        level: 3
                    }
                ]
            },
            {
                id: "622900",
                name: "临夏回族自治州",
                center: "103.212006,35.599446",
                level: 2,
                children: [
                    {
                        id: "622923",
                        name: "永靖县",
                        center: "103.319871,35.938933",
                        level: 3
                    },
                    {
                        id: "622926",
                        name: "东乡族自治县",
                        center: "103.389568,35.66383",
                        level: 3
                    },
                    {
                        id: "622901",
                        name: "临夏市",
                        center: "103.211634,35.59941",
                        level: 3
                    },
                    {
                        id: "622921",
                        name: "临夏县",
                        center: "102.993873,35.49236",
                        level: 3
                    },
                    {
                        id: "622927",
                        name: "积石山保安族东乡族撒拉族自治县",
                        center: "102.877473,35.712906",
                        level: 3
                    },
                    {
                        id: "622925",
                        name: "和政县",
                        center: "103.350357,35.425971",
                        level: 3
                    },
                    {
                        id: "622922",
                        name: "康乐县",
                        center: "103.709852,35.371906",
                        level: 3
                    },
                    {
                        id: "622924",
                        name: "广河县",
                        center: "103.576188,35.481688",
                        level: 3
                    }
                ]
            },
            {
                id: "623000",
                name: "甘南藏族自治州",
                center: "102.911008,34.986354",
                level: 2,
                children: [
                    {
                        id: "623001",
                        name: "合作市",
                        center: "102.91149,34.985973",
                        level: 3
                    },
                    {
                        id: "623027",
                        name: "夏河县",
                        center: "102.520743,35.200853",
                        level: 3
                    },
                    {
                        id: "623022",
                        name: "卓尼县",
                        center: "103.508508,34.588165",
                        level: 3
                    },
                    {
                        id: "623023",
                        name: "舟曲县",
                        center: "104.370271,33.782964",
                        level: 3
                    },
                    {
                        id: "623021",
                        name: "临潭县",
                        center: "103.353054,34.69164",
                        level: 3
                    },
                    {
                        id: "623025",
                        name: "玛曲县",
                        center: "102.075767,33.998068",
                        level: 3
                    },
                    {
                        id: "623026",
                        name: "碌曲县",
                        center: "102.488495,34.589591",
                        level: 3
                    },
                    {
                        id: "623024",
                        name: "迭部县",
                        center: "103.221009,34.055348",
                        level: 3
                    }
                ]
            },
            {
                id: "620700",
                name: "张掖市",
                center: "100.455472,38.932897",
                level: 2,
                children: [
                    {
                        id: "620724",
                        name: "高台县",
                        center: "99.81665,39.376308",
                        level: 3
                    },
                    {
                        id: "620721",
                        name: "肃南裕固族自治县",
                        center: "99.617086,38.837269",
                        level: 3
                    },
                    {
                        id: "620725",
                        name: "山丹县",
                        center: "101.088442,38.784839",
                        level: 3
                    },
                    {
                        id: "620722",
                        name: "民乐县",
                        center: "100.816623,38.434454",
                        level: 3
                    },
                    {
                        id: "620723",
                        name: "临泽县",
                        center: "100.166333,39.152151",
                        level: 3
                    },
                    {
                        id: "620702",
                        name: "甘州区",
                        center: "100.454862,38.931774",
                        level: 3
                    }
                ]
            },
            {
                id: "621000",
                name: "庆阳市",
                center: "107.638372,35.734218",
                level: 2,
                children: [
                    {
                        id: "621021",
                        name: "庆城县",
                        center: "107.885664,36.013504",
                        level: 3
                    },
                    {
                        id: "621024",
                        name: "合水县",
                        center: "108.019865,35.819005",
                        level: 3
                    },
                    {
                        id: "621025",
                        name: "正宁县",
                        center: "108.361068,35.490642",
                        level: 3
                    },
                    {
                        id: "621023",
                        name: "华池县",
                        center: "107.986288,36.457304",
                        level: 3
                    },
                    {
                        id: "621022",
                        name: "环县",
                        center: "107.308754,36.569322",
                        level: 3
                    },
                    {
                        id: "621027",
                        name: "镇原县",
                        center: "107.195706,35.677806",
                        level: 3
                    },
                    {
                        id: "621002",
                        name: "西峰区",
                        center: "107.638824,35.733713",
                        level: 3
                    },
                    {
                        id: "621026",
                        name: "宁县",
                        center: "107.921182,35.50201",
                        level: 3
                    }
                ]
            },
            {
                id: "621100",
                name: "定西市",
                center: "104.626294,35.579578",
                level: 2,
                children: [
                    {
                        id: "621124",
                        name: "临洮县",
                        center: "103.862186,35.376233",
                        level: 3
                    },
                    {
                        id: "621122",
                        name: "陇西县",
                        center: "104.637554,35.003409",
                        level: 3
                    },
                    {
                        id: "621123",
                        name: "渭源县",
                        center: "104.211742,35.133023",
                        level: 3
                    },
                    {
                        id: "621126",
                        name: "岷县",
                        center: "104.039882,34.439105",
                        level: 3
                    },
                    {
                        id: "621125",
                        name: "漳县",
                        center: "104.466756,34.848642",
                        level: 3
                    },
                    {
                        id: "621102",
                        name: "安定区",
                        center: "104.62577,35.579764",
                        level: 3
                    },
                    {
                        id: "621121",
                        name: "通渭县",
                        center: "105.250102,35.208922",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "630000",
        name: "青海省",
        center: "101.778916,36.623178",
        level: 1,
        children: [
            {
                id: "632500",
                name: "海南藏族自治州",
                center: "100.619542,36.280353",
                level: 2,
                children: [
                    {
                        id: "632521",
                        name: "共和县",
                        center: "100.619597,36.280286",
                        level: 3
                    },
                    {
                        id: "632523",
                        name: "贵德县",
                        center: "101.431856,36.040456",
                        level: 3
                    },
                    {
                        id: "632525",
                        name: "贵南县",
                        center: "100.74792,35.587085",
                        level: 3
                    },
                    {
                        id: "632522",
                        name: "同德县",
                        center: "100.579465,35.254492",
                        level: 3
                    },
                    {
                        id: "632524",
                        name: "兴海县",
                        center: "99.986963,35.58909",
                        level: 3
                    }
                ]
            },
            {
                id: "632800",
                name: "海西蒙古族藏族自治州",
                center: "97.370785,37.374663",
                level: 2,
                children: [
                    {
                        id: "632802",
                        name: "德令哈市",
                        center: "97.370143,37.374555",
                        level: 3
                    },
                    {
                        id: "632822",
                        name: "都兰县",
                        center: "98.089161,36.298553",
                        level: 3
                    },
                    {
                        id: "632823",
                        name: "天峻县",
                        center: "99.02078,37.29906",
                        level: 3
                    },
                    {
                        id: "632801",
                        name: "格尔木市",
                        center: "94.905777,36.401541",
                        level: 3
                    },
                    {
                        id: "632825",
                        name: "海西蒙古族藏族自治州直辖",
                        center: "95.357233,37.853631",
                        level: 3
                    },
                    {
                        id: "632821",
                        name: "乌兰县",
                        center: "98.479852,36.930389",
                        level: 3
                    },
                    {
                        id: "632803",
                        name: "茫崖市",
                        center: "90.855955,38.247117",
                        level: 3
                    }
                ]
            },
            {
                id: "630200",
                name: "海东市",
                center: "102.10327,36.502916",
                level: 2,
                children: [
                    {
                        id: "630202",
                        name: "乐都区",
                        center: "102.402431,36.480291",
                        level: 3
                    },
                    {
                        id: "630222",
                        name: "民和回族土族自治县",
                        center: "102.804209,36.329451",
                        level: 3
                    },
                    {
                        id: "630224",
                        name: "化隆回族自治县",
                        center: "102.262329,36.098322",
                        level: 3
                    },
                    {
                        id: "630225",
                        name: "循化撒拉族自治县",
                        center: "102.486534,35.847247",
                        level: 3
                    },
                    {
                        id: "630203",
                        name: "平安区",
                        center: "102.104295,36.502714",
                        level: 3
                    },
                    {
                        id: "630223",
                        name: "互助土族自治县",
                        center: "101.956734,36.83994",
                        level: 3
                    }
                ]
            },
            {
                id: "632600",
                name: "果洛藏族自治州",
                center: "100.242143,34.4736",
                level: 2,
                children: [
                    {
                        id: "632621",
                        name: "玛沁县",
                        center: "100.243531,34.473386",
                        level: 3
                    },
                    {
                        id: "632625",
                        name: "久治县",
                        center: "101.484884,33.430217",
                        level: 3
                    },
                    {
                        id: "632623",
                        name: "甘德县",
                        center: "99.902589,33.966987",
                        level: 3
                    },
                    {
                        id: "632624",
                        name: "达日县",
                        center: "99.651715,33.753259",
                        level: 3
                    },
                    {
                        id: "632622",
                        name: "班玛县",
                        center: "100.737955,32.931589",
                        level: 3
                    },
                    {
                        id: "632626",
                        name: "玛多县",
                        center: "98.211343,34.91528",
                        level: 3
                    }
                ]
            },
            {
                id: "632700",
                name: "玉树藏族自治州",
                center: "97.008522,33.004049",
                level: 2,
                children: [
                    {
                        id: "632724",
                        name: "治多县",
                        center: "95.616843,33.852322",
                        level: 3
                    },
                    {
                        id: "632726",
                        name: "曲麻莱县",
                        center: "95.800674,34.12654",
                        level: 3
                    },
                    {
                        id: "632722",
                        name: "杂多县",
                        center: "95.293423,32.891886",
                        level: 3
                    },
                    {
                        id: "632701",
                        name: "玉树市",
                        center: "97.008762,33.00393",
                        level: 3
                    },
                    {
                        id: "632723",
                        name: "称多县",
                        center: "97.110893,33.367884",
                        level: 3
                    },
                    {
                        id: "632725",
                        name: "囊谦县",
                        center: "96.479797,32.203206",
                        level: 3
                    }
                ]
            },
            {
                id: "632300",
                name: "黄南藏族自治州",
                center: "102.019988,35.517744",
                level: 2,
                children: [
                    {
                        id: "632322",
                        name: "尖扎县",
                        center: "102.031953,35.938205",
                        level: 3
                    },
                    {
                        id: "632321",
                        name: "同仁县",
                        center: "102.017604,35.516337",
                        level: 3
                    },
                    {
                        id: "632324",
                        name: "河南蒙古族自治县",
                        center: "101.611877,34.734522",
                        level: 3
                    },
                    {
                        id: "632323",
                        name: "泽库县",
                        center: "101.469343,35.036842",
                        level: 3
                    }
                ]
            },
            {
                id: "630100",
                name: "西宁市",
                center: "101.778916,36.623178",
                level: 2,
                children: [
                    {
                        id: "630123",
                        name: "湟源县",
                        center: "101.263435,36.684818",
                        level: 3
                    },
                    {
                        id: "630102",
                        name: "城东区",
                        center: "101.796095,36.616043",
                        level: 3
                    },
                    {
                        id: "630121",
                        name: "大通回族土族自治县",
                        center: "101.684183,36.931343",
                        level: 3
                    },
                    {
                        id: "630122",
                        name: "湟中区",
                        center: "101.569475,36.500419",
                        level: 3
                    },
                    {
                        id: "630103",
                        name: "城中区",
                        center: "101.784554,36.621181",
                        level: 3
                    },
                    {
                        id: "630104",
                        name: "城西区",
                        center: "101.763649,36.628323",
                        level: 3
                    },
                    {
                        id: "630105",
                        name: "城北区",
                        center: "101.761297,36.648448",
                        level: 3
                    }
                ]
            },
            {
                id: "632200",
                name: "海北藏族自治州",
                center: "100.901059,36.959435",
                level: 2,
                children: [
                    {
                        id: "632223",
                        name: "海晏县",
                        center: "100.90049,36.959542",
                        level: 3
                    },
                    {
                        id: "632221",
                        name: "门源回族自治县",
                        center: "101.618461,37.376627",
                        level: 3
                    },
                    {
                        id: "632224",
                        name: "刚察县",
                        center: "100.138417,37.326263",
                        level: 3
                    },
                    {
                        id: "632222",
                        name: "祁连县",
                        center: "100.249778,38.175409",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "640000",
        name: "宁夏回族自治区",
        center: "106.278179,38.46637",
        level: 1,
        children: [
            {
                id: "640500",
                name: "中卫市",
                center: "105.189568,37.514951",
                level: 2,
                children: [
                    {
                        id: "640521",
                        name: "中宁县",
                        center: "105.675784,37.489736",
                        level: 3
                    },
                    {
                        id: "640502",
                        name: "沙坡头区",
                        center: "105.190536,37.514564",
                        level: 3
                    },
                    {
                        id: "640522",
                        name: "海原县",
                        center: "105.647323,36.562007",
                        level: 3
                    }
                ]
            },
            {
                id: "640200",
                name: "石嘴山市",
                center: "106.376173,39.01333",
                level: 2,
                children: [
                    {
                        id: "640205",
                        name: "惠农区",
                        center: "106.775513,39.230094",
                        level: 3
                    },
                    {
                        id: "640221",
                        name: "平罗县",
                        center: "106.54489,38.90674",
                        level: 3
                    },
                    {
                        id: "640202",
                        name: "大武口区",
                        center: "106.376651,39.014158",
                        level: 3
                    }
                ]
            },
            {
                id: "640400",
                name: "固原市",
                center: "106.285241,36.004561",
                level: 2,
                children: [
                    {
                        id: "640424",
                        name: "泾源县",
                        center: "106.338674,35.49344",
                        level: 3
                    },
                    {
                        id: "640425",
                        name: "彭阳县",
                        center: "106.641512,35.849975",
                        level: 3
                    },
                    {
                        id: "640423",
                        name: "隆德县",
                        center: "106.12344,35.618234",
                        level: 3
                    },
                    {
                        id: "640422",
                        name: "西吉县",
                        center: "105.731801,35.965384",
                        level: 3
                    },
                    {
                        id: "640402",
                        name: "原州区",
                        center: "106.28477,36.005337",
                        level: 3
                    }
                ]
            },
            {
                id: "640300",
                name: "吴忠市",
                center: "106.199409,37.986165",
                level: 2,
                children: [
                    {
                        id: "640381",
                        name: "青铜峡市",
                        center: "106.075395,38.021509",
                        level: 3
                    },
                    {
                        id: "640303",
                        name: "红寺堡区",
                        center: "106.067315,37.421616",
                        level: 3
                    },
                    {
                        id: "640324",
                        name: "同心县",
                        center: "105.914764,36.9829",
                        level: 3
                    },
                    {
                        id: "640323",
                        name: "盐池县",
                        center: "107.40541,37.784222",
                        level: 3
                    },
                    {
                        id: "640302",
                        name: "利通区",
                        center: "106.199419,37.985967",
                        level: 3
                    }
                ]
            },
            {
                id: "640100",
                name: "银川市",
                center: "106.278179,38.46637",
                level: 2,
                children: [
                    {
                        id: "640104",
                        name: "兴庆区",
                        center: "106.278393,38.46747",
                        level: 3
                    },
                    {
                        id: "640105",
                        name: "西夏区",
                        center: "106.132116,38.492424",
                        level: 3
                    },
                    {
                        id: "640121",
                        name: "永宁县",
                        center: "106.253781,38.28043",
                        level: 3
                    },
                    {
                        id: "640122",
                        name: "贺兰县",
                        center: "106.345904,38.554563",
                        level: 3
                    },
                    {
                        id: "640106",
                        name: "金凤区",
                        center: "106.228486,38.477353",
                        level: 3
                    },
                    {
                        id: "640181",
                        name: "灵武市",
                        center: "106.334701,38.094058",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "650000",
        name: "新疆维吾尔自治区",
        center: "87.617733,43.792818",
        level: 1,
        children: [
            {
                id: "659005",
                name: "北屯市",
                center: "87.824932,47.353177",
                level: 2,
                children: [
                    {
                        id: "659005502",
                        name: "兵团一八七团",
                        center: "87.924291,47.240845",
                        level: 4
                    },
                    {
                        id: "659005503",
                        name: "兵团一八八团",
                        center: "87.708301,47.324817",
                        level: 4
                    }
                ]
            },
            {
                id: "659007",
                name: "双河市",
                center: "82.353656,44.840524",
                level: 2,
                children: [
                    {
                        id: "659007501",
                        name: "兵团八十一团",
                        center: "82.529215,44.770206",
                        level: 4
                    },
                    {
                        id: "659007502",
                        name: "兵团八十四团",
                        center: "82.092857,44.983276",
                        level: 4
                    },
                    {
                        id: "659007504",
                        name: "兵团八十六团",
                        center: "82.179768,44.855221",
                        level: 4
                    },
                    {
                        id: "659007505",
                        name: "兵团八十九团",
                        center: "82.375386,44.843212",
                        level: 4
                    },
                    {
                        id: "659007506",
                        name: "兵团九十团",
                        center: "82.599696,44.851632",
                        level: 4
                    }
                ]
            },
            {
                id: "659006",
                name: "铁门关市",
                center: "85.501218,41.827251",
                level: 2,
                children: [
                    {
                        id: "659006501",
                        name: "兵团二十九团",
                        center: "85.667787,41.787539",
                        level: 4
                    },
                    {
                        id: "659006502",
                        name: "农二师三十团",
                        center: "85.479656,41.823663",
                        level: 4
                    }
                ]
            },
            {
                id: "652700",
                name: "博尔塔拉蒙古自治州",
                center: "82.074778,44.903258",
                level: 2,
                children: [
                    {
                        id: "652723",
                        name: "温泉县",
                        center: "81.03099,44.973751",
                        level: 3
                    },
                    {
                        id: "652702",
                        name: "阿拉山口市",
                        center: "82.569389,45.16777",
                        level: 3
                    },
                    {
                        id: "652701",
                        name: "博乐市",
                        center: "82.072237,44.903087",
                        level: 3
                    },
                    {
                        id: "652722",
                        name: "精河县",
                        center: "82.892938,44.605645",
                        level: 3
                    }
                ]
            },
            {
                id: "653200",
                name: "和田地区",
                center: "79.92533,37.110687",
                level: 2,
                children: [
                    {
                        id: "653226",
                        name: "于田县",
                        center: "81.667845,36.854628",
                        level: 3
                    },
                    {
                        id: "653227",
                        name: "民丰县",
                        center: "82.692354,37.064909",
                        level: 3
                    },
                    {
                        id: "653201",
                        name: "和田市",
                        center: "79.927542,37.108944",
                        level: 3
                    },
                    {
                        id: "653224",
                        name: "洛浦县",
                        center: "80.184038,37.074377",
                        level: 3
                    },
                    {
                        id: "653221",
                        name: "和田县",
                        center: "79.81907,37.120031",
                        level: 3
                    },
                    {
                        id: "653225",
                        name: "策勒县",
                        center: "80.803572,37.001672",
                        level: 3
                    },
                    {
                        id: "653222",
                        name: "墨玉县",
                        center: "79.736629,37.271511",
                        level: 3
                    },
                    {
                        id: "653223",
                        name: "皮山县",
                        center: "78.282301,37.616332",
                        level: 3
                    }
                ]
            },
            {
                id: "654200",
                name: "塔城地区",
                center: "82.985732,46.746301",
                level: 2,
                children: [
                    {
                        id: "654225",
                        name: "裕民县",
                        center: "82.982157,46.202781",
                        level: 3
                    },
                    {
                        id: "654201",
                        name: "塔城市",
                        center: "82.983988,46.746281",
                        level: 3
                    },
                    {
                        id: "654226",
                        name: "和布克赛尔蒙古自治县",
                        center: "85.733551,46.793001",
                        level: 3
                    },
                    {
                        id: "654221",
                        name: "额敏县",
                        center: "83.622118,46.522555",
                        level: 3
                    },
                    {
                        id: "654224",
                        name: "托里县",
                        center: "83.60469,45.935863",
                        level: 3
                    },
                    {
                        id: "654223",
                        name: "沙湾县",
                        center: "85.622508,44.329544",
                        level: 3
                    },
                    {
                        id: "654202",
                        name: "乌苏市",
                        center: "84.677624,44.430115",
                        level: 3
                    }
                ]
            },
            {
                id: "659001",
                name: "石河子市",
                center: "86.041075,44.305886",
                level: 2,
                children: [
                    {
                        id: "659001001",
                        name: "新城街道",
                        center: "86.027643,44.29313",
                        level: 4
                    },
                    {
                        id: "659001002",
                        name: "向阳街道",
                        center: "86.05376,44.30704",
                        level: 4
                    },
                    {
                        id: "659001003",
                        name: "红山街道",
                        center: "86.05205,44.2962",
                        level: 4
                    },
                    {
                        id: "659001004",
                        name: "老街街道",
                        center: "86.03158,44.31187",
                        level: 4
                    },
                    {
                        id: "659001005",
                        name: "东城街道",
                        center: "86.09192,44.31004",
                        level: 4
                    },
                    {
                        id: "659001100",
                        name: "北泉镇",
                        center: "86.016731,44.333841",
                        level: 4
                    },
                    {
                        id: "659001200",
                        name: "石河子镇",
                        center: "86.0356,44.28642",
                        level: 4
                    },
                    {
                        id: "659001500",
                        name: "兵团一五二团",
                        center: "86.045721,44.192483",
                        level: 4
                    }
                ]
            },
            {
                id: "654300",
                name: "阿勒泰地区",
                center: "88.13963,47.848393",
                level: 2,
                children: [
                    {
                        id: "654326",
                        name: "吉木乃县",
                        center: "85.876064,47.434633",
                        level: 3
                    },
                    {
                        id: "654325",
                        name: "青河县",
                        center: "90.381561,46.672446",
                        level: 3
                    },
                    {
                        id: "654322",
                        name: "富蕴县",
                        center: "89.524993,46.993106",
                        level: 3
                    },
                    {
                        id: "654324",
                        name: "哈巴河县",
                        center: "86.418964,48.059284",
                        level: 3
                    },
                    {
                        id: "654321",
                        name: "布尔津县",
                        center: "86.86186,47.70453",
                        level: 3
                    },
                    {
                        id: "654323",
                        name: "福海县",
                        center: "87.494569,47.113128",
                        level: 3
                    },
                    {
                        id: "654301",
                        name: "阿勒泰市",
                        center: "88.138743,47.848911",
                        level: 3
                    }
                ]
            },
            {
                id: "659008",
                name: "可克达拉市",
                center: "80.63579,43.6832",
                level: 2,
                children: [
                    {
                        id: "659008502",
                        name: "兵团六十七团",
                        center: "80.636904,43.851678",
                        level: 4
                    },
                    {
                        id: "659008503",
                        name: "兵团六十八团",
                        center: "80.916121,43.877916",
                        level: 4
                    },
                    {
                        id: "659008507",
                        name: "兵团六十三团",
                        center: "80.551098,43.958882",
                        level: 4
                    },
                    {
                        id: "659008508",
                        name: "兵团六十四团",
                        center: "80.650274,44.033404",
                        level: 4
                    },
                    {
                        id: "659008509",
                        name: "兵团六十六团",
                        center: "81.02155,43.948887",
                        level: 4
                    }
                ]
            },
            {
                id: "650200",
                name: "克拉玛依市",
                center: "84.873946,45.595886",
                level: 2,
                children: [
                    {
                        id: "650204",
                        name: "白碱滩区",
                        center: "85.129882,45.689021",
                        level: 3
                    },
                    {
                        id: "650203",
                        name: "克拉玛依区",
                        center: "84.868918,45.600477",
                        level: 3
                    },
                    {
                        id: "650205",
                        name: "乌尔禾区",
                        center: "85.697767,46.08776",
                        level: 3
                    },
                    {
                        id: "650202",
                        name: "独山子区",
                        center: "84.882267,44.327207",
                        level: 3
                    }
                ]
            },
            {
                id: "659009",
                name: "昆玉市",
                center: "79.287372,37.207994",
                level: 2,
                children: [
                    {
                        id: "659009400",
                        name: "兵团一牧场",
                        center: "81.042139,36.294576",
                        level: 4
                    },
                    {
                        id: "659009401",
                        name: "兵团皮山农场",
                        center: "78.485135,37.688296",
                        level: 4
                    },
                    {
                        id: "659009501",
                        name: "兵团二二四团",
                        center: "79.312714,37.302847",
                        level: 4
                    }
                ]
            },
            {
                id: "652300",
                name: "昌吉回族自治州",
                center: "87.304012,44.014577",
                level: 2,
                children: [
                    {
                        id: "652323",
                        name: "呼图壁县",
                        center: "86.888613,44.189342",
                        level: 3
                    },
                    {
                        id: "652325",
                        name: "奇台县",
                        center: "89.591437,44.021996",
                        level: 3
                    },
                    {
                        id: "652324",
                        name: "玛纳斯县",
                        center: "86.217687,44.305625",
                        level: 3
                    },
                    {
                        id: "652302",
                        name: "阜康市",
                        center: "87.98384,44.152153",
                        level: 3
                    },
                    {
                        id: "652328",
                        name: "木垒哈萨克自治县",
                        center: "90.282833,43.832442",
                        level: 3
                    },
                    {
                        id: "652301",
                        name: "昌吉市",
                        center: "87.304112,44.013183",
                        level: 3
                    },
                    {
                        id: "652327",
                        name: "吉木萨尔县",
                        center: "89.181288,43.997162",
                        level: 3
                    }
                ]
            },
            {
                id: "650100",
                name: "乌鲁木齐市",
                center: "87.617733,43.792818",
                level: 2,
                children: [
                    {
                        id: "650107",
                        name: "达坂城区",
                        center: "88.30994,43.36181",
                        level: 3
                    },
                    {
                        id: "650102",
                        name: "天山区",
                        center: "87.620116,43.796428",
                        level: 3
                    },
                    {
                        id: "650105",
                        name: "水磨沟区",
                        center: "87.613093,43.816747",
                        level: 3
                    },
                    {
                        id: "650109",
                        name: "米东区",
                        center: "87.691801,43.960982",
                        level: 3
                    },
                    {
                        id: "650106",
                        name: "头屯河区",
                        center: "87.425823,43.876053",
                        level: 3
                    },
                    {
                        id: "650121",
                        name: "乌鲁木齐县",
                        center: "87.505603,43.982546",
                        level: 3
                    },
                    {
                        id: "650103",
                        name: "沙依巴克区",
                        center: "87.596639,43.788872",
                        level: 3
                    },
                    {
                        id: "650104",
                        name: "新市区",
                        center: "87.560653,43.870882",
                        level: 3
                    }
                ]
            },
            {
                id: "652800",
                name: "巴音郭楞蒙古自治州",
                center: "86.150969,41.768552",
                level: 2,
                children: [
                    {
                        id: "652827",
                        name: "和静县",
                        center: "86.391067,42.31716",
                        level: 3
                    },
                    {
                        id: "652828",
                        name: "和硕县",
                        center: "86.864947,42.268863",
                        level: 3
                    },
                    {
                        id: "652829",
                        name: "博湖县",
                        center: "86.631576,41.980166",
                        level: 3
                    },
                    {
                        id: "652826",
                        name: "焉耆回族自治县",
                        center: "86.5698,42.064349",
                        level: 3
                    },
                    {
                        id: "652824",
                        name: "若羌县",
                        center: "88.168807,39.023807",
                        level: 3
                    },
                    {
                        id: "652825",
                        name: "且末县",
                        center: "85.532629,38.138562",
                        level: 3
                    },
                    {
                        id: "652801",
                        name: "库尔勒市",
                        center: "86.145948,41.763122",
                        level: 3
                    },
                    {
                        id: "652822",
                        name: "轮台县",
                        center: "84.248542,41.781266",
                        level: 3
                    },
                    {
                        id: "652823",
                        name: "尉犁县",
                        center: "86.263412,41.337428",
                        level: 3
                    }
                ]
            },
            {
                id: "659002",
                name: "阿拉尔市",
                center: "81.285884,40.541914",
                level: 2,
                children: [
                    {
                        id: "659002001",
                        name: "金银川路街道",
                        center: "81.275289,40.55244",
                        level: 4
                    },
                    {
                        id: "659002002",
                        name: "幸福路街道",
                        center: "81.28612,40.54718",
                        level: 4
                    },
                    {
                        id: "659002003",
                        name: "青松路街道",
                        center: "81.247206,40.54208",
                        level: 4
                    },
                    {
                        id: "659002004",
                        name: "南口街道",
                        center: "81.307073,40.508659",
                        level: 4
                    },
                    {
                        id: "659002200",
                        name: "托喀依乡",
                        center: "81.120055,40.538677",
                        level: 4
                    },
                    {
                        id: "659002500",
                        name: "兵团七团",
                        center: "80.680372,40.591114",
                        level: 4
                    },
                    {
                        id: "659002501",
                        name: "兵团八团",
                        center: "80.79755,40.630222",
                        level: 4
                    },
                    {
                        id: "659002503",
                        name: "兵团十团",
                        center: "81.219975,40.608592",
                        level: 4
                    },
                    {
                        id: "659002505",
                        name: "兵团十二团",
                        center: "81.150747,40.459493",
                        level: 4
                    },
                    {
                        id: "659002507",
                        name: "兵团十四团",
                        center: "81.77579,40.69079",
                        level: 4
                    },
                    {
                        id: "659002508",
                        name: "兵团五团",
                        center: "80.854995,41.30389",
                        level: 4
                    },
                    {
                        id: "659002509",
                        name: "兵团十六团",
                        center: "80.840349,40.495478",
                        level: 4
                    },
                    {
                        id: "659002511",
                        name: "兵团第一师水利水电工程处",
                        center: "81.168871,40.678289",
                        level: 4
                    },
                    {
                        id: "659002513",
                        name: "阿拉尔农场",
                        center: "81.090654,40.58934",
                        level: 4
                    },
                    {
                        id: "659002514",
                        name: "兵团第一师幸福农场",
                        center: "81.040828,40.64542",
                        level: 4
                    },
                    {
                        id: "659002901",
                        name: "兵团二团",
                        center: "79.885588,40.550021",
                        level: 4
                    },
                    {
                        id: "659002902",
                        name: "兵团农一师沙井子水利管理处",
                        center: "79.795101,40.460603",
                        level: 4
                    },
                    {
                        id: "659002964",
                        name: "兵团九团",
                        center: "80.926653,40.555784",
                        level: 4
                    },
                    {
                        id: "659002966",
                        name: "兵团十一团",
                        center: "81.642427,40.648555",
                        level: 4
                    },
                    {
                        id: "659002967",
                        name: "兵团十三团",
                        center: "81.298635,40.667675",
                        level: 4
                    },
                    {
                        id: "659002968",
                        name: "兵团十五团",
                        center: "81.618197,40.683936",
                        level: 4
                    }
                ]
            },
            {
                id: "659004",
                name: "五家渠市",
                center: "87.526884,44.167401",
                level: 2,
                children: [
                    {
                        id: "659004001",
                        name: "军垦路街道",
                        center: "87.52007,44.16547",
                        level: 4
                    },
                    {
                        id: "659004002",
                        name: "青湖路街道",
                        center: "87.53404,44.16902",
                        level: 4
                    },
                    {
                        id: "659004003",
                        name: "人民路街道",
                        center: "87.55869,44.13562",
                        level: 4
                    },
                    {
                        id: "659004500",
                        name: "兵团一零一团",
                        center: "87.509188,44.048099",
                        level: 4
                    },
                    {
                        id: "659004960",
                        name: "蔡家湖镇",
                        center: "87.5394,44.40734",
                        level: 4
                    },
                    {
                        id: "659004961",
                        name: "梧桐镇",
                        center: "87.65121,44.26263",
                        level: 4
                    }
                ]
            },
            {
                id: "654000",
                name: "伊犁哈萨克自治州",
                center: "81.317946,43.92186",
                level: 2,
                children: [
                    {
                        id: "654003",
                        name: "奎屯市",
                        center: "84.901602,44.423445",
                        level: 3
                    },
                    {
                        id: "654021",
                        name: "伊宁县",
                        center: "81.524671,43.977876",
                        level: 3
                    },
                    {
                        id: "654025",
                        name: "新源县",
                        center: "83.258493,43.434249",
                        level: 3
                    },
                    {
                        id: "654028",
                        name: "尼勒克县",
                        center: "82.504119,43.789737",
                        level: 3
                    },
                    {
                        id: "654024",
                        name: "巩留县",
                        center: "82.227044,43.481618",
                        level: 3
                    },
                    {
                        id: "654027",
                        name: "特克斯县",
                        center: "81.840058,43.214861",
                        level: 3
                    },
                    {
                        id: "654026",
                        name: "昭苏县",
                        center: "81.126029,43.157765",
                        level: 3
                    },
                    {
                        id: "654002",
                        name: "伊宁市",
                        center: "81.316343,43.922209",
                        level: 3
                    },
                    {
                        id: "654022",
                        name: "察布查尔锡伯自治县",
                        center: "81.150874,43.838883",
                        level: 3
                    },
                    {
                        id: "654023",
                        name: "霍城县",
                        center: "80.872508,44.049912",
                        level: 3
                    },
                    {
                        id: "654004",
                        name: "霍尔果斯市",
                        center: "80.420759,44.201669",
                        level: 3
                    }
                ]
            },
            {
                id: "653000",
                name: "克孜勒苏柯尔克孜自治州",
                center: "76.172825,39.713431",
                level: 2,
                children: [
                    {
                        id: "653024",
                        name: "乌恰县",
                        center: "75.25969,39.716633",
                        level: 3
                    },
                    {
                        id: "653001",
                        name: "阿图什市",
                        center: "76.173939,39.712898",
                        level: 3
                    },
                    {
                        id: "653022",
                        name: "阿克陶县",
                        center: "75.945159,39.147079",
                        level: 3
                    },
                    {
                        id: "653023",
                        name: "阿合奇县",
                        center: "78.450164,40.937567",
                        level: 3
                    }
                ]
            },
            {
                id: "653100",
                name: "喀什地区",
                center: "75.989138,39.467664",
                level: 2,
                children: [
                    {
                        id: "653129",
                        name: "伽师县",
                        center: "76.741982,39.494325",
                        level: 3
                    },
                    {
                        id: "653122",
                        name: "疏勒县",
                        center: "76.053653,39.399461",
                        level: 3
                    },
                    {
                        id: "653127",
                        name: "麦盖提县",
                        center: "77.651538,38.903384",
                        level: 3
                    },
                    {
                        id: "653128",
                        name: "岳普湖县",
                        center: "76.7724,39.235248",
                        level: 3
                    },
                    {
                        id: "653126",
                        name: "叶城县",
                        center: "77.420353,37.884679",
                        level: 3
                    },
                    {
                        id: "653131",
                        name: "塔什库尔干塔吉克自治县",
                        center: "75.228068,37.775437",
                        level: 3
                    },
                    {
                        id: "653125",
                        name: "莎车县",
                        center: "77.248884,38.414499",
                        level: 3
                    },
                    {
                        id: "653124",
                        name: "泽普县",
                        center: "77.273593,38.191217",
                        level: 3
                    },
                    {
                        id: "653130",
                        name: "巴楚县",
                        center: "78.55041,39.783479",
                        level: 3
                    },
                    {
                        id: "653121",
                        name: "疏附县",
                        center: "75.863075,39.378306",
                        level: 3
                    },
                    {
                        id: "653123",
                        name: "英吉沙县",
                        center: "76.174292,38.929839",
                        level: 3
                    },
                    {
                        id: "653101",
                        name: "喀什市",
                        center: "75.98838,39.467861",
                        level: 3
                    }
                ]
            },
            {
                id: "659003",
                name: "图木舒克市",
                center: "79.077978,39.867316",
                level: 2,
                children: [
                    {
                        id: "659003001",
                        name: "齐干却勒街道",
                        center: "79.08711,39.86586",
                        level: 4
                    },
                    {
                        id: "659003002",
                        name: "前海街道",
                        center: "79.06544,39.86089",
                        level: 4
                    },
                    {
                        id: "659003003",
                        name: "永安坝街道",
                        center: "79.021036,39.846304",
                        level: 4
                    },
                    {
                        id: "659003504",
                        name: "兵团四十四团",
                        center: "79.133064,39.792584",
                        level: 4
                    },
                    {
                        id: "659003509",
                        name: "兵团四十九团",
                        center: "78.9136,39.701601",
                        level: 4
                    },
                    {
                        id: "659003513",
                        name: "兵团五十三团",
                        center: "79.43649,40.020138",
                        level: 4
                    },
                    {
                        id: "659003960",
                        name: "喀拉拜勒镇",
                        center: "78.741502,39.724144",
                        level: 4
                    },
                    {
                        id: "659003964",
                        name: "兵团五十一团",
                        center: "79.147267,39.982803",
                        level: 4
                    },
                    {
                        id: "659003965",
                        name: "兵团五十二团",
                        center: "79.19821,39.874829",
                        level: 4
                    },
                    {
                        id: "659003966",
                        name: "兵团五十团",
                        center: "79.439291,39.921107",
                        level: 4
                    }
                ]
            },
            {
                id: "652900",
                name: "阿克苏地区",
                center: "80.265068,41.170712",
                level: 2,
                children: [
                    {
                        id: "652926",
                        name: "拜城县",
                        center: "81.869881,41.796101",
                        level: 3
                    },
                    {
                        id: "652923",
                        name: "库车县",
                        center: "82.96304,41.717141",
                        level: 3
                    },
                    {
                        id: "652925",
                        name: "新和县",
                        center: "82.610828,41.551176",
                        level: 3
                    },
                    {
                        id: "652922",
                        name: "温宿县",
                        center: "80.243273,41.272995",
                        level: 3
                    },
                    {
                        id: "652901",
                        name: "阿克苏市",
                        center: "80.2629,41.171272",
                        level: 3
                    },
                    {
                        id: "652924",
                        name: "沙雅县",
                        center: "82.78077,41.226268",
                        level: 3
                    },
                    {
                        id: "652929",
                        name: "柯坪县",
                        center: "79.04785,40.50624",
                        level: 3
                    },
                    {
                        id: "652928",
                        name: "阿瓦提县",
                        center: "80.378426,40.638422",
                        level: 3
                    },
                    {
                        id: "652927",
                        name: "乌什县",
                        center: "79.230805,41.21587",
                        level: 3
                    }
                ]
            },
            {
                id: "650500",
                name: "哈密市",
                center: "93.51316,42.833248",
                level: 2,
                children: [
                    {
                        id: "650521",
                        name: "巴里坤哈萨克自治县",
                        center: "93.021795,43.599032",
                        level: 3
                    },
                    {
                        id: "650502",
                        name: "伊州区",
                        center: "93.509174,42.833888",
                        level: 3
                    },
                    {
                        id: "650522",
                        name: "伊吾县",
                        center: "94.692773,43.252012",
                        level: 3
                    }
                ]
            },
            {
                id: "650400",
                name: "吐鲁番市",
                center: "89.184078,42.947613",
                level: 2,
                children: [
                    {
                        id: "650402",
                        name: "高昌区",
                        center: "89.182324,42.947627",
                        level: 3
                    },
                    {
                        id: "650421",
                        name: "鄯善县",
                        center: "90.212692,42.865503",
                        level: 3
                    },
                    {
                        id: "650422",
                        name: "托克逊县",
                        center: "88.655771,42.793536",
                        level: 3
                    }
                ]
            }
        ]
    },
    {
        id: "710000",
        name: "台湾省",
        center: "121.509062,25.044332",
        level: 1
    },
    {
        id: "810000",
        name: "香港特别行政区",
        center: "114.173355,22.320048",
        level: 1,
        children: [
            {
                id: "810005",
                name: "油尖旺区",
                center: "114.173347,22.311632",
                level: 3
            },
            {
                id: "810012",
                name: "元朗区",
                center: "114.032528,22.44132",
                level: 3
            },
            {
                id: "810004",
                name: "南区",
                center: "114.160023,22.245811",
                level: 3
            },
            {
                id: "810006",
                name: "深水埗区",
                center: "114.163349,22.333775",
                level: 3
            },
            {
                id: "810008",
                name: "黄大仙区",
                center: "114.203985,22.336112",
                level: 3
            },
            {
                id: "810007",
                name: "九龙城区",
                center: "114.193047,22.312373",
                level: 3
            },
            {
                id: "810018",
                name: "离岛区",
                center: "113.946059,22.286371",
                level: 3
            },
            {
                id: "810003",
                name: "东区",
                center: "114.225965,22.279779",
                level: 3
            },
            {
                id: "810013",
                name: "北区",
                center: "114.147404,22.496143",
                level: 3
            },
            {
                id: "810001",
                name: "中西区",
                center: "114.154334,22.281931",
                level: 3
            },
            {
                id: "810014",
                name: "大埔区",
                center: "114.171713,22.44573",
                level: 3
            },
            {
                id: "810016",
                name: "沙田区",
                center: "114.195126,22.379715",
                level: 3
            },
            {
                id: "810015",
                name: "西贡区",
                center: "114.264813,22.314203",
                level: 3
            },
            {
                id: "810009",
                name: "观塘区",
                center: "114.214083,22.320679",
                level: 3
            },
            {
                id: "810011",
                name: "屯门区",
                center: "113.976308,22.393896",
                level: 3
            },
            {
                id: "810002",
                name: "湾仔区",
                center: "114.18299,22.276345",
                level: 3
            },
            {
                id: "810010",
                name: "荃湾区",
                center: "114.121234,22.368458",
                level: 3
            },
            {
                id: "810017",
                name: "葵青区",
                center: "114.13918,22.363908",
                level: 3
            }
        ]
    },
    {
        id: "820000",
        name: "澳门特别行政区",
        center: "113.54909,22.198951",
        level: 1,
        children: [
            {
                id: "820004",
                name: "大堂区",
                center: "113.55374,22.188119",
                level: 3
            },
            {
                id: "820001",
                name: "花地玛堂区",
                center: "113.552965,22.207882",
                level: 3
            },
            {
                id: "820007",
                name: "路凼填海区",
                center: "113.56925,22.136546",
                level: 3
            },
            {
                id: "820002",
                name: "花王堂区",
                center: "113.549052,22.199175",
                level: 3
            },
            {
                id: "820006",
                name: "嘉模堂区",
                center: "113.558783,22.154124",
                level: 3
            },
            {
                id: "820005",
                name: "风顺堂区",
                center: "113.54167,22.187778",
                level: 3
            },
            {
                id: "820003",
                name: "望德堂区",
                center: "113.550252,22.193791",
                level: 3
            },
            {
                id: "820008",
                name: "圣方济各堂区",
                center: "113.559954,22.124049",
                level: 3
            }
        ]
    },
    {
        id: "900000",
        name: "外国",
        center: "0,0",
        level: 1
    }
];
export default list;
