import { computed, defineComponent, ref, unref, reactive } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { loginValidate } from "@/hooks/component/loginValidate";
import { getScaletypeList, registerWithCorp } from "@/apis";
import { trim } from "@/utils";
import { globalStorage } from "@/utils/storage/base";
import pctJson from "../../../modules/register/assets/json";
import industry from "../../../modules/register/assets/js/industry";
export default defineComponent({
    name: "AuthCorpCreate",
    components: {},
    setup() {
        const { currentRoute, push } = useRouter();
        const form = reactive({
            corpname: "",
            industry: "",
            industryName: "",
            scaletype: "",
            scaletypeName: "",
            region: "",
            regionName: "",
            recommendcode: "",
            mobile: "",
            token: "",
            password: "",
            wechatcode: "",
            from: "WeChat"
        });
        // 人员规模是否展示
        const scaletypeVisible = ref(false);
        // 人员规模列表
        const scaletypeList = ref([]);
        let scaletype = [];
        // 所属行业是否展示
        const industryVisible = ref(false);
        // 所属行业列表
        const industryList = ref([]);
        industryList.value = industry;
        // 所属地区是否展示
        const regionVisible = ref(false);
        // 所属地区列表
        const regionList = ref([]);
        regionList.value = pctJson;
        // 已加入公司提示
        const corpTip = ref("");
        const corpDisabled = computed(() => form.corpname !== "" && form.scaletype !== "" && form.region !== "" && form.industryName !== "");
        const corpBtnType = computed(() => corpDisabled.value ? "primary" : "default");
        const corps = computed(() => globalStorage.getFormLocal("select_corps")
            ? JSON.parse(globalStorage.getFormLocal("select_corps"))
            : []);
        // 页面传值
        const mobile = computed(() => unref(currentRoute).query.mobile ?? "");
        const wechatcode = globalStorage.getFormLocal("login_wechat_code") ?? '';
        const haveuser = computed(() => unref(currentRoute).params.haveuser === "true");
        const token = computed(() => unref(currentRoute).params.token ?? "");
        const password = computed(() => unref(currentRoute).params.password ?? "");
        form.mobile = mobile.value;
        form.wechatcode = wechatcode;
        form.token = token.value;
        form.password = password.value;
        // 获取行业选择列表
        async function getScaletypeInfo() {
            const res = await getScaletypeList();
            scaletype = [...res];
            const scaletypeArr = [];
            // vant单行只支持一维数组 恰好后台返回的数据是从0开始的
            res.map((item) => {
                scaletypeArr[item.EnumValue] = item.Description;
            });
            scaletypeList.value = scaletypeArr;
        }
        // 创建企业
        async function handleCorpCreate(values) {
            const res = await registerWithCorp(form);
            if (res.errcode === 0) {
                // 清空code码 防止返回页面报失效
                globalStorage.setToLocal("login_wechat_code", "");
                push({
                    name: "AuthLogin",
                    query: {
                        mobile: mobile.value,
                        from: "register",
                    },
                    params: {
                        enginecode: res.enginecode,
                        from: "register",
                    },
                });
            }
            else {
                Toast(res.errmsg);
            }
        }
        // 确认人员规模
        function confirmScaletype(value) {
            form.scaletypeName = value;
            form.scaletype = (scaletype.find((item) => item.Description === value) || {}).EnumValue;
            scaletypeVisible.value = false;
        }
        // 确认地区
        function confirmRegion(value) {
            form.regionName = `${value[0].name} ${value[1].name} ${value[2].name}`;
            form.region = value[2].id;
            regionVisible.value = false;
        }
        // 确认行业
        function confirmIndustry(value) {
            form.industryName = value[1]
                ? `${value[0].label} / ${value[1].label}`
                : value[0].label;
            form.industry = value[1] ? value[1].label : value[0].label;
            industryVisible.value = false;
        }
        // 登录
        function jumpLogin() {
            push({
                name: "AuthLogin",
                query: {
                    mobile: trim(form.mobile),
                    from: "corp",
                },
            });
        }
        // 获取行业选择列表
        getScaletypeInfo();
        if (haveuser.value && corps.value.length > 0) {
            corps.value.map((item, index) => {
                if (index < 3) {
                    corpTip.value += `${item.corpname}、`;
                }
            });
            corpTip.value = corpTip.value.substring(0, corpTip.value.length - 1);
            corpTip.value = `你已加入${corpTip.value}等${corps.value.length}个企业，可直接`;
        }
        // 解决微信浏览器的键盘回弹问题
        const { inputSmooth } = loginValidate();
        inputSmooth();
        return {
            form,
            scaletypeVisible,
            scaletypeList,
            industryVisible,
            industryList,
            regionList,
            regionVisible,
            corpDisabled,
            corpBtnType,
            haveuser,
            corpTip,
            corps,
            handleCorpCreate,
            confirmScaletype,
            confirmRegion,
            confirmIndustry,
            jumpLogin,
        };
    },
});
